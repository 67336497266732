
import { defineComponent } from 'vue';
import { getModule } from 'vuex-module-decorators';
import readXlsxFile from 'read-excel-file';
import Icon from "../../components/Icon.vue";
import { mdiAlertCircle, mdiAlertCircleOutline, mdiPencilCircleOutline, mdiPlusCircleOutline } from '@mdi/js';
import { ILocation, ILocationListDisplay } from '../../store/interfaces/ILocation';
import LocationState, { ILocationState } from '../../store/modules/locations';
import RoomState, { IRoomState } from '../../store/modules/rooms';
import { IRoom, EnableRoomSettings, EnableRoomLocationSettings, IRoomListDisplay } from '../../store/interfaces/IRoom';
import writeXlsxFile from 'write-excel-file';

let locationState = {} as ILocationState;
let roomState = {} as IRoomState;

export default defineComponent({
    name: 'RoomsImport',
    data() {
        return {
            locations: [] as ILocationListDisplay[],
            rooms: [] as IRoomListDisplay[],
            roomsLoading: false,
            roomsImporting: false,
            roomsUploading: false,
            roomsToBeCreated: [] as any[],
            roomCount: 0 as number,
            fileName: '' as string,
            fileRoomCount: 0,
            fileRoomCreateCount: 0,
            fileRoomUpdateCount: 0,
            fileRoomErrors: 0,
            errorRooms: [] as any[],
            errorsCount: 0,
            totalRoomsCount:0,
            showCounts: false,
            showProgress: false,
            roomUploadProgressPercentage: '0',
            roomsCreatedCount: 0,
            roomsUpdatedCount: 0,
            locationsCreatedCount: 0,
            subLocationsCreatedCount: 0,
            mdiAlertCircle,
            mdiAlertCircleOutline,
            mdiPencilCircleOutline,
            mdiPlusCircleOutline,
            noImportText: "Feature not available in organization's plan. Setting will not be populated.", 
            roomColumns: [
                {
                    columnName: 'ROOM ID',
                    allowedValues: '',
                    required: 'No'
                },
                {
                    columnName: 'ROOM NAME',
                    allowedValues: '',
                    required: 'Yes'
                },
                {
                    columnName: 'ROOM CODE',
                    allowedValues: '',
                    required: 'No'
                },
                {
                    columnName: 'ENABLE MIRRORING',
                    allowedValues: 'Y/N',
                    required: 'No'
                },
                {
                    columnName: 'ENABLE SIGNAGE',
                    allowedValues: 'Y/N',
                    required: 'No'
                },
                {
                    columnName: 'ENABLE NATIVE RESOLUTION',
                    allowedValues: 'Y/N',
                    required: 'No'
                },
                {
                    columnName: 'SHOW CONNECTION INFO',
                    allowedValues: 'Y/N',
                    required: 'No'
                },
                {
                    columnName: 'CONNECTION LOCATION',
                    allowedValues: 'Top Left/Top Right/Bottom Left/Bottom Right',
                    required: 'No'
                },
                {
                    columnName: 'SHOW DATE AND TIME ON SIGNAGE',
                    allowedValues: 'Y/N',
                    required: 'No'
                },
                {
                    columnName: 'DATE AND TIME LOCATION',
                    allowedValues: 'Top Left/Top Right/Bottom Left/Bottom Right',
                    required: 'No'
                },
                {
                    columnName: 'ENABLE ALERTS',
                    allowedValues: 'Y/N',
                    required: 'No'
                },
                {
                    columnName: 'ENABLE MODERATOR CONTROLS',
                    allowedValues: 'Y/N',
                    required: 'No'
                },
                {
                    columnName: 'MODERATOR CONNECTION TYPE',
                    allowedValues: 'One Time/On Screen/Custom',
                    required: 'No'
                },
                {
                    columnName: 'MODERATOR PASSWORD',
                    allowedValues: '',
                    required: 'No'
                },
                {
                    columnName: 'LOCATION NAME',
                    allowedValues: '',
                    required: 'No'
                },
                {
                    columnName: 'SUB LOCATION NAME',
                    allowedValues: '',
                    required: 'No'
                }
            ]
        }
    },

    components: {
        Icon   
    },

    props: [
        'organization',
        'hasAlerts',
        'hasSignage',
        'hasModeratorControls'
    ],

    emits: ['import-complete'],

    methods: {
        formatConnectionPosition(position: string) {
            if(!position) { return undefined; }

            if(position.toLowerCase() == 'top left') {
                return 'topLeft';
            }

            if(position.toLowerCase() == 'top right') {
                return 'topRight';
            }

            if(position.toLowerCase() == 'bottom left') {
                return 'bottomLeft';
            }

            if(position.toLowerCase() == 'bottom right') {
                return 'bottomRight';
            }

            if(position.toLowerCase() == 'topleft') {
                return 'Top Left';
            }

            if(position.toLowerCase() == 'topright') {
                return 'Top Right';
            }

            if(position.toLowerCase() == 'bottomleft') {
                return 'Bottom Left';
            }

            if(position.toLowerCase() == 'bottomright') {
                return 'Bottom Right';
            }

            return undefined;
        },

        formatModeratorType(type: string) {
            if(!type) { return undefined; }

            if(type.toLowerCase() == 'one time') {
                return 'onScreenOneTime';
            }

            if(type.toLowerCase() == 'on screen') {
                return 'onScreen';
            }

            if(type.toLowerCase() == 'custom') {
                return 'password';
            }

            if(type.toLowerCase() == 'onscreenonetime') {
                return 'One Time';
            }

            if(type.toLowerCase() == 'onscreen') {
                return 'On Screen';
            }

            if(type.toLowerCase() == 'password') {
                return 'Custom';
            }

            return undefined;
        },

        convertYNToBoolean(value: string) {
            if(!value) { return undefined; }

            if(value.toLowerCase() == 'y') {
                return true;
            }

            if(value.toLowerCase() == 'n') {
                return false;
            }
            
            return undefined;
        },

        convertYNToEnableRoomSettings(value: string, orgValue: any) {
            if(!value) { return undefined; }

            if(this.convertYNToBoolean(value) == orgValue) {
                return EnableRoomSettings.useOrgSetting;
            }
            
            if(value.toLowerCase() == 'y') {
                return EnableRoomSettings.true;
            }

            if(value.toLowerCase() == 'n') {
                return EnableRoomSettings.false;
            }

            return undefined;
        },

        convertPositionToEnableRoomLocationSettings(position: string, orgValue: any) {
            if(!position) { return undefined; }

            if(this.formatConnectionPosition(position) == orgValue) {
                return EnableRoomLocationSettings.useOrgSetting;
            }

            if(position.toLowerCase() == 'top left') {
                return EnableRoomLocationSettings.topLeft;
            }

            if(position.toLowerCase() == 'top right') {
                return EnableRoomLocationSettings.topRight;
            }

            if(position.toLowerCase() == 'bottom left') {
                return EnableRoomLocationSettings.bottomLeft;
            }

            if(position.toLowerCase() == 'bottom right') {
                return EnableRoomLocationSettings.bottomRight;
            }

            if(position.toLowerCase() == 'topleft') {
                return EnableRoomLocationSettings.topLeft;
            }

            if(position.toLowerCase() == 'topright') {
                return EnableRoomLocationSettings.topRight;
            }

            if(position.toLowerCase() == 'bottomleft') {
                return EnableRoomLocationSettings.bottomLeft;
            }

            if(position.toLowerCase() == 'bottomright') {
                return EnableRoomLocationSettings.bottomRight;
            }

            return undefined;
        },

        convertEnableRoomLocationSettingsToPosition(position: string, orgValue: any) {
            if(!position) { return undefined; }

            if(position === '4') {
                return this.formatConnectionPosition(orgValue);
            }

            if(position === '0') {
                return 'Top Left';
            }

            if(position === '1') {
                return 'Top Right';
            }

            if(position === '2') {
                return 'Bottom Left';
            }

            if(position === '3') {
                return 'Bottom Right';
            }

            return undefined;
        },
        
        async loadLocations() {
            const response = await locationState.fetchLocationDisplayList({pageIndex:1, pageSize: 10000, organizationId: this.organization.id});
            this.locations = (response && response.success && response.data && response.data.data) ? response.data.data : [] as ILocationListDisplay[];
        },

        async loadRooms() {
            await roomState.fetchRoomDisplayList({pageIndex:1, pageSize:10000, organizationId:this.organization.id}).then((storeRes: any) => {
                this.rooms = storeRes.data && storeRes.data.data ? storeRes.data.data as IRoomListDisplay[] : [] as IRoomListDisplay[];
            })
        },

        clearFile() {
            this.roomsToBeCreated = [];
            this.fileRoomCount = 0;
            this.fileRoomCreateCount = 0;
            this.fileRoomUpdateCount = 0;
            this.fileName = '';
            this.showCounts = false;
            this.errorsCount = 0;
        },

        async onFileChange(file: File) {
            this.roomsImporting = true;
            this.roomsToBeCreated = [];
            this.fileRoomCount = 0;
            this.fileRoomCreateCount = 0;
            this.fileRoomUpdateCount = 0;
            this.fileName = file.name;
            this.fileRoomErrors = 0;
            this.showCounts = false;

            const schema = {
                'ROOM ID' : {
                    prop: 'id',
                    type: String 
                },

                'ROOM NAME' : {
                    prop: 'roomName',
                    type: String 
                },

                'ROOM CODE' : {
                    prop: 'roomCode',
                    type: String 
                },

                'ENABLE MIRRORING' : {
                    prop: 'enableMirroring',
                    type: String 
                },

                'ENABLE SIGNAGE' : {
                    prop: 'enableSignage',
                    type: String 
                },

                'ENABLE NATIVE RESOLUTION' : {
                    prop: 'enableHigherResolution',
                    type: String 
                },

                'SHOW CONNECTION INFO' : {
                    prop: 'showConnectionInfo',
                    type: String 
                },

                'CONNECTION LOCATION' : {
                    prop: 'connectionInfoPosition',
                    type: String 
                },

                'SHOW DATE AND TIME ON SIGNAGE' : {
                    prop: 'showSignageDateTime',
                    type: String 
                },

                'DATE AND TIME LOCATION' : {
                    prop: 'signageDateTimeLocation',
                    type: String 
                },

                'ENABLE ALERTS' : {
                    prop: 'enableAlerts',
                    type: String 
                },

                'ENABLE MODERATOR CONTROLS' : {
                    prop: 'enableModeratorControls',
                    type: String 
                },

                'MODERATOR CONNECTION TYPE' : {
                    prop: 'moderatorConnectionType',
                    type: String 
                },

                'MODERATOR PASSWORD' : {
                    prop: 'moderatorPassword',
                    type: String 
                },

                'LOCATION NAME' : {
                    prop: 'locationName',
                    type: String 
                },

                'SUB LOCATION NAME' : {
                    prop: 'subLocationName',
                    type: String 
                }
            }

            let xlsxfile = file;

            const readFile = await readXlsxFile(xlsxfile, {schema});
            if(readFile.errors.length == 0) {
                this.roomsToBeCreated = readFile.rows;
            }

            this.fileRoomCount = this.roomsToBeCreated.length;
            await this.loadRooms();

            for(const room of this.roomsToBeCreated) {
                // Does the room already exist based on room id
                if(room.id) {
                    room.existingRoom = this.rooms.find(x => x.id == room.id);

                    // Throw error is there is an invalid room id
                    if(!room.existingRoom) {
                        room.errorMsg = "Room id does not exist. Room will not be updated or created.";
                        this.fileRoomErrors++;
                    }
                    else {
                        this.fileRoomUpdateCount++;
                    }
                }
                else {
                    this.fileRoomCreateCount++;
                }

                // Make sure room codes are between 6 and 25 characters
                if(room.roomCode && (room.roomCode.length < 6 || room.roomCode.length > 25)) {
                    room.invalidRoomCode = true;
                    room.invalidRoomCodeText = "Room code must be at least 6 characters and no more than 25 characters.";
                    room.errorMsg = room.existingRoom ? "Room will not be updated." : "Room will not be created.";
                    this.fileRoomErrors++;
                }
                else {
                    room.invalidRoomCode = false;
                }
                
                // Determine if there are any org overrides
                room.enableHigherResolutionOverride = room.enableHigherResolution && this.convertYNToBoolean(room.enableHigherResolution) != this.organization.enableHigherResolution;
                room.showSignageDateTimeOverride = room.showSignageDateTime && this.convertYNToBoolean(room.showSignageDateTime) != this.organization.showSignageDateTime;
                room.signageDateTimeLocationOverride = room.signageDateTimeLocation && this.formatConnectionPosition(room.signageDateTimeLocation) != this.organization.signageDateTimeLocation;
            }

            this.roomsImporting = false;
        },

        async onPopulateRooms() {
            this.roomCount = 0;
            this.roomsCreatedCount = 0;
            this.roomsUpdatedCount = 0;
            this.locationsCreatedCount = 0;
            this.subLocationsCreatedCount = 0;
            this.errorsCount = 0;
            this.errorRooms = [];
            this.roomsUploading = true;
            this.totalRoomsCount = this.roomsToBeCreated.length;
            this.showCounts = true;
            this.showProgress = true;

            await this.loadLocations();
            let location = {} as ILocation;

            for(const room of this.roomsToBeCreated) {
                if(room.invalidRoomCode) {
                    room.errorMsg = room.existingRoom ? 'Cannot update a room that has an invalid room code.' : 'Cannot create a room that has an invalid room code.';
                    this.errorRooms.push(room);
                    this.errorsCount++;
                }
                else if(!room.existingRoom && room.id) {
                    room.errorMsg = 'Room id does not exist. Room was not updated or created.';
                    this.errorRooms.push(room);
                    this.errorsCount++;
                }
                else {
                    const matchingLocations: ILocationListDisplay[] = this.locations.filter((x:any) => x && x.name && room.locationName && x.name.toLowerCase() == room.locationName.toLowerCase());
                    const sublocations: ILocationListDisplay[] = this.locations.map(x => x.subLocations).flat();
                    const matchingSubLocations = sublocations.filter((x:any) =>  x && x.name && room.subLocationName && x.name.toLowerCase() == room.subLocationName.toLowerCase());

                    if(room.subLocationName){
                        if(!room.locationName) {
                            room.errorMsg = 'Cannot create a room that has a sub location but no location.'
                            this.errorRooms.push(room);
                            this.errorsCount++;
                        }
                        else {
                            const matchingLocationIds = matchingLocations.map((loc:any) => loc.id);
                            const matchingSubLocation = matchingSubLocations.find((x:any) => x && x.name && room.subLocationName && x.name.toLowerCase() == room.subLocationName.toLowerCase() && matchingLocationIds.includes(x.parentId));
                        
                            if(matchingSubLocation) {
                                location = {id:matchingSubLocation.id, type:'Location'} as ILocation;
                            }
                            else {
                                const matchingLocation = matchingLocations.find((x:any) => x && x.name && room.locationName && x.name.toLowerCase() == room.locationName.toLowerCase());
                
                                if(matchingLocation)
                                {
                                    const newSubLocation = {
                                        name: room.subLocationName,
                                        parentLocation: {id: matchingLocation.id, type:'Location'} as ILocation,
                                        organization: this.organization
                                    } as ILocation;

                                    (newSubLocation as any).parentId = matchingLocation.id;

                                    const locationResponse = await locationState.createLocation(newSubLocation);
                                    if(locationResponse.success && locationResponse.data) {
                                        location = locationResponse.data;
                                        await this.loadLocations();
                                        this.subLocationsCreatedCount++;
                                    }
                                }
                                else {
                                    const newLocation = {
                                        name: room.locationName,
                                        parentLocation: undefined,
                                        organization: this.organization
                                    } as ILocation;

                                    const locationResponse = await locationState.createLocation(newLocation);
                                    if(locationResponse.success && locationResponse.data) {
                                        this.locationsCreatedCount++;
                                        
                                        const newSubLocation = {
                                            name: room.subLocationName,
                                            parentLocation: locationResponse.data,
                                            organization: this.organization
                                        } as ILocation;

                                        (newSubLocation as any).parentId = locationResponse.data.id;

                                        const subLocationResponse = await locationState.createLocation(newSubLocation);
                                        if(subLocationResponse.success && subLocationResponse.data) {
                                            location = subLocationResponse.data;
                                            await this.loadLocations();
                                            this.subLocationsCreatedCount++;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    else {
                        if(room.locationName){
                            const matchingLocation = matchingLocations.find((x:any) => x && x.name && room.locationName && x.name.toLowerCase() == room.locationName.toLowerCase());
                            
                            if(matchingLocation) {
                                location = {id: matchingLocation.id, type: 'Location'} as ILocation;
                            }
                            else {
                                const newLocation = {
                                    name: room.locationName,
                                    parentLocation: undefined,
                                    organization: this.organization
                                } as ILocation;

                                const locationResponse = await locationState.createLocation(newLocation)
                                if(locationResponse.success && locationResponse.data) {
                                    location = locationResponse.data;
                                    await this.loadLocations();
                                    this.locationsCreatedCount++;
                                }
                            }
                        }
                    }
                }

                if(!this.errorRooms.includes(room)){
                    if(room.existingRoom) {
                        const roomToUpdate = {
                            id: room.existingRoom.id,
                            roomName: room.roomName ?? room.existingRoom.roomName,
                            roomCode: room.roomCode ?? room.existingRoom.roomCode,
                            organization: this.organization,
                            enableMirroring: this.convertYNToBoolean(room.enableMirroring) ?? room.existingRoom.enableMirroring,
                            enableHigherResolution: this.convertYNToEnableRoomSettings(room.enableHigherResolution, this.organization.enableHigherResolution) ?? room.existingRoom.enableHigherResolution,
                            showConnectionInfo: this.convertYNToBoolean(room.showConnectionInfo) ?? room.existingRoom.showConnectionInfo,
                            connectionInfoPosition: this.formatConnectionPosition(room.connectionInfoPosition) ?? room.existingRoom.connectionInfoPosition,
                            showSignageDateTime: this.convertYNToEnableRoomSettings(room.showSignageDateTime, this.organization.showSignageDateTime) ?? room.existingRoom.showSignageDateTime,
                            signageDateTimeLocation: this.convertPositionToEnableRoomLocationSettings(room.signageDateTimeLocation, this.organization.signageDateTimeLocation) ?? room.existingRoom.signageDateTimeLocation,
                            location: location ?? room.existingRoom.location
                        } as IRoom;

                        if(this.hasSignage) {
                            roomToUpdate.enableSignage = this.convertYNToBoolean(room.enableSignage) ?? room.existingRoom.enableSignage;
                        }

                        if(this.hasAlerts) {
                            roomToUpdate.enableAlerts = this.convertYNToBoolean(room.enableAlerts) ?? room.existingRoom.enableAlerts;
                            
                        }
                    
                        if(this.hasModeratorControls) {
                            roomToUpdate.enableModeratorControls = this.convertYNToBoolean(room.enableModeratorControls) ?? room.existingRoom.enableModeratorControls;
                            roomToUpdate.moderatorConnectionType = this.formatModeratorType(room.moderatorConnectionType) ?? room.existingRoom.moderatorConnectionType;

                            if(roomToUpdate.enableModeratorControls && roomToUpdate.moderatorConnectionType == 'password') {
                                roomToUpdate.moderatorPassword = room.moderatorPassword ?? room.existingRoom.moderatorPassword;
                            }
                            else {
                                roomToUpdate.moderatorPassword = undefined;
                            }
                        }

                        const storeUpdateResponse = await roomState.updateRoom(roomToUpdate);
                        if(!storeUpdateResponse.success) {
                            room.errorMsg = storeUpdateResponse.reason ? storeUpdateResponse.reason : "Error updating room.";
                            this.errorRooms.push(room);
                            this.errorsCount++;
                        }
                        else {
                            this.roomsUpdatedCount++;
                        }
                    }
                    else {
                        const newRoom = {
                            roomName: room.roomName,
                            roomCode: room.roomCode,
                            organization: this.organization,
                            enableMirroring: this.convertYNToBoolean(room.enableMirroring),
                            enableSignage: this.hasSignage ? this.convertYNToBoolean(room.enableSignage) : null,
                            enableHigherResolution: this.convertYNToEnableRoomSettings(room.enableHigherResolution, this.organization.enableHigherResolution),
                            showConnectionInfo: this.convertYNToBoolean(room.showConnectionInfo),
                            connectionInfoPosition: this.formatConnectionPosition(room.connectionInfoPosition),
                            showSignageDateTime: this.convertYNToEnableRoomSettings(room.showSignageDateTime, this.organization.showSignageDateTime),
                            signageDateTimeLocation: this.convertPositionToEnableRoomLocationSettings(room.signageDateTimeLocation, this.organization.signageDateTimeLocation),
                            enableAlerts: this.hasAlerts ? this.convertYNToBoolean(room.enableAlerts) : null,
                            enableModeratorControls: this.hasModeratorControls ? this.convertYNToBoolean(room.enableModeratorControls) : null,
                            moderatorConnectionType: this.hasModeratorControls && this.convertYNToBoolean(room.enableModeratorControls) ? this.formatModeratorType(room.moderatorConnectionType) : null,
                            moderatorPassword: this.hasModeratorControls && this.convertYNToBoolean(room.enableModeratorControls) && this.formatModeratorType(room.moderatorConnectionType) == 'password' ? room.moderatorPassword : null,
                            location: location
                        } as IRoom;

                        const storeResponse = await roomState.createRoom(newRoom);
                        if(!storeResponse.success) {
                            room.errorMsg = storeResponse.reason ? storeResponse.reason : "Error creating room.";
                            this.errorRooms.push(room);
                            this.errorsCount++;
                        }
                        else {
                            this.roomsCreatedCount++;
                        }
                    }

                    this.roomUploadProgressPercentage = (((this.roomsCreatedCount + this.roomsUpdatedCount + this.errorsCount)/this.totalRoomsCount) * 100).toFixed(0);
                }
            }

            this.roomsToBeCreated = this.errorRooms;

            //update rooms after creation is complete
            this.$emit('import-complete');
            this.fileName = '';
            this.fileRoomCount = 0;
            this.roomsUploading = false;
            this.showProgress = false;

            if(this.errorsCount == 0){
                this.showCounts = false;
            }
        },

        convertEnableRoomSettingsToYN(value: any, orgValue: any) {
            if(value === '2') {
                return this.convertBooleanToYN(orgValue);
            }
            
            if(value === '1' || value === true) {
                return 'Y';
            }

            if(value === '0' || value === false) {
                return 'N';
            }

            return undefined;
        },

        convertBooleanToYN(value: boolean) {
           if(value === true) {
                return 'Y';
            }

            if(value === false) {
                return 'N';
            }
            
            return undefined;
        },

        async onExportRooms(blankTemplate: boolean) {
            let roomsToExport: any[] = [];
            let fileName = 'MassRoomCreationTemplate';

            if(!blankTemplate) {
                fileName = 'ExportedRooms.xlsx';
                
                const fetchResponse = await roomState.fetchRoomsForExport(this.organization.id);
                if(!fetchResponse.success) {
                    (this as any).$message.error('Error fetching rooms for export.');
                    return;
                }
            
                const orgRooms = fetchResponse.data;

                if(orgRooms) {
                    roomsToExport = orgRooms.map((room: any) => {
                        return {
                            id: room._id,
                            roomName: room.roomName,
                            roomCode: room.roomCode,
                            enableMirroring: this.convertBooleanToYN(room.enableMirroring),
                            enableSignage: this.hasSignage ? this.convertBooleanToYN(room.enableSignage): undefined,
                            enableHigherResolution: this.convertEnableRoomSettingsToYN(room.enableHigherResolution, this.organization.enableHigherResolution),
                            showConnectionInfo: this.convertBooleanToYN(room.showConnectionInfo),
                            connectionInfoPosition: this.formatConnectionPosition(room.connectionInfoPosition),
                            showSignageDateTime: this.convertEnableRoomSettingsToYN(room.showSignageDateTime, this.organization.showSignageDateTime),
                            signageDateTimeLocation: this.convertEnableRoomLocationSettingsToPosition(room.signageDateTimeLocation, this.organization.signageDateTimeLocation),
                            enableAlerts: this.hasAlerts ? this.convertBooleanToYN(room.enableAlerts) : undefined,
                            enableModeratorControls: this.hasModeratorControls ? this.convertBooleanToYN(room.enableModeratorControls) : undefined,
                            moderatorConnectionType: this.hasModeratorControls ? this.formatModeratorType(room.moderatorConnectionType) : undefined,
                            moderatorPassword: this.hasModeratorControls && room.moderatorConnectionType == 'password'  ? room.moderatorPassword : undefined,
                            locationName: room.location && room.location.parentName ? room.location.parentName : room.location ? room.location.name : undefined,
                            subLocationName: room.location && room.location.parentName ? room.location.name : undefined
                        }
                    }) as any[];
                }
            }

            const schema = [
                        {
                            column: 'ROOM ID',
                            type: String,
                            value: (room: any) => room.id
                        },
                        {
                            column: 'ROOM NAME',
                            type: String,
                            value: (room: any) => room.roomName
                        },
                        {
                            column: 'ROOM CODE',
                            type: String,
                            value: (room: any) => room.roomCode
                        },
                        {
                            column: 'ENABLE MIRRORING',
                            type: String,
                            value: (room: any) => room.enableMirroring
                        },
                        {
                            column: 'ENABLE SIGNAGE',
                            type: String,
                            value: (room: any) => room.enableSignage
                        },
                        {
                            column: 'ENABLE NATIVE RESOLUTION',
                            type: String,
                            value: (room: any) => room.enableHigherResolution
                        },
                        {
                            column: 'SHOW CONNECTION INFO',
                            type: String,
                            value: (room: any) => room.showConnectionInfo
                        },
                        {
                            column: 'CONNECTION LOCATION',
                            type: String,
                            value: (room: any) => room.connectionInfoPosition
                        },
                        {
                            column: 'SHOW DATE AND TIME ON SIGNAGE',
                            type: String,
                            value: (room: any) => room.showSignageDateTime
                        },
                        {
                            column: 'DATE AND TIME LOCATION',
                            type: String,
                            value: (room: any) => room.signageDateTimeLocation
                        },
                        {
                            column: 'ENABLE ALERTS',
                            type: String,
                            value: (room: any) => room.enableAlerts
                        },
                        {
                            column: 'ENABLE MODERATOR CONTROLS',
                            type: String,
                            value: (room: any) => room.enableModeratorControls
                        },
                        {
                            column: 'MODERATOR CONNECTION TYPE',
                            type: String,
                            value: (room: any) => room.moderatorConnectionType
                        },
                        {
                            column: 'MODERATOR PASSWORD',
                            type: String,
                            value: (room: any) => room.moderatorPassword
                        },
                        {
                            column: 'LOCATION NAME',
                            type: String,
                            value: (room: any) => room.locationName
                        },
                        {
                            column: 'SUB LOCATION NAME',
                            type: String,
                            value: (room: any) => room.subLocationName
                        }
            ] as any;

            await writeXlsxFile(roomsToExport, {
                schema,
                fileName: fileName
            })
        }
    },

    computed: {
        showUploadInstructions(): boolean {
            if(this.fileName || this.errorsCount > 0) {
                return false;
            }
            return true;
        },

        showExport(): boolean {
            if(this.rooms && this.rooms.length > 0) {
                return true;
            }

            return false;
        }
    },

    async created() {
        locationState = getModule(LocationState);
        roomState = getModule(RoomState);

        await this.loadRooms();

    }
});
