import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17b48554"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_leftsection = _resolveComponent("leftsection")!
  const _component_rightsection = _resolveComponent("rightsection")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_leftsection, {
          style: _normalizeStyle({ background: _ctx.item.backgroundColor }),
          class: "item__content"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createElementVNode("p", {
                style: _normalizeStyle({ color: _ctx.item.backgroundColor })
              }, [
                _createElementVNode("span", {
                  class: "item__headline1 textfit__headline",
                  style: _normalizeStyle({ background: _ctx.item.fontColor })
                }, _toDisplayString(_ctx.item.headline1), 5)
              ], 4),
              _createElementVNode("p", {
                style: _normalizeStyle({ color: _ctx.item.backgroundColor })
              }, [
                _createElementVNode("span", {
                  class: "item__headline2 textfit__headline",
                  style: _normalizeStyle({ background: _ctx.item.fontColor })
                }, _toDisplayString(_ctx.item.headline2), 5)
              ], 4),
              _createElementVNode("h2", {
                style: _normalizeStyle({ color: _ctx.item.fontColor }),
                class: "item__header textfit__header"
              }, _toDisplayString(_ctx.item.secondaryHeadline), 5),
              _createElementVNode("p", {
                style: _normalizeStyle({ color: _ctx.item.fontColor }),
                class: "item__description textfit__description--multiline"
              }, _toDisplayString(_ctx.item.descriptionText), 5)
            ])
          ]),
          _: 1
        }, 8, ["style"]),
        _createVNode(_component_rightsection, {
          style: _normalizeStyle({ background: 'url('+_ctx.item.media.asset1_url+') center center no-repeat', backgroundSize: 'cover'})
        }, null, 8, ["style"])
      ]))
    : _createCommentVNode("", true)
}