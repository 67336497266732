import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", {
    class: "template__container",
    style: _normalizeStyle(_ctx.backgroundStyle)
  }, [
    _createElementVNode("h1", {
      class: "textfit__header",
      style: _normalizeStyle({ color: _ctx.item.fontColor })
    }, _toDisplayString(_ctx.item.headerText), 5),
    _createElementVNode("h2", {
      class: "textfit__title",
      style: _normalizeStyle({ color: _ctx.item.fontColor })
    }, _toDisplayString(_ctx.item.title), 5),
    _createElementVNode("h3", {
      class: "textfit__subtitle--multiline",
      style: _normalizeStyle({ color: _ctx.item.fontColor })
    }, _toDisplayString(_ctx.item.subTitle), 5)
  ], 4))
}