import {
    Action,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';

import store from '../../store';

import { ILocation, ILocationDropDownList, ILocationListDisplay } from '../interfaces/ILocation';
import { getAuthInstance } from '../../auth';
import axios from 'axios';
import { ISqStoreActionResponse } from '../interfaces/ISqStoreActionResponse';
import { ILocationModelSearch, IModelSearchResponse } from '../interfaces/IModelSearch';

export interface ILocationState {
    fetchLocations(id?: string): Promise<ISqStoreActionResponse<ILocation[]>>;
    fetchLocation(locationId: string): Promise<ISqStoreActionResponse<ILocation>>;
    fetchLocationsDDL(reload?: boolean): Promise<ISqStoreActionResponse<ILocationDropDownList[]>>;
    createLocation(location: ILocation): Promise<ISqStoreActionResponse<ILocation>>;
    updateLocation(location: ILocation): Promise<ISqStoreActionResponse<void>>;
    deleteLocation(locationId: string): Promise<ISqStoreActionResponse<void>>;
    fetchLocationDisplayList(locationSearch: ILocationModelSearch): Promise<ISqStoreActionResponse<IModelSearchResponse<ILocationListDisplay[]>>>;
}

export interface IRemoveSublocationsInput {
    parentLocation: ILocation; 
    subLocationIds: string[];
}

export interface IAddSubLocationsInput {
    parentLocation: ILocation;
    subLocationIds: string[];
}

export interface IAddRoomsToLocation {
    location: ILocation;
    roomIds: string[];
}

@Module({ dynamic: true, store, name: 'locations' })
export default class LocationState extends VuexModule implements ILocationState {
    // THESE HAVE TO BE NAMED UNIQUELY
    // THE WAY STATE WORKS IF YOU ALTER THIESE VIA CONTEXT 
    // ANY NAMED THE SAME WAY WILL CHANGE ALSO
    private locations: ILocation[] = [];
    private isLocationsLoaded = false;

    private locationsDropDownList: ILocationDropDownList[] = [];
    private isLocationsDDLLoaded = false;

    //#region GETTERS
    //#endregion

    //#region ACTIONS
    @Action
    async fetchLocations(id?: string): Promise<ISqStoreActionResponse<ILocation[]>> {
        if (this.isLocationsLoaded && !id) return { success: true };

        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            let fetchLocationsUrl = `${process.env.VUE_APP_API_HOST}/api/locations?extendedData=true`;

            if(id) {
                fetchLocationsUrl = `${process.env.VUE_APP_API_HOST}/api/locations/super/${id}`;
                this.context.commit('setIsLocationsLoaded', false);
            }
            else {
                this.context.commit('setIsLocationsLoaded', true);
            }

            const axiosResponse = await axios.get(fetchLocationsUrl, authHeader);
            this.context.commit('setLocations', axiosResponse.data);
           
            return { success: true };
        }
        catch(e:any) {
            console.error('Locations Store Error:', e);
        }

        return { success: false, reason: 'Error fetching locations.' };
    }

    @Action
    async fetchLocation(locationId: string): Promise<ISqStoreActionResponse<ILocation>> {
        try {
            let location: ILocation = {} as ILocation;
            const authHeader = await getAuthInstance().getAxiosHeader();
            const fetchLocationUrl = `${process.env.VUE_APP_API_HOST}/api/locations/${locationId}?extendedData=true`;
            const axiosResponse = await axios.get(fetchLocationUrl, authHeader);

            const locationResponse: ILocation[] = axiosResponse.data

            if(locationResponse && locationResponse.some((x:ILocation)=>x.id == locationId)) {
                location = locationResponse[locationResponse.findIndex(x=>x.id == locationId)];
            }

            return { success: true, data: location };
        }
        catch(e:any) {
            console.error('Locations Store Error:', e);
        }

        return { success: false, reason: 'Error fetching location.' };
    }

    @Action
    async fetchLocationsDDL(reload = false): Promise<ISqStoreActionResponse<ILocationDropDownList[]>> {
        try {
            if(!reload && this.isLocationsDDLLoaded) return { success: true, data: this.locationsDropDownList };
            const authHeader = await getAuthInstance().getAxiosHeader();

            const fetchLocationUrl = `${process.env.VUE_APP_API_HOST}/api/locations/dropdownlist`;
            const axiosResponse = await axios.get(fetchLocationUrl, authHeader);

            return { success: true, data: axiosResponse.data.data };
        }
        catch(e:any) {
            console.error('Locations Store Error:', e);
        }

        return { success: false, reason: 'Error fetching location.' };
    }

    @Action
    async fetchLocationDisplayList(locationSearch: ILocationModelSearch): Promise<ISqStoreActionResponse<IModelSearchResponse<ILocationListDisplay[]>>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            let fetchLocationsUrl = `${process.env.VUE_APP_API_HOST}/api/locations`;

            if(locationSearch.pageIndex && locationSearch.pageSize) {
                fetchLocationsUrl = `${fetchLocationsUrl}?pageIndex=${locationSearch.pageIndex}&pageSize=${locationSearch.pageSize}`;

                if(locationSearch.organizationId) {
                    fetchLocationsUrl = `${fetchLocationsUrl}&organizationId=${locationSearch.organizationId}`;
                }

                if(locationSearch.ids && locationSearch.ids.length > 0) {
                    fetchLocationsUrl = `${fetchLocationsUrl}&ids=${locationSearch.ids}`;
                }

                if(locationSearch.name) {
                    fetchLocationsUrl = `${fetchLocationsUrl}&name=${locationSearch.name}`;
                }

                if(locationSearch.parentId) {
                    fetchLocationsUrl = `${fetchLocationsUrl}&parentid=${locationSearch.parentId}`;
                }

                if(locationSearch.sortField && locationSearch.sortOrder) {
                    fetchLocationsUrl = `${fetchLocationsUrl}&sortField=${locationSearch.sortField}&sortOrder=${locationSearch.sortOrder}`;
                }
            }

            const axiosResponse = await axios.get(fetchLocationsUrl, authHeader);

            return { success: true, data: {itemsCount: axiosResponse.data.itemsCount, data: axiosResponse.data.data} as IModelSearchResponse<ILocationListDisplay[]> };
        }
        catch(e:any) {
            console.error('Locations Store Error:', e);
        }

        return { success: false, reason: 'Error fetching location.' };
    }

    @Action
    async createLocation(location: ILocation): Promise<ISqStoreActionResponse<ILocation>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            const createLocationUrl = `${ process.env.VUE_APP_API_HOST }/api/locations/`;
            const axiosResponse = await axios.post(createLocationUrl, location, authHeader);

             //to updated device and room count
            this.fetchLocation(axiosResponse.data.id);
           
            if(this.isLocationsLoaded) {
                this.context.commit('setLocations', [...this.locations, axiosResponse.data]);
            }   

            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Locations Store Error:', e);
        }

        return { success: false, reason: 'Error creating location.'};
    }

    @Action
    async updateLocation(location: ILocation): Promise<ISqStoreActionResponse<void>> {
        let clientFailureResponse = '';
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
        
            const updateLocationUrl = `${ process.env.VUE_APP_API_HOST }/api/locations/${location.id}`;
            const axiosResponse = await axios.put(updateLocationUrl, location, authHeader);
            
            const mutatedLocations = [
                ...this.locations.filter(x=>x.id !== location.id),
                axiosResponse.data
            ];

            this.context.commit('setLocations', mutatedLocations);
            return { success: true };
        }
        catch(e:any) {
            if(e.response) {
                console.error(e.response.status);
                if(e.response.status === 413 && location.alertPolygon && location.alertPolygon.length > 1000) {
                    clientFailureResponse = ' You\'re location area selection contains too many coordinates. Select the area using lines instead of free drawing, or make the selection area smaller.';
                }
            }
            console.error('Locations Store Error:', e);
        }

        return { success: false, reason: `Error updating locations.${clientFailureResponse}`};
    }

    @Action
    async deleteLocation(locationId: string): Promise<ISqStoreActionResponse<void>> { 
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
        
            const deleteLocationUrl = `${ process.env.VUE_APP_API_HOST }/api/locations/${locationId}`; // needs an id based on the signature, but not used in the api...
            const axiosResponse = await axios.delete(deleteLocationUrl, authHeader);

            const deleteIds = axiosResponse.data;
            
            const mutatedLocations = [
                ...this.locations.filter(x=> !(deleteIds.includes(x.id)))
            ];

            this.context.commit('setLocations', mutatedLocations);
            return { success: true };
        }
        catch(e:any) {
            console.error('Locations Store Error:', e);
        }

        return { success: false, reason: 'Error deleting location.'};
    }
    //#endregion

    //#region MUTATIONS

    @Mutation
    async setLocations(locations: ILocation[]): Promise<void> {
        this.locations = locations;
    }

    @Mutation
    async setIsLocationsLoaded(isLoaded: boolean): Promise<void> {
        this.isLocationsLoaded = isLoaded;
    }

    @Mutation
    async setLocationsDropDownList(locationsDropDownList: ILocationDropDownList[]): Promise<void> {
        this.locationsDropDownList = locationsDropDownList;
    } 

    @Mutation
    async setIsLocationsDDLLoaded(isLocationsDDLLoaded: boolean): Promise<void> {
        this.isLocationsDDLLoaded = isLocationsDDLLoaded;
    }
    //#endregion


}
