import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5dd06b0e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "item"
}
const _hoisted_2 = {
  style: {"{ border-color":"item.fontColor}"},
  class: "item__border"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_leftsection = _resolveComponent("leftsection")!
  const _component_middlesection = _resolveComponent("middlesection")!
  const _component_rightsection = _resolveComponent("rightsection")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_leftsection, {
          style: _normalizeStyle({ background: 'url('+_ctx.item.media.asset1_media_url+') center center no-repeat', backgroundSize: 'cover'})
        }, null, 8, ["style"]),
        _createVNode(_component_middlesection, {
          style: _normalizeStyle({ background: _ctx.item.backgroundColor }),
          class: "item__content"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createElementVNode("p", {
                style: _normalizeStyle({ color: _ctx.item.fontColor,  textShadow: '10px 10px 0' + _ctx.item.shadowColor }),
                class: "item__headline textfit__headline"
              }, _toDisplayString(_ctx.item.headline), 5),
              _createElementVNode("p", {
                style: _normalizeStyle({ color: _ctx.item.fontColor }),
                class: "item__secondaryHeadline textfit__secondary-headline"
              }, _toDisplayString(_ctx.item.secondaryHeadline), 5),
              _createElementVNode("p", {
                style: _normalizeStyle({ color: _ctx.item.shadowColor }),
                class: "item__text1 textfit__text1"
              }, _toDisplayString(_ctx.item.text1), 5),
              _createElementVNode("p", {
                style: _normalizeStyle({ color: _ctx.item.fontColor }),
                class: "item__text2 textfit__text2"
              }, [
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.item.text2), 1)
              ], 4)
            ])
          ]),
          _: 1
        }, 8, ["style"]),
        _createVNode(_component_rightsection, {
          style: _normalizeStyle({ background: 'url('+_ctx.item.media.asset2_media_url+') center center no-repeat', backgroundSize: 'cover'})
        }, null, 8, ["style"])
      ]))
    : _createCommentVNode("", true)
}