import {
    Action,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';

import store from '..';
import axios from 'axios';

import { ISignageList, IMessageSignageListUpdate } from '../interfaces/ISignage';
import { getAuthInstance } from '../../auth';
import { ISqStoreActionResponse } from '../interfaces/ISqStoreActionResponse';
import { v4 as uuidv4 } from 'uuid';

let signageEventSource: ISignageEventSource[] = [];
const instanceId = uuidv4();

export interface IScheduleProperties {
    isDayEnabled: boolean;
    isDateEnabled: boolean;
    isTimeEnabled: boolean;
    scheduledTimeSet: boolean;
    activeDaysOfWeek: string[];
    startTime: null | Date;
    endTime: null | Date;
    startDate: null | Date;
    endDate: null | Date;
}

export interface IScheduleSignageListPayload {
    scheduleProperties: IScheduleProperties;
    signageLists: ISignageList[];
}

export interface ISignageEventSource {
    signageListId: string;
    instanceId: string;
    eventSource: EventSource;
}

export interface ISignageListState {
    readonly allSignageLists: ISignageList[];
    readonly reactiveAllSignageLists: ISignageList[];
    readonly signageListById: (signageListId: string) => ISignageList;
    readonly reactiveSignageListById: (signageListId: string) => ISignageList;
    readonly hasSchedultConflict: (id: string) => boolean;
    readonly messageSignageListUpdate: (signageListId: string) => IMessageSignageListUpdate;
    fetchSignageLists(reload?: boolean): Promise<ISqStoreActionResponse<void>>;
    fetchSignageListEventsById(signageListId: string): Promise<ISqStoreActionResponse<void>>;
    fetchScheduleConflicts(): Promise<ISqStoreActionResponse<void>>;
    fetchSignageListsForRooms(roomIds: string[]): Promise<ISqStoreActionResponse<ISignageList[]>>;
    deleteSignageList(signageList: ISignageList): Promise<ISqStoreActionResponse<ISignageList>>;
    createSignageList(signageList: ISignageList): Promise<ISqStoreActionResponse<ISignageList>>;
    updateSignageList(signageList : ISignageList): Promise<ISqStoreActionResponse<ISignageList>>;
    setRoomList(signageList: ISignageList): Promise<ISqStoreActionResponse<ISignageList>>;
    scheduleSignageLists(scheduleSignagePayload: IScheduleSignageListPayload): Promise<ISqStoreActionResponse<ISignageList>>;
}

@Module({ dynamic: true, store, name: 'signagelist' })
export default class SignageListState extends VuexModule implements ISignageListState {
    private signageLists: ISignageList[] = [];
    private scheduleConflicts: any;
    private messageListUpdates: IMessageSignageListUpdate[] = [];

    private isSignageListsLoaded = false;

    //#region GETTERS
    get allSignageLists(): ISignageList[] {
        const signageLists = [...this.signageLists.map((x)=>Object.assign({}, x))];
        return signageLists;
    }


    get reactiveAllSignageLists(): ISignageList[] {
        const signageLists = this.signageLists;
        return signageLists;
    }

    get signageListById(): (signageListId: string) => ISignageList {
        return (signageListId: string) => {
            const signageList = this.signageLists.find(x=>x.id === signageListId);
            return JSON.parse(JSON.stringify(signageList)) ?? {} as ISignageList;
        };
    }

    get reactiveSignageListById(): (signageListId: string) => ISignageList {
        return (signageListId: string) => {
            const signageList = this.signageLists.find(x=>x.id === signageListId);
            return signageList ?? {} as ISignageList;
        };
    }

    get hasSchedultConflict(): (id: string) => boolean {
        return (id: string) => {  
            if(this.scheduleConflicts) {
                const ids = this.scheduleConflicts.map((x: any) => [x.signageListId, x.signageItems.flat(1)]).flat(2);
                return ids.includes(id);
            }
            return false;
        };
    }

    get messageSignageListUpdate(): (signageListId: string) => IMessageSignageListUpdate {
        return (signageListId: string) => {
            const update = this.messageListUpdates.find(x => x.signageListId === signageListId);
            return update ? update : {} as IMessageSignageListUpdate;
        }
    }
    //#endregion


    //#region ACTIONS
    @Action
    async fetchSignageLists(reload?: boolean): Promise<ISqStoreActionResponse<void>> {
        if (this.isSignageListsLoaded && !reload) return { success: true };

        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            const fetchSignageListsUrl = `${process.env.VUE_APP_API_HOST}/api/signagelists`;
            const axiosResponse = await axios.get(fetchSignageListsUrl, authHeader);

            this.context.commit('setSignageLists', axiosResponse.data);
            this.context.commit('setIsSignageListsLoaded', true);

            return { success: true };
        }
        catch(e:any) {
            console.error('Signage List Store Error:', e);
        }
        return { success: false, reason: 'Error fetching signage lists.' };
    }

    @Action
    async fetchSignageListEventsById(signageListId: string): Promise<ISqStoreActionResponse<void>> {
        try {
            // We also need to set up the event source 
            // for when signage items are done updating
            const sseUrl = `${ process.env.VUE_APP_API_HOST }/api/serverevents/event/lid.${signageListId}/${ instanceId }`;
            const eventSource = new EventSource(sseUrl);

            signageEventSource = [
                ...signageEventSource.filter(x=>x.signageListId !== signageListId), 
                {signageListId: signageListId, instanceId: instanceId, eventSource: eventSource}
            ];

            eventSource.onmessage = ((event) => {
                const responseMessage = JSON.parse(event.data) as any;
                const responseSignageList = responseMessage.signageList as ISignageList;
                const signageListUpdate = {
                    messageId: responseMessage.id,
                    signageListId: signageListId,
                    importError: responseSignageList.importError,
                    signageItems: responseSignageList.signageItems
                } as IMessageSignageListUpdate;

                this.context.commit('setListMessageUpdate', signageListUpdate);
            });

            return { success: true };
        }
        catch(e:any) {
            console.error('Signage List Store Error:', e);
        }
        return { success: false, reason: 'Error fetching signage lists events.' };
    }
    
    @Action
    async fetchScheduleConflicts(): Promise<ISqStoreActionResponse<void>> {
        
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            const fetchScheduleConflictsUrl = `${process.env.VUE_APP_API_HOST}/api/signagelists/scheduleconflicts`;
            const axiosResponse = await axios.get(fetchScheduleConflictsUrl, authHeader);

            this.context.commit('setScheduleConflicts', axiosResponse.data);

            return { success: true};
        }
        catch(e:any) {
            console.error('Signage List Store Error:', e);
        }
        return { success: false, reason: 'Error fetching signage list schedule conflicts.' };
    }

    @Action
    async fetchSignageListsForRooms(roomIds: string[]): Promise<ISqStoreActionResponse<ISignageList[]>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            const fetchSignageListsUrl = `${process.env.VUE_APP_API_HOST}/api/signagelists/listsforrooms`;
            const axiosResponse = await axios.post(fetchSignageListsUrl, roomIds, authHeader);

            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Signage List Store Error:', e);
        }
        return { success: false, reason: 'Error fetching signage lists.' };
    }

    @Action
    async deleteSignageList(signageList: ISignageList): Promise<ISqStoreActionResponse<ISignageList>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            const deleteListUrl = `${process.env.VUE_APP_API_HOST}/api/signagelists/${ signageList.id }`;
            const axiosResponse = await axios.delete(deleteListUrl, authHeader);
            
            if(signageList && signageList.id) {
                const mutatedSignageLists = [
                    ...this.signageLists.filter(x=> !(x.id?.includes(signageList.id as any)))
                ];
                this.context.commit('setSignageLists', mutatedSignageLists);
            }
            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Signage List Store Error:', e);
        }
        return { success: false, reason: 'Error deleting sigange list.' };
    }

    @Action
    async createSignageList(signageList: ISignageList): Promise<ISqStoreActionResponse<ISignageList>>  {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            const createListUrl = `${process.env.VUE_APP_API_HOST}/api/signagelists/`;
            const axiosResponse = await axios.post(createListUrl, signageList, authHeader);

            if(this.isSignageListsLoaded) {
                this.context.commit('setSignageLists', [...this.signageLists, axiosResponse.data]);
            }

            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Signage List Store Error:', e);
        }
        return { success: false, reason: 'Error creating sigange list.' };
    }

    @Action
    async updateSignageList(signageList : ISignageList): Promise<ISqStoreActionResponse<ISignageList>>  {
        try {
            const updateSignageListUrl = `${ process.env.VUE_APP_API_HOST }/api/signagelists/${signageList.id}`;

            // we only want to update the data, not the items
            const updatingSignageListClone = {... signageList, signageItems: null};
            const authHeader = await getAuthInstance().getAxiosHeader();

            const axiosResponse = await axios.put(updateSignageListUrl, updatingSignageListClone, authHeader);

            // todo need this commit

            const mutatedSignageLists = [
                ...this.signageLists.filter(x=>x.id !== signageList.id),
                axiosResponse.data
            ];

            this.context.commit('setSignageLists', mutatedSignageLists);
            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Signage List Store Error:', e);
        }
        return { success: false, reason: 'Error updating sigange list.' };
    }
    

    @Action
    async setRoomList(signageList: ISignageList): Promise<ISqStoreActionResponse<ISignageList>> {
        try {
            const roomsList = signageList.rooms;
            const authHeader = await getAuthInstance().getAxiosHeader();
            const setRoomListUrl = `${process.env.VUE_APP_API_HOST}/api/signagelists/${ signageList.id }/setroomlist`;
            const axiosResponse = await axios.put(setRoomListUrl, roomsList, authHeader);

            //forcing refresh of signage list to determine if room changes elminate any lists based on role
            this.context.commit('setIsSignageListsLoaded', false);
            await this.fetchSignageLists();

            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Signage List Store Error:', e);
        }
        return { success: false, reason: 'Error updating sigange list.' };
    }

    @Action
    async scheduleSignageLists(scheduleSignagePayload: IScheduleSignageListPayload): Promise<ISqStoreActionResponse<ISignageList>> {
        try {
            const signageListIds = scheduleSignagePayload.signageLists.map((x: any) => x.id);
            const urlPayload = {
                scheduleProperties: scheduleSignagePayload.scheduleProperties,
                signageItems: signageListIds
            }

            const scheduleSignageListUrl = `${ process.env.VUE_APP_API_HOST }/api/signagelists/schedule`;

            const authHeader = await getAuthInstance().getAxiosHeader();

            const axiosResponse = await axios.put(scheduleSignageListUrl, urlPayload, authHeader);

            const listIds = scheduleSignagePayload.signageLists.map((x: any) => x.id);
            
            const mutatedSignageLists = [
                ...this.signageLists.filter((x: any) => !listIds.includes(x.id)),
                ...axiosResponse.data
            ];

            this.context.commit('setSignageLists', mutatedSignageLists);
            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Signage List Store Error:', e);
        }
        return { success: false, reason: 'Error scheduling sigange list.' };
    }

    //#region MUTATIONS
    @Mutation
    async setSignageLists(signageLists: ISignageList[]): Promise<void> {
        this.signageLists = signageLists;
    }

    @Mutation
    async setIsSignageListsLoaded(isLoaded: boolean): Promise<void> {
        this.isSignageListsLoaded = isLoaded;
    }

    @Mutation
    async setScheduleConflicts(scheduleConflicts: string[]): Promise<void> {
        this.scheduleConflicts = scheduleConflicts;
    }

    @Mutation
    async setListMessageUpdate(listMessage: IMessageSignageListUpdate): Promise<void> {
        // if we already have an update for the message replace it    
        this.messageListUpdates = [
            ...this.messageListUpdates.filter(x => x.signageListId != listMessage.signageListId),
            listMessage
        ]
    }
    //#endregion
}








