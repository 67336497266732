
import { defineComponent } from 'vue';
import { IEmergencyAlertServer } from '../../store/interfaces/IEmergencyAlertServer';
import EmergencyAlertServersState, { IEmergencyAlertServersState } from '../../store/modules/emergencyAlertServers';
import { getModule } from 'vuex-module-decorators';
import Icon from '../../components/Icon.vue';
import { mdiLightbulb, mdiInformation } from '@mdi/js';
import moment from "moment";
import xmlFormat from "xml-formatter";
import { IModelSelectionPool } from '../../store/interfaces/IModelSelectionPool';
import { IRoom, IRoomDropDownList } from '../../store/interfaces/IRoom';
import { ILocation, ILocationDropDownList } from '../../store/interfaces/ILocation';
import RoomState, { IRoomState } from '../../store/modules/rooms';
import LocationState, { ILocationState } from '../../store/modules/locations';
import UserState, { IUserState } from '../../store/modules/users';
import ModelSelector from '../../components/ModelSelector.vue';
import AlertDetailModal from '../../components/AlertDetailModal.vue';
import EmergencyAlertsState, { IEmergencyAlertsState } from '../../store/modules/emergencyAlerts';
import PageDirtyService from '../../services/PageDirtyService';
import TierLimit from '../../components/TierLimit.vue';
import TierState, { ITierState } from '../../store/modules/tier';

let emergencyAlertServersState = {} as IEmergencyAlertServersState,
    locationState = {} as ILocationState,
    roomState = {} as IRoomState,
    userState = {} as IUserState,
    emergencyAlertsState = {} as IEmergencyAlertsState,
    tierState = {} as ITierState;

export default defineComponent({
    name: 'AlertServersView',
    data() {
        return {
            mdiLightbulb,
            mdiInformation,
            serverFilter: '' as string,
            isAlertServersLoading: true as boolean,
            isAlertServerDetailsLoading: true as boolean,
            isAlertDetailsVisible: false as boolean,
            selectedEmergencyAlertServer: {} as IEmergencyAlertServer,
            recentAlerts: [],
            selectedRecentAlertDetails: {},
            textXml: '' as string,
            roomsDDL: [] as IRoomDropDownList[],
            locationsDDL: [] as ILocationDropDownList[],
            rules: {
                serverName: [
                    {
                        required: true,
                        message: 'Please enter an Alert Server name',
                        trigger: 'blur',
                    },
                ],
                rooms: [
                    {
                        validator: this.checkRooms,
                        trigger: 'blur',
                    },
                ],
                locations: [
                    {
                        validator: this.checkLocations,
                        trigger: 'blur',
                    },
                ]
            }
        }
    },

    props: ['creationMode'],

    components: {
        Icon,
        ModelSelector,
        AlertDetailModal,
        TierLimit
    },

    computed: {
         allEmergencyAlertServers(): IEmergencyAlertServer[] | undefined {
            if(emergencyAlertServersState.allEmergencyAlertServers) {
                const activeServers = emergencyAlertServersState.allEmergencyAlertServers.filter(server => server.is_active).sort((a,b) => (a.serverName > b.serverName) ? 1 : ((b.serverName > a.serverName) ? -1 : 0));
                const inactiveServers = emergencyAlertServersState.allEmergencyAlertServers.filter(server => !server.is_active).sort((a,b) => (a.serverName > b.serverName) ? 1 : ((b.serverName > a.serverName) ? -1 : 0));
                return [...activeServers, ...inactiveServers];
            }
            return [];
        },

        filteredEmergencyAlertServers() {
            if(this.serverFilter) {
                const searchValue = this.serverFilter.toLowerCase();
                return (this as any).allEmergencyAlertServers.filter((alert: IEmergencyAlertServer)=> {
                    const alertTitle = alert.serverName.toLowerCase();
                    return  alertTitle.includes(searchValue);
                });
            } else {
                return this.allEmergencyAlertServers;
            }
        },

        pushServerURL(): string | null {
            if(this.selectedEmergencyAlertServer.serverToken && this.selectedEmergencyAlertServer.serverId) {
                return (
                    process.env.VUE_APP_ALERT_HOST +
                    "/new?token=" +
                    this.selectedEmergencyAlertServer.serverToken +
                    "&serverId=" +
                    this.selectedEmergencyAlertServer.serverId
                );
            }
            return null;
        },

        selectedServerRoomsCount() {
            if(this.selectedEmergencyAlertServer && this.selectedEmergencyAlertServer.rooms) {
                return this.selectedEmergencyAlertServer.rooms.length;
            }

            return 0;
        },

        selectedServerLocationsCount() {
            if(this.selectedEmergencyAlertServer && this.selectedEmergencyAlertServer.locations) {
                return this.selectedEmergencyAlertServer.locations.length;
            }

            return 0;
        },

        alertEnabledRooms(): IRoomDropDownList[] | undefined {
            if(!this.roomsDDL) return undefined;

            return this.roomsDDL.filter((x:IRoomDropDownList) => x.enableAlerts);
        },

        newAlertServer(): IEmergencyAlertServer {
            return {
                type: 'CAPAlertServer',
                is_active: true,
                serverName: '',
                serverType: 'Push',
                alertExpirationDefaultDuration: '15',
                statuses: ["Actual", "Exercise", "System", "Test", "Draft"],
                severities: ["Extreme", "Severe", "Moderate", "Minor", "Unknown"],
                urgencies: ["Immediate", "Expected", "Future", "Past", "Draft"],
                certainties: ["Observed", "Likely", "Possible", "Unlikely", "Unknown"],
                alertTypes: ["Geo", "Met", "Safety", "Security", "Rescue", "Fire", "Health", "Env", "Transport", "Infra", "CBRNE", "Other"],
                rooms: [],
                locations: []
            } as IEmergencyAlertServer;
        },

        hideSaveButton(): boolean {
            if(this.creationMode || (this.selectedEmergencyAlertServer && this.selectedEmergencyAlertServer.id && this.selectedEmergencyAlertServer.is_active)) {
                return false;
            }
            return true;
        },

        showTierCallout(): boolean {
            if(!tierState.orgTier.emergencyAlertsEnabled) {
                return true;
            }
            return false;
        }
    },

    methods: {
        getTextXml() {
            let xmlString: string;
            xmlString =
                '<?xml version="1.0" encoding="utf-8" standalone="yes" ?><alert xmlns="urn:oasis:names:tc:emergency:cap:1.2"><identifier>' +
                this.testIndentifier() +
                "</identifier><sender>Ditto Settings Portal Test</sender><sent>" +
                moment
                    .utc()
                    .format()
                    .replace("Z", "-00:00") +
                "</sent><status>Actual</status><msgType>Alert</msgType><references/><scope>Public</scope><note>Testing the functionality of Emergency Alerts in Ditto.</note><info><language>en-US</language><category>Infra</category><event>A test of the Ditto Emergency Alert Functionality</event><responseType>Monitor</responseType><urgency>Expected</urgency><severity>Minor</severity><certainty>Observed</certainty><effective>" +
                moment
                    .utc()
                    .format()
                    .replace("Z", "-00:00") +
                "</effective><onset>" +
                moment
                    .utc()
                    .format()
                    .replace("Z", "-00:00") +
                "</onset><expires>" +
                moment
                    .utc()
                    .add("5", "minutes")
                    .format()
                    .replace("Z", "-00:00") +
                "</expires><headline>TEST - Ditto Emergency Alert Test</headline><description>This is a test of the Ditto Emergency Alert system.</description><instruction>This is only a test. No action is necessary.</instruction></info></alert>";

            return xmlFormat(xmlString, { collapseContent: true });
        },

        async checkRooms(rule: any, value: any, callback: any)  {
            if(!this.selectedEmergencyAlertServer.rooms.length && !this.selectedEmergencyAlertServer.locations.length) {
                callback(new Error('Please select rooms or locations or this alert.'))
            } else {
                callback() 
            }
        },

        async checkLocations(rule: any, value: any, callback: any)  {
            if(!this.selectedEmergencyAlertServer.locations.length && !this.selectedEmergencyAlertServer.rooms.length) {
                callback(new Error('Please select rooms or locations or this alert.'))
            } else {
                callback() 
            }
        },

        async loadRecentAlerts(server: IEmergencyAlertServer) {
            if(this.selectedEmergencyAlertServer && this.selectedEmergencyAlertServer.id && !this.creationMode) {
                this.recentAlerts = [];     
                const storeResponse = await emergencyAlertServersState.getRecentAlerts(server);
                if(!storeResponse.success) {
                    (this as any).$message.error(storeResponse.reason ? `Failed to load recent alerts: ${storeResponse.reason}` : 'Failed to load recent alerts');    
                }

                this.recentAlerts.map((alert: any) => {
                    alert.status = alert.is_active? 'Active' : 'Inactive'
                })

                this.recentAlerts = storeResponse.data;
            }
        },

        async loadSelectedEmergencyAlertServer(id?: any): Promise<IEmergencyAlertServer>{ 
            this.isAlertServerDetailsLoading = true;
            if(!this.creationMode) {
                const idToLoad = id ? id : this.$route.params.id;
                const serverToLoad = emergencyAlertServersState.serverById(idToLoad);
    
                if(serverToLoad) {
                    await this.loadRecentAlerts(serverToLoad);
                    this.textXml = this.getTextXml();
                }
                
                this.isAlertServerDetailsLoading = false;

                return serverToLoad? serverToLoad : {} as IEmergencyAlertServer;
            }

            return this.newAlertServer;
        },

        async deactivateAlertServer(server: IEmergencyAlertServer) {
           if(!server) return;
           try {
                await (this as any).$confirm('This will immediately deactivate the server and stop processing emergency alerts for users. Are you sure you want to deactivate this server?', 'Warning', {
                        confirmButtonText: 'Deactivate',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    });

                 const storeResponse = await emergencyAlertServersState.deactivateAlertServer(server);

                if(!storeResponse.success) {
                    (this as any).$message.error(storeResponse.reason ? `Deactivate alert server failed: ${storeResponse.reason}` : 'Deactivate alert server failed');
                    return;
                }

                (this as any).$message.success('Alert server deactivated');
            }
            catch {
                //canceled
            }
        },

        async changeDetection() {
            let originalAlertServer = {} as any;
            if(this.selectedEmergencyAlertServer && this.selectedEmergencyAlertServer.id) {
                originalAlertServer = emergencyAlertServersState.serverById(this.selectedEmergencyAlertServer.id);
            }
            else if (this.creationMode) {
                originalAlertServer = {
                    type: 'CAPAlertServer',
                    is_active: true,
                    serverName: '',
                    serverType: 'Push',
                    alertExpirationDefaultDuration: '15',
                    statuses: ["Actual", "Exercise", "System", "Test", "Draft"],
                    severities: ["Extreme", "Severe", "Moderate", "Minor", "Unknown"],
                    urgencies: ["Immediate", "Expected", "Future", "Past", "Draft"],
                    certainties: ["Observed", "Likely", "Possible", "Unlikely", "Unknown"],
                    alertTypes: ["Geo", "Met", "Safety", "Security", "Rescue", "Fire", "Health", "Env", "Transport", "Infra", "CBRNE", "Other"],
                    rooms: [],
                    locations: []
                };
            }
            
            PageDirtyService.monitorObjectSet([
                {defaultObject: originalAlertServer, mutatedObject: this.selectedEmergencyAlertServer},
            ]);
        },

        async onSubmit() {
            const isValid = await (this.$refs['serverForm'] as any).validate();
            if(!isValid) return;

            if(this.creationMode) {
                const storeCreateResponse = await emergencyAlertServersState.createAlertServer(this.selectedEmergencyAlertServer);

                if(!storeCreateResponse.success) {
                    (this as any).$message.error(storeCreateResponse.reason ? `Failed to create alerts server: ${storeCreateResponse.reason}` : 'Failed to create alert server');    
                    return;
                }

                (this as any).$message.success('Alert server has been created.');
                
                if(storeCreateResponse.data) {        
                    this.$router.push({ path: `/alertservers/${storeCreateResponse.data.id}` });
                    this.loadRecentAlerts(storeCreateResponse.data);
                }
                PageDirtyService.markPageClean();
                return;
            }
            
            const storeUpdateResponse = await emergencyAlertServersState.updateAlertServer(this.selectedEmergencyAlertServer);

            if(!storeUpdateResponse.success) {
                (this as any).$message.error(storeUpdateResponse.reason ? `Failed to update alert server: ${storeUpdateResponse.reason}` : 'Failed to update alert server');  
                return;  
            }

            (this as any).$message.success('Alert server has been updated.');
            PageDirtyService.markPageClean();
            return;
        },

        testIndentifier(): string {
            var S4 = function() {
                return (((1 + Math.random()) * 0x10000) | 0)
                    .toString(16)
                    .substring(1);
            };
            return (
                S4() +
                S4() +
                "-" +
                S4() +
                "-" +
                S4() +
                "-" +
                S4() +
                "-" +
                S4() +
                S4() +
                S4()
            );
        },

        manageRoomsClickHandler() {
            const alertEnabledRoomsMap: IModelSelectionPool[] = (this as any).alertEnabledRooms.map((room: IRoomDropDownList) =>{
                return {
                    id: room.id,
                    name: room.roomName,
                    selected: this.selectedEmergencyAlertServer.rooms.map((r: IRoom) => r.id).includes(room.id)
                }
            });
            (this as any).$refs['roomModelSelector'].activate(alertEnabledRoomsMap);
        },

        manageLocationsClickHandler() {
            const locationsMap = this.locationsDDL.map((x: ILocationDropDownList) => {
                return {
                    id: x.id,
                    name: x.name,
                    selected: this.selectedEmergencyAlertServer.locations.some((l: ILocation) => l.id == x.id)
                }
            });
            
            (this as any).$refs['locationModelSelector'].activate(locationsMap);
        },

        async roomSelectionChanged(rooms: IRoom[]) {
            const selectedRooms = rooms.filter((room: any)=> { 
                return room.selected
            });

            const selectedRoomsId = selectedRooms.map((a: any)=> {
                return a.id;
            });

            const hydratedSelectedRooms = (this as any).alertEnabledRooms.filter((room: any)=> {
                return selectedRoomsId.includes(room.id);
            });
            this.selectedEmergencyAlertServer.rooms = hydratedSelectedRooms;
            await (this.$refs['serverForm'] as any).validate();
        },

        async locationSelectionChanged(locations: ILocation[]) {
            const selectedLocations = locations.filter((location: any)=> { 
                return location.selected
            });

            const selectedLocationsId = selectedLocations.map((a: any)=> {
                return a.id;
            });

            const hydratedSelectedLocations = this.locationsDDL.filter((location: ILocationDropDownList)=> {
                return selectedLocationsId.includes(location.id);
            });
            this.selectedEmergencyAlertServer.locations = hydratedSelectedLocations;
            await (this.$refs['serverForm'] as any).validate();
        },

        showAlertDetails(alert: any) {
            this.selectedRecentAlertDetails = alert;
            (this as any).$refs['alertDetailsModal'].activate(alert);
            this.isAlertDetailsVisible = true;
        },

        async sendTestAlert() {
            if(this.selectedEmergencyAlertServer && this.selectedEmergencyAlertServer.id) {
                this.isAlertServerDetailsLoading = true;

                const alertInfo = {
                    alertXml: this.textXml,
                    id: this.selectedEmergencyAlertServer.id
                }
                const alertResponse = await emergencyAlertsState.sendTestAlert(alertInfo);

                if(alertResponse.success) {
                    (this as any).$message.success('Test emergency alert sent succesfully');

                    this.textXml = this.getTextXml();
                    await this.loadRecentAlerts(this.selectedEmergencyAlertServer);
                }
                else {
                    (this as any).$message.error(alertResponse.reason ? `Error sending test alert: ${alertResponse.reason}` : 'Error sending test alert');  
                }

                this.isAlertServerDetailsLoading = false;
            }
        }
    },

    async created () {
        this.isAlertServersLoading = true;
        emergencyAlertServersState = getModule(EmergencyAlertServersState);
        roomState = getModule(RoomState);
        locationState = getModule(LocationState);
        userState = getModule(UserState);
        emergencyAlertsState = getModule(EmergencyAlertsState);
        tierState = getModule(TierState);

        const responses = await Promise.all([
            locationState.fetchLocationsDDL(),
            roomState.fetchRoomsDDL(true),
            emergencyAlertServersState.fetchEmergencyAlertServers(),
            userState.fetchMe(),
            tierState.fetchTier()
        ]);


        if(responses.length > 0 && responses[0].success) {
            this.locationsDDL = responses[0].data ?? [] as ILocationDropDownList[]
        }

        if(responses.length > 1 && responses[1].success) {
            this.roomsDDL = roomState.nonLegacyRooms;
        }

        this.selectedEmergencyAlertServer = await this.loadSelectedEmergencyAlertServer();
        if(this.selectedEmergencyAlertServer && this.selectedEmergencyAlertServer.id) {
            await this.loadRecentAlerts(this.selectedEmergencyAlertServer);
        }
        this.isAlertServersLoading = false;
        
        this.$watch(() => this.$route.params, () => {
            this.$nextTick(async ()=> {
                this.selectedEmergencyAlertServer = await this.loadSelectedEmergencyAlertServer();
                await this.loadRecentAlerts(this.selectedEmergencyAlertServer);
                const serverDetailsBody = (this as any).$refs['serverDetailsBody'];
                if(serverDetailsBody) {
                    serverDetailsBody.scrollTop = 0;
                }
            });
            
        },

        { immediate: false });
    },

    watch: {
    'selectedEmergencyAlertServer': {
      handler(){
        this.changeDetection();
      },
      deep: true
    }
  }
})
