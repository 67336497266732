
import { defineComponent } from 'vue'
import DigitalAssetUpload from '../../components/DigitalAssetUpload.vue'

export default defineComponent({
    data() {
        return {
            signageItemLayoutData: {} as any
        }
    },
    
    props: {
        layoutDataEdit: {
            type: Object,
            default: null
        },
        layoutType: {
            type: String,
            default: ''
        },
        signageAssets: {
            type: Object,
            default: null
        },
    },

    components: {
        DigitalAssetUpload
    },

    computed: {
        assetUrl() {
            if (this.signageAssets && this.signageAssets.length) {
                const signageAsset = this.signageAssets.find((sa: any) => sa.id == this.signageItemLayoutData.media.asset1);
                if (signageAsset && signageAsset.thumbUrl) {
                    return signageAsset.thumbUrl;
                }
                return null;
            }
            return null;
        }
    },

    created () {
        this.signageItemLayoutData = JSON.parse(JSON.stringify(this.layoutDataEdit));
    },

    watch: {
        'signageItemLayoutData': {
            handler(newValue: any){
                this.$emit('updateLayoutData', {updatedLayoutData: newValue, shouldUpdatePreview: true});
            },
            deep: true
        },
    }
})
