import {
    Action,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';

import store from '../../store';
import { IEmergencyAlert } from '../interfaces/IEmergencyAlert';
import { getAuthInstance } from '../../auth';
import axios from 'axios';
import { ISqStoreActionResponse } from '../interfaces/ISqStoreActionResponse';

export interface IEmergencyAlertsState {
    readonly allEmergencyAlerts: IEmergencyAlert[] | undefined;
    readonly alertById: (id: string) => IEmergencyAlert | undefined;
    
    fetchEmergencyAlerts(): Promise<ISqStoreActionResponse<void>>;
    createAlert(emergencyAlert: IEmergencyAlert): Promise<ISqStoreActionResponse<IEmergencyAlert>>;
    updateAlert(emergencyAlert: IEmergencyAlert): Promise<ISqStoreActionResponse<IEmergencyAlert>>;
    sendTestAlert(testAlert: { alertXml: any; id: any; }): Promise<ISqStoreActionResponse<void>>;
}

@Module({ dynamic: true, store, name: 'emergencyAlerts' })
export default class EmergencyAlertState extends VuexModule implements IEmergencyAlertsState {

    // THESE HAVE TO BE NAMED UNIQUELY
    // THE WAY STATE WORKS IF YOU ALTER THIESE VIA CONTEXT 
    // ANY NAMED THE SAME WAY WILL CHANGE ALSO
    private emergencyAlerts: IEmergencyAlert[] = [];
    private isEmergencyAlertsLoaded = false;

    //#region GETTERS
    get allEmergencyAlerts(): IEmergencyAlert[] {
        const alerts = [...this.emergencyAlerts.map((x)=>Object.assign({}, x))];
        return alerts.sort((alertA: IEmergencyAlert)=>{
            return alertA.is_active ?  -1 : 1;
        });
    }

    get alertById(): (id: string) => IEmergencyAlert | undefined {
        return (id: string) => {
            if (!this.isEmergencyAlertsLoaded) return undefined;
            const alert = this.allEmergencyAlerts.find((x: IEmergencyAlert) => x.id === id);
            return alert ? Object.assign({}, alert) : undefined;
        };
    }
    //#endregion


    //#region ACTIONS
    @Action
    async fetchEmergencyAlerts(): Promise<ISqStoreActionResponse<void>> {
        
        if (this.isEmergencyAlertsLoaded) return { success: true };

        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            const fetchEmergencyAlertsUrl = `${process.env.VUE_APP_API_HOST}/api/emergencyalerts`;
            const axiosResponse = await axios.get(fetchEmergencyAlertsUrl, authHeader);

            this.context.commit('setEmergencyAlerts', axiosResponse.data);
            this.context.commit('setIsEmergencyAlertsLoaded', true);
            return { success: true };
        }
        catch(e:any) {
            console.error('Emergency Alerts Store Error:', e);
        }

        return { success: false, reason: 'Error fetching emergency alerts.' };
    }

    //#region ACTIONS
    @Action
    async createAlert(emergencyAlert: IEmergencyAlert): Promise<ISqStoreActionResponse<IEmergencyAlert>> {
        let failReason = 'Error creating emergency alert.';
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            const createEmergencyAlertsUrl = `${process.env.VUE_APP_API_HOST}/api/emergencyalerts`;
            const axiosResponse = await axios.post(createEmergencyAlertsUrl, emergencyAlert, authHeader);

            if(this.isEmergencyAlertsLoaded) {
                this.context.commit('setEmergencyAlerts', [...this.emergencyAlerts, axiosResponse.data]);
            }

            return { success: true, data: axiosResponse.data }
        }
        catch(e:any) {
            console.log('error', e);
            console.log('response', e.response);
            console.error('Emergency Alerts Store Error:', e);
            if(e.response && e.response.data) {
                failReason = e.response.data;
            }
        }

        return { success: false, reason: failReason };
    }

    //#region ACTIONS
    @Action
    async updateAlert(emergencyAlert: IEmergencyAlert): Promise<ISqStoreActionResponse<IEmergencyAlert>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            
            const updateEmergencyAlertsUrl = `${process.env.VUE_APP_API_HOST}/api/emergencyalerts/${ emergencyAlert.id }`;
            const axiosResponse = await axios.put(updateEmergencyAlertsUrl, emergencyAlert, authHeader);
            
            const mutatedEmergencyAlerts = [
                ...this.emergencyAlerts.filter(x=>x.id !== emergencyAlert.id),
                axiosResponse.data
            ];

            this.context.commit('setEmergencyAlerts', mutatedEmergencyAlerts);

            return { success: true, data: axiosResponse.data };
        }

        catch(e:any) {
            console.error('Emergency Alerts Store Error:', e);
        }

        return { success: false, reason: 'Error creating emergency alert.' };
    }

    //#region ACTIONS
    @Action
    async sendTestAlert(testAlert: { alertXml: any; id: any; }): Promise<ISqStoreActionResponse<void>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            authHeader.headers['CAPAlertId'] = testAlert.id;
            authHeader.headers['Content-Type'] = 'application/xml';
            
            const sendTestAlertUrl = `${process.env.VUE_APP_API_HOST}/api/emergencyalerts/newTest`;
            await axios.post(sendTestAlertUrl, testAlert.alertXml, authHeader);

            return { success: true };
        }

        catch(e:any) {
            console.error('Emergency Alerts Store Error:', e);
        }

        return { success: false, reason: 'Error sending test alert.' };
    }

    //#region MUTATIONS
    @Mutation
    async setEmergencyAlerts(emergencyAlerts: IEmergencyAlert[]): Promise<void> {
        this.emergencyAlerts = emergencyAlerts;
    }

    @Mutation
    async setIsEmergencyAlertsLoaded(isLoaded: boolean): Promise<void> {
        this.isEmergencyAlertsLoaded = isLoaded;
    }
    //#endregion
}
