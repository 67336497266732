
import { defineComponent } from 'vue';
import moment from 'moment';
import Chart from 'chart.js';
import { v4 as uuidv4 } from 'uuid';

export default defineComponent({
    name: 'UsageStatBarGraph',
    data () {
        return {
            chartLoading: true,
            chartId: uuidv4(),
            chart: {},
            innerLoading: true,
            sumLabel: 0
        }
    },
    computed: {
        noStats() {
            if(this.loading || this.trialMode) return false;
            for (const data of this.data) {
                if(data.response && data.response.length) {
                    const hasData = data.response.some((x: any)=>{
                        if(x.length == 2 && x[0]) {
                            return true;
                        }
                        return false;
                    });

                    if(hasData) return false;
                }
            }
            return true;
        }
    },

    props: ['title', 'subTitle', 'groupBy', 'loading', 'height', 'width', 'dataPointStyle', 'data', 'enableSumLabel', 'durationType', 'trialMode'],
    methods: {
        getDates(arr: any[]) {
            if(!arr || !arr.length) return;

            const dates = [];
            const format = this.groupBy === 'hour' ? 'hh:mm A' : 'MMM DD';

            for(const data of arr) {
                const convertedTime = moment.unix(data[1]).format(format);
                dates.push(convertedTime);
            }
            return dates;
        },
        getHits: function (arr: any[]) {
            if(!arr || !arr.length) return;
            
            const hits = [];
            let totalHits: any = 0
            for(const data of arr) {
                const val = data[0] ? data[0] : 0;
                hits.push(val.toFixed(1));
                totalHits = totalHits + Math.round(val.toFixed(1));
            }

            this.sumLabel = !this.durationType ? totalHits : this.formatDurationWithPadding(moment.duration(totalHits, 'seconds'));
            return hits;
        },
        buildChart(chartDOMElement: any, data: any) {
            if(!data) return;

            return new Chart(chartDOMElement, {
                type: 'bar',
                data: {
                    labels: this.getDates(data),
                    datasets: this.dataPointStyle.map((x: any) => {return {
                        label: x.label,
                        data: this.getHits(data),
                        backgroundColor: x.backgroundColor,
                        borderColor: x.borderColor,
                        borderWidth: 1
                    }})
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    tooltips: {
                        mode: 'x-axis',
                        displayColors: false,
                        callbacks: {
                            label: (tooltipItems) => {
                                if(this.durationType)
                                {
                                    return this.formatDurationWithPadding(moment.duration(tooltipItems.yLabel, 'second'))
                                }
                                return tooltipItems.yLabel as any;
                            },
                            title: (tooltipItem) => {
                                if(this.groupBy === 'week') {
                                    return `Week of ${tooltipItem[0].xLabel}`;
                                }
                                return tooltipItem[0].xLabel as string;
                            }
                        }
                    },
                    hover: {
                        mode: 'x-axis'
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                fontSize: 10,
                                beginAtZero: true,
                                callback: (value: any) => {
                                    if(this.durationType) {
                                        return this.formatDurationWithPadding(moment.duration(value, this.durationType)); 
                                    }
                                    
                                    if (value % 1 === 0) { return Math.round(value); }
                                }
                            },
                            gridLines: {
                                color: 'rgba(6, 75, 74, 0.1)',
                                zeroLineColor: 'rgba(6, 75, 74,0.1)'
                            },
                        }],
                        xAxes: [{
                            ticks: {
                                fontSize: 10,
                                autoSkipPadding: 40
                            },
                            gridLines: {
                                drawOnChartArea: false
                            },
                        }]
                    }
                }
            });
        },
        formatDurationWithPadding (duration: moment.Duration) {
            const formattedHours = (Math.floor(duration.asHours())).toString().length > 2 ?
                (Math.floor(duration.asHours())) :
                '0' + (Math.floor(duration.asHours()));

            return [
                formattedHours,
                ('0' + duration.minutes()).slice(-2),
                ('0' + duration.seconds()).slice(-2),
            ].join(':')
        }
    },
    mounted() {
        this.$nextTick(() => {
            const ctx1 = document.getElementById(this.chartId);
            this.chart = this.buildChart(ctx1, this.data) as any;
            this.innerLoading = false;
        });
    },
    watch: {
        data () {
            if(!this.data || !this.data.length) return;

            this.innerLoading = true;
            let maxLength = 0;

            for(let i=0; i < this.data.length; i++) {

                if(!(this.chart as any).data.datasets[i]) (this.chart as any).data.datasets.push({});
                (this.chart as any).data.datasets[i].data = this.getHits(this.data[i].response);
                
                if(this.data[i].response.length > this.data[maxLength].response) {
                    maxLength = i;
                }
            }
            
            (this.chart as any).data.labels = this.getDates(this.data[maxLength].response);
            (this.chart as any).update();
            this.innerLoading = false;
        }
    }

});
