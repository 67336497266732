
import { defineComponent } from 'vue'
import axios from 'axios';
import { mdiMagnify } from '@mdi/js'
import Icon from '../Icon.vue'

export default defineComponent({
    name: "WeatherTodayLayoutEditor",
    data: ()=>({
        displayLightbox: false,
        popoutPreview: false,
        editLocation: false,
        newLocation: '',
        locations: [] as any[],
        signageItemLayoutData: {} as any,
        mdiMagnify
    }),

    components: {
        Icon
    },

    props: {
        layoutDataEdit: {
            type: Object,
            default: null
        },
    },

    methods: {
        searchLocations(location: any, cb: any) {
            if(location !== '') {  
                axios.get(`https://api.weatherapi.com/v1/search.json?key=06b4f1656f984044bac135452233108&q=${location}`).then((response)=>{
                    if(response.data) {  
                        cb(response.data);
                    }
                });
             }
             else {
                 this.locations = [];
             }
        },

        handleLocationSelect(location: any) {
            this.newLocation = location
            this.signageItemLayoutData.location = location;
            this.editLocation = false;
            this.newLocation = '';
        },

        onEditLocation() {
            if(this.editLocation) {
                this.editLocation = false;
                this.newLocation = '';
            }
            else {
                this.editLocation = true;
            }
        }
    },

    created (){
        this.signageItemLayoutData = JSON.parse(JSON.stringify(this.layoutDataEdit));
    },

    watch: {
        'signageItemLayoutData': {
            handler(newValue: any){
                this.$emit('updateLayoutData', {updatedLayoutData: newValue, shouldUpdatePreview: false});
            },
            deep: true
        }
    }
});
