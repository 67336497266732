import { RouteLocation } from 'vue-router';
import { getAuthInstance } from './authService';
import OnboardStatusState from '../store/modules/onboarding';
import { getModule } from 'vuex-module-decorators';
import RoleState, { IRoleState } from '../store/modules/role';

// eslint-disable-next-line @typescript-eslint/ban-types
export const authGuard = async (to: RouteLocation, from: RouteLocation, next: Function): Promise<any> => {
    const authService = getAuthInstance();
    const fn = async (to: RouteLocation) => {
        if (to.matched.some(record => record.meta.noAuth)) {
            return next();
        }
    
        if (authService.isAuthenticated) {
            const onboardStatusState = getModule(OnboardStatusState);
            await onboardStatusState.fetchOnboardStatus();
            if(onboardStatusState.onboardStatusLoaded && onboardStatusState.userOnboardStatus.isOnboarding && onboardStatusState.userOnboardStatus.showDialog && 
                onboardStatusState.currentStep && onboardStatusState.currentStep.routerGuard && (to.path !== onboardStatusState.currentStep.routerGuard)) {
                return next(onboardStatusState.currentStep.routerGuard);
            }
            
            const roleState = getModule(RoleState) as IRoleState;
            await roleState.fetchUserRole();
            let authorized = false;

            if(roleState) {
                authorized = authService.checkRoleApplicationAccess(to.fullPath, roleState);
            }

            if(!authorized) {
                return next('/unauthorized');
            }

            return next();
        }
        if(!authService.isAuthenticated) {
            authService.loginWithRedirect();
        }
    };
    while(!authService || authService.isLoading) {
        await new Promise<void>(resolve => setTimeout(()=>resolve(), 500)).then(()=>console.log("fired"));
    }
    if(!authService.isLoading) {
        return fn(to);
    }
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const unAuthGuard = async (to: RouteLocation, from: RouteLocation, next: Function): Promise<any> => {
    const authService = getAuthInstance();
    const fn = async () => {
        if(!authService.isAuthenticated) {
            return next();
        }

        // redirect to dashboard
        return next('/');
    };
    while(!authService || authService.isLoading) {
        await new Promise<void>(resolve => setTimeout(()=>resolve(), 500)).then(()=>console.log("fired"));
    }
    if(!authService.isLoading) {
        return fn();
    }
};