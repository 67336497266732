
import { defineComponent } from 'vue';
import { getModule } from 'vuex-module-decorators'

import RoomState, { IRoomState } from '../../../store/modules/rooms';
import { IRoom } from '../../../store/interfaces/IRoom';

let roomState = {} as IRoomState;

export default defineComponent({
    name: 'RoomBackground',
    data() {
        return {
            room: {} as IRoom,
            roomId: '' as string,
            element: {} as any,
            context: {} as any,
        }
    },

    async created () {
        roomState = getModule(RoomState);
        this.room = (await roomState.fetchRoom({id:this.$route.params.id as string, reload: false})).data ?? {} as IRoom ;

        this.element = this.$refs.roomBackgroundCanvas;
        this.context = this.element.getContext("2d");

        const img = new Image();
        img.src = `${window.location.protocol}//${window.location.hostname}/images/ditto-room-background.png`;

        img.onload = () => {
            if(this.context) {
                this.context.drawImage(img, 0,0);
                this.context.fillStyle ="#FFF";
                this.context.font = "88px Helvetica";

                const roomName = this.$refs.roomName as any;
                const roomCode = this.$refs.roomCode as any;

                this.context.fillText(roomName.innerHTML, (2560-((roomName.getBoundingClientRect().width*2)/2)), 2000);
                this.context.fillText(roomCode.innerHTML, (2560-((roomCode.getBoundingClientRect().width*2)/2)), 2200);

                const dataURL = this.element.toDataURL();
                const roomBackgroundCanvasImage = this.$refs.roomBackgroundCanvasImage as any;
                roomBackgroundCanvasImage.src = dataURL;
            }
        };
    }
});

