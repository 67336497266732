export interface IUIValidationService {
    requiredValidator(formModel: any): any;
    maxLengthValidator(formModel: any, maxLength: number): any;
    minLengthValidator(formModel: any, minLength: number): any;
    betweenLengthValidator(formModel: any, minLength: number, maxLength: number): any;
    betweenNumberValidator(formModel: any, min: number, max: number): any;
    creditCardNumberValidator(formModel: any): any;
}

export class UIValidationService implements IUIValidationService {
    private visaPattern = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    private mastPattern = /^(?:5[1-5][0-9]{14})$/;
    private amexPattern = /^(?:3[47][0-9]{13})$/;
    private discPattern = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/; 
    
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/explicit-module-boundary-types
    requiredValidator(formModel: any): any {
        return (rule: any, value: any, callback: any) => {
            const modelValue = eval(`formModel.${rule.fullField}`);
            if(!modelValue || modelValue === '') return callback(new Error(rule.message));
            return callback();
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/explicit-module-boundary-types
    maxLengthValidator(formModel: any, maxLength: number): any {
        return (rule: any, value: any, callback: any) => {
            const modelValue = eval(`formModel.${rule.fullField}`);
            if(!modelValue || modelValue.length > maxLength) return callback(new Error(rule.message));
            return callback();
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/explicit-module-boundary-types
    minLengthValidator(formModel: any, minLength: number): any {
        return (rule: any, value: any, callback: any) => {
            const modelValue = eval(`formModel.${rule.fullField}`);
            if(!modelValue || modelValue.length < minLength) return callback(new Error(rule.message));
            return callback();
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/explicit-module-boundary-types
    betweenLengthValidator(formModel: any, minLength: number, maxLength: number): any {
        return (rule: any, value: any, callback: any) => {
            const modelValue = eval(`formModel.${rule.fullField}`);
            if(!modelValue || modelValue.length > maxLength || modelValue.length < minLength) return callback(new Error(rule.message));
            return callback();
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/explicit-module-boundary-types
    betweenNumberValidator(formModel: any, min: number, max: number): any {
        return (rule: any, value: any, callback: any) => {
            const modelValue = eval(`formModel.${rule.fullField}`);
            if(!modelValue || modelValue > max || modelValue < min) return callback(new Error(rule.message));
            return callback();
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/explicit-module-boundary-types
    creditCardNumberValidator(formModel: any): any {
        return (rule: any, value: any, callback: any) => {
            const modelValue = eval(`formModel.${rule.fullField}`);

            if(
                modelValue &&
                (this.visaPattern.test(modelValue) || 
                this.mastPattern.test(modelValue) ||
                this.amexPattern.test(modelValue) ||
                this.discPattern.test(modelValue))
            ) {
                return callback();
            }
            
            return callback(new Error(rule.message));
        }
    }
}

export default new UIValidationService();

