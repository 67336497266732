import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_leftsection = _resolveComponent("leftsection")!
  const _component_rightsection = _resolveComponent("rightsection")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "item",
        style: _normalizeStyle(_ctx.backgroundStyle)
      }, [
        _createVNode(_component_leftsection, {
          style: _normalizeStyle(_ctx.widthStyle)
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.contentClass, _ctx.item.useImage ? _ctx.leftAlignClass : _ctx.rightAlignClass])
            }, [
              _createElementVNode("p", {
                style: _normalizeStyle({ color: _ctx.item.fontColor }),
                class: "item__headline"
              }, _toDisplayString(_ctx.item.headline), 5),
              _createElementVNode("p", {
                style: _normalizeStyle({ color: _ctx.item.fontColor }),
                class: "item__subhead"
              }, _toDisplayString(_ctx.item.subhead), 5)
            ], 2)
          ]),
          _: 1
        }, 8, ["style"]),
        (_ctx.item.useImage)
          ? (_openBlock(), _createBlock(_component_rightsection, {
              key: 0,
              style: _normalizeStyle({ background: 'url('+_ctx.item.media.asset1_url+') center center no-repeat', backgroundSize: 'cover'})
            }, null, 8, ["style"]))
          : _createCommentVNode("", true)
      ], 4))
    : _createCommentVNode("", true)
}