
import { defineComponent } from 'vue';
import { mdiPencilOutline } from '@mdi/js';

export default defineComponent({
    data() {
        return {
            signageItemLayoutData: {} as any,
            mdiPencilOutline
        }
    },

    props: {
        layoutDataEdit: {
            type: Object,
            default: null
        },
        layoutType: {
            type: String,
            default: ''
        },
        signageAssets: {
            type: Object,
            default: null
        },
        isCreatingNewSignageItem: {
            type: Boolean
        }
    },

    created () {
        this.signageItemLayoutData = JSON.parse(JSON.stringify(this.layoutDataEdit));
    }
})
