
import { defineComponent } from 'vue';
import { IModelSelectionPool } from '../store/interfaces/IModelSelectionPool';
import { mdiCheckBold, mdiAlertCircle, mdiSwapHorizontal } from '@mdi/js';

export default defineComponent({
    name: 'TwoColumnSelector',
    data: () => ({
        selectionPool: [] as IModelSelectionPool[],
        selectionPoolSearch: '',
        selectedPoolSearch: '',
        mdiSwapHorizontal,
        mdiCheckBold,
        mdiAlertCircle

    }),
    computed: {
        isDisabled: function() {
            return true;
        },

        searchPlaceholder: function(): string {
            return 'Search ' + this.$props.modelTypeName;
        },

        saveButtonText(): string {
            return this.addButton? 'Add' : 'Save';
        }
    },

    props: {
        // For v-model
		modelValue: String,

        loading: Boolean,

        title: String,

        subTitle: String,

        modelTypeName: String,

        showRemoveRoomMessage: Boolean,

        addButton: Boolean
    },
    
    emits: ['update:modelValue', 'selection-pool-change'],

    methods: {
        activate(selectionPoolInput: IModelSelectionPool[]) {
            this.selectionPool = Object.assign([], selectionPoolInput);
        },

        selectionPoolClick(item: IModelSelectionPool) {
            if(item.selected) return;
            this.selectionPool[this.selectionPool.indexOf(item)].selected = true;
            this.$emit('selection-pool-change', this.selectionPool);
        },
        
        selectedPoolClick(item: IModelSelectionPool) {
            if(!item.selected) return;
            this.selectionPool[this.selectionPool.indexOf(item)].selected = false;
            this.$emit('selection-pool-change', this.selectionPool);
        }
    }
})
