
import { defineComponent } from 'vue';
import { getModule } from 'vuex-module-decorators';
import SubscriptionState, { ISubscriptionState, IUpdateSubscriptionInput , ISubscriptionCreationInupt, ICalculateSubscriptionAdjustmentInput} from '../../store/modules/subscriptions';
import TierState, { ITierState } from '../../store/modules/tier';
import { ITier, ICheckTierChangePayload } from '../../store/interfaces/ITier';
import Icon from "../../components/Icon.vue";
import { mdiAlertCircleOutline } from '@mdi/js';
import moment from 'moment';
import SubscriptionPaymentModal from '../billing/SubscriptionPaymentModal.vue';

let subscriptionState = {} as ISubscriptionState;
let tierState = {} as ITierState;

export default defineComponent({
    name: 'AdjustPlanSubscription',
    data() {
        return {
            isLoading: true,
            isUpdating: false,
            allowChange: true,
            featuresBlockChange: false,
            isUpdateModalVisible: false,
            isStartSubscriptionModalVisible: false,
            limitsOverage: [],
            usedFeatures: [],
            mdiAlertCircleOutline,
            newSubscriptionTotal: '' as string,
            isUpdateLoading: false,
            nowDateTime: moment.utc(),
            keyIndex: 0,
            isDailyChecked: false
        }
    },

    components: {
        Icon,
        SubscriptionPaymentModal
    },

    props: [
        'plan', 
        'organization', 
        'isPO', 
        'numberOfReceivers', 
        'billingFrequency',
        'currentReceiverCount', 
        'isUpdate', 
        'isCurrentPlan', 
        'hasBilling',
        'allowPurchaseOrChange'
    ],
   
    computed: {
        planDescription() {
            switch (this.plan.id) {
                case 'basic':
                    return 'Get started with screen mirroring and digital signage.';
                
                case 'premium':
                    return 'Get more features and screens to boost content sharing.';

                case 'elite':
                    return "Get everything you'll ever need.";
            
                default:
                    return "Get started with screen mirroring and digital signage.";
            }
        },

        planYearlyPrice() {
            const product = subscriptionState.allSubscriptionPlans.find((x:any) => x.id === this.plan.stripeProductId );
            if(product) {
                const pricing = product.pricing.find((x:any) => x.recurring.interval === 'year');
                if(pricing) {
                    return (pricing.unit_amount/100).toFixed(2);
                }
            }

            return '0.00';
        },

        planMonthlyPrice() {
            const product = subscriptionState.allSubscriptionPlans.find((x:any) => x.id === this.plan.stripeProductId );
            if(product) {
                const pricing = product.pricing.find((x:any) => x.recurring.interval === 'month');
                if(pricing) {
                    return (pricing.unit_amount/100).toFixed(2);
                }
            }

            return '0.00';
        },

        planDailyPrice() {
            const product = subscriptionState.allSubscriptionPlans.find((x:any) => x.id === this.plan.stripeProductId );
            if(product) {
                const pricing = product.pricing.find((x:any) => x.recurring.interval === 'day');
                if(pricing) {
                    return (pricing.unit_amount/100).toFixed(2);
                }
            }

            return '0.00';
        },

        hasDailyPrice() {
            const product = subscriptionState.allSubscriptionPlans.find((x:any) => x.id === this.plan.stripeProductId );
            if(product) {
                const pricing = product.pricing.find((x:any) => x.recurring.interval === 'day');
                if(pricing) {
                    return true;
                }
            }

            return false;
        },

        planPrice() {
            switch (this.billingFrequency){
                case 'year':
                    return this.planYearlyPrice;
                case 'month': 
                    return this.planMonthlyPrice;
                case 'day':
                    return this.planDailyPrice;
                default:
                    return '0.00'
            }
        },

        planUserLimit(): string {
            let limit = this.plan.restrictionUsers ?? 0;
            return `${limit === 0 ? 'Unlimited' : limit === -1 ? 'No' : limit}  Ditto Account Portal user${limit === 1 ? '' : 's'}`;
        },
        
        planReceiverLimit(): string {
            let limit = this.plan.restrictionReceivers ?? 0;
            return `${limit === 0 ? 'Unlimited' : limit === -1 ? 'No' : limit} ${limit === 0 || limit === -1 ? 'r' : 'R'}eceiver${limit === 1 ? '' : 's'}`;
        },

        planSignageItemLimit(): string {
            let limit = this.plan.restrictionSignageItems ?? 0;
            return `${limit === 0 ? 'Unlimited' : limit === -1 ? 'No' : limit} ${limit === 0 || limit === -1 ? 's' : 'S'}ignage item${limit === 1 ? '' : 's'}`;
        },

        planStorageLimit(): string {
            let limit = this.plan.restrictionStorageGBPerRec ?? 0;
            return `${limit === 0 ? 'Unlimited' : limit === -1 ? 'No' : `${limit} GB of`} storage/receiver`;
        },

        receiverCount: {
            get(){
                return this.numberOfReceivers;
            },

            set(newValue: any){
                this.$emit('updateReceiverCount', newValue);
            }
        },
    
        yearlyBilling: {
            get(){
                return this.billingFrequency;
            },

            set(newValue: any){

                this.$emit('updateFequency', newValue);
            }
        },

        frequencySelection: {
            get(){
                return this.billingFrequency;
            },

            set(newValue: any){
                this.$emit('updateFequency', newValue);
            }
        },

        totalPrice(): string {
            return `${ this.calculatedPrice }/${ this.billingFrequency }`;
        },

        isInPlanChangeWindow(): boolean {
            const startWindow = moment.utc(this.organization.billing.expiration_date).subtract(30, 'days');
            return moment(this.nowDateTime).isSameOrAfter(moment(startWindow));
        },

        //calcauting overage usages
        amountOverUsersQuota(): number {
            let restriction = this.plan.restrictionUsers ?? 0;
            if(this.isPO || restriction == 0 || restriction > tierState.orgTierUsage.userCount) {
                return 0;
            }
            return tierState.orgTierUsage.userCount - restriction;
        },

        amountOverReceiversQuota(): number {
            let restriction = this.plan.restrictionReceivers ?? 0;
            if(this.isPO || restriction == 0 || restriction > tierState.orgTierUsage.receiverCount) {
                return 0;
            }
            return tierState.orgTierUsage.receiverCount - restriction;
        },  

        amountOverSignageQuota(): number {
            let restriction = this.plan.restrictionSignageItems ?? 0;
            if(this.isPO || restriction == 0 || restriction > tierState.orgTierUsage.signageItemCount) {
                return 0;
            }
            return tierState.orgTierUsage.signageItemCount - restriction;
        },  

        amountOverStorageQuota(): number {
            let restriction = this.plan.restrictionStorageGBPerRec * this.numberOfReceivers ?? 0;
            
            if(this.isPO || restriction == 0 || restriction > tierState.orgTierUsage.storageUsedGB) {
                return 0;
            }
            return tierState.orgTierUsage.storageUsedGB - restriction;
        },

        calculatedPrice(): string {
            let newPrice = 0;
            switch(this.billingFrequency){
                case 'year':
                    newPrice = Number(this.planYearlyPrice) * this.receiverCount;
                    break
                case 'month':
                    newPrice = Number(this.planMonthlyPrice) * this.receiverCount;
                    break
                case 'day':
                    newPrice = Number(this.planDailyPrice) * this.receiverCount;
                    break
            }

            return newPrice.toFixed(2);
        },

        noChanges(): boolean {
            return tierState.orgTier.id == this.plan.id && this.receiverCount == this.currentReceiverCount && this.billingFrequency == this.organization.billing.billingFrequency;
        },

        updateButtonText(): string {
            return this.plan.id == tierState.orgTier.id ? this.noChanges ? 'Current Plan' : 'Buy Now' : `Purchase ${this.plan.name}`;
        },

        lessThanActiveReceivers(): boolean {
            return this.receiverCount < tierState.orgTierUsage.receiverCount;
        },

        receiverCountAllowed(): boolean {
            if(this.plan.restrictionReceivers !== 0 && this.receiverCount > this.plan.restrictionReceivers) {
                return false;
            }

            if(this.lessThanActiveReceivers) {
                return false;
            }

            return true;
        },

        isDowngrade() {
            if(!this.newSubscriptionTotal) {
                return true;
            }
            return this.newSubscriptionTotal.includes('-');
        },

        expirationDate(): string {
            const utcExp = moment.utc(this.organization.billing.expiration_date);
            const localExp = moment(utcExp).local().toDate();
            return moment(localExp).format('MM/DD/YYYY');
        },

        calculatedYearlyDiscount(): string {
            const monthlyPerYear = Number(this.planMonthlyPrice) * 12;
            const percentage = (monthlyPerYear - Number(this.planYearlyPrice)) / (monthlyPerYear) * 100;
            return `${Math.round(percentage)}% off`;
        }
    },
    
    methods: {
        goToComparePlans() {
            this.$emit('goToComparePlans', 'comparePlans');
        },

        async checkPlan() {
           if(this.isPO || (tierState.orgTier.id == this.plan.id)) { return; }

           let proposedOrganization = Object.assign({}, this.organization);
           proposedOrganization.billing.subscription_limit = this.numberOfReceivers;
           
            let payload = {
                organization: proposedOrganization,
                oldTier: tierState.orgTier,
                newTier: this.plan as ITier,
                newSubscriptionLimit: this.numberOfReceivers
            } as ICheckTierChangePayload

            const planCheckResponse = await tierState.checkTierChange(payload);

            if(!planCheckResponse.success) {
                (this as any).$message.error(planCheckResponse.reason ? `${planCheckResponse.reason}` : 'Checking plan change failed.');
                return;
            }
           
            this.allowChange = planCheckResponse.data.allowChange;
            if(this.allowChange) { return; }

            this.featuresBlockChange = planCheckResponse.data.featureUsedIds && planCheckResponse.data.featureUsedIds.length > 0;

            if(planCheckResponse.data.overRestrictionsIds && planCheckResponse.data.overRestrictionsIds.length > 0) {
                const limits: any = tierState.featuresList.filter((limit: any) => planCheckResponse.data.overRestrictionsIds.includes(limit.id)).map((x: any) => x.name);
                this.limitsOverage = limits;
            }

            if(planCheckResponse.data.featureUsedIds && planCheckResponse.data.featureUsedIds.length > 0) {    
                const features: any = tierState.featuresList.filter((feature: any) => planCheckResponse.data.featureUsedIds.includes(feature.id)).map((x: any) => x.name);
                this.usedFeatures = features;
            }

            return;
        },

        async onUpdateClick(isUpdate: boolean) {
            await this.checkPlan();
            if(isUpdate) {
                this.isUpdateModalVisible = true;
                this.isUpdateLoading = true;

                this.nowDateTime = moment.utc();

                const payload = {
                    customerId: this.organization.billing.customer_id,
                    quantity: this.receiverCount,
                    tierId: this.plan.stripeProductId,
                    billingFrequency: this.billingFrequency
                } as ICalculateSubscriptionAdjustmentInput;

                const amount = await subscriptionState.calculateSubscriptionAdjustment(payload);
            
                if(amount.data) {
                    this.newSubscriptionTotal = amount.data;
                }

                this.isUpdateLoading = false;
            }
            else {
                this.newSubscriptionTotal = '';
                this.isStartSubscriptionModalVisible = true;
            }
        },
    
        async updateSubscription() {
            if(!this.receiverCountAllowed) { return; }
           
            this.$emit('isSaving', true);

            this.isUpdating = true;
            const payload = {
                    customerId: this.organization.billing.customer_id,
                    tier: this.plan,
                    quantity: this.receiverCount,
                    organization: this.organization,
                    calculatedTotal: this.newSubscriptionTotal,
                    billingFrequency: this.billingFrequency
                } as IUpdateSubscriptionInput;
          
            const subscriptionResponse = await subscriptionState.updateSubscription(payload);

            if(!subscriptionResponse.success) {
                (this as any).$message.error("Subscription Update Failed", subscriptionResponse.reason);
            }

            await this.checkPlan();

            this.isUpdating = false;
            this.isUpdateModalVisible = false;

            this.$emit('isSaving', false);
            this.$emit('updateSubscription', subscriptionResponse.success);
        },

        async startSubscription() {
            this.isStartSubscriptionModalVisible = false;

            this.$emit('isSaving', true);

            this.isUpdating = true;

            const payload = {
                customer: this.organization.billing.customer_id,
                planId: this.plan.id,
                productId: this.plan.stripeProductId,
                quantity: this.receiverCount,
                billingFrequency: this.billingFrequency
            } as ISubscriptionCreationInupt;

            const startSubscriptionResponse = await subscriptionState.startSubscription(payload);

            if(!startSubscriptionResponse.success) {
                (this as any).$message.error(startSubscriptionResponse.reason ? `${startSubscriptionResponse.reason}` : 'Starting subscription failed.');
            }

            this.isUpdating = false;

            this.$emit('isSaving', false);
            this.$emit('updateSubscription', startSubscriptionResponse.success);
        },

        moreHelpClick() {
            this.$emit('requestPO');
        }
    },

    async created() {
        subscriptionState = getModule(SubscriptionState);
        tierState = getModule(TierState);

        await this.checkPlan();

        this.isLoading = false;
    }
});

