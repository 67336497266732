import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DigitalAssetUpload = _resolveComponent("DigitalAssetUpload")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    "hide-required-asterisk": "",
    ref: "form",
    "label-position": "top",
    model: _ctx.signageItemLayoutData
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_form_item, {
          class: "col-lg-6",
          label: "Top Left Media"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DigitalAssetUpload, {
              allowVideo: "true",
              sizeLimitMB: "300",
              layoutProperty: "quadrant1",
              layoutType: _ctx.layoutType,
              assetUrl: _ctx.assetUrl[0]
            }, null, 8, ["layoutType", "assetUrl"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          class: "col-lg-6",
          label: "Top Right Media"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DigitalAssetUpload, {
              allowVideo: "true",
              sizeLimitMB: "300",
              layoutProperty: "quadrant2",
              layoutType: _ctx.layoutType,
              assetUrl: _ctx.assetUrl[1]
            }, null, 8, ["layoutType", "assetUrl"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_el_form_item, {
          class: "col-lg-6",
          label: "Bottom Left Media"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DigitalAssetUpload, {
              allowVideo: "true",
              sizeLimitMB: "300",
              layoutProperty: "quadrant3",
              layoutType: _ctx.layoutType,
              assetUrl: _ctx.assetUrl[2]
            }, null, 8, ["layoutType", "assetUrl"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          class: "col-lg-6",
          label: "Bottom Right Media"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DigitalAssetUpload, {
              allowVideo: "true",
              sizeLimitMB: "300",
              layoutProperty: "quadrant4",
              layoutType: _ctx.layoutType,
              assetUrl: _ctx.assetUrl[3]
            }, null, 8, ["layoutType", "assetUrl"])
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["model"]))
}