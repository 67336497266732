
import { defineComponent } from 'vue';
import moment from 'moment';
import Chart from 'chart.js';
import RoomState, { IRoomState } from '../store/modules/rooms';
import { getModule } from 'vuex-module-decorators';
import UserState, { IUserState } from '../store/modules/users';
import StatState, { IStatState, IStatFilterOptionModel, IStatRangeSelectionModel, IStatQueryModel } from '../store/modules/stats';
import UsageStatBarGraph from './UsageStatBarGraph.vue';
import { IRoomDropDownList, IRoomListDisplay } from '../store/interfaces/IRoom';
import { ISqStoreActionResponse } from '../store/interfaces/ISqStoreActionResponse';
import { ILocationDropDownList } from '../store/interfaces/ILocation';
import { IModelSearchResponse } from '../store/interfaces/IModelSearch';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

let statState = {} as IStatState;
let roomState = {} as IRoomState;
let userState = {} as IUserState;

export default defineComponent({
    name: 'OrganizationReports',
    data(){
        return {
            pageLoading: true,
            dateRange: [moment.utc().subtract(7, 'days').toDate(), moment.utc().toDate()],
            presetValues: [
                { 
                    label: 'Today', 
                    value: [moment.utc(), moment.utc()]
                },
                {
                    label: 'This week',
                    value: [moment.utc().subtract(7, 'days'), moment.utc()]
                },
                {
                    label: 'Last 30 days',
                    value: [moment.utc().subtract(30, 'days'), moment.utc()]
                },
                {
                    label: 'Last 60 days',
                    value: [moment.utc().subtract(60, 'days'), moment.utc()]
                },
                {
                    label: 'Last 90 days',
                    value: [moment.utc().subtract(90, 'days'), moment.utc()]
                },
                {
                    label: 'Last 120 days',
                    value: [moment.utc().subtract(120, 'days'), moment.utc()]
                },
                {
                    label: 'This year',
                    value: [moment().startOf('year').utc(), moment.utc()]
                }
            ] as any,
            roomSelectionOptions: [] as IRoomDropDownList[],
            roomSelectionIdx: 0,
            roomsWithLocations: [] as IRoomListDisplay[], 
            locationSelectionOptions: [] as ILocationDropDownList[],
            locationSelectionIdx: 0,
            groupBy: 'hour',
            groupings: [
                {
                    value: 'hour',
                    label: 'Hours'
                },
                {
                    value: 'day',
                    label: 'Days'
                },
                {
                    value: 'week',
                    label: 'Weeks'
                }
            ],
            charts: {
                totalConnectionData: [[]] as number[][] | null,
                totalConnectionsLoading: true,
                totalTimeMirroringData: [[]] as number[][] | null,
                totalTimeMirroringLoading: true,
                averageTimeMirroringData: [[]] as number[][] | null,
                averageTimeMirroringLoading: true,
                dittoConnectUsageData: [[]] as number[][] | null,
                dittoConnectUsageLoading: true,
            },
            trialData:[[
                3,
                moment().subtract(6, 'days').unix()
            ],
            [
                15,
                moment().subtract(5, 'days').unix()
            ],
            [
                31,
                moment().subtract(4, 'days').unix()
            ],
            [
                21,
                moment().subtract(3, 'days').unix()
            ],
            [
                19,
                moment().subtract(2, 'days').unix()
            ],
            [
                12,
                moment().subtract(1, 'days').unix()
            ],
            [
                4,
                moment().unix()
            ]]
        }
    },

    components: {
        UsageStatBarGraph,
        VueDatePicker
    },

    props: {
        organizationId: {
            type: String,
            default: ''
        },

        border: {
            type: Boolean,
            default: false
        },

        locationFilter: {
            type: Boolean,
            default: false
        },

        roomFilter: {
            type: Boolean,
            default: false
        },
        showExampleData: {
            type: Boolean,
            deafult: false
        }
    },

    computed: {
        filteredRoomSelectionOptions(): IStatFilterOptionModel[] {
            if(!this.roomFilter) return [];

            if(!this.roomSelectionOptions || this.roomSelectionOptions.length < 1) return [{option: 'All', value:'', idx:0}];
            let i = 1;
            const roomOptions = this.roomSelectionOptions.map(x => {
                return {
                    option: x.roomName,
                    value: x.id,
                    idx: i++
                }
            })

            roomOptions.unshift({option: 'All', value:'', idx:0});

            return roomOptions;
        },

        filteredLocationSelectionOptions(): IStatFilterOptionModel[] {
            if(!this.locationFilter) return [];

            if(!this.roomsWithLocations || this.roomsWithLocations.length < 1) return [{option: 'All', value:'', idx:0}];

            let i = 1;
            let locationOptions = this.roomsWithLocations.map((x: IRoomListDisplay) => {
                return {
                    option: x.location.name,
                    value: x.location.id,
                    idx: i++
                }
            })

            locationOptions = locationOptions.filter((value, index, self) => {
                return index === self.findIndex((t)=>(t.value === value.value));
            });

            locationOptions.unshift({option: 'All', value:'', idx:0});

            return locationOptions;
        },

        isTrial(): boolean {
            const me = userState.me;
            if(!(me && me.organization && me.organization.billing)) return true;
            if(me.organization.billing.billing_type.toLowerCase() !== 'trial' && this.showExampleData) return true;
            return me.organization.billing.billing_type.toLowerCase() === 'trial';
        },

        dateDayDiff(): number {
            return moment.duration(moment(this.dateRange[1]).diff(moment(this.dateRange[0]))).asDays();
        },

        minDate() {
            const aYearAgo = moment.utc().subtract(1, 'years');
            const dataMinDate = moment('1/1/2023').utc();

            if(dataMinDate.isBefore(aYearAgo)) {
                return aYearAgo.toDate();
            }
            return dataMinDate.toDate();
        }
    },

    methods: {
        setGroupBy() {
            if(this.dateDayDiff <= 1 ) {
                this.groupBy = 'hour';
            }
            else if(this.dateDayDiff <= 31) {
                this.groupBy = 'day';
            }
            else {
                this.groupBy = 'week'
            }
        },

        groupingAllowed(group: string): boolean {
            let allowed = false as boolean;

            switch(group) {
                case 'hour':
                    if(this.dateDayDiff <= 3) {
                        allowed = true;
                    } 
                    break;
                case 'day':
                    if(this.dateDayDiff > 0 && this.dateDayDiff <= 31) {
                        allowed = true;
                    } 
                    break;
                case 'week':
                    if(this.dateDayDiff > 7) {
                        allowed = true;
                    } 
                    break;
                default:
                    allowed = false;
            }

            return allowed;
        },

        getDates(arr: any[]) {
            const dates = [];
            for(const data of arr) {
                const convertedTime = moment.unix(data[1]).format('MMM DD');
                dates.push(convertedTime);
            }
            return dates;
        },

        getHits: function (arr: any[]) {
            const hits = [];
            for(const data of arr) {
                const val = data[0] ? data[0] : 0;
                hits.push(val.toFixed(1));
            }
            return hits;
        },

        buildChart(chartDOMElement: any, data: any) {
            return new Chart(chartDOMElement, {
                    type: 'bar',
                    data: {
                        labels: this.getDates(data),
                        datasets: [{
                            label: 'Total connections',
                            data: this.getHits(data),
                            backgroundColor: 'rgba(13, 141, 253,0.65)',
                            borderColor: 'rgba(13, 141, 253,1)',
                            borderWidth: 0
                        }]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        tooltips: {
                            mode: 'x-axis',
                            displayColors: false,
                            callbacks: {
                                title: (tooltipItem) => {
                                    if(this.groupBy === 'week') {
                                        return `Week of ${tooltipItem[0].xLabel}`;
                                    }
                                    return tooltipItem[0].xLabel as string;
                                }
                            }
                        },
                        hover: {
                            mode: 'x-axis'
                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    fontSize: 10,
                                    beginAtZero: true,
                                    callback: function (value: any) { if (value % 1 === 0) { return Math.round(value); } }
                                },
                                gridLines: {
                                    color: 'rgba(0,20,70,0.1)',
                                    zeroLineColor: 'rgba(0,20,70,0.01)'
                                },
                            }],
                            xAxes: [{
                                ticks: {
                                    fontSize: 10,
                                    autoSkipPadding: 40
                                },
                                gridLines: {
                                    drawOnChartArea: false
                                },
                            }]
                        }
                    }
                });
        },

        async doQueries() {
            if(!userState.me || !this.organizationId) return;
            
            this.charts.totalConnectionsLoading = true;
            this.charts.totalTimeMirroringLoading = true;
            this.charts.averageTimeMirroringLoading = true;
            this.charts.dittoConnectUsageLoading = true;

            const promiseArray = [];

            const rangeSelection = {
                dateRange: {rangeStart: this.dateRange[0], rangeEnd: this.dateRange[1]},
                groupBy: this.groupBy
            } as IStatRangeSelectionModel;

            let group = {};
            //location
            if(this.locationSelectionIdx > 0) {
                group = {type:'location', _id: this.filteredLocationSelectionOptions.find(x=>x.idx === this.locationSelectionIdx)?.value };
            }
            // room
            else if(this.roomSelectionIdx > 0) {
                group = {type:'room', _id: this.filteredRoomSelectionOptions.find(x=>x.idx === this.roomSelectionIdx)?.value };
            }
            // org
            else {
                group = {type:'organization', _id: this.organizationId}
            }

            promiseArray.push(new Promise<void>((resolve, reject) => {
                if(!userState.me) return reject();
                const statQuery = {stats: ['connectionCount'], 
                    aggregate:'sum', 
                    group: group, 
                    organizationId: this.organizationId,
                    rangeSelection: rangeSelection} as IStatQueryModel;
                statState.fetchStats(statQuery).then((tcd)=>{
                    if(!tcd.success) {
                        // (this as any).$message.error(tcd.reason ? `${tcd.reason}` : 'Error retrieving stats.');
                        this.charts.totalConnectionsLoading = false;
                    } else {
                        if(!tcd.data) tcd.data = [[]];
                        this.charts.totalConnectionData = tcd.data;
                        this.charts.totalConnectionsLoading = false;
                    }
                    resolve();
                });
            }));

            promiseArray.push(new Promise<void>((resolve, reject) => {
                if(!userState.me) return reject();
                const statQuery = {stats: ['timeMirroring'], 
                    aggregate:'sum', 
                    group: group, 
                    organizationId: this.organizationId,
                    rangeSelection: rangeSelection} as IStatQueryModel;
                statState.fetchStats(statQuery).then((tm)=>{
                    if(!tm.success) {
                        // (this as any).$message.error(tm.reason ? `${tm.reason}` : 'Error retrieving stats.');
                        this.charts.totalTimeMirroringLoading = false;
                    } else {
                        if(!tm.data) tm.data = [[]];
                        this.charts.totalTimeMirroringData = tm.data;
                        this.charts.totalTimeMirroringLoading = false;
                    }
                    resolve();
                });
            }));

            promiseArray.push(new Promise<void>((resolve, reject) => {
                if(!userState.me) return reject();
                const statQuery = {stats: ['timeMirroring'], 
                    aggregate:'average', 
                    group: group, 
                    organizationId: this.organizationId,
                    rangeSelection: rangeSelection} as IStatQueryModel;
                statState.fetchStats(statQuery).then((atm)=>{
                    if(!atm.success) {
                        // (this as any).$message.error(atm.reason ? `${atm.reason}` : 'Error retrieving stats.');
                        this.charts.averageTimeMirroringLoading = false;
                    } else {
                        if(!atm.data) atm.data = [[]];
                        this.charts.averageTimeMirroringData = atm.data;
                        this.charts.averageTimeMirroringLoading = false;
                    }
                    resolve();
                });
            }));

            promiseArray.push(new Promise<void>((resolve, reject) => {
                if(!userState.me) return reject();
                const statQuery = {stats: ['oneTime.*.*','installed.*.*'], 
                    aggregate:'sum', 
                    group: group, 
                    organizationId: this.organizationId,
                    rangeSelection: rangeSelection} as IStatQueryModel;
                statState.fetchStats(statQuery).then((dcu)=>{
                    if(!dcu.success) {
                        // (this as any).$message.error(dcu.reason ? `${dcu.reason}` : 'Error retrieving stats.');
                        this.charts.dittoConnectUsageLoading = false;
                    } else{
                        if(!dcu.data) dcu.data = [[]];
                        this.charts.dittoConnectUsageData = dcu.data;
                        this.charts.dittoConnectUsageLoading = false;
                    }
                    resolve();
                });
            }));

            await Promise.all(promiseArray);        
        },

        isDateDisabled(date: any): boolean {
            return moment(date).isBefore('2/1/2023');
        }
    },
    async created () {
        this.charts.totalConnectionData = [...this.trialData];
        this.charts.totalTimeMirroringData = [...this.trialData];
        this.charts.averageTimeMirroringData = [...this.trialData];
        this.charts.dittoConnectUsageData = [...this.trialData];

        userState = getModule(UserState);
        statState = getModule(StatState);

        let promises = [] as any;
        promises.push(userState.fetchMe(null));

        if(this.roomFilter) {
            roomState = getModule(RoomState);
            promises.push(
                roomState.fetchRoomsDDL().then((res: ISqStoreActionResponse<IRoomDropDownList[]>)=> {
                    this.roomSelectionOptions = res.data ?? [] as IRoomDropDownList[];
                })
            );
        }

        if(this.locationFilter) {

            if(!roomState) roomState = getModule(RoomState);

            promises.push(
                roomState.fetchRoomDisplayList({
                    pageIndex: 1,
                    pageSize: 100000
                }).then((res: ISqStoreActionResponse<IModelSearchResponse<IRoomListDisplay[]>>) => {
                    if(!res.data || !res.data.data || res.data.data.length < 1) return this.roomsWithLocations = [] as IRoomListDisplay[];
                    this.roomsWithLocations = res.data.data.filter((d: IRoomListDisplay) => d.location && d.location.id);
                })
            )
        }

        await Promise.all(promises);

        this.pageLoading = false;

        if(!this.isTrial) {
            await this.doQueries();
        }
    },
    
    mounted() {
        this.$nextTick(async () => {
            // were not gonna render real data
            if(this.isTrial) {
                this.charts.totalConnectionsLoading = false;
                this.charts.totalTimeMirroringLoading = false;
                this.charts.averageTimeMirroringLoading = false;
                this.charts.dittoConnectUsageLoading = false;
                return;
            }

            this.dateRange = [moment.utc().subtract(7, 'days').toDate(), moment.utc().toDate()];
        })
    },
    watch: {
        organizationId: {
            async handler() {
                this.doQueries();
            },
            immediate: true
        },

        async groupBy() {
            if(this.isTrial) {
                this.charts.totalConnectionData = [{response: [[]]}] as any;
                this.charts.totalTimeMirroringData = [{response: [[]]}] as any;
                this.charts.averageTimeMirroringData = [{response: [[]]}] as any;
                this.charts.dittoConnectUsageData = [{response: [[]]}] as any;
                setTimeout(()=>{
                    this.charts.totalConnectionData = [{response: this.trialData}] as any;
                    this.charts.totalTimeMirroringData = [{response: this.trialData}] as any;
                    this.charts.averageTimeMirroringData = [{response: this.trialData}] as any;
                    this.charts.dittoConnectUsageData = [{response: this.trialData}] as any;
                }, 500);
                return;
            }

            await this.doQueries(); 
        },

        async dateRange() {
            if(this.isTrial) {
                this.charts.totalConnectionData = [{response: [[]]}] as any;
                this.charts.totalTimeMirroringData = [{response: [[]]}] as any;
                this.charts.averageTimeMirroringData = [{response: [[]]}] as any;
                this.charts.dittoConnectUsageData = [{response: [[]]}] as any;
                setTimeout(()=>{
                    this.charts.totalConnectionData = [{response: this.trialData}] as any;
                    this.charts.totalTimeMirroringData = [{response: this.trialData}] as any;
                    this.charts.averageTimeMirroringData = [{response: this.trialData}] as any;
                    this.charts.dittoConnectUsageData = [{response: this.trialData}] as any;
                }, 500);
                return;
            }

            this.setGroupBy()
            await this.doQueries();
        },

        async roomSelectionIdx(newVal) {
            if(newVal != 0) {
                this.locationSelectionIdx = 0;
            }

            if(this.isTrial) {
                this.charts.totalConnectionData = [{response: [[]]}] as any;
                this.charts.totalTimeMirroringData = [{response: [[]]}] as any;
                this.charts.averageTimeMirroringData = [{response: [[]]}] as any;
                this.charts.dittoConnectUsageData = [{response: [[]]}] as any;
                setTimeout(()=>{
                    this.charts.totalConnectionData = [{response: this.trialData}] as any;
                    this.charts.totalTimeMirroringData = [{response: this.trialData}] as any;
                    this.charts.averageTimeMirroringData = [{response: this.trialData}] as any;
                    this.charts.dittoConnectUsageData = [{response: this.trialData}] as any;
                }, 500);
                return;
            }

            await this.doQueries();
        },

        async locationSelectionIdx(newVal) {
            if(newVal != 0) {
                this.roomSelectionIdx = 0;
            }

            if(this.isTrial) {
                this.charts.totalConnectionData = [{response: [[]]}] as any;
                this.charts.totalTimeMirroringData = [{response: [[]]}] as any;
                this.charts.averageTimeMirroringData = [{response: [[]]}] as any;
                this.charts.dittoConnectUsageData = [{response: [[]]}] as any;
                setTimeout(()=>{
                    this.charts.totalConnectionData = [{response: this.trialData}] as any;
                    this.charts.totalTimeMirroringData = [{response: this.trialData}] as any;
                    this.charts.averageTimeMirroringData = [{response: this.trialData}] as any;
                    this.charts.dittoConnectUsageData = [{response: this.trialData}] as any;
                }, 500);
                return;
            }

            await this.doQueries();
        }
    }
});
