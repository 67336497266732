
import { defineComponent } from 'vue'
import axios from 'axios';
export default defineComponent({
    data: ()=> {
        return {
            item: {} as any
        }
    },
    props: ['id', 'signature'],

    mounted() {
        return axios.get(`${ process.env.VUE_APP_API_HOST }/api/signages/${this.id}/templaterequest/${this.signature}`).then((res) => {
            this.item = res.data;
        },
        (err) => console.error(err));
    }
})
