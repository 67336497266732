
import { getModule } from 'vuex-module-decorators';
import { IOrganization, IOrganizationIPWhiteList } from '../../store/interfaces/IOrganization';
import UserState, { IUserState } from '../../store/modules/users';
import OrganizationState, { IOrganizationState } from '../../store/modules/organizations';
import BrandingState, { IBrandingState } from '../../store/modules/brandings';
import { defineComponent, nextTick } from 'vue';
import { mdiRefresh, mdiDelete} from '@mdi/js'
import shortid from 'shortid';
import { IBranding } from '../../store/interfaces/IBranding';
import DigitalAssetUpload from '../../components/DigitalAssetUpload.vue'
import digitalAssetUploadService from '../../services/DigitalAssetUploadService';
import PageDirtyService from '../../services/PageDirtyService';
import { IUser } from '../../store/interfaces/IUser';
import { isRange } from 'range_check';
import TierState, { ITierState } from '../../store/modules/tier';
import TierLimit from '../../components/TierLimit.vue';

let userState = {} as IUserState;
let organizationState = {} as IOrganizationState;
let brandingState = {} as IBrandingState;
let tierState = {} as ITierState;
const DEFAULT_BACKGROUND = '#4BA291';
const DEFAULT_TEXT = '#ffffff';

export default defineComponent ({
    name: 'OrganizationCustomization',
    components: {
        DigitalAssetUpload,
        TierLimit
    },
    data() {
        return {
            mdiDelete, mdiRefresh,
            viewLoading: true,
            saving: false,
            activeTab: 'connectPage',
            mutatableOrg: {} as IOrganization,
            organization: {} as IOrganization,
            branding: {
                logo:'noImageHolder' as string | null,
                backgroundImage:'noImageHolder' as string | null
            } as IBranding,
            connectLogoUpload: null as File | null,
            connectBackgroundUpload: null as File | null,
            appBannerUpload: null as File | null,
            receiverLogoUpload: null as File | null,
            receiverBackgroundUpload: null as File | null,
            brandingSender: {
                logo:'noImageHolder',
                backgroundImage:'noImageHolder'
            } as IBranding,
            brandingReceiver: {
                logo:'noImageHolder',
                backgroundImage:'noImageHolder'
            } as IBranding,
            successTab: [] as string[],
            errorTab: [] as string[],
            useBackgroundColorConnectPage: false,
            updateBranding: false,
            updateBrandingSender: false,
            updateBrandingReceiver: false,
            updateSettings: false,
            ipWhiteList: {} as IOrganizationIPWhiteList,
            rules:{
                enableIpAddressWhiteList: { required: true, validator: this.validateEnableWhiteList, trigger: 'blur' },
            },
        }
    },
    
    inject: [
        'windowWidth'
    ],

    computed: {
        connectPageUrl(): string | null {
            if(!this.mutatableOrg || (!this.mutatableOrg.landingId || this.mutatableOrg.landingId === '')) return null;
            const domainNameArray = window.location.host.split('.');
            domainNameArray.splice(0,1);
            const domainName = domainNameArray.join('.');
            return `${window.location.protocol}//connect.${domainName}/org/${this.mutatableOrg.landingId}`;
        },
        me(): IUser {
            return userState.me ?? {} as IUser;
        },

        allowIpAddressWhitelist(): boolean {
            return tierState.orgTier.ipAddressWhitelist;
        },

        showTierCallout(): boolean {
            if(!tierState.orgTier.ipAddressWhitelist) {
                return true;
            }
            return false;
        }
    },
    methods: {
        validateEnableWhiteList(rule: any, value: any, callback: any) {
            if(this.mutatableOrg.enableIpAddressWhiteList == false) return callback();

            if(this.mutatableOrg.enableIpAddressWhiteList == true) {
                // verify that we have something in the whitelist
                if(this.mutatableOrg.ipAddressWhiteList && this.mutatableOrg.ipAddressWhiteList.length > 0) {
                    return callback();
                }
            }
            
            const errorMsg = 'In order to enable this setting, you must add one IP address to the table below first.';

            (this as any).$message.error(errorMsg);

            return callback(errorMsg);
        },
        async onCancel() {
            try {
                await (this as any).$confirm('Are you sure you wish to undo Customization changes?', 'Warning', {
                    confirmButtonText: 'Undo Changes',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                });

                this.saving = true;

                if ((this.$refs as any).connectLogoUpload) {
                    this.connectLogoUpload = null as any;
                    (this.$refs as any).connectLogoUpload.resetImage();
                }
                if((this.$refs as any).connectBackgroundUpload) {
                    this.connectBackgroundUpload = null as any;
                    (this.$refs as any).connectBackgroundUpload.resetImage();
                }

                if((this.$refs as any).receiverBackgroundUpload) {
                    this.receiverBackgroundUpload = null as any;
                    (this.$refs as any).receiverBackgroundUpload.resetImage();
                }

                if((this.$refs as any).receiverLogoUpload) {
                    this.receiverLogoUpload = null as any;
                    (this.$refs as any).receiverLogoUpload.resetImage();
                }
                if((this.$refs as any).appBannerUpload) {
                    this.appBannerUpload = null as any;
                    (this.$refs as any).appBannerUpload.resetImage();
                }

                this.loadMutableData();

                this.mutatableOrg.enableHigherResolution = this.organization.enableHigherResolution;
                this.mutatableOrg.showSignageDateTime = this.organization.showSignageDateTime;
                this.mutatableOrg.signageDateTimeLocation = this.organization.signageDateTimeLocation;
                this.mutatableOrg.enableIpAddressWhiteList = this.organization.enableIpAddressWhiteList;
                this.mutatableOrg.ipAddressWhiteList = [...this.organization.ipAddressWhiteList];

                this.saving = false;
            }
            catch {
                // cancelled
                return;
            }
        },
        async generateConnectPageUrl() {
            if(!this.mutatableOrg || this.saving) return;
            if(this.mutatableOrg.landingId && this.mutatableOrg.landingId !== '') {
                // throw the confirm
                try {
                    await (this as any).$confirm("This will change your organization's custom URL and could potentially break existing links. Are you sure you want to change the organization custom URL?", 'Warning', {
                        confirmButtonText: 'Change Code',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    });
                }
                catch(e:any) {
                    // user canceled
                    return;
                }
            }

            this.saving = true;

            const newLandingId = shortid.generate();
            this.mutatableOrg.landingId = newLandingId;
            const storeResponse = await organizationState.updateOrganization(this.mutatableOrg);

            if(!storeResponse.success) {
                this.saving = false;
                (this as any).$message.error(storeResponse.reason ? `Connect page url rotate failed: ${storeResponse.reason}` : 'Connect page url rotate failed');
                return;
            }

            userState.fetchMe({reload:true}).then(() => {
                this.loadMutableData();
            });

            this.saving = false;
            (this as any).$message.success('Connect page url rotated');
        },
        async resetReceiver() {
            try {
                await (this as any).$confirm("This will reset your custom Ditto Receiver styles back to default settings. Are you sure you want to reset these styles?", 'Warning', {
                    confirmButtonText: 'Reset to Default',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                });

                if((this.$refs as any).receiverBackgroundUpload) {
                    this.receiverBackgroundUpload = null;
                    (this.$refs as any).receiverBackgroundUpload.resetImage();
                }
                if((this.$refs as any).receiverLogoUpload) {
                    this.receiverLogoUpload = null;
                    (this.$refs as any).receiverLogoUpload.resetImage();
                }
                this.brandingReceiver.backgroundImage = null as any;
                this.brandingReceiver.logo = null as any;
                this.brandingReceiver.backgroundColor = DEFAULT_BACKGROUND;
                this.brandingReceiver.textColor = DEFAULT_TEXT;
            }
            catch(e:any) {
                // user canceled
                return;
            }
        },
        async resetConnectBranding() {
            try {
                await (this as any).$confirm("This will reset your app banner back to default settings. Are you sure you want to reset this?", 'Warning', {
                    confirmButtonText: 'Reset to Default',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                });
                if((this.$refs as any).appBannerUpload) {
                    this.appBannerUpload = null as any;
                    (this.$refs as any).appBannerUpload.resetImage();
                }
                this.brandingSender.banner = null as any;
            }
            catch(e:any) {
                // user canceled
                return;
            }
        },
        async resetConnectPageBranding() {
            try {
                await (this as any).$confirm("This will reset your custom connection page styles back to default settings. Are you sure you want to reset these styles?", 'Warning', {
                    confirmButtonText: 'Reset to Default',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                });

                if((this.$refs as any).connectLogoUpload) {
                    this.connectLogoUpload = null as any;
                    (this.$refs as any).connectLogoUpload.resetImage();
                }
                if((this.$refs as any).connectBackgroundUpload) {
                    this.connectBackgroundUpload = null as any;
                    (this.$refs as any).connectBackgroundUpload.resetImage();
                }
                this.branding.backgroundImage = null as any;
                this.branding.logo = null as any;
                this.branding.backgroundColor = DEFAULT_BACKGROUND;
                this.branding.textColor = DEFAULT_TEXT;
                this.useBackgroundColorConnectPage = false;
            }
            catch(e:any) {
                // user canceled
                return;
            }
        },
        colorPickerChange() {
            this.branding.backgroundColor = this.branding.backgroundColor ?? DEFAULT_BACKGROUND;
            this.branding.textColor = this.branding.textColor ?? DEFAULT_TEXT;

            this.brandingReceiver.backgroundColor = this.brandingReceiver.backgroundColor ?? DEFAULT_BACKGROUND;
            this.brandingReceiver.textColor = this.brandingReceiver.textColor ?? DEFAULT_TEXT;
        },
        addIPAddress() {
            if(this.ipWhiteList.ipAddress && (this.validateIPaddress(this.ipWhiteList.ipAddress) || this.validateIPaddressV6(this.ipWhiteList.ipAddress))) {
                if(this.mutatableOrg.ipAddressWhiteList && 
                    //this.mutatableOrg.ipAddressWhiteList.length &&
                    !this.mutatableOrg.ipAddressWhiteList.some((x)=>x.ipAddress.toLowerCase() == this.ipWhiteList.ipAddress?.toLowerCase())) {
                            this.mutatableOrg.ipAddressWhiteList.push({
                                ipAddress: this.ipWhiteList.ipAddress , description: this.ipWhiteList.description ? this.ipWhiteList.description : '',
                            });

                            this.ipWhiteList = {} as IOrganizationIPWhiteList;
                            this.detectChanges()
                            return;
                }
            }

            (this as any).$message.error("Please enter a valid ip address.");
        },
        removeIPAddress(ip: string) {
            this.mutatableOrg.ipAddressWhiteList = this.mutatableOrg.ipAddressWhiteList.filter((x)=>x.ipAddress != ip);
            this.detectChanges();
        },
        validateIPaddress(ipaddress: string) 
        {
            if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress))
            {
                return true
            }
            return false
        },
        validateIPaddressV6(ipaddressV6: string) 
        {
            const valid = isRange(ipaddressV6);

            return valid;
        },
        loadMutableData() {
            if(!this.mutatableOrg || !brandingState.allBrandings) return;

            this.connectLogoUpload = null;
            this.connectBackgroundUpload = null;
            this.appBannerUpload = null;
            this.receiverLogoUpload = null;
            this.receiverBackgroundUpload = null;

            if(this.mutatableOrg && this.mutatableOrg.branding) {
                this.branding = Object.assign({}, brandingState.allBrandings.find(x => x.id === this.mutatableOrg?.branding.id) ?? {} as IBranding);
                this.useBackgroundColorConnectPage = this.branding.backgroundColor ? true : false;
                this.branding.backgroundColor = this.branding.backgroundColor ?? DEFAULT_BACKGROUND;
                this.branding.textColor = this.branding.textColor ?? DEFAULT_TEXT
            }

            if(this.mutatableOrg && this.mutatableOrg.brandingSender) {
                this.brandingSender = Object.assign({}, brandingState.allBrandings.find(x => x.id === this.mutatableOrg?.brandingSender.id) ?? {} as IBranding);
            }

            if(this.mutatableOrg && this.mutatableOrg.brandingReceiver) {
                this.brandingReceiver = Object.assign({}, brandingState.allBrandings.find(x => x.id === this.mutatableOrg?.brandingReceiver.id) ?? {} as IBranding); 
                this.brandingReceiver.backgroundColor = this.brandingReceiver.backgroundColor ?? DEFAULT_BACKGROUND;
                this.brandingReceiver.textColor = this.brandingReceiver.textColor ?? DEFAULT_TEXT
            }

            this.saving = false;
        },
        async onSubmit(): Promise<void> {
            //this.validateEnableWhiteList()
            const isValid = await (this.$refs['orgSettingsForm'] as any).validate();

            if(!isValid) return;

            if(!brandingState.allBrandings) return;
            this.saving = true;
            // do save stuff

            const uploadPromises: Promise<any>[] = [];
            if(this.connectLogoUpload) {
                uploadPromises.push(this.uploadAsset(this.connectLogoUpload, 'logo_connect', this.branding, 'logo'));
            }

            if(this.connectBackgroundUpload) {
                uploadPromises.push(this.uploadAsset(this.connectBackgroundUpload, 'backgroundImage_connect', this.branding, 'backgroundImage'));
            }

            if(this.appBannerUpload) {
                uploadPromises.push(this.uploadAsset(this.appBannerUpload, 'banner', this.brandingSender, 'banner'));
            }

            if(this.receiverLogoUpload) {
                uploadPromises.push(this.uploadAsset(this.receiverLogoUpload, 'logo_receiver', this.brandingReceiver, 'logo'));
            }

            if(this.receiverBackgroundUpload) {
                uploadPromises.push(this.uploadAsset(this.receiverBackgroundUpload, 'backgroundImage_receiver', this.brandingReceiver, 'backgroundImage'));
            }
            await Promise.allSettled(uploadPromises);

            this.detectChanges();

            this.successTab = [];
            this.errorTab = [];

            if(this.updateBranding) {
                await this.updateOrAddBranding(this.branding, 'Connect Page');
                this.updateBranding = false;
            }

            if(this.updateBrandingSender) {
                await this.updateOrAddBranding(this.brandingSender, 'Ditto Connect');
                this.updateBrandingSender = false;
            }

            if(this.updateBrandingReceiver) {
                await this.updateOrAddBranding(this.brandingReceiver, 'Ditto Receiver');
                this.updateBrandingReceiver = false;
            }

            if(this.updateSettings) {
                const tabName = 'Organization Settings';
                const storeResponse = await organizationState.updateOrganization(this.mutatableOrg);
                if(storeResponse.success) {
                    this.successTab.push(tabName);
                }
                else {
                    this.errorTab.push(tabName);
                }
                await this.loadOrganization();
                await userState.fetchMe({ reload: true });
                this.updateSettings = false;
            }

            if (this.successTab.length > 0) {
                let successHTML = 'Organization customization updated for<br>';
                for(const success of this.successTab) {
                    successHTML = `${successHTML}${success}<br>`;
                }
                (this as any).$message({
                    dangerouslyUseHTMLString: true,
                    message: successHTML,
                    type: 'success'
                });
            }
            await nextTick;
            if (this.errorTab.length > 0) {
                let errorHTML = 'Organization customization update failed for<br>';
                for(const err of this.errorTab) {
                    errorHTML = `${errorHTML}${err}<br>`;
                }
                (this as any).$message({
                    dangerouslyUseHTMLString: true,
                    message: errorHTML,
                    type: 'error'
                });
            }

            this.loadMutableData();
            this.detectChanges();
            this.saving = false;
        },
        async updateOrAddBranding(branding: IBranding, brandingType: string) {
            if (branding.id) {
                if(brandingType === 'Connect Page') {
                    //setting background and text colors to null if they are the default colors unless the use background color switch is on for connect page
                    branding.backgroundColor = this.branding.backgroundColor == DEFAULT_BACKGROUND ? null : this.branding.backgroundColor;
                    branding.textColor = this.branding.textColor == DEFAULT_TEXT ? null : this.branding.textColor;
                    branding.backgroundColor = !this.useBackgroundColorConnectPage ? null : (this.branding.backgroundColor ?? DEFAULT_BACKGROUND);
                }
                
                if(brandingType === 'Ditto Connect'){
                    //setting background image to null if use background color switch is on for connect page
                    branding.backgroundImage = this.useBackgroundColorConnectPage ? null : this.branding.backgroundImage;
                }

                if(brandingType === 'Ditto Receiver'){
                    //setting background and text colors to null if they are the default colors unless the use background color switch is on for receiver
                    branding.backgroundColor = this.brandingReceiver.backgroundColor == DEFAULT_BACKGROUND ? null : this.brandingReceiver.backgroundColor;
                    branding.textColor = this.brandingReceiver.textColor == DEFAULT_TEXT ? null : this.brandingReceiver.textColor;
                }
                
                const storeResponse = await brandingState.updateBranding(branding);
                if(storeResponse.success) {
                    this.successTab.push(brandingType);
                    return;
                }
            }
            this.errorTab.push(brandingType);
        },
        async uploadAsset(uploadFile: File, fileName: string, branding: IBranding, brandingProperty: string): Promise<void> {
            const digitalAsset = await digitalAssetUploadService.upload({
                file: uploadFile,
                secure: false,
                assetQuotaType: 0, //none
                generateThumb: false,
                fileName: fileName,
            })

            if(digitalAsset && digitalAsset.assetFiles && digitalAsset.assetFiles[0]) {
                (branding as any)[brandingProperty as string] = digitalAsset.assetFiles[0].url;
                return;
            }
            console.error(`Failed to up load image (${brandingProperty})`);
            return;

        },
        detectChanges() {
            if(!brandingState.allBrandings) return;

            const branding = brandingState.allBrandings.find(x => x.id === this.mutatableOrg?.branding.id) ?? {} as IBranding;
            const defaultUseBackgroundColorConnectPage = branding.backgroundColor ? true : false;
            branding.backgroundColor = branding.backgroundColor ?? DEFAULT_BACKGROUND;
            branding.textColor = branding.textColor ?? DEFAULT_TEXT
            
            const senderBranding = brandingState.allBrandings.find(x => x.id === this.mutatableOrg?.brandingSender.id) ?? {} as IBranding;

            const receiverBranding = brandingState.allBrandings.find(x => x.id === this.mutatableOrg?.brandingReceiver.id) ?? {} as IBranding;
            receiverBranding.backgroundColor = receiverBranding.backgroundColor ?? DEFAULT_BACKGROUND;
            receiverBranding.textColor = receiverBranding.textColor ?? DEFAULT_TEXT

            const brandingDirty = PageDirtyService.monitorObjectSet([
                {defaultObject: branding, mutatedObject: this.branding},
                {defaultObject: defaultUseBackgroundColorConnectPage, mutatedObject: this.useBackgroundColorConnectPage},
                {defaultObject: null, mutatedObject: this.connectLogoUpload},
                {defaultObject: null, mutatedObject: this.connectBackgroundUpload}
            ]);

            if(brandingDirty) {
                this.updateBranding = true;
            }
            else {
                this.updateBranding = false;
            }

            const senderBrandingDirty = PageDirtyService.monitorObjectSet([
                {defaultObject: senderBranding, mutatedObject: this.brandingSender},
                {defaultObject: null, mutatedObject: this.appBannerUpload}
            ]);

            if(senderBrandingDirty) {
                this.updateBrandingSender = true;
            }
            else {
                this.updateBrandingSender = false;
            }

            const receiverBrandingDirty = PageDirtyService.monitorObjectSet([
                {defaultObject: receiverBranding, mutatedObject: this.brandingReceiver},
                {defaultObject: null, mutatedObject: this.receiverLogoUpload},
                {defaultObject: null, mutatedObject: this.receiverBackgroundUpload}
            ]);

            if(receiverBrandingDirty) {
                this.updateBrandingReceiver = true;
            }
            else {
                this.updateBrandingReceiver = false;
            }

            const orgSettingsDirty = PageDirtyService.monitorObjectSet([
                {defaultObject: this.organization.enableHigherResolution, mutatedObject: this.mutatableOrg.enableHigherResolution},
                {defaultObject: this.organization.showSignageDateTime, mutatedObject: this.mutatableOrg.showSignageDateTime},
                {defaultObject: this.organization.signageDateTimeLocation, mutatedObject: this.mutatableOrg.signageDateTimeLocation},
                {defaultObject: this.organization.enableIpAddressWhiteList, mutatedObject: this.mutatableOrg.enableIpAddressWhiteList},
                {defaultObject: JSON.stringify(this.organization.ipAddressWhiteList), mutatedObject: JSON.stringify(this.mutatableOrg.ipAddressWhiteList)},
                {defaultObject: this.organization.enableReceiverLowProfileMode, mutatedObject: this.mutatableOrg.enableReceiverLowProfileMode},
            ]);

            if(orgSettingsDirty) {
                this.updateSettings = true;
            }
            else {
                this.updateSettings = false;
            }

            if(brandingDirty || senderBrandingDirty || receiverBrandingDirty || orgSettingsDirty) {
                PageDirtyService.markPageDirty();
            }
            else {
                PageDirtyService.markPageClean();
            }
        },

        async loadOrganization() {
            if(userState.me && userState.me.organization) {
                await organizationState.fetchOrganization(userState.me.organization.id, true);
                
                this.organization = Object.assign({}, organizationState.getOrganization);
                
                if(!userState.me.organization.branding || !userState.me.organization.brandingSender || !userState.me.organization.brandingReceiver) {
                    const storeResponse = await organizationState.addBrandingsToOrganization(userState.me.organization);
                    if(!storeResponse.success) {
                        (this as any).$message.error("Error getting user information.");
                        return;
                    }
                }
                this.mutatableOrg = Object.assign({}, this.organization);

                this.mutatableOrg.ipAddressWhiteList = [...this.organization.ipAddressWhiteList];
                
                await brandingState.fetchBrandings();
                return;
            }
            return;
        },
    },
    async created() {
        shortid.characters('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_-');
        userState = getModule(UserState);
        organizationState = getModule(OrganizationState);
        brandingState = getModule(BrandingState);
        tierState = getModule(TierState);

        await userState.fetchMe();
        await tierState.fetchTier()

        if(!userState.me) {
            (this as any).$message.error("Error getting user information.");
            return;
        }
        
        await this.loadOrganization();
        this.loadMutableData();

        this.viewLoading = false;
    },
    watch: {
        mutatableOrg: {
            handler () {
                this.detectChanges();
            },
            deep: true
        },
        branding: {
            handler () {
                this.detectChanges();
            },
            deep: true
        },
        useBackgroundColorConnectPage: {
            handler () {
                this.detectChanges();
            },
            deep: true
        },
        brandingSender: {
            handler () {
                this.detectChanges();
            },
            deep: true
        },
        brandingReceiver: {
            handler () {
                this.detectChanges();
            },
            deep: true
        },
        connectLogoUpload: {
            handler () {
                this.detectChanges();
            },
            deep: true
        },
        connectBackgroundUpload: {
            handler () {
                this.detectChanges();
            },
            deep: true
        },
        appBannerUpload: {
            handler () {
                this.detectChanges();
            },
            deep: true
        },
        receiverLogoUpload: {
            handler () {
                this.detectChanges();
            },
            deep: true
        },
        receiverBackgroundUpload: {
            handler () {
                this.detectChanges();
            },
            deep: true
        }
    }
})
