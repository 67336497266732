
import { defineComponent } from 'vue';
import ReceiverState, { IReceiverState } from '../../store/modules/receivers';
import { getModule } from 'vuex-module-decorators'
import { IReceiver, IReceiverListDisplay } from '../../store/interfaces/IReceiver';
import { IRoomState } from '../../store/modules/rooms';
import RoomState from '../../store/modules/rooms';
import SubscriptionStatusProgress from '../../components/SubscriptionStatusProgress.vue'
import { mdiDelete, mdiHelp } from '@mdi/js';
import { ILocation } from '../../store/interfaces/ILocation';
import UserState, { IUserState } from '../../store/modules/users';
import TierState, { ITierState } from '../../store/modules/tier';
import TierLimit from '../../components/TierLimit.vue';
import { IOnboardStatus } from '../../store/interfaces/IOnboardingStatus';
import OnboardStatusState, { IOnboardStatusState } from '../../store/modules/onboarding';
import { IReceiverModelSearch } from '../../store/interfaces/IModelSearch';

export interface IReceiverIndexViewModel extends IReceiver {
    roomId: string;
    roomName: string;
    location: ILocation;
}

let receiverState = {} as IReceiverState;
let roomState = {} as IRoomState;
let onboardingStatusState = {} as IOnboardStatusState;
let userState = {} as IUserState;
let tierState = {} as ITierState;

export default defineComponent({
    name: 'ReceiversView',
    components: {
        SubscriptionStatusProgress,
        TierLimit
    },

    data() {
      return {
        domLoaded: false as boolean,
        selectedReceiverIds: [] as any,
        receiverSearch: '',
        receiverPlatformFilter: '',
        receiverPlatforms: [ { "value": "tvos", "display": "tvOS" }, { "value": "windows", "display": "Windows" }, { "value": "other", "display": "Other" }, { "value": "chromecast", "display": "Chromecast" }, { "value": "atvlegacy", "display": "ATV 2/3" } ],
        mdiDelete,
        mdiHelp,
        loading: true,
        timer: '' as any,
        allReceivers: [] as IReceiverListDisplay[],
        receiverCount: 10 as number,
        searchDebounce: null as any,
        paging: {
            pageSize: 10,
            pageIndex: 1,
            sortField: 'displayName',
            sortOrder: 'asc'
        } as IReceiverModelSearch
      }
    },

    computed: {
        canActivateReceivers(): boolean {
            return tierState.canActivateReceivers;
        },

        tierLimitReachedMessage() {
            return 'Upgrade your Ditto account to link additional rooms and receivers.'
        },

        allReceiversDisplay: function(): IReceiverListDisplay[] | undefined {
            if(!roomState || !this.allReceivers) return;

            return this.allReceivers
            .map((x:IReceiverListDisplay) => {
                return {...x, 
                    deviceType: x.deviceType?.replace(/SSP/g,'Ditto Receiver'),
                    platform: x.platform?.replace(/windows/g, 'Windows').replace(/tvos/g, 'tvOS').replace(/other/g, 'Other').replace(/chromecast/g, 'Chromecast').replace(/atvlegacy/g, 'ATV 2/3')
                };
            });
        },
        filterEnabled(): boolean {
            return (this.receiverSearch && this.receiverSearch != '') || (this.receiverPlatformFilter && this.receiverPlatformFilter != '') ? true : false;
        }
    },

    methods: {
        sortTable(e: any) {
            this.paging.sortField = !e.prop ? 'displayName' : e.prop.split(',')[0];
            this.paging.sortOrder = e.order == 'descending' ? 'desc' : 'asc';

            if(this.searchDebounce) clearTimeout(this.searchDebounce);
            this.searchDebounce = setTimeout(()=> {
                this.doReceiverSearch(false);
                this.loading = true;
            }, 1000);  
        },
        async createNewOnboarding() {
            let newOnboarding = {} as IOnboardStatus;
            newOnboarding.organization = userState.me && userState.me.organization ? userState.me.organization : undefined;
            newOnboarding.user = userState.me;
            newOnboarding.initiatedUser = userState.me;
            newOnboarding.isOnboarding = true;
            newOnboarding.showDialog = true;
            newOnboarding.guideStepId = 'overviewInitial';
            newOnboarding.step = 1;

            await onboardingStatusState.createOnboardStatus(newOnboarding);
            await onboardingStatusState.fetchOnboardStatus(true);
            if(!(this as any).hideOnboardingByAccess.value) {
                onboardingStatusState.start();
            }
            
        },

        async onCreate() {
            if(onboardingStatusState && onboardingStatusState.userOnboardStatus && onboardingStatusState.userOnboardStatus.isOnboarding && onboardingStatusState.userOnboardStatus.showDialog) {
                await onboardingStatusState.nextStep();
            }
            this.$router.push({ path: `/receivers/new` });
            return;
        },

        handleSelectionChange(selections: any) {
            if(!selections) return;
            this.selectedReceiverIds = selections.map((x: any) => x.id);
        },

        handleCurrentChange(row: any, column: any) {
            if(column.property === "id") return;
            this.$router.push({ path: `/receivers/${row.id}` })
        },

        async handleUnlink(){
            if(!this.selectedReceiverIds || this.selectedReceiverIds.length == 0) return
            let onboardingReceiver : boolean = ((this as any).userOnboardStatus && !(this as any).userOnboardStatus.value.showDialog && (this as any).userOnboardStatus.value.isOnboarding && (this as any).userOnboardStatus.value.device && this.selectedReceiverIds.includes((this as any).userOnboardStatus.value.device.id)) ?? false;
            try {
                await (this as any).$confirm(`${onboardingReceiver ? 'A selected receiver was created using the Ditto setup tutorial. Editing this receiver before completing the Ditto setup tutorial will cause the tutorial to restart from the beginning. ' : ''}Are you sure you want to unlink ${this.selectedReceiverIds.length > 1 ? 'these' : 'this'} receiver${this.selectedReceiverIds.length > 1 ? 's' : ''}?`, 'Warning', {
                    confirmButtonText: 'Continue',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                });

                const storeResponse = await roomState.removeReceivers(this.selectedReceiverIds);

                if(!storeResponse.success) {
                    (this as any).$message.error(storeResponse.reason ? `Unlink failed: ${storeResponse.reason}` : 'Unlink failed');
                    return;
                }
                if(onboardingReceiver) {
                    await onboardingStatusState.end();
                    await this.createNewOnboarding();
                }

                this.doReceiverSearch(false);
                
                await tierState.fetchActivateReceiversCheck(true);

                (this as any).$message.success('Receivers Unlinked');   
            }
            catch{
                //canceled
            }
        },
        async handleDelete (row: any) {
            if(!this.allReceivers) return;
            if(this.selectedReceiverIds.indexOf(row.id) === -1) {
                this.selectedReceiverIds = [];
                this.selectedReceiverIds.push(row.id);
            }
            await this.massDeleteReceivers();
        },

        async massDeleteReceivers() {
            if (!this.selectedReceiverIds || this.selectedReceiverIds.length === 0)
				return;
            try {
                let confirmMessage = '';
                let onboardingMessage = '';
                const anyOnboardingReceivers: boolean = ((this as any).userOnboardStatus && !(this as any).userOnboardStatus.value.showDialog && (this as any).userOnboardStatus.value.isOnboarding && (this as any).userOnboardStatus.value.device && this.selectedReceiverIds.includes((this as any).userOnboardStatus.value.device.id)) ?? false;

                if(anyOnboardingReceivers) {
                    onboardingMessage = `${this.selectedReceiverIds.length == 1 ? 'This' : 'A selected'} receiver was created using the Ditto setup tutorial. Deleting this receiver before completing the Ditto setup tutorial will cause the tutorial to restart from the beginning. `
                }

                if(this.selectedReceiverIds.length === this.receiverCount && this.selectedReceiverIds.length > 1) {
                    confirmMessage = `Are you sure you want to delete all ${this.selectedReceiverIds.length} receivers?`;
                }
                else if( this.selectedReceiverIds.length === 1) {
                    confirmMessage = `Are you sure you want to delete this receiver?`;
                }
                else {
                    confirmMessage = `Are you sure you want to delete ${this.selectedReceiverIds.length} of ${this.receiverCount} receivers'?`;
                }
				await (this as any).$confirm(
					`${onboardingMessage ? onboardingMessage : ''}${confirmMessage}`,
					'Warning',
					{
						confirmButtonText: 'Continue',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
					}
				);

                this.loading = true;
                const receiverDeletePromise = [];

                for (const id of this.selectedReceiverIds) {
                    const receiver = this.allReceivers.find((x) => x.id === id) as any;
					receiverDeletePromise.push(receiverState.deleteReceiver(receiver));
				}

                const responses = await Promise.all(receiverDeletePromise);

				const failureResponses = responses.filter((x) => !x.success);

                await tierState.fetchActivateReceiversCheck(true);
                this.doReceiverSearch(true);
                if(anyOnboardingReceivers) {
                    await onboardingStatusState.end();
                    await this.createNewOnboarding();
                }

                if (failureResponses && failureResponses.length > 0) {
					for (const response of failureResponses) {
                        (this as any).$message.error(response.reason ? `Receiver delete failed: ${response.reason}` : 'Receiver delete failed');
					}
                    this.selectedReceiverIds
                    this.loading = false;
					return;
				}

                (this as any).$message.success( `Receiver${this.selectedReceiverIds.length == 1 ? '' : 's'} deleted`);
                this.selectedReceiverIds
                this.loading = false;
            }
            catch(e) {
                // Cancelled
            }
        },

        receiverStatus(status: any): string {
            let recStatus = '-';
            switch(status) {
                case 1:
                    recStatus = 'LEGACY';
                    break;
                case 2: 
                    recStatus = 'OFFLINE';
                    break;
                case 3:
                    recStatus = 'ONLINE';
                    break;
            }

            return recStatus;
        },

        handleFilterChange() {
            //this.loading = true;
            this.handleCurrentPageChange(1);
        },

        handleSizeChange(pageSize: number) {
            this.paging.pageSize = pageSize;
            this.handleCurrentPageChange(1);
        },

        handleCurrentPageChange(pg: number) {
            if(this.searchDebounce) clearTimeout(this.searchDebounce);
            this.searchDebounce = setTimeout(()=> {
                this.paging.pageIndex = pg;
                this.doReceiverSearch(false);
                this.loading = true;
            }, 1000);            
        },


        async doReceiverSearch(fromCreated = false) {
            //this.loading = true;
            if(!fromCreated) {
                this.paging.locationId = undefined;
                this.paging.isActive = undefined;
                this.paging.status = undefined;
                this.paging.name = undefined;
                this.paging.platform = undefined;
            
                // if(this.locationFilter) {
                //     this.paging.locationId = this.locationFilter;
                // }

                // if(this.activeFilter) {
                //     this.paging.isActive = this.activeFilter.toLowerCase() == 'yes';
                // }

                // if(this.statusFilter) {
                //     this.paging.status = this.statusFilter;
                // }

                if(this.receiverPlatformFilter) {
                    this.paging.platform = this.receiverPlatformFilter;
                }

                if(this.receiverSearch) {
                    this.paging.name = this.receiverSearch;
                }

            }

            const ssPaging = JSON.stringify(this.paging);
            sessionStorage.setItem('devicepage', ssPaging);

            const storeRes = await receiverState.fetchReceiverDisplayList(this.paging);

            this.allReceivers = storeRes.data && storeRes.data.data ? storeRes.data.data : [] as IReceiverListDisplay[];
            this.receiverCount = storeRes.data && storeRes.data.itemsCount ? storeRes.data.itemsCount : 0;

            if(this.paging.pageIndex > 1 && this.allReceivers.length == 0) {
                this.paging.pageIndex = this.paging.pageIndex - 1;
                this.doReceiverSearch(false);
                return;
            } else {
                if(!fromCreated) this.loading = false;
                return;
            }
        }
    },
    inject: [
        'userOnboardStatus',
        'currentStep',
        'windowWidth',
        'hideOnboardingByAccess'
    ],
    mounted() {
        this.$nextTick(()=>{
            this.domLoaded = true;
        });
    },

    beforeUnmount () {
        clearInterval(this.timer);
    },

    async created () {
        receiverState = getModule(ReceiverState);
        userState = getModule(UserState);
        onboardingStatusState = getModule(OnboardStatusState);
        roomState = getModule(RoomState);
        tierState = getModule(TierState);

        await Promise.all([
            this.doReceiverSearch(true),
            onboardingStatusState.fetchOnboardStatus(),
            tierState.fetchActivateReceiversCheck(),
            userState.fetchMe(null)
        ]);

        if(!userState.me) {
            console.error("Error getting user information.");
            return;
        }

        this.loading = false;
    }
});
