
import { defineComponent } from 'vue';
import { getAuthInstance } from '../../auth';

export default defineComponent({
  name: 'Logout',
  created() {
      const authInstance = getAuthInstance();
      if(authInstance) {
        authInstance.logout({returnTo:`${window.location.protocol}//${window.location.hostname}`});
      }
      else {
          window.location.href = `https://${process.env.VUE_APP_AUTH0_DOMAIN}/v2/logout`;
      }
  }
});
