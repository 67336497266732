
import axios from 'axios';
import { defineComponent } from 'vue'
import { mdiWeatherSunny,mdiWeatherPartlyCloudy,mdiWeatherCloudy,mdiWeatherFog,mdiWeatherPartlyRainy,mdiWeatherPartlySnowy, mdiWeatherPartlySnowyRainy,
mdiWeatherSnowyRainy, mdiWeatherPartlyLightning, mdiWeatherSnowy, mdiWeatherSnowyHeavy, mdiWeatherRainy, mdiWeatherPouring, mdiWeatherHail, mdiWeatherLightningRainy} from '@mdi/js'

export default defineComponent({
    name: 'WeatherTodayLayout' as string,
    data: ()=>({
        item: {} as any,
        locationQuery: '' as any,
        weather: {} as any,
        weatherIconKey: {
            1000: { icon: mdiWeatherSunny, fill: '#E2D248' },
            1003: { icon: mdiWeatherPartlyCloudy, fill: '#E2D248' },
            1006: { icon: mdiWeatherCloudy, fill: '#A7A7A7' },
            1009: { icon: mdiWeatherCloudy, fill: '#A7A7A7' },
            1030: { icon: mdiWeatherFog, fill: '#9499AB'},
            1063: { icon: mdiWeatherPartlyRainy, fill: '#989FFF' },
            1066: { icon: mdiWeatherPartlySnowy, fill: '#7CB5E6' },
            1069: { icon: mdiWeatherPartlySnowyRainy, fill: '#7C9CE6' },
            1072: { icon: mdiWeatherSnowyRainy, fill: '#7C9CE6' },
            1087: { icon: mdiWeatherPartlyLightning, fill: '#EEB156' },
            1114: { icon: mdiWeatherSnowy, fill: '#7CB5E6' },
            1117: { icon: mdiWeatherSnowyHeavy, fill: '#7CB5E6' },
            1135: { icon: mdiWeatherFog, fill: '#9499AB' },
            1147: { icon: mdiWeatherFog, fill: '#9499AB' },
            1150: { icon: mdiWeatherRainy, fill: '#989FFF' }, //patchy light drizzle
            1153: { icon: mdiWeatherRainy, fill: '#989FFF' },
            1168: { icon: mdiWeatherSnowyRainy, fill: '#7C9CE6' },
            1171: { icon: mdiWeatherSnowyRainy, fill: '#7C9CE6' },
            1180: { icon: mdiWeatherPartlyRainy, fill: '#989FFF' }, //patchy light  rain,
            1183: { icon: mdiWeatherRainy, fill: '#989FFF' }, //light rain
            1186: { icon: mdiWeatherPartlyRainy, fill: '#989FFF' }, //moderate rain at times
            1189: { icon: mdiWeatherRainy, fill: '#989FFF' }, //moderate rain
            1192: { icon: mdiWeatherPartlyRainy, fill: '#989FFF' }, //jeaby rain at time
            1195: { icon: mdiWeatherPouring, fill: '#989FFF' }, //heavy rain
            1198: { icon: mdiWeatherRainy, fill: '#989FFF' },
            1201: { icon: mdiWeatherPouring, fill: '#989FFF' },
            1204: { icon: mdiWeatherSnowyRainy, fill: '#7C9CE6' },
            1207: { icon: mdiWeatherSnowyRainy, fill: '#7C9CE6' },
            1210: { icon: mdiWeatherPartlySnowy, fill: '#7CB5E6' },
            1213: { icon: mdiWeatherSnowy, fill: '#7CB5E6' },
            1216: { icon: mdiWeatherPartlySnowy, fill: '#7CB5E6' }, //patchy moderate snow
            1219: { icon: mdiWeatherSnowy, fill: '#7CB5E6' }, //moderate snow
            1222: { icon: mdiWeatherPartlySnowy, fill: '#7CB5E6' }, //patchy heavy snow
            1225: { icon: mdiWeatherSnowyHeavy, fill: '#7CB5E6' },
            1237: { icon: mdiWeatherHail, fill: '#60D8E6' }, //ice pellets
            1240: { icon: mdiWeatherPartlyRainy, fill: '#989FFF' }, //light rain shower
            1243: { icon: mdiWeatherPartlyRainy, fill: '#989FFF' }, //moderate of heavy rain shower
            1246: { icon: mdiWeatherPartlyRainy, fill: '#989FFF' }, //torrential rain shower
            1249: { icon: mdiWeatherPartlySnowyRainy, fill: '#7C9CE6' }, //light sleet showers
            1252: { icon: mdiWeatherPartlySnowyRainy, fill: '#7C9CE6' }, //moderate/heavy sleet showers
            1255: { icon: mdiWeatherPartlySnowy, fill: '#7CB5E6' },
            1258: { icon: mdiWeatherPartlySnowy, fill: '#7CB5E6' },
            1261: { icon: mdiWeatherHail, fill: '#60D8E6' }, // ice pellet showers
            1264: { icon: mdiWeatherHail, fill: '#60D8E6' },
            1273: { icon: mdiWeatherPartlyLightning, fill: '#EEB156' }, //pathy light rain w/ thunder
            1276: { icon: mdiWeatherLightningRainy, fill: '#EEB156' },
            1279: { icon: mdiWeatherPartlySnowy, fill: '#7CB5E6' }, //patchy light snow w/ thunder
            1282: { icon: mdiWeatherSnowyHeavy, fill: '#7CB5E6' },
        } as any         
    }), 

    computed: {
        shortenedForecast() {
            // Get the current hour when the forecast query was made 
            const currentDate = new Date(this.weather.location.localtime);
            const currentHour = currentDate.getHours();

            // We need to check if we're within 4 hours of midnight
            // so we can add the next days forecast to the array also.
            let forcastHours = [];
            if(currentHour > 20) {
                forcastHours = this.weather.forecast.forecastday[0].hour.concat(this.weather.forecast.forecastday[1].hour);
            } else {
                forcastHours = this.weather.forecast.forecastday[0].hour;
            }

            // Find the current time within the days
            // forecast array and grab the following 3 hours
            let found = forcastHours.findIndex((hour: any)=>{
                let hr = new Date(hour.time).getHours();
                return hr == currentHour;
            });

            //If we're halfway through the hour lets show the next hours outlook
            if(currentDate.getMinutes() > 30) {
                found++;
            }
            return forcastHours.slice(found, found + 4);
        },

        today() {
            return this.weather.forecast.forecastday[0].day;
        },

        dayOfWeek() {
            let day = new Date(this.weather.location.localtime).getDay();
            let daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

            return daysOfWeek[day];
        },

        isFahrenheit(): boolean {
            if(this.item.temperatureScale == 'Fahrenheit')
            {
                return true;
            }
            return false;
        }
    },

    props: ['id', 'signature'],

    mounted() {
        axios.get(`${ process.env.VUE_APP_API_HOST }/api/signages/${this.id}/templaterequest/${this.signature}`).then((res) => {
            this.item = res.data;           
            this.locationQuery = this.item.location;
            if(this.item.location.id) {
                this.locationQuery = `id:${this.item.location.id}`;
            }
            else if(this.item.location.lat && this.item.location.lon) {
                this.locationQuery = `${this.item.location.lat} ${this.item.location.lon}`;
            }
           
        }).then(() => {
            axios.get(`https://api.weatherapi.com/v1/forecast.json?key=06b4f1656f984044bac135452233108&q=${this.locationQuery}&days=2&aqi=no&alerts=no`).then((response)=>{
                if(response.data) {
                    this.weather = response.data;
                } else {
                    window.location.href = 'https://httpstat.us/400';
                }
            });
        })
    }
});

