
import { defineComponent, nextTick } from 'vue';
import { getModule } from 'vuex-module-decorators';
import CardState, { ICardState } from '../../store/modules/cards';
import { ICustomer, ICustomerShippingAddress, ICustomerShipping } from '../../store/interfaces/ICustomer';
import CustomerState, { ICustomerState } from '../../store/modules/customers';
import UserState, { IUserState } from '../../store/modules/users';
import UIValidationService from '../../services/UIValidationService';
import { Stripe, StripeCardElement, loadStripe } from '@stripe/stripe-js';
import OrganizationState, { IOrganizationState } from '../../store/modules/organizations';
import { ICard } from '../../store/interfaces/ICard';
import SubscriptionState, { ISubscriptionState } from '../../store/modules/subscriptions';

let cardState = {} as ICardState;
let customerState = {} as ICustomerState;
let userState = {} as IUserState;
let organizationState = {} as IOrganizationState
let stripe = null as Stripe | null;
let cardElement = null as StripeCardElement | null;
let subscriptionState = {} as ISubscriptionState;

export default defineComponent({
    name: 'SubscriptionPaymnetModal',
    data() {
        return {
            isLoading: true,
            useNewCard: false,
            customer: {
                shipping: {
                    address:{} as ICustomerShippingAddress
                } as ICustomerShipping
            } as ICustomer,
            customerRules: {
                name: [{validator: UIValidationService.requiredValidator(this), message: 'Please enter a valid name', fullField:'customer.shipping.name', trigger: 'blur'}],
                line1: [{validator: UIValidationService.requiredValidator(this), message: 'Please enter a valid address', fullField:'customer.shipping.address.line1', trigger: 'blur'}],
                city: [{validator: UIValidationService.requiredValidator(this), message: 'Please enter a valid city', fullField:'customer.shipping.address.city', trigger: 'blur'}],
                state: [{validator: UIValidationService.requiredValidator(this), message: 'Please enter a valid state', fullField:'customer.shipping.address.state', trigger: 'blur'}],
                postal_code: [{validator: UIValidationService.requiredValidator(this), message: 'Please enter a valid zip code', fullField:'customer.shipping.address.postal_code', trigger: 'blur'}],
                country: [{validator: UIValidationService.requiredValidator(this), message: 'Please enter a valid coutry', fullField:'customer.shipping.address.country', trigger: 'blur'}]
            }
        }
    },

    props: ['isUpdate', 'planName', 'planId', 'receiverCount', 'totalPrice'],

    emits: ['update:modelValue', 'start-subscription', 'update-payment'],

    computed: {
        card(): ICard | null {
            if(!cardState.allCards) return null
            return cardState.allCards[0];
        },

        cardBrand(): string {
            if(!this.card || !this.card.brand) return '';
            return this.card.brand.toLowerCase();
        }
    },

    methods: {
        async loadCardElement() {
            if(!process.env.VUE_APP_STRIPE_KEY) {console.error('stripe key!!!'); return;}
            
            await nextTick(async () => {
                const ce = this.$refs["card-element"];
                stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY as any);
                if(!stripe) return;
                const elements = stripe.elements();
                cardElement = elements.create("card");
                if(ce) {
                    cardElement.mount(ce as any);
                }
            });
        },

        async onCancel() {
            if(!userState.me || !customerState.customerForCurrentUser(userState.me)) { 
                this.customer = {shipping: {address:{} as ICustomerShippingAddress} as ICustomerShipping} as ICustomer;
            }
            else {
                (this as any).$refs['form'].clearValidate();
                
                this.customer = JSON.parse(JSON.stringify(customerState.customerForCurrentUser(userState.me))) as ICustomer;

                if(!this.customer || !this.customer.shipping || !this.customer.shipping.address)
                {
                    if(cardState.allCards) {
                        const card = cardState.allCards[0];
                        this.customer.shipping = {
                            name: card.name ? card.name : undefined,
                            address:{
                                line1: card.address_line1,
                                line2: card.address_line2,
                                city: card.address_city,
                                state: card.address_state,
                                postal_code: card.address_zip,
                                country: card.address_country
                            }
                        } as ICustomerShipping
                    }
                }

                this.customer.vat_number = userState.me.organization.billing.vat_number;
            }

            this.$emit('update:modelValue', false);
        },

        validateForm (): Promise<boolean> {
            return new Promise((resolve) => {
                (this as any).$refs['form'].validate((valid: boolean, obj: any) => {
                    if(!valid) {
                        let errorHTML = '';
                        for(const key in obj) {
                            for(const err of obj[key]) {
                                errorHTML = `${errorHTML}${err.message}<br>`;
                            }
                        }
                        console.error(errorHTML);
                        (this as any).$message({
                            dangerouslyUseHTMLString: true,
                            message: errorHTML,
                            type: 'error'
                        });
                    }

                    resolve(valid);
                });
            });
        },

        async loadCustomer() {
            if(userState.me) {
                this.customer = JSON.parse(JSON.stringify(customerState.customerForCurrentUser(userState.me))) as ICustomer??this.customer;
                
                if(!this.customer || !this.customer.shipping || !this.customer.shipping.address)
                {
                    if(cardState.allCards) {
                        const card = cardState.allCards[0];
                        this.customer.shipping = {
                            name: card.name ? card.name : undefined,
                            address:{
                                line1: card.address_line1,
                                line2: card.address_line2,
                                city: card.address_city,
                                state: card.address_state,
                                postal_code: card.address_zip,
                                country: card.address_country
                            }
                        } as ICustomerShipping
                    }
                }

                this.customer.vat_number = userState.me.organization.billing.vat_number;
            }
        },

        async onSubmit() {
            if(this.isLoading) return;
            if(!await this.validateForm()) return;
            this.isLoading = true;
        
            const vatNumber = this.customer.vat_number;

            const me = userState.me;
            if(!me) return;

            if(!this.card || this.useNewCard) {
                const tokenSuccess = await this.getStripeCardToken();

                if(!tokenSuccess) {
                    this.isLoading = false;
                    return;
                }
            }

            if(this.customer.id) {
                const customerResponse = await customerState.UpdateCustomer(this.customer);

                if(!customerResponse.success || !customerResponse.data) {
                    (this as any).$message.error(customerResponse.reason ? `Payment information update failed: ${customerResponse.reason}` : 'Payment information update failed');
                    this.isLoading = false;
                    return;
                }

                if(this.isUpdate) {
                    (this as any).$message.success('Payment information updated');
                }
            }
            else {
                this.customer.email = me.email;
                const customerResponse = await customerState.CreateCustomer(this.customer);

                if(!customerResponse.success || !customerResponse.data) {
                    (this as any).$message.error(customerResponse.reason ? `Payment information creation failed: ${customerResponse.reason}` : 'Payment information creation failed');
                    this.isLoading = false;
                    return;
                }

                me.organization.billing.customer_id = customerResponse.data[0].id;
            }

            me.organization.billing.vat_number = vatNumber ? vatNumber : '';
            me.organization.billing.stripe_card_error =  '';

            const updateOrgResponse = await organizationState.updateOrganization(me.organization);

            if(!updateOrgResponse.success) {
                (this as any).$message.error(updateOrgResponse.reason ? `Organization update failed: ${updateOrgResponse.reason}` : 'Organization update failed');
                this.isLoading = false;
                return;
            }
            
            await Promise.all([
                cardState.fetchCards(true),
                userState.fetchMe({reload: true}),
                userState.fetchSubscriptionStatus(true),
                subscriptionState.fetchSubscriptions(true),
            ]);

            await this.loadCustomer();

            if(!this.isUpdate) {
                this.$emit('start-subscription', this.customer);
            }
            else {
                this.$emit('update-payment', this.customer);
            }
           
            this.isLoading = false;
        },

        async getStripeCardToken (): Promise<boolean> {
            if(!stripe || !cardElement) return false;
            const stripeResponse = await stripe.createToken(cardElement, {
                address_line1: this.customer.shipping.address.line1, 
                address_line2: this.customer.shipping.address.line2??'', 
                address_city: this.customer.shipping.address.city, 
                address_state: this.customer.shipping.address.state, 
                address_zip: this.customer.shipping.address.postal_code, 
                address_country: this.customer.shipping.address.country
            });
            
            if(stripeResponse.error) {
                // throw validation waring
                if(this.isUpdate) {
                    (this as any).$message.error(stripeResponse.error.message ? `Payment information update failed: ${stripeResponse.error.message}` : 'Payment information update failed');
                }
                else {
                    (this as any).$message.error(stripeResponse.error.message ? `Payment failed: ${stripeResponse.error.message}` : 'Payment failed');
                }
                return false;
            }

            if(!stripeResponse.token) return false;

            this.customer.source = stripeResponse.token.id;
            
            return true;
        }
    },

    async created() {
        cardState = getModule(CardState);
        customerState = getModule(CustomerState);
        userState = getModule(UserState);
        organizationState = getModule(OrganizationState);
        subscriptionState = getModule(SubscriptionState);
        
        await Promise.all([
            cardState.fetchCards(true),
            customerState.fetchCustomers(),
            userState.fetchMe()
        ]);

        if(!userState.me || !customerState.customerForCurrentUser(userState.me)) { 
            this.isLoading = false;
            return;
        }

        await this.loadCustomer();

        this.isLoading = false;
    },

    watch: {
        'useNewCard': {
            handler(newValue: any){
                if(newValue) {
                    this.loadCardElement();
                }
            },
            deep: true
        },
    }   
});
