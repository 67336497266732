
import { defineComponent } from 'vue';
import UserState, { IUserState } from '../store/modules/users';
import { getModule } from 'vuex-module-decorators';
import { IAuthService, getAuthInstance } from '../auth';

let userState = {} as IUserState;
export default defineComponent({
    name: 'Error',
    data() {
        return {
            parsedMessage: '',
            userId: '' as string | null,
            userEmail:'' as string | null,
            resendVerificationSuccess: null as boolean | null,
            auth: null as IAuthService | null,
            sendingReverificationEmail: false as boolean
        }
    },
    computed: {
        authenticated(): boolean {
            if(!this.auth) return false;
            return this.auth.isAuthenticated;
        },
        dateYear() {
        return (new Date()).getFullYear();
        }
    },
    methods: {
        async resendVerificationEmail() {
            if(!this.userId || this.sendingReverificationEmail) return;

            this.sendingReverificationEmail = true;
            const stateResponse = await userState.resendEmailVerification(this.userId);
            
            this.resendVerificationSuccess = stateResponse.success;
        },
        onLogin() {
            if(this.authenticated) {
                this.$router.push({ path: `/logout` })
            }
            else {
                this.$router.push({ path: `/` })
            }
        }
    },
    async created() {
        this.auth = getAuthInstance();
        userState = getModule(UserState);
        await userState.fetchMe(null);
        
        if(this.$route.query && 
            this.$route.query.error_description) {
            const splitMessage = (this.$route.query.error_description as string).split('|');
            this.parsedMessage = splitMessage[0];
            this.userId = splitMessage.length > 1 ? splitMessage[1] : null;
            this.userEmail = splitMessage.length > 2 ? decodeURIComponent(splitMessage[2]) : null;
        }

        if(this.$route.query && this.$route.query.canvastate) {
            //Wait 5 seconds to show user error meddage and then send failure back to Canva
            const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
            await delay(5000);
            window.location.href = `https://canva.com/apps/configured?success=false&state=${this.$route.query.canvastate}`;
        }
    }
});
