import {
    Action,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';

import store from '../../store';

import { IReceiver, IReceiverDropDownList, IReceiverListDisplay } from '../interfaces/IReceiver';
import { getAuthInstance } from '../../auth';
import axios from 'axios';
import { ISqStoreActionResponse } from '../interfaces/ISqStoreActionResponse';
import { IPairing } from '../interfaces/IPairing';
import UserState from './users';
import { getModule } from 'vuex-module-decorators'
import { IModelSearchResponse, IReceiverModelSearch } from '../interfaces/IModelSearch';

export interface IReceiverState {
    fetchReceiver(receiverFetchReq: {id: string, reload: boolean}): Promise<ISqStoreActionResponse<IReceiver>>;
    createReceiver(receiver: IReceiver): Promise<ISqStoreActionResponse<IReceiver>>;
    updateReceiver(receiver: IReceiver): Promise<ISqStoreActionResponse<IReceiver>>;
    deleteReceiver(receiver: IReceiver): Promise<ISqStoreActionResponse<void>>;
    pairReceiver(pairingCode: string): Promise<ISqStoreActionResponse<IReceiver>>;
    getPairingByReceiverId(deviceId: string): Promise<ISqStoreActionResponse<any>>;
    generatePairingRegistryKey(receiver: IReceiver): Promise<ISqStoreActionResponse<IPairing>>;
    fetchReceiversDLL(reload?: boolean): Promise<ISqStoreActionResponse<IReceiverDropDownList[]>>;
    fetchReceiverDisplayList(receiverSearch: IReceiverModelSearch): Promise<ISqStoreActionResponse<IModelSearchResponse<IReceiverListDisplay[]>>>;
}

@Module({ dynamic: true, store, name: 'receivers' })
export default class ReceiverState extends VuexModule implements IReceiverState {
    // THESE HAVE TO BE NAMED UNIQUELY
    // THE WAY STATE WORKS IF YOU ALTER THIESE VIA CONTEXT 
    // ANY NAMED THE SAME WAY WILL CHANGE ALSO
    private receiver: IReceiver = {} as IReceiver;
    private isReceiverLoaded = false;

    private receiversDropDownList: IReceiverDropDownList[] = [];
    private isReceiversDDLLoaded = false;

    //#region GETTERS

    //#endregion

    //#region ACTIONS
    @Action
    async fetchReceiver(fetchReceiverReq: {id: string, reload: boolean}): Promise<ISqStoreActionResponse<IReceiver>> {
        
        if (!fetchReceiverReq.reload && (this.isReceiverLoaded && this.receiver.id == fetchReceiverReq.id)) return { success: true, data: Object.assign({}, this.receiver) };

        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            const fetchReceiverUrl = `${process.env.VUE_APP_API_HOST}/api/devices/${fetchReceiverReq.id}`;
            const axiosResponse = await axios.get(fetchReceiverUrl, authHeader);

            this.context.commit('setReceiver', axiosResponse.data);
            this.context.commit('setReceiverLoaded', true);
            return { success: true, data: Object.assign({}, this.receiver) };
        }
        catch(e:any) {
            console.error('Receivers Store Error:', e);
        }

        return { success: false, reason: 'Error fetching receivers.' };
    }

    @Action
    async createReceiver(receiver: IReceiver): Promise<ISqStoreActionResponse<IReceiver>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            
            const createReceiverUrl = `${ process.env.VUE_APP_API_HOST }/api/devices/`;
            const axiosResponse = await axios.post(createReceiverUrl, receiver, authHeader);
            
            this.context.commit('setReceiver', axiosResponse.data);
            this.context.commit('setReceiverLoaded', true);
            
            return { success: true, data: Object.assign({}, this.receiver) };
        }
        catch(e:any) {
            console.error('Receivers Store Error:', e);
            if(e.response && e.response.data) {
                return { success: false, reason: e.response.data };        
            }
        }

        return { success: false, reason: 'Error creating receiver.'};
    }

    @Action
    async updateReceiver(receiver: IReceiver): Promise<ISqStoreActionResponse<IReceiver>> {
        let errorMessage = 'Error updating receivers.';
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
        
            const updateReceiverUrl = `${ process.env.VUE_APP_API_HOST }/api/devices/${receiver.id}`;
            const axiosResponse = await axios.put(updateReceiverUrl, receiver, authHeader);
            
            this.context.commit('setReceiver', axiosResponse.data);
            this.context.commit('setReceiverLoaded', true);
            return { success: true, data: Object.assign({}, this.receiver) };
        }
        catch(error: any) {
            if(error.response && error.response.data) {
                errorMessage = error.response.data;
            }
            console.error('Receivers Store Error:', error);
        }

        return { success: false, reason: errorMessage};
    }

    // @Action
    // async unlinkReceivers(receiverId: string): Promise<ISqStoreActionResponse<void>> {
    //     try{
    //         const authHeader = await getAuthInstance().getAxiosHeader();
        
    //         const unlinkReceiversUrl = `${ process.env.VUE_APP_API_HOST }/api/devices/unlinkDevices`;
    //         const payload = { receiverId };
    //         const axiosResponse = await axios.post(unlinkReceiversUrl, payload, authHeader);
            
    //         const mutatedReceivers = [
    //             ...this.receivers.filter(x=>x.id !== receiverId),
    //             axiosResponse.data
    //         ];

    //         this.context.commit('setReceivers', mutatedReceivers);

    //         const userState = getModule(UserState);
    //         userState.fetchSubscriptionStatus(true);
            
    //         return { success: true };
    //     }
    //     catch(e:any) {
    //         console.error('Receivers Store Error:', e);
    //     }

    //     return { success: false, reason: 'Error unlinking receivers.'};
    // }

    @Action
    async deleteReceiver(receiver: IReceiver): Promise<ISqStoreActionResponse<void>> { 
        let errorMessage = 'Error deleting receiver.';
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            const deleteReceiverUrl = `${ process.env.VUE_APP_API_HOST }/api/devices/${receiver.id}`; // needs an id based on the signature, but not used in the api...
            await axios.delete(deleteReceiverUrl, authHeader);
            
            const userState = getModule(UserState);
            userState.fetchSubscriptionStatus(true);
            
            return { success: true };
        }
        catch(error :any) {
            if(error.response && error.response.data) {
                errorMessage = error.response.data;
            }
            console.error('Receivers Store Error:', error);
        }
     
        return { success: false, reason: errorMessage};
    }

    @Action
    async pairReceiver(pairingCode: string): Promise<ISqStoreActionResponse<IReceiver>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            const pairReceiverUrl = `${ process.env.VUE_APP_API_HOST }/api/pairings/pair/${pairingCode}`;
            const axiosResponse = await axios.get(pairReceiverUrl, authHeader);

            this.context.commit('setReceiver', axiosResponse.data.device);
            this.context.commit('setReceiverLoaded', true);
            return { success: true, data: Object.assign({}, this.receiver) };
        }
        catch(e:any) {
            console.error('Receivers Store Error:', e);
        }

        return { success: false, reason: 'Error pairing receiver.'};
    }

    @Action
    async getPairingByReceiverId(deviceId: string): Promise<ISqStoreActionResponse<IPairing>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            const getPairingUrl = `${ process.env.VUE_APP_API_HOST }/api/pairings/pair/device/${deviceId}`;
            const axiosResponse = await axios.get(getPairingUrl, authHeader);

            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            // we get 404's lets not surface a error for this
            // console.error('Receivers Store Error:', e);
        }

        return { success: false, reason: 'Error generation pairing key.'};
    }

    @Action
    async generatePairingRegistryKey(receiver: IReceiver): Promise<ISqStoreActionResponse<IPairing>> {
        try {
            const pairingRequest = {
                "prePair": true,
                "deviceId": receiver.id,
                "device_type": receiver.deviceType,
                "display_name": receiver.displayName,
                "platform": receiver.platform.toLowerCase(),
            }
            const authHeader = await getAuthInstance().getAxiosHeader();
            const pairReceiverUrl = `${ process.env.VUE_APP_API_HOST }/api/pairings/`;
            const axiosResponse = await axios.post(pairReceiverUrl, pairingRequest, authHeader);

            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Receivers Store Error:', e);
        }

        return { success: false, reason: 'Error generation pairing key.'};
    }

    @Action
    async fetchReceiversDLL(reload = false): Promise<ISqStoreActionResponse<IReceiverDropDownList[]>> {
        try {
            if(!reload && this.isReceiversDDLLoaded) return { success: true, data: this.receiversDropDownList };

            const authHeader = await getAuthInstance().getAxiosHeader();

            const receiversDropDownListURL = `${process.env.VUE_APP_API_HOST}/api/devices/dropdownlist`;
            
            const axiosResponse = await axios.get(receiversDropDownListURL, authHeader);

            this.context.commit('setReceiversDDL', axiosResponse.data);
            this.context.commit('setIsReceiversDDLLoaded', true);

            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Rooms Store Error:', e);
        }

        return { success: false, reason: 'Error fetching available rooms.' };

    }
    
    @Action
    async fetchReceiverDisplayList(receiverSearch: IReceiverModelSearch): Promise<ISqStoreActionResponse<IModelSearchResponse<IReceiverListDisplay[]>>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            let fetchReceiversUrl = `${process.env.VUE_APP_API_HOST}/api/devices`;

            if(receiverSearch.pageIndex && receiverSearch.pageSize) {
                fetchReceiversUrl = `${fetchReceiversUrl}?pageIndex=${receiverSearch.pageIndex}&pageSize=${receiverSearch.pageSize}`;

                if(receiverSearch.organizationId) {
                    fetchReceiversUrl = `${fetchReceiversUrl}&organizationId=${receiverSearch.organizationId}`;
                }

                if(receiverSearch.ids && receiverSearch.ids.length > 0) {
                    fetchReceiversUrl = `${fetchReceiversUrl}&ids=${receiverSearch.ids}`;
                }

                if(receiverSearch.locationId) {
                    fetchReceiversUrl = `${fetchReceiversUrl}&locationId=${receiverSearch.locationId}`;
                }

                if(receiverSearch.deviceType) {
                    fetchReceiversUrl = `${fetchReceiversUrl}&deviceType=${receiverSearch.deviceType}`;
                }

                if(receiverSearch.platform) {
                    fetchReceiversUrl = `${fetchReceiversUrl}&platform=${receiverSearch.platform}`;
                }

                if(receiverSearch.status) {
                    fetchReceiversUrl = `${fetchReceiversUrl}&status=${receiverSearch.status}`;
                }

                if(receiverSearch.isActive !== null && receiverSearch.isActive !== undefined) {
                    fetchReceiversUrl = `${fetchReceiversUrl}&isActive=${receiverSearch.isActive}`;
                }

                if(receiverSearch.name) {
                    fetchReceiversUrl = `${fetchReceiversUrl}&name=${receiverSearch.name}`;
                }

                if(receiverSearch.sortField && receiverSearch.sortOrder) {
                    fetchReceiversUrl = `${fetchReceiversUrl}&sortField=${receiverSearch.sortField}&sortOrder=${receiverSearch.sortOrder}`;
                }
            }

            const axiosResponse = await axios.get(fetchReceiversUrl, authHeader);

            return { success: true, data: {itemsCount: axiosResponse.data.itemsCount, data: axiosResponse.data.data} as IModelSearchResponse<IReceiverListDisplay[]> };
        }
        catch(e:any) {
            console.error('Receiver Store Error:', e);
        }

        return { success: false, reason: 'Error searching Receivers.' };
    }
    //#endregion

    //#region MUTATIONS
    @Mutation
    async setReceiver(receiver: IReceiver): Promise<void> {
        this.receiver = receiver;
    }

    @Mutation
    async setReceiverLoaded(isLoaded: boolean): Promise<void> {
        this.isReceiverLoaded = isLoaded;
    }

    @Mutation
    async setReceiversDDL(receiverDropDownList: IReceiverDropDownList[]): Promise<void> {
        this.receiversDropDownList = receiverDropDownList;
    } 

    @Mutation
    async setIsReceiversDDLLoaded(isReceiversDDLLoaded: boolean): Promise<void> {
        this.isReceiversDDLLoaded = isReceiversDDLLoaded;
    }
    //#endregion
}