import {
    Action,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';

import store from '..';
import { IReceiverStatus } from '../interfaces/IReceiverStatus';
import { getAuthInstance } from '../../auth';
import axios from 'axios';
import { ISqStoreActionResponse } from '../interfaces/ISqStoreActionResponse';

export interface IReceiverStatusState {
    readonly allReceiverStatuses: IReceiverStatus[] | undefined;
    readonly statusByReceiverId: (id: string) => IReceiverStatus | undefined;
    readonly statusesByRoomId: (id: string) => IReceiverStatus[] | undefined;

    fetchStatuses(): Promise<ISqStoreActionResponse<void>>;
}


@Module({ dynamic: true, store, name: 'receiverstatuses' })
export default class ReceiverStatusState extends VuexModule implements IReceiverStatusState {

    // THESE HAVE TO BE NAMED UNIQUELY
    // THE WAY STATE WORKS IF YOU ALTER THIESE VIA CONTEXT
    // ANY NAMED THE SAME WAY WILL CHANGE ALSO
    private statuses: IReceiverStatus[] = [];


    //#region GETTERS
    get allReceiverStatuses(): IReceiverStatus[] {
        return [...this.statuses.map((x : IReceiverStatus)=>Object.assign({}, x))];
    }

    get statusByReceiverId(): (id: string) => IReceiverStatus | undefined {
        return (id: string) => {
            const receiverstatus =  this.statuses.find(x => x.receiverId === id);
            return receiverstatus ? receiverstatus : undefined;
        };
    }

    get statusesByRoomId(): (id: string) => IReceiverStatus[] | undefined {
        return (id: string) => {
            const roomReceiverStatuses =  this.statuses.filter(x => x.roomId === id);

            return [...roomReceiverStatuses.map(x=>Object.assign({}, x))];
        };
    }

    @Action
    async fetchStatuses(): Promise<ISqStoreActionResponse<void>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            const fetchReceiverStatusUrl = `${process.env.VUE_APP_API_HOST}/api/receiverstatuses/`;
            const axiosResponse = await axios.get(fetchReceiverStatusUrl, authHeader);
            this.context.commit('setReceiverStatuses', axiosResponse.data);
            return { success: true };
        }
        catch(e:any) {
            console.error('Receiver Status Store Error:', e);
        }

        return { success: false, reason: 'Error fetching receiver statuses.' };
    }
    //#endregion

    //#region MUTATIONS
    @Mutation
    async setReceiverStatuses(statuses: IReceiverStatus[]): Promise<void> {
        this.statuses = statuses;
    }
    //#endregion
}