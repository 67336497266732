
import { defineComponent } from 'vue'
import axios from 'axios';
import textFit from 'textfit';

export default defineComponent({
    name: 'FeatureTwoLayout' as string,
    props: ['id', 'signature'],
    data:(()=> {
        return {
            item: {} as any,
            loaded: false as boolean
        }
    }),

    mounted() {
        return axios.get(`${ process.env.VUE_APP_API_HOST }/api/signages/${this.id}/templaterequest/${this.signature}`).then((res) => {
            this.item = res.data;
            this.loaded = true;
        },
        (err) => console.error(err));
    },

    watch: {
        item() {
            this.$nextTick(() => {
                textFit(document.getElementsByClassName('textfit__headline'), { minFontSize: 10, maxFontSize: 300 });
                textFit(document.getElementsByClassName('textfit__secondary-headline'), { minFontSize: 10, maxFontSize: 100, widthOnly: true, detectMultiLine: false });
                textFit(document.getElementsByClassName('textfit__description'), { minFontSize: 10, maxFontSize: 120 });
                textFit(document.getElementsByClassName('textfit__banner'), { minFontSize: 10, maxFontSize: 75, widthOnly: true, detectMultiLine: false });
            });
        }
    }
});

