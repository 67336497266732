import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10b0875c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item" }
const _hoisted_2 = { class: "item__header--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", {
      style: _normalizeStyle({ background: _ctx.item.backgroundColor }),
      class: "item__content"
    }, [
      _createElementVNode("h1", {
        style: _normalizeStyle({ color: _ctx.item.fontColor }),
        class: _normalizeClass([{ 'item__header' : _ctx.item.header != '' }, "textfit__header"])
      }, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.item.header), 1)
      ], 6),
      _createElementVNode("div", null, [
        _createElementVNode("h2", {
          style: _normalizeStyle({ color: _ctx.item.fontColor }),
          class: "item__title textfit__title"
        }, _toDisplayString(_ctx.item.title), 5),
        _createElementVNode("p", {
          style: _normalizeStyle({ color: _ctx.item.fontColor }),
          class: "item__description textfit__description--multiline"
        }, _toDisplayString(_ctx.item.description), 5),
        _createElementVNode("span", {
          class: "item__price",
          style: _normalizeStyle({ color: _ctx.item.backgroundColor })
        }, _toDisplayString(_ctx.item.price), 5)
      ]),
      _createElementVNode("span", {
        style: _normalizeStyle({ color: _ctx.item.fontColor }),
        class: "item__footer-text textfit__footer--multiline"
      }, _toDisplayString(_ctx.item.disclaimer), 5)
    ], 4),
    (_ctx.item && _ctx.item.media)
      ? (_openBlock(), _createElementBlock("section", {
          key: 0,
          style: _normalizeStyle({ background: 'url('+_ctx.item.media.asset1_url+') center center no-repeat', backgroundSize: 'cover'})
        }, null, 4))
      : _createCommentVNode("", true)
  ]))
}