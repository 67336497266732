import {
    Action,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';

import store from '..';

import { getAuthInstance } from '../../auth';
import axios from 'axios';
import { ISqStoreActionResponse } from '../interfaces/ISqStoreActionResponse';
import { IInvoice } from '../interfaces/IInvoice';

export interface IInvoiceState {
    readonly allInvoices: IInvoice[] | null;
    fetchInvoices(reload?: boolean): Promise<ISqStoreActionResponse<IInvoice[] | null>>;
}

@Module({ dynamic: true, store, name: 'invoices' })
export default class InvoiceState extends VuexModule implements IInvoiceState {
    private INVOICES: IInvoice[] = null as any;
    //#region GETTERS
    get allInvoices(): IInvoice[] | null {
        if(!this.INVOICES) return null;
        return [...this.INVOICES.map((x)=>Object.assign({}, x))];
    }

    // get cardById(): (id: string) => ICard | null {
    //     return (id: string) => {
    //         if (!this.CARDS) return null;
    //         const sub = this.CARDS.find(x => x.id === id);
    //         return sub ? Object.assign({}, sub) : null;
    //     };
    // }
    //#endregion

    //#region ACTIONS
    @Action
    async fetchInvoices(reload = false): Promise<ISqStoreActionResponse<IInvoice[] | null>> {
        if (!reload && this.INVOICES) return { success: true, data: [...this.INVOICES.map((x)=>Object.assign({}, x))]};

        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            
            const fetchInvoicesUrl = `${process.env.VUE_APP_API_HOST}/api/invoices`;
            const axiosResponse = await axios.get(fetchInvoicesUrl, authHeader);

            if(axiosResponse && Array.isArray(axiosResponse.data) && axiosResponse.data.length > 0 ) {
                this.context.commit('setInvoices', axiosResponse.data);
            }

            const returnData = this.INVOICES ? [...(this.INVOICES as IInvoice[]).map((x)=>Object.assign({}, x))] : null;
            return { success: true, data: returnData as any};
        }
        catch(e:any) {
            console.error('Invoice Store Error:', e);
        }

        return { success: false, reason: 'Error fetching invoices.' };
    }

    //#endregion

    //#region MUTATIONS

    @Mutation
    async setInvoices(invoices: IInvoice[]): Promise<void> {
        this.INVOICES = invoices;
    }
    //#endregion


}
