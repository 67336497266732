
import { defineComponent } from 'vue';
import { IReceiver } from '../../store/interfaces/IReceiver';
import { mdiKeyboardBackspace, mdiArrowLeft } from '@mdi/js';
import Icon from '../../components/Icon.vue';
import { getModule } from 'vuex-module-decorators';
import OnboardStatusState, { IOnboardStatusState } from '../../store/modules/onboarding';
let onboardStatusState = {} as IOnboardStatusState;

export default defineComponent({
    name: 'NewReceiverTypeView',
    props: ['newReceiverModel', 'legacyReceiversEnabled'],
    data() {
        return {
            domLoaded: false as boolean,
            newReceiver: {} as IReceiver,
            mdiKeyboardBackspace,
            mdiArrowLeft
        }
    },

    mounted() {
        this.newReceiver = Object.assign([], this.newReceiverModel);
        onboardStatusState = getModule(OnboardStatusState);
        this.$nextTick(()=> {
            this.domLoaded = true;
        });
    },

    updated() {
        this.$nextTick(()=> {
            this.domLoaded = true;
        });
    },

    components: {
        Icon
    },

    inject: [
        'currentStep',
        'userOnboardStatus'
    ],
    methods: {
        onPreviousStep() {
            this.$emit('receiver-type', null);
        },
        async onReceiverTypeSelection (receiverType: string) {
            if(receiverType == 'apple4')
            {
                this.newReceiver.deviceType = 'AirPlay',
                this.newReceiver.hardwareType = 'ATV4',
                this.newReceiver.platform = 'tvos'
            }
            else if(receiverType == 'windows')
            {
                this.newReceiver.deviceType = 'Windows',
                this.newReceiver.hardwareType = 'DittoReceiver',
                this.newReceiver.platform = 'windows'
            }
            else if(receiverType == 'apple3')
            {
                this.newReceiver.deviceType = 'AirPlay',
                this.newReceiver.hardwareType = 'ATV3',
                this.newReceiver.platform = 'atvlegacy'
            }
            else if(receiverType == 'chromecast')
            {
                this.newReceiver.deviceType = 'Cast',
                this.newReceiver.hardwareType = 'Chromecast',
                this.newReceiver.platform = 'chromecast'
            }
            else
            {
                this.newReceiver.deviceType = '',
                this.newReceiver.hardwareType = 'Other',
                this.newReceiver.platform = 'other'
            }
            
            this.$emit('receiver-type', this.newReceiver);
        },

        async onContactUs() {
            window.open('https://www.airsquirrels.com/ditto/contact-sales', '_blank');

            await onboardStatusState.putUserActions([{
                actionTagId: 'contactUsLink',
                actionDescription: "User clicked contact us link"
            }]);
        }
    }
});
