
import { getModule } from 'vuex-module-decorators';
import { defineComponent } from 'vue';
import moment from 'moment';
import Icon from "../../components/Icon.vue";
import { mdiAlertCircle, mdiInformationOutline } from '@mdi/js';
import PaymentSummary from '../../components/billing/payment-management/paymentSummary.vue';
import AdjustPlanSubscription from '../../components/billing/AdjustPlanSubscription.vue';
import PaymentHistoryInvoices from '../../components/billing/PaymentHistoryInvoices.vue';
import ComparePlans from '../../components/ComparePlans.vue';
import SubscriptionPaymentModal from '../../components/billing/SubscriptionPaymentModal.vue';
import OrganizationState, { IOrganizationState } from '../../store/modules/organizations';
import UserState, { IUserState } from '../../store/modules/users';
import SubscriptionState, { ISubscriptionState } from '../../store/modules/subscriptions';
import CustomerState, { ICustomerState } from '../../store/modules/customers';
import RoleState, { IRoleState } from '../../store/modules/role';
import TierState, { ITierState } from '../../store/modules/tier';
import CardState, { ICardState } from '../../store/modules/cards';
import { IPurchaseOrderUser } from '../../store/interfaces/IPurchaseOrderUser';
import { IOrganization } from '../../store/interfaces/IOrganization';
import { ISubscription } from '../../store/interfaces/ISubscription';
import { ISubscriptionStatus } from '../../store/interfaces/ISubscriptionStatus';
import { ICustomer, ICustomerShipping } from '../../store/interfaces/ICustomer';

let userState = {} as IUserState;
let subscriptionState = {} as ISubscriptionState;
let organizationState = {} as IOrganizationState;
let roleState = {} as IRoleState;
let tierState = {} as ITierState;
let customerState = {} as ICustomerState;
let cardState = {} as ICardState;

export default defineComponent({
    name: 'OrganizationBillingNew',
    components: {
        PaymentSummary,
        AdjustPlanSubscription,
        PaymentHistoryInvoices,
        ComparePlans,
        Icon,
        SubscriptionPaymentModal
    },
    data() {
        return {
            organization: { } as IOrganization,
            billingFrequency: 'year',
            isUpdatePaymentModalVisible: false,
            updatedReceiverCount: 0,
            featureCategories: [] as any,
            viewLoading: true,
            activeTab: 'myAccount',
            poHeader: '',
            additionalInformation: '',
            poPlaceholder: '',
            purchaseOrderModalVisible: false,
            purchaseOrderSubmitting: false,
            purchaseOrderForm: {
                numberOfReceivers: 1
            },
            allPlans: [] as any,
            customer: {} as ICustomer,
            mdiAlertCircle,
            mdiInformationOutline,
            keyIndex: 0,
            rules: {
                numberOfReceivers: [{
                    type: 'number',
                    required: true,
                    message: 'Please enter a valid number.',
                    trigger: 'blur'
                }]
            },
        }
    },
    computed: {
        trialExtension(): string | null {
            let show: string = null as any;
            if (!this.me) return show;

            if(this.isTrial && moment.utc().isAfter(this.expirationDate)) {
                show = 'renew';
            }

            if (this.isTrial && moment.utc().isAfter(this.expirationDate) &&
                this.isTrial &&
                (this.me.organization.billing.max_trials && this.me.organization.billing.trial_count) &&
                this.me.organization.billing.trial_count < this.me.organization.billing.max_trials
            ) {
                show = 'renew';
            }
            else if (this.isTrial && moment.utc().isAfter(this.expirationDate) &&
                this.isTrial &&
                (this.me.organization.billing.max_trials && this.me.organization.billing.trial_count) &&
                this.me.organization.billing.trial_count >= this.me.organization.billing.max_trials
            ) {
                show = 'expired';
            }

            return show;
        },

        stripeSubscription(): ISubscription | null {
            if (!subscriptionState.allSubscriptions) return null;
            return subscriptionState.allSubscriptions[0];
        },

        subscriptionLimit(): string {
            let limit = '0';
            if(this.isTrial) {
                limit = 'Unlimited';
            }
            else if(this.stripeSubscription && this.stripeSubscription.quantity) {
                limit = this.stripeSubscription.quantity.toString();
            } 
            else if(this.organization && this.organization.billing && this.organization.billing.subscription_limit) {
                limit = this.organization.billing.subscription_limit.toString();
            }
             
            return limit;
        },

        expirationDate(): string {
            if (!userState.me || !this.organization.billing) return '';
            const utcExp = moment.utc(this.organization.billing.expiration_date);
            const localExp = moment(utcExp).local().toDate();
            return moment(localExp).format('MM/DD/YYYY');
        },

        subscriptionStatus(): ISubscriptionStatus | null {
            if (!userState.subscriptionStatus) return null;
            return userState.subscriptionStatus;
        },

        formattedAmount(): string {
            if (!this.stripeSubscription) return '0.00';
            const sub = this.stripeSubscription;
            return ((sub.plan.amount * sub.quantity) / 100).toFixed(2);
        },

        me(): any {
            return userState.me;
        },

        hasBilling(): boolean {
            return roleState.isAdmin || roleState.hasBillingAccess;
        },

        isTrial(): boolean {
            if (!userState.subscriptionStatus) return false;
            return userState.subscriptionStatus.type.toUpperCase() === "TRIAL";
        },

        isPO(): boolean {
            if (!userState.subscriptionStatus) return false;
            return userState.subscriptionStatus.type.toUpperCase() === "PO";
        },

        isSubscriptionActive(): boolean {
            if (!userState.subscriptionStatus || this.isTrial) return false;
            return userState.subscriptionStatus.subscriptionActive;
        },

        isCanceled(): boolean {
            if (this.isTrial || this.isPO) return false;
            if (!this.stripeSubscription) return true;

            return this.stripeSubscription.cancel_at_period_end || !!this.stripeSubscription.cancel_at;
        },

        canEditPayment(): boolean {
            if(!this.hasBilling || !this.customer || (this.customer && !this.customer.shipping) || this.isPO || this.isTrial || !this.stripeSubscription) {
                return false;
            } 

            if(this.stripeSubscription.status == 'past_due' || this.stripeSubscription.status == 'incomplete_expired') {
                return true;
            }

            if(this.stripeSubscription.status == 'canceled') {
                return false;
            }

            if(this.isSubscriptionActive) {
                return true;
            }

            return false;
        },

        allowPurchaseOrChange(): boolean {
            if(!this.hasBilling || this.isPO) {
                return false;
            } 

            if(this.stripeSubscription) {
                if(this.stripeSubscription.status == 'past_due' || this.stripeSubscription.status == 'incomplete' || this.stripeSubscription.status == 'incomplete_expired') {
                    return false;
                }

                if(this.stripeSubscription.status == 'canceled') {
                    return true;
                }
            }

            return true;
        },

        expirationText(): string {
            if(this.isCanceled) {
                if(moment.utc().isBefore(this.expirationDate)) {
                    return 'Cancels on ';
                } 
                else {
                    return 'Cancelled on ';
                }
            }

            if(userState.subscriptionStatus && !userState.subscriptionStatus.subscriptionActive) {
                return 'Expired on ';
            }

            if(this.isTrial) {
                return 'Expires on '
            }
            else {
                return 'Renews on ';
            }
        },

        stripeCardErrorMessage(): string {
            var errorMessage = '';

            if (this.me.organization.billing.stripe_card_error) {
                const contactCardCompany = ['authentication_required', 'approve_with_id', 'call_issuer', 'card_not_supported', 'card_velocity_exceeded', 'currency_not_supported', 'do_not_honor', 'do_not_try_again', 'fradulent', 'generic_decline', 'invalid_account', 'invalid_amount', 'issuer_not_available', 'lost_card', 'merchant_blacklist', 'new_account_information_available', 'no_action_taken', 'not_permitted', 'pickup_card', 'processing_error', 'reenter_transaction', 'restricted_card', 'revocation_of_all_authorizations', 'revocation_of_authorization', 'security_violcation', 'service_not_allowed', 'stolen_card', 'stop_payment_order', 'transaction_not_allowed', 'try_again_later'];
                const incorrectCardInfo = ['incorrect_number', 'incorrect_cvc', 'incorrect_pin', 'incorrect_zip', 'invalid_cvc', 'invalid_expiry_month', 'invalid_number', 'invalid_pin'];
                const updateCardInfo = ['insufficient_funds', 'testmode_decline', 'withdrawal_count_limit_exceeded'];

                if (this.me.organization.billing.stripe_card_error == 'expired_card') {
                    errorMessage = 'Your credit / debit card is expired.';
                }
                else if (updateCardInfo.includes(this.me.organization.billing.stripe_card_error)) {
                    errorMessage = 'There were issues processing your credit / debit card.';
                }
                else if (contactCardCompany.includes(this.me.organization.billing.stripe_card_error)) {
                    errorMessage = 'There were issues processing your credit / debit card. Please reach out to your card issuer or update your payment information.';
                }
                else if (incorrectCardInfo.includes(this.me.organization.billing.stripe_card_error)) {
                    errorMessage = 'Your credit / debit card has incorrect information.';
                }
                
                if(errorMessage) {
                    if(!this.allowPurchaseOrChange) {
                        errorMessage = `${errorMessage} Please update your payment information.`;
                    }
                    else {
                        errorMessage = `${errorMessage} Please purchase a plan using updated payment information.`;
                    }
                }
            }

            return errorMessage;
        },

        currrentTier(): any {
            return tierState.orgTier;
        },

        orgUserUsage(): string {
            const userLimit = !tierState.orgTier.restrictionUsers && tierState.orgTier.restrictionUsers != 0 ? 'NA' : tierState.orgTier.restrictionUsers == 0 ? 'Unlimited' : tierState.orgTier.restrictionUsers;
            return `${tierState.orgTierUsage.userCount}/${userLimit}`;
        },

        orgReceiverUsage(): string {
            const receiverLimit = !tierState.orgTier.restrictionReceivers && tierState.orgTier.restrictionReceivers != 0 ? 'NA' : tierState.orgTier.restrictionReceivers == 0 ? 'Unlimited' : tierState.orgTier.restrictionReceivers;
            return `${tierState.orgTierUsage.receiverCount}/${receiverLimit}`;
        },

        orgSignageUsage(): string {
            const signageLimit = !tierState.orgTier.restrictionSignageItems && tierState.orgTier.restrictionSignageItems != 0 ? 'NA' : tierState.orgTier.restrictionSignageItems == 0 ? 'Unlimited' : tierState.orgTier.restrictionSignageItems;
            return `${tierState.orgTierUsage.signageItemCount}/${signageLimit}`;
        },

        usedStorageToSize() {
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
            let bytes = tierState.orgTierUsage.storageUsedBytes;

            if (bytes === 0) return '0'
            const i = Math.floor(Math.log(bytes) / Math.log(1024));
            if (i === 0) return `${bytes} ${sizes[i]})`
            return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
        },

        orgStorageUsage(): string {
            const subscriptionLimit = this.organization.billing.subscription_limit ? this.organization.billing.subscription_limit : 0;
            const storageLimit = !tierState.orgTier.restrictionStorageGBPerRec && subscriptionLimit != 0 ? 'NA' : subscriptionLimit == 0 ? 'Unlimited' : `${(tierState.orgTier.restrictionStorageGBPerRec * subscriptionLimit)} GB`;
            return `${this.usedStorageToSize}/${storageLimit}`;
        },

        numberOfReceivers(): number {
            if(this.updatedReceiverCount > 0) {
                return this.updatedReceiverCount;
            }

            if(this.isTrial) {
                return tierState.orgTierUsage.receiverCount == 0 ? 1 :  tierState.orgTierUsage.receiverCount;
            }
            
            return Number(this.subscriptionLimit) ? Number(this.subscriptionLimit) : 1;
        },

        showRecommended(): boolean {
            if(!this.currrentTier.id || this.currrentTier.id === 'premium' || this.currrentTier.id === 'elite') {
                return false;
            }
            return true;
        },

        isUpdate(): boolean {
            return !this.isTrial && !this.isPO && this.isSubscriptionActive;
        }
    },

    methods: {
        async updateSubscriptionStatus() {
            this.viewLoading = true;

            await Promise.all([
                subscriptionState.fetchSubscriptions(true),
                userState.fetchSubscriptionStatus(true),
                userState.reloadMe()
            ]);

            this.viewLoading = false;
        },

        async reactivateTrial() {
            this.viewLoading = true;
            const storeResponse = await organizationState.restartTrial(this.me.organization);

            if (!storeResponse.success) {
                (this as any).$message.error(storeResponse.reason ? `${storeResponse.reason}` : 'Restarting trial failed');
                return;
            }

            await this.updateSubscriptionStatus();

            (this as any).$message.success('Trial restarted!');
            this.viewLoading = false;
        },

        onCancelPo() {
            this.purchaseOrderModalVisible = false;
            (this as any).$refs['purchaseOrderForm'].clearValidate();
            this.purchaseOrderForm.numberOfReceivers = 1;
            this.additionalInformation = '';
        },

        openPoModal(needMore: boolean) {
            this.poHeader = needMore ? 'Contact Us' : 'Purchase Order';
            this.poPlaceholder = needMore ? 'Tell us if you need to exceed the maximum values for various Elite plan elements (signage items, account portal users, storage).' : '';
            this.purchaseOrderModalVisible = true;
            this.purchaseOrderForm.numberOfReceivers = this.updatedReceiverCount ? this.updatedReceiverCount : this.numberOfReceivers ? Number(this.numberOfReceivers) : 1;
        },

        async loadPaymentInformation() {
            await customerState.fetchCustomers();
            if(!userState.me || !customerState.customerForCurrentUser(userState.me)) { 
                return;
            }

            this.customer = JSON.parse(JSON.stringify(customerState.customerForCurrentUser(userState.me))) as ICustomer;

            if(!this.customer || !this.customer.shipping || !this.customer.shipping.address)
            {
                if(cardState.allCards) {
                    await cardState.fetchCards(true);
                    const card = cardState.allCards[0];
                    this.customer.shipping = {
                        name: card.name ? card.name : undefined,
                        address:{
                            line1: card.address_line1,
                            line2: card.address_line2,
                            city: card.address_city,
                            state: card.address_state,
                            postal_code: card.address_zip,
                            country: card.address_country
                        }
                    } as ICustomerShipping
                }
            }
        },

        onSaving(isSaving: boolean) {
            this.viewLoading = isSaving;
        },

        async onUpdateSubscription(updateSuccess: boolean) {
            this.viewLoading = true;
            this.keyIndex++;

            if(!updateSuccess && this.isUpdate) {this.updatedReceiverCount = 0}

            await Promise.all([
                tierState.fetchTier(),
                tierState.fetchActivateReceiversCheck(true),
                tierState.fetchAddUsersCheck(true),
                tierState.fetchAddSignageItemCheck(true)
            ]);

            await this.updateSubscriptionStatus();

            this.organization = this.me.organization;

            await this.loadPaymentInformation();
            
            this.viewLoading = false;
        },  

        async onSubmitPurchaseOrder() {
            (this as any).$refs['purchaseOrderForm'].validate((valid: any) => { if (!valid) { return } });

            if (!userState.me) {
                (this as any).$message.error("There was an error processing your request.");
                return;
            }
            else {
                this.purchaseOrderSubmitting = true;

                const requestPOUser = {
                    room_count: this.purchaseOrderForm.numberOfReceivers,
                    company_name: userState.me.organization.name,
                    customer_name: userState.me.name,
                    customer_email: userState.me.email,
                    request_date: new Date(),
                    additionalInformation: this.additionalInformation
                } as IPurchaseOrderUser;

                const storeResponse = await userState.requestPurchaseOrder(requestPOUser);

                if (!storeResponse.success) {
                    (this as any).$message.error("There was an error processing your request.", storeResponse.reason);
                    this.purchaseOrderSubmitting = false;
                    return;
                }

                (this as any).$message.success('Your request has been sent. Someone will be in contact with you shortly.');

                this.purchaseOrderModalVisible = false;
                this.purchaseOrderSubmitting = false;
            }
        },

        getPlanPricing(planId: string, frequency: string): string {
            const tier = tierState.tiersList.find((x:any) => x.id === planId );
            const product = this.allPlans.find((x:any) => x.id === tier?.stripeProductId );
            const pricing = product.pricing.find((x:any) => x.recurring.interval === frequency);
            if(pricing) {
                return (pricing.unit_amount/100).toFixed(2);
            }
            return ''
        },

        getPlan(planId: string) {
            const tier = tierState.tiersList.find((x:any) => x.id === planId );
            return tier;
        },

        updateFequency(frequency: string) {
            this.billingFrequency = frequency;
        },

        updateNumberOfReceivers(count: number) {
            this.updatedReceiverCount = count;
        },

        goToComparePlans(tab: string) {
            this.activeTab = tab;
        },

        onUpdatePayment() {
            this.isUpdatePaymentModalVisible = false;
            this.keyIndex++;
        }
    },

    inject: [
        'windowWidth'
    ],

    async created() {
        if(this.$route.hash === '#compareplans') {
            this.activeTab = 'comparePlans';
        }

        userState = getModule(UserState);
        subscriptionState = getModule(SubscriptionState);
        organizationState = getModule(OrganizationState);
        roleState = getModule(RoleState);
        tierState = getModule(TierState);
        customerState = getModule(CustomerState);
        cardState = getModule(CardState);

        await Promise.all([
            subscriptionState.fetchSubscriptions(),
            subscriptionState.fetchSubscriptionPlans(),
            userState.fetchSubscriptionStatus(),
            userState.fetchMe(null),
            roleState.fetchUserRole(),
            tierState.fetchTier(),
            tierState.fetchTierUsage(),
            tierState.fetchAllFeaturesList(),
            tierState.fetchAllFeaturesByCategoryList()  
        ]);

        await tierState.fetchAllTiersList();
        
        if (!userState.me) {
            console.error("Error getting user information.");
            return;
        }

        this.allPlans = subscriptionState.allSubscriptionPlans;

        this.organization = this.me.organization;

        if(this.organization && this.organization.billing && this.organization.billing.billingFrequency) {
            this.billingFrequency = this.organization.billing.billingFrequency;
        }

        if(this.organization && this.organization.billing && this.organization.billing.customer_id) {
            await this.loadPaymentInformation();
        }
  
        this.viewLoading = false;
    }
})
