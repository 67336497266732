import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, TransitionGroup as _TransitionGroup, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8d8b1e5a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dt-card__body" }
const _hoisted_2 = { class: "list-wrapper pool" }
const _hoisted_3 = {
  key: 0,
  class: "dt-list"
}
const _hoisted_4 = ["onClick", "disabled"]
const _hoisted_5 = { class: "dt-list__title" }
const _hoisted_6 = {
  class: "icon icon--color icon--small",
  viewBox: "0 0 24 24"
}
const _hoisted_7 = ["d"]
const _hoisted_8 = {
  key: 0,
  class: "no-location-search-results"
}
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  key: 1,
  class: "empty-message"
}
const _hoisted_11 = { class: "divider" }
const _hoisted_12 = {
  class: "icon",
  viewBox: "0 0 24 24"
}
const _hoisted_13 = ["d"]
const _hoisted_14 = { class: "list-wrapper active" }
const _hoisted_15 = {
  key: 0,
  class: "dt-list active"
}
const _hoisted_16 = ["onClick", "disabled"]
const _hoisted_17 = { class: "dt-list__title" }
const _hoisted_18 = {
  key: 0,
  class: "no-location-search-results"
}
const _hoisted_19 = ["src"]
const _hoisted_20 = {
  key: 1,
  class: "no-location-search-results"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("article", _hoisted_2, [
      _createVNode(_component_el_form, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, { prop: "searchPool" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                placeholder: _ctx.searchPlaceholder,
                modelValue: _ctx.selectionPoolSearch,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectionPoolSearch) = $event))
              }, null, 8, ["placeholder", "modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.selectionPool)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectionPool.filter(x=>x.name.toLowerCase().includes(this.selectionPoolSearch.toLowerCase())), (item) => {
              return (_openBlock(), _createElementBlock("li", {
                key: item.id,
                class: _normalizeClass([{ added: item.selected }, "dt-list__item"]),
                onClick: ($event: any) => (_ctx.selectionPoolClick(item)),
                disabled: _ctx.isDisabled
              }, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(item.name), 1),
                _withDirectives((_openBlock(), _createElementBlock("svg", _hoisted_6, [
                  _createElementVNode("path", { d: _ctx.mdiCheckBold }, null, 8, _hoisted_7)
                ], 512)), [
                  [_vShow, item.selected]
                ])
              ], 10, _hoisted_4))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.selectionPool)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("img", {
                    src: require('../assets/images/Whats-Mirroring.png')
                  }, null, 8, _hoisted_9),
                  _createElementVNode("span", null, "No " + _toDisplayString(_ctx.modelTypeName) + " match search", 1)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("span", null, "Uh oh. It doesn't look like you've created any " + _toDisplayString(_ctx.modelTypeName) + "!!.", 1)
                ]))
          ], 64))
    ]),
    _createElementVNode("span", _hoisted_11, [
      (_openBlock(), _createElementBlock("svg", _hoisted_12, [
        _createElementVNode("path", { d: _ctx.mdiSwapHorizontal }, null, 8, _hoisted_13)
      ]))
    ]),
    _createElementVNode("article", _hoisted_14, [
      _createVNode(_component_el_form, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, { prop: "searchSelected" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                placeholder: _ctx.searchPlaceholder,
                modelValue: _ctx.selectedPoolSearch,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedPoolSearch) = $event))
              }, null, 8, ["placeholder", "modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.selectionPool)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_15, [
            _createVNode(_TransitionGroup, { name: "swap-list" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectionPool.filter(x=>x.selected).filter(x=>x.name.toLowerCase().includes(this.selectedPoolSearch.toLowerCase())), (item) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: item.id,
                    onClick: ($event: any) => (_ctx.selectedPoolClick(item)),
                    class: "dt-list__item",
                    disabled: _ctx.isDisabled
                  }, [
                    _createElementVNode("span", _hoisted_17, _toDisplayString(item.name), 1)
                  ], 8, _hoisted_16))
                }), 128))
              ]),
              _: 1
            })
          ]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.selectionPool)
              ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                  _createElementVNode("img", {
                    src: require('../assets/images/Whats-Mirroring.png')
                  }, null, 8, _hoisted_19),
                  _createElementVNode("span", null, "No " + _toDisplayString(_ctx.modelTypeName) + " match search", 1)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_20, [
                  _createElementVNode("span", null, "No " + _toDisplayString(_ctx.modelTypeName) + "!", 1),
                  _createElementVNode("span", null, "Add " + _toDisplayString(_ctx.modelTypeName) + "s by selecting from the pool to the left", 1)
                ]))
          ], 64))
    ])
  ]))
}