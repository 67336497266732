import axios from 'axios';
import { getAuthInstance } from '../auth';
import { IDigitalAsset } from '../store/interfaces/IDigitalAsset';
import { IDigitalAssetUpload } from '../store/interfaces/IDigitalAssetUpload';

export interface IDigitalAssetUploadValidationResult {
    isValid: boolean;
    isVideoFile: boolean;
    invalidReason?: string;
}

export interface IDigitalAssetUploadService {
    validateFileUpload(file: any): Promise<IDigitalAssetUploadValidationResult>;
    getFilePreview(file: any): Promise<string | ArrayBuffer | null>;
    upload(digitalAssetUpload: IDigitalAssetUpload): Promise<IDigitalAsset>;
}

const ACCEPTED_FILETYPES = [
    "image/png", "image/jpg", "image/jpeg", "video/mp4"
]

const FILE_SIZE_LIMIT_MB = 300;

export class DigitalAssetUploadService implements IDigitalAssetUploadService {
    private _reader: FileReader;
    constructor() {
        this._reader = new FileReader();
    }

    public async validateFileUpload(file: File, allowVideo = true, sizeLimitMB: number = FILE_SIZE_LIMIT_MB): Promise<IDigitalAssetUploadValidationResult> {
        const mediaUploadValidationResult: IDigitalAssetUploadValidationResult = {
            isValid: true,
            isVideoFile: false
        };

        if(!file) {
            mediaUploadValidationResult.isValid = false
            mediaUploadValidationResult.invalidReason = 'Unable to evaluate file';
            return mediaUploadValidationResult;
        }

        if (!ACCEPTED_FILETYPES.some((ft) => ft === file.type)) {
            mediaUploadValidationResult.isValid = false
            mediaUploadValidationResult.invalidReason = 'Invalid file type';
            return mediaUploadValidationResult;
        }

        if (file.type.match("video/mp4")) {
            mediaUploadValidationResult.isVideoFile = true;
        }

        if(!allowVideo && mediaUploadValidationResult.isVideoFile) {
            mediaUploadValidationResult.isValid = false
            mediaUploadValidationResult.invalidReason = 'Invalid file type (video files not accepted)';
            return mediaUploadValidationResult;
        }

        const fileSizeInMB = ((file.size/1024)/1024);

        if(sizeLimitMB < fileSizeInMB) {
            mediaUploadValidationResult.isValid = false
            mediaUploadValidationResult.invalidReason = `File size of ${fileSizeInMB.toFixed(2)}MB is over the ${sizeLimitMB}MB limit`;
            return mediaUploadValidationResult;
        }

        return mediaUploadValidationResult
    }

    public getFilePreview(file: File): Promise<string | ArrayBuffer | null> {
        return new Promise((resolve, reject) => {
            this._reader.onloadend = ()=>{
                resolve(this._reader.result);
            }

            this._reader.onerror = () => {
                reject('Failed to read file');
            }

            this._reader.readAsDataURL(file);            
        });
    }

    public async upload(digitalAssetUpload: IDigitalAssetUpload): Promise<IDigitalAsset> {
        const digitalAssetsURL = `${ process.env.VUE_APP_API_HOST }/api/digitalassets/upload`;
        const authHeader = await getAuthInstance().getAxiosHeader();

        const formData = new FormData();
        formData.append('assetQuotaType', JSON.stringify(digitalAssetUpload.assetQuotaType));
        formData.append('file', digitalAssetUpload.file);
        formData.append('secure', JSON.stringify(digitalAssetUpload.secure));
        formData.append('generateThumb', JSON.stringify(digitalAssetUpload.generateThumb));

        if(digitalAssetUpload.imageHeight) {
            formData.append('imageHeight', JSON.stringify(digitalAssetUpload.imageHeight));
        }
        if(digitalAssetUpload.imageWidth) {
            formData.append('imageWidth', JSON.stringify(digitalAssetUpload.imageWidth));
        }
        if(digitalAssetUpload.fileName) {
            formData.append('fileName', JSON.stringify(digitalAssetUpload.fileName));
        }
        if(digitalAssetUpload.id) {
            formData.append('id', digitalAssetUpload.id);
        }

        const axiosResponse = await axios.post(digitalAssetsURL, formData, authHeader);
        return axiosResponse.data;
    }
}

export default new DigitalAssetUploadService();