import {
    Action,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';

import store from '..';

import { getAuthInstance } from '../../auth';
import axios from 'axios';
import { ISqStoreActionResponse } from '../interfaces/ISqStoreActionResponse';
import { ICard } from '../interfaces/ICard';

export interface ICardState {
    readonly allCards: ICard[] | null;
    readonly cardById: (id: string) => ICard | null;
    fetchCards(reload?: boolean): Promise<ISqStoreActionResponse<ICard[] | null>>;
}

@Module({ dynamic: true, store, name: 'cards' })
export default class CardState extends VuexModule implements ICardState {
    private CARDS: ICard[] = null as any;
    //#region GETTERS
    get allCards(): ICard[] | null {
        if(!this.CARDS) return null;
        return [...this.CARDS.map((x)=>Object.assign({}, x))];
    }

    get cardById(): (id: string) => ICard | null {
        return (id: string) => {
            if (!this.CARDS) return null;
            const sub = this.CARDS.find(x => x.id === id);
            return sub ? Object.assign({}, sub) : null;
        };
    }
    //#endregion

    //#region ACTIONS
    @Action
    async fetchCards(reload = false): Promise<ISqStoreActionResponse<ICard[] | null>> {
        if (!reload && this.CARDS) return { success: true, data: [...this.CARDS.map((x)=>Object.assign({}, x))]};

        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            
            const fetchCardsUrl = `${process.env.VUE_APP_API_HOST}/api/cards`;
            const axiosResponse = await axios.get(fetchCardsUrl, authHeader);

            if(axiosResponse && Array.isArray(axiosResponse.data) && axiosResponse.data.length > 0 ) {
                this.context.commit('setCards', axiosResponse.data);
            }

            const returnData = this.CARDS ? [...(this.CARDS as ICard[]).map((x)=>Object.assign({}, x))] : null;
            return { success: true, data: returnData as any};
        }
        catch(e:any) {
            console.error('Card Store Error:', e);
        }

        return { success: false, reason: 'Error fetching cards.' };
    }

    //#endregion

    //#region MUTATIONS

    @Mutation
    async setCards(cards: ICard[]): Promise<void> {
        this.CARDS = cards;
    }
    //#endregion


}
