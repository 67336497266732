import {
    Action,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';

import store from '../../store';
import { getAuthInstance } from '../../auth';
import axios from 'axios';
import { IGroup } from '../interfaces/IGroup';
import { ISqStoreActionResponse } from '../interfaces/ISqStoreActionResponse';

export interface IGroupState {
    readonly allGroups: IGroup[] | undefined;

    fetchGroups(): Promise<ISqStoreActionResponse<void>>;
    createGroup(group: IGroup): Promise<ISqStoreActionResponse<IGroup>>;
}

@Module({ dynamic: true, store, name: 'groups' })
export default class GroupState extends VuexModule implements IGroupState {
    // THESE HAVE TO BE NAMED UNIQUELY
    // THE WAY STATE WORKS IF YOU ALTER THIESE VIA CONTEXT 
    // ANY NAMED THE SAME WAY WILL CHANGE ALSO
    private GROUPS: IGroup[] = [];
    private isGroupsLoaded = false;


    //#region GETTERS
    get allGroups(): IGroup[] {
        return [...this.GROUPS.map((x)=>Object.assign({}, x))];
    }
    //#endregion


    //#region ACTIONS
    @Action
    async fetchGroups(): Promise<ISqStoreActionResponse<void>> {
        
        if (this.isGroupsLoaded) return { success: true };

        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            const fetchGroupsUrl = `${process.env.VUE_APP_API_HOST}/api/groups`;
            const axiosResponse = await axios.get(fetchGroupsUrl, authHeader);
            
            this.context.commit('setGroups', axiosResponse.data);
            this.context.commit('setIsGroupsLoaded', true);

            return { success: true };
        }
        catch(e:any) {
            console.error('Groups Store Error:', e);
        }

        return { success: false, reason: 'Error fetching Groups.' };
    }

    @Action
    async createGroup(group: IGroup): Promise<ISqStoreActionResponse<any>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            const createGroupUrl = `${ process.env.VUE_APP_API_HOST }/api/groups/`;
            const payload = group;
            const axiosResponse = await axios.post(createGroupUrl, payload, authHeader);
            
            if(this.isGroupsLoaded) {
                this.context.commit('setGroups', [...this.GROUPS, axiosResponse.data]);
            }

            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Groups Store Error:', e);
        }

        return { success: false, reason: 'Error creating group.'};
    }
    //#endregion


    //#region MUTATIONS
    @Mutation
    async setGroups(groups: IGroup[]): Promise<void> {
        this.GROUPS = groups;
    }

    @Mutation
    async setIsGroupsLoaded(isLoaded: boolean): Promise<void> {
        this.isGroupsLoaded = isLoaded;
    }
    //#endregion
}
