import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-94d7b6fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "item"
}
const _hoisted_2 = { class: "item__content" }
const _hoisted_3 = { style: {"{border-color":"item.fontColor}"} }
const _hoisted_4 = { class: "item__headline2 textfit__headline2" }
const _hoisted_5 = {
  style: {"{border-color":"item.fontColor}"},
  class: "item__border"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_leftsection = _resolveComponent("leftsection")!
  const _component_rightsection = _resolveComponent("rightsection")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "left-gradient",
          style: _normalizeStyle({background: _ctx.item.bottomBackgroundColor, background: 'linear-gradient(' + _ctx.item.topBackgroundColor + ' 0%, ' + _ctx.item.bottomBackgroundColor + ' 100%)'})
        }, null, 4),
        _createVNode(_component_leftsection, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("p", {
                style: _normalizeStyle({ color: _ctx.item.fontColor }),
                class: "item__headline1 textfit__headline1"
              }, [
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.item.headline1), 1)
              ], 4),
              _createElementVNode("div", {
                style: _normalizeStyle({ color: _ctx.item.headlineFontColor }),
                class: "item__headline2-container"
              }, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.item.headline2), 1),
                _createElementVNode("div", {
                  class: "item__headline2--accent-top",
                  style: _normalizeStyle([Boolean(_ctx.item.headline2.trim()) ? { background: _ctx.item.fontColor} : { background: _ctx.none }])
                }, null, 4),
                _createElementVNode("div", {
                  class: "item__headline2--accent-bot",
                  style: _normalizeStyle([Boolean(_ctx.item.headline2.trim()) ? { border: '10px solid' + _ctx.item.fontColor} : { border: _ctx.none }])
                }, null, 4)
              ], 4),
              _createElementVNode("h2", {
                style: _normalizeStyle({ color: _ctx.item.fontColor }),
                class: "item__header textfit__secondary-headline"
              }, _toDisplayString(_ctx.item.secondaryHeadline), 5),
              _createElementVNode("p", {
                style: _normalizeStyle({ color: _ctx.item.fontColor }),
                class: "item__description textfit__description"
              }, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.item.descriptionText), 1)
              ], 4)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_rightsection, {
          style: _normalizeStyle({ background: 'url('+_ctx.item.media.asset1_url+') center center no-repeat', backgroundSize: 'cover'})
        }, null, 8, ["style"])
      ]))
    : _createCommentVNode("", true)
}