import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("section", {
        key: 0,
        class: "template__container",
        style: _normalizeStyle(_ctx.backgroundStyle)
      }, [
        _createElementVNode("h1", {
          class: "textfit__headline",
          style: _normalizeStyle({ color: _ctx.item.fontColor })
        }, [
          _createElementVNode("span", {
            class: "headline",
            style: _normalizeStyle([Boolean(_ctx.item.headline1.trim()) ? { background: _ctx.item.fontBackgroundColor } : { background: _ctx.none }])
          }, _toDisplayString(_ctx.item.headline1), 5)
        ], 4),
        _createElementVNode("h1", {
          class: "textfit__headline",
          style: _normalizeStyle({ color: _ctx.item.fontColor })
        }, [
          _createElementVNode("span", {
            class: "headline",
            style: _normalizeStyle([Boolean(_ctx.item.headline2.trim()) ? { background: _ctx.item.fontBackgroundColor } : { background: _ctx.none }])
          }, _toDisplayString(_ctx.item.headline2), 5)
        ], 4),
        _createElementVNode("h3", {
          class: "textfit__secondary-headline",
          style: _normalizeStyle({ color: _ctx.item.fontColor })
        }, [
          _createElementVNode("span", {
            class: "headline",
            style: _normalizeStyle([Boolean(_ctx.item.secondaryHeadline.trim()) ? { background: _ctx.item.fontBackgroundColor } : { background: _ctx.none }])
          }, _toDisplayString(_ctx.item.secondaryHeadline), 5)
        ], 4)
      ], 4))
    : _createCommentVNode("", true)
}