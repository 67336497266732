
import { defineComponent } from 'vue';
import ReceiverState, { IReceiverState } from '../../store/modules/receivers';
import { getModule } from 'vuex-module-decorators'
import { IReceiver } from '../../store/interfaces/IReceiver';
import RoomState, { IRoomState } from '../../store/modules/rooms';
import { IRoom, IRoomDropDownList } from '../../store/interfaces/IRoom';
import UserState, { IUserState } from '../../store/modules/users';
import { IPairing } from '../../store/interfaces/IPairing';
import DepolymentBlobService from '../../services/DeploymentBlobService';
import { IOnboardStatus } from '../../store/interfaces/IOnboardingStatus';
import OnboardStatusState, { IOnboardStatusState } from '../../store/modules/onboarding';
import PageDirtyService, { IDataMonitorSet } from '../../services/PageDirtyService';
import { mdiHelp } from '@mdi/js';
import ReceiverStatusState, { IReceiverStatusState } from '../../store/modules/receiverstatuses'
import { IReceiverStatus } from '../../store/interfaces/IReceiverStatus';
import TierState, { ITierState } from '../../store/modules/tier';
import TierLimit from '../../components/TierLimit.vue';

let onboardStatusState = {} as IOnboardStatusState;
let receiverState = {} as IReceiverState;
let roomState = {} as IRoomState;
let userState = {} as IUserState;
let receiverStatusState = {} as IReceiverStatusState;
let tierState = {} as ITierState;

let defaultReceiver = {} as IReceiver;
let defaultRoomToLink = null as IRoomDropDownList | null;

export default defineComponent({
    name: 'ReceiverDetailsView',
    data() {
        return {
            receiver: {} as IReceiver,
            viewLoading: true,
            saving: true,
            activeTab: 'overview',
            pairing: null as IPairing | null,
            activeRoom: null as IRoom | null,
            roomToLink: null as IRoomDropDownList | null,
            roomToUnLink: null as string | null,
            deviceType: '' as string,
            timer: '' as any,
            availableRooms: [] as IRoomDropDownList[],
            receiverStatus: undefined as IReceiverStatus | undefined,
            deviceTypeOptions: [{
                value: '',
                label: 'Connection Type'
                }, {
                value: 'AirPlay',
                label: 'Legacy (AirPlay)'
                }, {
                value: 'Cast',
                label: 'Legacy (Cast)'
                }, {
                value: 'SSP',
                label: 'Ditto Receiver'
            }] as any[],
            rules: {
                displayName: [
                    {
                        required: true,
                        message: 'Please enter a receiver name',
                        trigger: 'blur'
                    },
                    {
                        min: 1,
                        max: 60,
                        message: 'Receiver names must be 60 characters or less',
                        trigger: 'blur'
                    }
                ]
            },
            marks: {
                0: 'Bright',
                100: 'Dim'
            },
            mdiHelp
        }
    },

    components: {
        TierLimit
    },

    computed: {
        canActivateReceivers(): boolean {
            return tierState.canActivateReceivers;
        },

        tierLimitReachedMessage() {
            return 'Upgrade your Ditto account to link additional rooms and receivers.'
        },

        isSSP(): boolean {
            if(!this.receiver || !this.receiver.deviceType) return false;
            return this.receiver.deviceType.toLowerCase() === 'ssp'
        },
        noRoomsAvailable(): boolean {
            if(this.availableRooms.length == 0 && !this.roomToLink) {return true}
            return false;
        },
        noRoomsAvailablePlaceholder(): string {
            if(this.availableRooms.length == 0) {return 'No Rooms Available'}
            return 'Select';
        },
        filteredDeviceTypeOptions(): any[] {
            if(this.isSSP){
                return this.deviceTypeOptions;
            }

            return this.deviceTypeOptions.filter((x: any)=>x.value !== 'SSP');
        },
        unusedRoomCount(): number {
            if(!userState.subscriptionStatus) return 0;
            if(userState.subscriptionStatus.type === 'Trial') return 1;

            return userState.subscriptionStatus.subscriptionAvailability;
        },
        isTrial(): boolean {
            if(!userState.subscriptionStatus) return true;
            return userState.subscriptionStatus.type === 'Trial';
        },
        isActiveReceiver(): boolean {
            if(!this.receiver || !this.receiver.id) return false;
            return (this.activeRoom && this.activeRoom.id) ? true : false;
        },
        isPlatform() {
            return (platform: 'windows' | 'mac' | 'tvos'): boolean => {
                if(!this.receiver || !this.receiver.platform || !platform) return false;

                return this.receiver.platform.toLowerCase() === platform.toLowerCase();
            }
        },
        onboardingReceiver(): boolean {
            return ((this as any).userOnboardStatus && !(this as any).userOnboardStatus.value.showDialog && (this as any).userOnboardStatus.value.isOnboarding && (this as any).userOnboardStatus.value.device && (this as any).userOnboardStatus.value.device.id == this.receiver.id) ?? false;
        },
        allReceiverStatus () : string {
            if (!this.isSSP) {
                return 'LEGACY';
            }
            else {
                if(this.receiverStatus) {
                    return 'ONLINE';
                }
                else {
                    return 'OFFLINE';
                }
            }
        },
        legacyReceiversEnabled() {
            if(!userState.me) return false;
            return userState.me.organization.featureFlags.allowlegacyReceivers;
        }
    },
    inject: [
        'userOnboardStatus',
        'windowWidth',
        'hideOnboardingByAccess'
    ],
    methods: {
        async createNewOnboarding() {
            let newOnboarding = {} as IOnboardStatus;
            newOnboarding.organization = userState.me && userState.me.organization ? userState.me.organization : undefined;
            newOnboarding.user = userState.me;
            newOnboarding.initiatedUser = userState.me;
            newOnboarding.isOnboarding = true;
            newOnboarding.showDialog = true;
            newOnboarding.guideStepId = 'overviewInitial';
            newOnboarding.step = 1;

            await onboardStatusState.createOnboardStatus(newOnboarding);
            await onboardStatusState.fetchOnboardStatus(true);
            if(!(this as any).hideOnboardingByAccess.value) {
                onboardStatusState.start();
            }
        },
        validateForm (): Promise<boolean> {
            return new Promise((resolve) => {
                (this as any).$refs['form'].validate((valid: boolean, obj: any) => {
                    if(!valid) {
                        let errorHTML = '';
                        for(const key in obj) {
                            for(const err of obj[key]) {
                                errorHTML = `${errorHTML}${err.message}<br>`;
                            }
                        }
                        console.error(errorHTML);
                        (this as any).$message({
                            dangerouslyUseHTMLString: true,
                            message: errorHTML,
                            type: 'error'
                        });
                    }

                    resolve(valid);
                });
            });
        },
        async onUnlinkRoom () {
            this.roomToLink = null;
            if(!this.activeRoom) return;
            this.roomToUnLink = this.activeRoom.id;
        },
        onCancel () {
            this.$router.push({ path: '/receivers' });
        },
        async onDelete () {
            if(this.saving) return;
            this.saving = true;
            try {
                await (this as any).$confirm(`${this.onboardingReceiver ? 'This receiver was created using the Ditto setup tutorial. Deleting this receiver before completing the Ditto setup tutorial will cause the tutorial to restart from the beginning. ' : ''}Are you sure you want to delete this receiver?`, 'Warning', {
                    confirmButtonText: 'Continue',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                });

                const storeResponse = await receiverState.deleteReceiver(this.receiver);

                if(!storeResponse.success) {
                    (this as any).$message.error(storeResponse.reason ? `Receiver delete failed: ${storeResponse.reason}` : 'Receiver delete failed');
                    return;
                }
                if(this.onboardingReceiver) {
                    await onboardStatusState.end();
                    await this.createNewOnboarding();
                }
                (this as any).$message.success('Receiver Deleted');
                
                PageDirtyService.markPageClean();
                await tierState.fetchActivateReceiversCheck(true);
                
                this.$router.push({ path: '/receivers' });
            }
            catch{
                //canceled
            }

            this.saving = false;
        },
        async deactivateReceiver (): Promise<boolean> {
            const roomStoreRemoveResponse = await roomState.removeReceivers([this.receiver.id]);
            if(!roomStoreRemoveResponse.success) {
                (this as any).$message.error(roomStoreRemoveResponse.reason ? `Failed to remove receiver from room: ${roomStoreRemoveResponse.reason}` : 'Failed to remove receiver from room');
                return false;
            }

            this.activeRoom = null;
            await tierState.fetchActivateReceiversCheck(true);

            return true;
        },
        async activateReceiver (): Promise<boolean> {
            if(!this.roomToLink) return false;
            const roomStoreAddResponse = await roomState.addReceivers({roomId: this.roomToLink.id, receiverIds: [this.receiver.id]});
            if(!roomStoreAddResponse.success) {
                (this as any).$message.error(roomStoreAddResponse.reason ? `Failed to add receiver from room: ${roomStoreAddResponse.reason}` : 'Failed to add receiver from room');
                return false;
            }

            await tierState.fetchActivateReceiversCheck(true);

            return true;
        },
        async onSave () {
            try {                
                const response = await roomState.fetchRoomByReceiverId(this.receiver.id);

                const currentRoom = response.data ?? {} as IRoom;

                if(this.onboardingReceiver) {
                    await (this as any).$confirm('This receiver was created using the Ditto setup tutorial. Editing this receiver before completing the Ditto setup tutorial will cause the tutorial to restart from the beginning. Are you sure you want to edit this receiver?', 'Warning', {
                        confirmButtonText: 'Continue',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    });
                }

                if(!this.roomToLink && this.isActiveReceiver && this.roomToUnLink) {
                    await (this as any).$confirm('Are you sure you want to unlink this receiver?', 'Warning', {
                        confirmButtonText: 'Unlink Receiver',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    });
                }

                if(this.saving) return;
                if(!await this.validateForm()) return;

                this.saving = true;

                if(!this.isSSP) {
                    this.deviceTypeOptions.find((x) => {
                        if(x.value == this.receiver.deviceType) {
                            this.receiver.deviceType = x.value;
                        }
                    });
                }
                const storeResponse = await receiverState.updateReceiver(this.receiver);

                if(!storeResponse.success) {
                    (this as any).$message.error(storeResponse.reason ? `Receiver update failed: ${storeResponse.reason}` : 'Receiver update failed');
                    this.saving = false;
                    return;
                }

                if(currentRoom && this.roomToLink && currentRoom.id == this.roomToLink.id){
                    //do nothing: there is no change to the room 
                }
                else {
                    if(this.isActiveReceiver && this.roomToUnLink || this.roomToLink && currentRoom.id != this.roomToLink.id) {
                        await this.deactivateReceiver();
                    }

                    if(this.roomToLink && currentRoom.id != this.roomToLink.id) {
                        await this.activateReceiver();
                    }

                    this.loadStatuses();
                }

                if(this.onboardingReceiver) {
                    await onboardStatusState.end();
                    await this.createNewOnboarding();
                }

                (this as any).$message.success('Receiver saved');
                this.saving = false;
                await this.loadReceiver(true);
                await tierState.fetchActivateReceiversCheck(true);

                (this as any).$message.success('Receiver saved');

                PageDirtyService.markPageClean();
            }
            catch {
                //cancelled
            }
        },
        generatePairingRegistryKey() {
            if(!this.pairing) return;
            DepolymentBlobService.GenerateRegistryKey(this.pairing);
        },
        async loadStatuses(): Promise<void> {
            const statusesResponse = await receiverStatusState.fetchStatuses();
            
            this.receiverStatus = await receiverStatusState.statusByReceiverId(this.receiver.id);

            if(!statusesResponse.success) {
                (this as any).$message.error(statusesResponse.reason ? `Receiver status retrival failed: ${statusesResponse.reason}` : 'Receiver status retrival failed');
                return;
            }
        },
        async loadReceiver(reload: boolean) {
            this.receiver = (await receiverState.fetchReceiver({id: this.$route.params.id as string, reload: reload})).data ?? {} as IReceiver;
            defaultReceiver = JSON.parse(JSON.stringify(this.receiver));

            if(this.receiver && this.receiver.id) {
                try {
                    const storeResponse = await roomState.fetchRoomByReceiverId(this.receiver.id);
                    
                    if(storeResponse.success && storeResponse.data) {
                        this.activeRoom = Object.assign({}, storeResponse.data);
                    }
                }
                catch(e: any) {
                    // happens if we don't have a room attached....
                    this.activeRoom = null;
                }
                
                const pairingResponse = await receiverState.getPairingByReceiverId(this.receiver.id)
                if(pairingResponse.success && pairingResponse.data) {
                    this.pairing = pairingResponse.data;
                }
            }
            if(this.activeRoom) {
                this.roomToLink = {
                    id: this.activeRoom.id,
                    type: this.activeRoom.type,
                    roomName: this.activeRoom.roomName,
                    roomCode: this.activeRoom.roomCode as string
                };

                defaultRoomToLink = JSON.parse(JSON.stringify(this.roomToLink));
            }

            this.availableRooms = (await roomState.fetchRoomsDDLAvailableForReceiver({activeRoom: this.activeRoom, isSSP: this.isSSP, reload})).data ?? [];

            PageDirtyService.markPageClean();

            this.viewLoading = false;
            this.saving = false;
        },
        changeDetection() {
            const monitorSet: IDataMonitorSet[] = [
                {defaultObject: defaultReceiver, mutatedObject: this.receiver},
                {defaultObject: defaultRoomToLink, mutatedObject: this.roomToLink},
                {defaultObject: null, mutatedObject: this.roomToUnLink},
            ];
            
            PageDirtyService.monitorObjectSet(monitorSet);
        },
        isVersionLessThan(major: number, minor: number, patch: number) {
            if(!this.receiver == null || !this.receiver.lastKnownVersionMajor == null || !this.receiver.lastKnownVersionMinor == null || !this.receiver.lastKnownVersionPatch == null) return true;

            if(this.receiver.lastKnownVersionMajor > major) return false;
            if(this.receiver.lastKnownVersionMajor == major && this.receiver.lastKnownVersionMinor > minor) return false;
            if(this.receiver.lastKnownVersionMajor == major && this.receiver.lastKnownVersionMinor == minor && this.receiver.lastKnownVersionPatch >= patch) return false;

            return true;
        }
    },
    beforeUnmount () {
        clearInterval(this.timer);
    },
    async created () {
        receiverState = getModule(ReceiverState);
        roomState = getModule(RoomState);
        userState = getModule(UserState);
        onboardStatusState = getModule(OnboardStatusState);
        receiverStatusState = getModule(ReceiverStatusState);
        tierState = getModule(TierState);

        await Promise.all([
            userState.fetchSubscriptionStatus(),
            userState.fetchMe(null),
            receiverStatusState.fetchStatuses(),
            tierState.fetchActivateReceiversCheck()
        ]);



        if(!userState.me) {
            console.error("Error getting user information.");
            return;
        }



        await this.loadReceiver(false);
        await this.loadStatuses();

        this.timer = setInterval(this.loadStatuses, 120000);

        this.viewLoading = false;
        this.saving = false;
    },
     watch: {
        receiver: {
            handler () {
               this.changeDetection();
            },
            deep: true
        },
        roomToLink: {
            handler () {
               this.changeDetection();
            },
            deep: true
        },
        roomToUnLink: {
            handler () {
               this.changeDetection();
            },
            deep: true
        }
    }
});
