
import { defineComponent } from 'vue';
import NewReceiverPairing from '../receivers/NewReceiverPairing.vue';
import NewReceiverType from '../receivers/NewReceiverType.vue';
import NewReceiverFinalPairing from '../receivers/NewReceiverFinalPairing.vue';
import NewReceiverRoom from '../receivers/NewReceiverRoom.vue';
import NewReceiverSuccess from '../receivers/NewReceiverSuccess.vue';
import ReceiverState, { IReceiverState } from '../../store/modules/receivers';
import RoomState, { IRoomState } from '../../store/modules/rooms';
import { getModule } from 'vuex-module-decorators'
import { IReceiver } from '../../store/interfaces/IReceiver';
import { IRoom } from '../../store/interfaces/IRoom';
import DeploymentBlobService from '../../services/DeploymentBlobService';
import TierState, { ITierState } from '../../store/modules/tier';
import TierLimit from '../../components/TierLimit.vue';

import OnboardStatusState, { IOnboardStatusState } from '../../store/modules/onboarding';
import UserState, { IUserState } from '../../store/modules/users';
let onboardStatusState = {} as IOnboardStatusState;

let receiverState = {} as IReceiverState;
let roomState = {} as IRoomState;
let tierState = {} as ITierState;
let userState = {} as IUserState;

export default defineComponent({
    name: 'NewReceiverView',
    components: {
        NewReceiverPairing,
        NewReceiverType,
        NewReceiverFinalPairing,
        NewReceiverRoom,
        NewReceiverSuccess,
        TierLimit
    },
    data() {
      return {
        newReceiverModel: {} as IReceiver,
        loading: false as boolean,
        step: 1 as number,
        availableRooms: [] as any,
        canActivateReceivers: true as boolean,
        showTierDialog: false
      }
    },

    inject: ['userOnboardStatus', 'currentStep'],

    computed: {
        tierLimitReachedMessage() {
            return 'Upgrade your Ditto account to link additional rooms and receivers.'
        },
        legacyReceiversEnabled() {
            if(!userState.me) return false;
            return userState.me.organization.featureFlags.allowlegacyReceivers;
        }
    },

    methods: {
        //#region UI Methods
        nextStep(override?: number) {
            if(override) {
                this.step = override;
                return;
            }
            this.step++;
            if(onboardStatusState && onboardStatusState.userOnboardStatus && onboardStatusState.userOnboardStatus.isOnboarding && onboardStatusState.userOnboardStatus.showDialog){
                this.$nextTick(() => {
                    onboardStatusState.nextStep();
                });
            }
        },

        previousStep() {
            this.step--;
            if(onboardStatusState && onboardStatusState.userOnboardStatus && onboardStatusState.userOnboardStatus.isOnboarding && onboardStatusState.userOnboardStatus.showDialog){
                this.$nextTick(() => {
                    onboardStatusState.previousStep();
                });
            }
        },
        //#endregion
        //#region UI Event triggers
        onRestart() {
            this.step = 1;
            this.newReceiverModel = {} as IReceiver;
        },
        async onPair (pairingCode: string | null) {
           if(!pairingCode || pairingCode == '') {
               this.nextStep();
               return;
            }

            if(!await this.pair(pairingCode)) return;

            this.loadAvailableRooms();
            this.nextStep(4);
       },
       async onReceiverType(newReceiver: IReceiver) {
           if(!newReceiver) {
               this.previousStep();
               return;
            }

            this.newReceiverModel = newReceiver;

            this.nextStep();
       },
        async onCreateReceiver (newReceiver: IReceiver) {
            if(!newReceiver) {
               this.previousStep();
               return;
            }

            if(!await this.createReceiver(newReceiver)) return;

            this.nextStep();
        },
        async onFinalPairing (pairingCode: string, newReceiver: IReceiver) {
            if(!newReceiver && !pairingCode) return this.previousStep();



            if(pairingCode && pairingCode !== '#') {
                if(!await this.pair(pairingCode)) return;
                this.loadAvailableRooms();
                this.nextStep(4);
                return;
            }

            if(!await this.createReceiver(newReceiver)) return;

            if(pairingCode === '#') {
                const pairingDataResponse = await receiverState.generatePairingRegistryKey(this.newReceiverModel);
                if(pairingDataResponse.success && pairingDataResponse.data) {
                    DeploymentBlobService.GenerateRegistryKey(pairingDataResponse.data);
                }
            }
            this.loadAvailableRooms();
            this.nextStep();
        },
        async onRoomLink(selectedRoom: IRoom) {
            if(!selectedRoom) {
               this.nextStep();
               return;
            }

            if(selectedRoom && !this.canActivateReceivers) {
                this.showTierDialog = true;
                return;
            }

            if(!await this.linkRoom(selectedRoom)) return;

            this.nextStep();
        },
        //#endregion
        //#region methods
        async pair(pairingCode: string): Promise<boolean> {
            this.loading = true;
            const storeResponse = await receiverState.pairReceiver(pairingCode);

            if(!storeResponse.success || !storeResponse.data) {
                this.loading = false;
                (this as any).$message.error(storeResponse.reason ? `Pairing receiver failed: ${storeResponse.reason}` : 'Pairing receiver failed');
                return false;
            }

            this.newReceiverModel = storeResponse.data;

            if(onboardStatusState && onboardStatusState.userOnboardStatus && onboardStatusState.userOnboardStatus.isOnboarding && onboardStatusState.userOnboardStatus.showDialog) {
                await onboardStatusState.attachReceiver(this.newReceiverModel);
                onboardStatusState.goToStep(12);
                this.$router.push({ path: `/receivers` });
            }

            this.loading = false;
            (this as any).$message.success('Receiver paired');
            return true;
        },
        async createReceiver(newReceiver: IReceiver): Promise<boolean> {
            this.loading = true;
            const storeResponse = await receiverState.createReceiver(newReceiver);

            if(!storeResponse.success || !storeResponse.data) {
                this.loading = false;
                (this as any).$message.error(storeResponse.reason ? `Creating receiver failed: ${storeResponse.reason}` : 'Creating receiver failed');
                return false;
            }

            this.newReceiverModel = storeResponse.data;
            this.loading = false;
            (this as any).$message.success('Receiver created');
            
            if(onboardStatusState && onboardStatusState.userOnboardStatus && onboardStatusState.userOnboardStatus.isOnboarding && onboardStatusState.userOnboardStatus.showDialog) {
                await onboardStatusState.attachReceiver(this.newReceiverModel);
                this.$router.push({ path: `/receivers` });
            }

            return true;
        },
        async linkRoom(room: IRoom): Promise<boolean> {
            this.loading = true;
            const storeResponse = await roomState.addReceivers({roomId: room.id, receiverIds: [this.newReceiverModel.id]});

            if(!storeResponse.success) {
                this.loading = false;
                (this as any).$message.error(storeResponse.reason ? `Adding receiver to room failed: ${storeResponse.reason}` : 'Adding receiver to room failed');
                return false;
            }

            this.loading = false;
            (this as any).$message.success('Receiver added to room');

            await tierState.fetchActivateReceiversCheck(true);

            return true;
        },
        async loadAvailableRooms(): Promise<void> {
            const isSSP = this.newReceiverModel.deviceType == 'SSP'
            this.availableRooms = (await roomState.fetchRoomsDDLAvailableForReceiver({activeRoom: null, isSSP: isSSP, reload: true})).data ?? [];
        }
        //#endregion
    },

    async created () {
        receiverState = getModule(ReceiverState);
        roomState = getModule(RoomState);
        onboardStatusState = getModule(OnboardStatusState);
        tierState = getModule(TierState);
        userState = getModule(UserState);
        
        await Promise.all([
            onboardStatusState.fetchOnboardStatus(),
            tierState.fetchActivateReceiversCheck(),
            userState.fetchMe(null)
        ]);

        this.canActivateReceivers = tierState.canActivateReceivers;
    }
});
