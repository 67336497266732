import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b1fc58c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "outer-container",
  class: "outer-container"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "occupancy__content" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "occupancyDesc__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("section", _hoisted_2, [
          _createElementVNode("article", {
            class: "column",
            style: _normalizeStyle(_ctx.backgroundStyle)
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(Boolean(_ctx.item.headline.trim()) ? 'label' : ''),
              style: _normalizeStyle({ background: _ctx.item.headlineBackgroundColor })
            }, [
              _createElementVNode("div", {
                style: _normalizeStyle({ color: _ctx.item.headlineFontColor }),
                class: "title"
              }, _toDisplayString(_ctx.item.headline), 5)
            ], 6),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", {
                class: "limit",
                style: _normalizeStyle({ color: _ctx.item.occupancyFontColor })
              }, [
                (_ctx.item.useImage)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      class: "img",
                      src: _ctx.item.media.asset1_url
                    }, null, 8, _hoisted_4))
                  : _createCommentVNode("", true),
                _createTextVNode(_toDisplayString(_ctx.item.occupancyLimit), 1)
              ], 4)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("span", {
                class: "description",
                style: _normalizeStyle({ color: _ctx.item.occupancyFontColor })
              }, _toDisplayString(_ctx.item.description), 5)
            ])
          ], 4)
        ])
      ]))
    : _createCommentVNode("", true)
}