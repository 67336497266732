
import { defineComponent } from 'vue';
import { getModule } from 'vuex-module-decorators';
import TierState, { ITierState } from '../store/modules/tier';
import gsap from "gsap";
import Icon from "../components/Icon.vue";
import { mdiInformationOutline, mdiCheck } from '@mdi/js';

let tierState = {} as ITierState;

export default defineComponent({
    name: 'ComparePlans',
    data() {
        return {
            isLeftShadowVisible: false,
            isRightShadowVisible: false,
            featureCategories: [] as any,
            mdiInformationOutline,
            mdiCheck,
            showTrial: false
        }
    },

    components: {
        Icon
    },

    props: ['subscriptionPlan'],

    computed: {
        showRecommended(): boolean {
            if(!this.subscriptionPlan || this.subscriptionPlan === 'premium' || this.subscriptionPlan === 'elite') {
                return false;
            }
            return true;
        }
    },

    methods: {
        toggleTableRow(i: any) {
            this.featureCategories[i].isExpanded = !this.featureCategories[i].isExpanded;
        },

        async beforeEnter(el: any) {
            gsap.set(el, { height: 0 })
        },

        async enter(el: any, done: any) {
            gsap.to(el, { height: `${el.scrollHeight}px`, duration: 0.66, ease: 'power2.out', onComplete: () => { done(); } })
        },

        async leave(el: any, done: any) {
            gsap.to(el, { height: 0, duration: 0.66, ease: 'power2.out', onComplete: () => { done(); } })
        },

        setPlanComparison() {
            //get limits
            const limits = tierState.featuresByCategoryList.filter((x: any) => x._id === 'Limits').map((x: any)=>{  
                const sortedLimits = x.features.sort((a: any, b: any) => (a.order === b.order ? 0 : (a.order < b.order ? -1 : 1)));

                return {
                    categoryName: x._id,
                    isExpanded: true,
                    features: sortedLimits
                } as any;
            });

            //get features
            const features = tierState.featuresByCategoryList.filter((x: any) => x._id !== 'Limits').map((x: any)=>{  
                const sortedFeatures = x.features.sort((a: any, b: any) => (a.order === b.order ? 0 : (a.order < b.order ? -1 : 1)));

                return {
                    categoryName: x._id,
                    isExpanded: false,
                    features: sortedFeatures
                } as any;
            });

            features.sort((a: any, b: any) => (b.categoryName > a.categoryName) ? 1 : ((a.categoryName > b.categoryName) ? -1 : 0))
            
            this.featureCategories = [...limits, ...features];
        },

        getLimit(plans: any, planColumn: string, featureId: string): string {
            const plan = plans.find((x: any) => x._id == planColumn);
            let limit = plan[featureId];

            if(limit == 0) { 
                return 'Unlimited';
            }

            if(limit == -1) { 
                return '0';
            }

            if(featureId === 'restrictionStorageGBPerRec') {
                limit = Number(limit);
            }
            return limit;
        },

        checkFeature(plans: any, planColumn: string): boolean {
            return plans.some((x : any) => x._id == planColumn);
        }
    },

    async created() {
        tierState = getModule(TierState);

        await tierState.fetchAllFeaturesByCategoryList();

        this.setPlanComparison();

        this.$nextTick(()=>{
            const tierTableScroll = (this.$refs['tierTableScroll'] as any);
            let visibleWidth = tierTableScroll.offsetWidth;
            let totalWidth = tierTableScroll.scrollWidth;
            let amountScroll = tierTableScroll.scrollLeft;

            this.isRightShadowVisible = (totalWidth - 1) > (amountScroll + visibleWidth);

            tierTableScroll.addEventListener('scroll', ()=>{
                requestAnimationFrame(()=>{
                    visibleWidth = tierTableScroll.offsetWidth;
                    totalWidth = tierTableScroll.scrollWidth;
                    amountScroll = tierTableScroll.scrollLeft;

                    this.isLeftShadowVisible = amountScroll > 0;
                    this.isRightShadowVisible = (totalWidth - 1) > (amountScroll + visibleWidth);
                }); 
            });

            window.addEventListener('resize', ()=>{
                requestAnimationFrame(()=>{
                    visibleWidth = tierTableScroll.offsetWidth;
                    totalWidth = tierTableScroll.scrollWidth;
                    amountScroll = tierTableScroll.scrollLeft;
                    this.isLeftShadowVisible = amountScroll > 0;
                    this.isRightShadowVisible = (totalWidth - 1) > (amountScroll + visibleWidth);
                }); 
            });
        });
    }
})
