
import { getModule } from 'vuex-module-decorators';
import { IUser } from '../../store/interfaces/IUser';
import { IRole } from '../../store/interfaces/IRole';
import UserState, { IUserState } from '../../store/modules/users';
import { defineComponent } from 'vue';
import { mdiDelete, mdiShieldStar, mdiShieldLock } from '@mdi/js';
import RoleState, { IRoleState } from '../../store/modules/role';
import TierState, { ITierState } from '../../store/modules/tier';
import Icon from '../../components/Icon.vue';
import TierLimit from '../../components/TierLimit.vue';
interface IUserWithRole extends IUser {
   roleObject: IRole;
}
let userState = {} as IUserState;
let roleState = {} as IRoleState;
let tierState = {} as ITierState;

export default defineComponent ({
    name: 'Users',
    data() {
        return {
            mdiDelete, mdiShieldLock, mdiShieldStar,
            myId: '' as string,
            userRoleFilter: '',
            userSearch: '',
            loading: false as boolean,
            showTierDialog: false
        }
    },

    components: {
        Icon,
        TierLimit
    },

    computed: {
        roles(): IRole[] | null {
            return roleState.roles;
        },

        usersWithRoleMap(): IUserWithRole[] | undefined {
            if(!userState.allUsers) return [];

            return userState.allUsers.map((user: any) => {
                const foundRole = this.roles ? this.roles.find((role: IRole) => role.id === user.role) : ''
                return {
                    ...user,
                    roleObject: foundRole
                } as IUserWithRole;
            }) as IUserWithRole[];
        },

        isAdmin(): boolean {
            return roleState.isAdmin || roleState.hasSuperAccess;
        },

        canAddUsers(): boolean {
            return tierState.canAddUsers;
        },

        usersWithoutMe(): IUser[] | undefined {
            return (this as any).usersWithRoleMap.filter((user: IUser)=> user.id != this.myId);
        },

        filteredUsers: function(): IUser[] | undefined {
            if(!this.usersWithoutMe) return;

            return this.usersWithoutMe.filter(x => {
                const matchUserRole = x.role.toLowerCase().includes(this.userRoleFilter.toLowerCase());
                const matchUserRoleSearch = x.role.toLowerCase().includes(this.userSearch.toLowerCase());
                const matchName = x.name.toLowerCase().includes(this.userSearch.toLowerCase());
                const matchEmail = x.email.toLowerCase().includes(this.userSearch.toLowerCase());

                return matchUserRole && (matchName || matchEmail || matchUserRoleSearch);
            });
        },

        tierLimitReachedMessage() {
            return 'You do not have any user seats remaining. Upgrade your Ditto account to add more users.'
        }

    },

    methods: {
        handleCurrentChange(current: any) {
            this.$router.push({ path: `/users/${current.id}` })
        },

        async onDelete(selectedUsed: IUser) {
            await (this as any).$confirm('Are you sure you want to delete this user?', 'Warning', {
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
                type: 'warning'
            });

            await userState.deleteUser(selectedUsed);
            (this as any).$message.success("User has been deleted.");
            await tierState.fetchAddUsersCheck(true);
        },

        onNewUser() {
            if(!this.canAddUsers) {
                this.showTierDialog = true;
                return;
            }
            this.$router.push({ path: '/users/new' });
            return;
            
        },

        onMyUser() {
            this.$router.push({ path: `/users/${this.myId}` });
            return;  
        }
    },

    async created() {
        this.loading = true;
        userState = getModule(UserState);
        roleState = getModule(RoleState);
        tierState = getModule(TierState);

        await Promise.all([
            userState.fetchUsers(),
            userState.fetchMe(null),
            roleState.fetchUserRole(),
            roleState.fetchRoles(),
            tierState.fetchAddUsersCheck(),
            tierState.fetchAddUsersCheck()
        ]); 

        if(!userState.me) {
            (this as any).$message.error("Error getting user information.");
            return;
        }

        // Make sure only admins can see this page
        if(!this.isAdmin) {
            this.$router.push({ path: `/unauthorized` });
            return;
        }

        this.myId = userState.me.id;
        this.loading = false;
    }
})
