
import { defineComponent } from 'vue';
import { IModelSelectionPool } from '../store/interfaces/IModelSelectionPool';
import { mdiCheckBold, mdiAlertCircle, mdiSwapHorizontal } from '@mdi/js';
import Icon from "./Icon.vue";

export default defineComponent({
    name: 'ModelSelector',
    data: () => ({
        visable: false,
        selectionPool: [] as IModelSelectionPool[],
        selectionPoolSearch: '',
        selectedPoolSearch: '',
        mdiSwapHorizontal,
        mdiCheckBold,
        mdiAlertCircle

    }),
    computed: {
        searchPlaceholder: function(): string {
            return 'Search ' + this.$props.modelTypeName;
        },

        isVisible: {
            

            get() {
                return this.modelValue != undefined ? this.modelValue : this.visable;
            },

            // setter
            set(newValue: boolean) {
                this.visable = newValue;
                this.$emit('update:modelValue', false);
            }

        },

        saveButtonText(): string {
            return this.addButton? 'Add' : 'Save';
        },

        shouldShowRemoveRoomMessage(): boolean {
            if(this.showRemoveRoomMessage && !this.selectionPool.filter((x: any)=>x.selected && !x.disabled).length) {
                return true;
            }
            return false;
        }
    },

    components: {
        Icon
    },

    props: {
        // For v-model
		modelValue: String,

        loading: Boolean,

        title: String,

        subTitle: String,

        modelTypeName: String,

        showRemoveRoomMessage: Boolean,

        showNoAccessRoomMessage: Boolean,

        addButton: Boolean,
        
        hideSaveButton: Boolean,

        includesAll: Boolean
    },
    
    emits: ['update:modelValue', 'selection-pool-change'],

    methods: {
        activate(selectionPoolInput: IModelSelectionPool[]) {
    
            this.selectionPool = Object.assign([], selectionPoolInput);
            this.visable = true;
        },
        onCancel() {
            this.isVisible = false;
            this.selectionPool = [];
        },
        onSave() {
            if(!this.modelValue) {
                this.visable = false;
            }
            this.$emit('selection-pool-change', this.selectionPool);
            // this.selectionPool = [];
        },
        selectionPoolClick(item: IModelSelectionPool) {
            if(item.selected || item.disabled) return;

            if(this.includesAll) {
                if(item.id === '*') {
                    this.selectionPool.map((x: any) => {
                        if (x.id === '*') {
                            x.selected = true;
                        } 
                        else {
                            x.selected = false;
                        }
                    });
                    return;
                }
                else {
                    const allItemIndex = this.selectionPool.findIndex((x: any) => x.id === '*');
                    this.selectionPool[allItemIndex].selected = false;
                    this.selectionPool[this.selectionPool.indexOf(item)].selected = true;
                    return;
                }
            }  

            this.selectionPool[this.selectionPool.indexOf(item)].selected = true;
        },
        selectedPoolClick(item: IModelSelectionPool) {
            if(!item.selected || item.disabled) return;
            this.selectionPool[this.selectionPool.indexOf(item)].selected = false;
        }
    }
})
