
    import { defineComponent } from 'vue'
    import axios from 'axios';

    export default defineComponent({
        data: ()=>({
            item: {} as any,
            loaded: false as boolean
        }),

        props: ['id', 'signature'],

        mounted() {
            return axios.get(`${ process.env.VUE_APP_API_HOST }/api/signages/${this.id}/templaterequest/${this.signature}`).then((res) => {
                this.item = res.data;
                this.loaded = false;
            });
        }
    })
