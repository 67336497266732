
import { defineComponent } from 'vue';
import { getModule } from 'vuex-module-decorators';
import UserState, { IUserState } from '../../store/modules/users';
import { IUser } from '../../store/interfaces/IUser';
import moment from 'moment';
import { ISubscriptionStatus } from '../../store/interfaces/ISubscriptionStatus';
import RoomState, { IRoomState } from '../../store/modules/rooms';
import UsageStatBarGraph from '../../components/UsageStatBarGraph.vue';
import StatState, { IStatState, IStatQueryModel } from '../../store/modules/stats';
import SubscriptionStatusProgress from '../../components/SubscriptionStatusProgress.vue';
import { mdiCheckBold } from '@mdi/js';
import OnboardStatusState, { IOnboardStatusState } from '../../store/modules/onboarding';
import RoleState, { IRoleState } from '../../store/modules/role';
import TierState, { ITierState } from '../../store/modules/tier';
import ComparePlans from '../../components/ComparePlans.vue';

let onboardStatusState = {} as IOnboardStatusState;
let userState = {} as IUserState;
let roomState = {} as IRoomState;
let statState = {} as IStatState;
let roleState = {} as IRoleState;
let tierState = {} as ITierState;

export default defineComponent({
    name: 'DashboardView',
    data(){
        return {
            me: undefined as IUser | undefined,
            isLoading: true,
            subscriptionStatus: undefined as ISubscriptionStatus | undefined,
            roomsCount: 0 as number,
            totalConnectionsLoading: true,
            totalConnectionsData: [[]] as number[][] | null,
            totalTimeMirroringLoading: true,
            totalTimeMirroringData: [[]] as number[][] | null,
            isSystemRole: true as boolean,
            mdiCheckBold,
            trialData:[[
                3,
                moment().subtract(6, 'days').unix()
            ],
            [
                15,
                moment().subtract(5, 'days').unix()
            ],
            [
                31,
                moment().subtract(4, 'days').unix()
            ],
            [
                21,
                moment().subtract(3, 'days').unix()
            ],
            [
                19,
                moment().subtract(2, 'days').unix()
            ],
            [
                12,
                moment().subtract(1, 'days').unix()
            ],
            [
                4,
                moment().unix()
            ]]
        }
    },

    inject: [
        'userOnboardStatus',
        'hideOnboardingOnMobile',
        'hideOnboardingByAccess'
    ],

    components: {
        UsageStatBarGraph,
        SubscriptionStatusProgress,
        ComparePlans
    },

    computed: {
        hasBillingAccess(): boolean {
            return roleState.hasBillingAccess;
        },

        isTrial(): boolean {
            if(!(this.me && this.me.organization && this.me.organization.billing)) return true;
            return this.me.organization.billing.billing_type.toLowerCase() === 'trial';
        },

        accountStatus(): string {
            if(!(this.me && this.me.organization && this.me.organization.billing)) return "Trial";
            return this.me.organization.billing.billing_type;
        },

        renewalDate(): string {
            if(!(this.me && this.me.organization && this.me.organization.billing)) return '';
            const utcExp = moment.utc(this.me.organization.billing.expiration_date);
            const localExp = moment(utcExp).local().toDate();
            return moment(localExp).format('MM/DD/YYYY');
        },

        expirationDate(): string {
            if(!(this.me && this.me.organization && this.me.organization.billing)) return '';
            const utcExp = moment.utc(this.me.organization.billing.expiration_date);
            const localExp = moment(utcExp).local().toDate();
            return moment(localExp).format('MM/DD/YYYY');
        },

        usageStats(): boolean {
            if(!(tierState.orgTier.clientUsageStatsEnabled && this.isSystemRole)) return false;
            return tierState.orgTier.clientUsageStatsEnabled;
        },

        percentage(): number {
            if(!this.subscriptionStatus) return 0;
            if(this.subscriptionStatus.subscriptionLimit == 0) return 0;
            return (this.subscriptionStatus.currentActiveDeviceCount / this.subscriptionStatus.subscriptionLimit)*100;
        },

        isSubscriptionActive(): boolean {
            if(!userState.subscriptionStatus) return false;
            return userState.subscriptionStatus.subscriptionActive
        },

        tierName(): string {
            return tierState.orgTier.name;
        },

        orgTier(): any {
            return tierState.orgTier;
        },

        orgUserUsage(): string {
            const userLimit =  !tierState.orgTier.restrictionUsers && tierState.orgTier.restrictionUsers != 0 ? 'NA' : tierState.orgTier.restrictionUsers == 0 ? 'Unlimited' : tierState.orgTier.restrictionUsers;
            return `${tierState.orgTierUsage.userCount}/${userLimit}`;
        },

        orgReceiverUsage(): string {
            
            const receiverLimit = !tierState.orgTier.restrictionReceivers && tierState.orgTier.restrictionReceivers != 0 ? 'NA' : tierState.orgTier.restrictionReceivers == 0 ? 'Unlimited' : tierState.orgTier.restrictionReceivers;
            return `${tierState.orgTierUsage.receiverCount}/${receiverLimit}`;
        },

        orgSignageUsage(): string {
            const signageLimit = !tierState.orgTier.restrictionSignageItems && tierState.orgTier.restrictionSignageItems != 0 ? 'NA' : tierState.orgTier.restrictionSignageItems == 0 ? 'Unlimited' : tierState.orgTier.restrictionSignageItems;
            return `${tierState.orgTierUsage.signageItemCount}/${signageLimit}`;
        },

        usedStorageToSize() {
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
            let bytes = tierState.orgTierUsage.storageUsedGB * 1024 * 1024 * 1024;

            if (bytes === 0) return '0'
            const i = Math.floor(Math.log(bytes) / Math.log(1024));
            if (i === 0) return `${bytes} ${sizes[i]})`
            return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
        },

        orgStorageUsage(): string {
            const subscriptionLimit = this.me ? this.me.organization.billing.subscription_limit : 0;
            const storageLimit = !tierState.orgTier.restrictionStorageGBPerRec && subscriptionLimit != 0  ? 'NA' : subscriptionLimit == 0 ? 'Unlimited' : `${(tierState.orgTier.restrictionStorageGBPerRec * subscriptionLimit)} GB`;
            return `${this.usedStorageToSize}/${storageLimit}`;
        }
    },
    
    methods: {
        onStartOnboarding() {
            if(!(this as any).hideOnboardingByAccess.value) {
                onboardStatusState.start();
            }
        },     

        percentageFormat(): string {
            if(!this.subscriptionStatus) return '';

            return `✓ ${this.subscriptionStatus.currentActiveDeviceCount} Active ${this.isTrial ? '' : 'Subscribed'} Receiver${this.subscriptionStatus.currentActiveDeviceCount > 1 ? 's' : ''}`;
        },

        async loadTotalConnectionStatistics () {
            if(!this.me) return [[]];

            const statQuery = {
                stats: ['connectionCount'], 
                aggregate:'sum', 
                group: {type: 'organization', _id: this.me.organization.id}, 
                organizationId: this.me.organization.id,
                rangeSelection: {
                    dateRange: {rangeStart: moment.utc().subtract(30, 'days'), rangeEnd: moment.utc()},
                    groupBy: 'week'
                }
            } as IStatQueryModel;

            const tcd = await statState.fetchStats(statQuery);

            if(!tcd.success || !tcd.data) {
                this.totalConnectionsData = [...this.trialData];
                this.totalTimeMirroringData = [...this.trialData];
                this.totalTimeMirroringLoading = false;
                this.totalConnectionsLoading = false;
                return;
            }


            this.totalConnectionsData =  tcd.data;
            this.totalConnectionsLoading = false;
        },

        async loadTotalTimeMirriring () {
            if(!this.me) return [[]];
            const statQuery = {
                stats: ['timeMirroring'],
                aggregate:'sum',
                group: {type: 'organization', _id: this.me.organization.id},
                organizationId: this.me.organization.id,
                rangeSelection: {
                    dateRange: {rangeStart: moment.utc().subtract(30, 'days'), rangeEnd: moment.utc()},
                    groupBy: 'week'
                }
            } as IStatQueryModel;

            const tcd = await statState.fetchStats(statQuery);

            if(!tcd.success || !tcd.data) {
                this.totalConnectionsData = [...this.trialData];
                this.totalTimeMirroringData = [...this.trialData];
                this.totalTimeMirroringLoading = false;
                this.totalConnectionsLoading = false;
                return;
            }
            this.totalTimeMirroringData = tcd.data;
            this.totalTimeMirroringLoading = false;
        }
    },
    async created () {
        this.totalConnectionsData = [...this.trialData];
        this.totalTimeMirroringData = [...this.trialData];
        this.totalTimeMirroringLoading = false;
        this.totalConnectionsLoading = false;

        userState = getModule(UserState);
        roomState = getModule(RoomState);
        statState = getModule(StatState);
        roleState = getModule(RoleState);
        tierState = getModule(TierState);

        onboardStatusState = getModule(OnboardStatusState);

        await Promise.all([
            userState.fetchMe(null),
            roomState.fetchRoomsDDL(false).then((roomsDDL)=>{this.roomsCount = roomsDDL.data ? roomsDDL.data.length : 0}),
            userState.fetchSubscriptionStatus(),
            onboardStatusState.fetchOnboardStatus(),
            roleState.fetchUserRole()
        ]);

        this.me = userState.me;
        if(this.me && this.me.superUser) {
            await Promise.all([
                tierState.fetchAllFeaturesList(),
                tierState.fetchAllTiersList()
            ]);
        }
        else {
            await Promise.all([
                tierState.fetchTier(),
                tierState.fetchTierUsage()
            ]);
        }
        this.isLoading = false;
        
        this.subscriptionStatus = userState.subscriptionStatus;
        this.isSystemRole = roleState.isSystemRole;

        if(this.isTrial || !this.isSubscriptionActive) {
            return;
        }

        this.loadTotalConnectionStatistics();
        this.loadTotalTimeMirriring();
    }

});
