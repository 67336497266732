
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'TierLimit',
    data() {
        return {  }
    },

    props: [
        'modelValue',
        'isCallout',
        'isLimit',
        'message'
    ],

    inject: [
        'windowWidth'
    ],

    emits: [
        'update:modelValue'
    ],

    computed: {
        title(): string {
            return this.isLimit ? 'Plan Limit Reached' : 'Upgrade Plan';
        },

        showCompareLink(): boolean {
            if(this.message.includes('unlock more features')) {
                return true;
            }

            return false;
        }
    },

    methods: {
        handleClose(){
            if(!this.isLimit) {
                this.$router.push({ path: '/' });
                return;
            }
            
            this.$emit('update:modelValue', false);
        },

        onCancel() {
            if(!this.isLimit) {
                this.$router.push({ path: '/' });
                return;
            }

            this.$emit('update:modelValue', false);
        },

        onUpgrade() {
            this.$router.push({ path: '/organization/billing' });
            return;
        }
    }
})

