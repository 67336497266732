import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1139b9d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "quadrants"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "image",
          style: _normalizeStyle({ background: 'url('+_ctx.item.media.quadrant1_url+') center center no-repeat', backgroundSize: 'cover' })
        }, null, 4),
        _createElementVNode("div", {
          class: "image",
          style: _normalizeStyle({ background: 'url('+_ctx.item.media.quadrant2_url+') center center no-repeat', backgroundSize: 'cover' })
        }, null, 4),
        _createElementVNode("div", {
          class: "image",
          style: _normalizeStyle({ background: 'url('+_ctx.item.media.quadrant3_url+') center center no-repeat', backgroundSize: 'cover' })
        }, null, 4),
        _createElementVNode("div", {
          class: "image",
          style: _normalizeStyle({ background: 'url('+_ctx.item.media.quadrant4_url+') center center no-repeat', backgroundSize: 'cover' })
        }, null, 4)
      ]))
    : _createCommentVNode("", true)
}