export enum RoleApplicationPermission {
    none = 0, // no access
    read = 1, // read only access
    write = 2 // read and write access
}

export interface IRoleApplication {
    receivers: RoleApplicationPermission;
    rooms: RoleApplicationPermission;
    locations: RoleApplicationPermission;
    signage: RoleApplicationPermission;
    alerts: RoleApplicationPermission;
    organization: RoleApplicationPermission;
    billing: RoleApplicationPermission;
    users: RoleApplicationPermission;
    super: RoleApplicationPermission;
}

export interface IRole {
    id: string;
    type: 'Role';
    name: string;
    description: string;
    locationIds: Array<string>; 
    roomIds: Array<string>; 
    signageListIds: Array<string>;
    signageIds: Array<string>;
    deviceIds: Array<string>;
    application: IRoleApplication;
    readOnly: boolean;
    createdAt: Date;
    updatedAt: Date;
    organizationId: string;
    CreateOrgDefaultRoles(organizationId: string): Promise<IRole[]>;
}