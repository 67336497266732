
import { defineComponent } from 'vue';
import { getModule } from 'vuex-module-decorators';
import InvoiceState, { IInvoiceState } from '../../store/modules/invoices';
import { IInvoice } from '../../store/interfaces/IInvoice';
import ChargeState, { IChargeState } from '../../store/modules/charges';
import { ICharge } from '../../store/interfaces/ICharge';
import moment from 'moment';
import SubscriptionState, { ISubscriptionState } from '../../store/modules/subscriptions';

let invoiceState: IInvoiceState = null as any;
let chargeState: IChargeState = null as any;
let subscriptionState: ISubscriptionState = null as any;

export interface IPaymentData {
    date: string;
    description: string;
    amount: string;
    status: string;
    success: boolean;
    failureMessage: string;
    receipt_URL: string;
    isInvoice: boolean;
} 

export default defineComponent({
    name: 'PaymentHistoryInvoices',
    data() {
        return {
            isLoading: true,
            restartModalVisible: false,
            cancelModalVisible: false
        }
    },
    
    props: ['isCanceled', 'isSubscriptionActive'],

    computed: {
        paymentData(): IPaymentData[] {
            let paymentData: IPaymentData[] = [];
            let supressedCharges: string[] = [];

            if(this.invoices && this.invoices.length > 0) {
                paymentData = this.invoices.map((x: IInvoice)=>{
                    const date = moment.utc(x.created * 1000).local();
                    const prodId = x.lines.data[0] && x.lines.data[0].plan ? x.lines.data[0].plan.product : '';
                    const product = subscriptionState.allSubscriptionPlans.find(p => p.id == prodId);
                    
                    return {
                        date: date.format('L'),
                        description: `${product ? product.name : 'Ditto'} Subscription`,
                        amount: (x.amount_paid / 100).toFixed(2),
                        status: x.status.charAt(0).toUpperCase() + x.status.slice(1),
                        success: x.paid,
                        failureMessage: x.status.charAt(0).toUpperCase() + x.status.slice(1),
                        receipt_URL: x.hosted_invoice_url,
                        isInvoice: true
                    } as IPaymentData
                }) as IPaymentData[];

                supressedCharges = this.invoices.map((x: IInvoice)=> x.charge);
            }

            if(this.charges && this.charges.length > 0) {
                const chargeData = this.charges.filter((x) => !supressedCharges.includes(x.id)).map((x: ICharge)=>{
                    const date = moment.utc(x.created * 1000).local();
                    return {
                        date: date.format('L'),
                        description: x.description,
                        amount: (x.amount / 100).toFixed(2),
                        status: x.captured ? 'Succeeded' : 'Failed',
                        success: x.captured,
                        failureMessage: x.failure_message,
                        receipt_URL: x.receipt_url,
                        isInvoice: false
                    } as IPaymentData
                }) as IPaymentData[];

                paymentData = [...paymentData, ...chargeData];
            }

            return paymentData
        },

        charges(): ICharge[] {
            if(!chargeState || !chargeState.allCharges) return [];
            return chargeState.allCharges;
        },

        invoices(): IInvoice[] {
            if(!invoiceState || !invoiceState.allInvoices) return [];
            return invoiceState.allInvoices;
        }
    },
    
    methods: {
        onCanelClick() {
            this.cancelModalVisible = true;
        },

        async cancelSubscription() {
            if(!subscriptionState || !subscriptionState.allSubscriptions || subscriptionState.allSubscriptions.length === 0) return;
             try {
                this.isLoading = true;
                const response = await subscriptionState.cancelSubscription(subscriptionState.allSubscriptions[0].id);

                if(!response.success) {
                    (this as any).$message.error(response.reason ? `Subscription cancelation failed: ${response.reason}` : 'Subscription cancelation failed');
                    this.isLoading = false;
                    return;
                }
                (this as any).$message.success('Subscription canceled');
            }
            catch {
                // canceled
            }  

            this.isLoading = false;
            this.cancelModalVisible = false;
        },

        onClickRestart() {
            this.restartModalVisible = true;
        },

        async restartSubscription() {
            //a subscription can only be retarted if the subscription is still active
            if(!subscriptionState.allSubscriptions || !this.isSubscriptionActive) return;
            try {
                const stripeSubscription = subscriptionState.allSubscriptions[0];

                this.isLoading = true;

                const storeResponse = await subscriptionState.reactivateSubscription(stripeSubscription);

                if(!storeResponse.success) {
                    (this as any).$message.error(storeResponse.reason ? `Subscription reactivation failed: ${storeResponse.reason}` : 'Subscription reactivation failed');
                    this.isLoading = false;
                    return;
                }

                (this as any).$message.success('Subscription reactivated');
            }
            catch(e:any){
                if(e === 'cancel') { this.isLoading = false; return; }
                console.error(e);
                (this as any).$message.error(`Subscription reactivation failed: ${e}`);
            }

            this.isLoading = false;
            this.restartModalVisible = false;
        }
    },

    async created() {
        invoiceState = getModule(InvoiceState);
        chargeState = getModule(ChargeState);
        subscriptionState = getModule(SubscriptionState);
        
        await Promise.all([
            invoiceState.fetchInvoices(true),
            chargeState.fetchCharges(),
            subscriptionState.fetchSubscriptions()
        ]);

        this.isLoading = false;
    }
});
