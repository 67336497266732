import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cf7a3e3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "directory__content" }
const _hoisted_2 = { class: "directoy__cols" }
const _hoisted_3 = { class: "directory__col" }
const _hoisted_4 = { class: "directory__col-items" }
const _hoisted_5 = { class: "directory__col" }
const _hoisted_6 = { class: "directory__col-items" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("section", {
        key: 0,
        class: "directory",
        style: _normalizeStyle({ background: _ctx.item.background })
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h1", {
            style: _normalizeStyle({ color: _ctx.item.font })
          }, [
            _createElementVNode("span", {
              class: "headline textfit__header",
              style: _normalizeStyle([Boolean(_ctx.item.header.trim()) ? { background: _ctx.item.directory } : { background: _ctx.none }])
            }, _toDisplayString(_ctx.item.header), 5)
          ], 4),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("article", _hoisted_3, [
              _createElementVNode("div", {
                class: _normalizeClass(["directory__location-header textfit__headline", !Boolean(_ctx.item.header.trim()) ? 'directory__location-header--no-header-height' : '']),
                style: _normalizeStyle([Boolean(_ctx.item.column1.location.trim()) ? { color: _ctx.item.font, background: _ctx.item.directory } : { background: _ctx.none }])
              }, _toDisplayString(_ctx.item.column1.location), 7),
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.column1.directory, (directoryItem, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "directory__col-item",
                    style: _normalizeStyle({ borderColor : _ctx.item.directory })
                  }, [
                    _createElementVNode("div", {
                      class: "directory__col-item-name textfit__directory",
                      style: _normalizeStyle({ color: _ctx.item.font, borderColor: _ctx.item.directory })
                    }, _toDisplayString(directoryItem.name), 5),
                    _createElementVNode("div", {
                      class: "directory__col-item-loc textfit__directory",
                      style: _normalizeStyle({ color: _ctx.item.roomNumberFont, borderColor: _ctx.item.directory, background: _ctx.item.roomNumberBackground })
                    }, _toDisplayString(directoryItem.roomNumber), 5)
                  ], 4))
                }), 128))
              ])
            ]),
            _createElementVNode("article", _hoisted_5, [
              _createElementVNode("div", {
                class: _normalizeClass(["directory__location-header textfit__headline", !Boolean(_ctx.item.header.trim()) ? 'directory__location-header--no-header-height' : '']),
                style: _normalizeStyle([Boolean(_ctx.item.column2.location.trim()) ? { color: _ctx.item.font, background: _ctx.item.directory } : { background: _ctx.none }])
              }, _toDisplayString(_ctx.item.column2.location), 7),
              _createElementVNode("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.column2.directory, (directoryItem, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "directory__col-item",
                    style: _normalizeStyle({ borderColor : _ctx.item.directory })
                  }, [
                    _createElementVNode("div", {
                      class: "directory__col-item-name textfit__directory",
                      style: _normalizeStyle({ color: _ctx.item.font, borderColor: _ctx.item.directory })
                    }, _toDisplayString(directoryItem.name), 5),
                    _createElementVNode("div", {
                      class: "directory__col-item-loc textfit__directory",
                      style: _normalizeStyle({ color: _ctx.item.roomNumberFont, borderColor: _ctx.item.directory, background: _ctx.item.roomNumberBackground })
                    }, _toDisplayString(directoryItem.roomNumber), 5)
                  ], 4))
                }), 128))
              ])
            ])
          ])
        ])
      ], 4))
    : _createCommentVNode("", true)
}