
import { defineComponent } from 'vue';
import DigitalAssetUploadService from '../services/DigitalAssetUploadService';
import { getModule } from 'vuex-module-decorators';
import SignageState, { ISignageState } from '../store/modules/signage';
let signageState = {} as ISignageState;

export default defineComponent({
    name: 'DigitalAssetUpload',
    data() {
        return {
            dropArea: {} as HTMLElement,
            uploadedImageSrc: '' as string,
            dropZoneHovered: false,
            currentlyUploading: false,
            isUploadingVideo: false,
            img: '' as string | ArrayBuffer | null
        }
    },

    props: [
        'assetUrl', 
        'maxHeightOverride', 
        'allowVideo', 
        'sizeLimitMB', 
        'layoutProperty', 
        'layoutType',
        'assetIndex'
        ],
    
    computed: {
        mediaLayoutPreviewURL(): any {
            // 1st priority is a newly added image, not yet saved
            if(this.img || this.assetUrl) {
                return this.img ? this.img : this.assetUrl;
            }
            return '';
        }
    },
    methods: {
        resetImage() {  
            this.img = null;
            this.currentlyUploading = false;
            this.isUploadingVideo = false;
        },
        dragEnterHandler: function(e: DragEvent) {
            e.preventDefault();
            e.stopPropagation();
            this.dropZoneHovered = true;
        },

        dragLeaveHandler: function(e: DragEvent) {
            e.preventDefault();
            e.stopPropagation();
            this.dropZoneHovered = false;
        },

        dragOverHandler: function(e: DragEvent) {
            e.preventDefault();
            e.stopPropagation();
            this.dropZoneHovered = true;
        },

        dropHandler: function(e: DragEvent) {
            e.preventDefault();
            e.stopPropagation();
            this.dropZoneHovered = false;
            this.currentlyUploading = true;
            if(e && e.dataTransfer) {
                this.newFileHandler(e.dataTransfer.files[0]); 
            }
        },

        newFileHandler: async function(file: File) {
            const allowVideoUpload: boolean = (this.allowVideo === 'true');
            const mediaUploadValidationResult = await DigitalAssetUploadService.validateFileUpload(file, allowVideoUpload, this.sizeLimitMB);

            if(!mediaUploadValidationResult.isValid) {
                (this as any).$message.error(`Invalid file upload: ${mediaUploadValidationResult.invalidReason}`);
                this.resetImage();
                return;
            }

            this.isUploadingVideo = mediaUploadValidationResult.isVideoFile;
            if(this.layoutType === 'media') {
                if (this.isUploadingVideo) {
                    this.$emit('isUploadingVideo', true);
                }
                else {
                    this.$emit('isUploadingVideo', false);
                }
            }

            this.img = await DigitalAssetUploadService.getFilePreview(file);
            
            //if this is singage call the singage handler
            if(this.layoutProperty) {
                let payload = { file: file, layoutProperty: this.layoutProperty, layoutType: this.layoutType };
                return signageState.fileAddedHandler(payload);
            }

            this.$emit('file-upload-trigger', file);

            return;
        }
    },

    created() {
        signageState = getModule(SignageState);
    }
});
