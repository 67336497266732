
import { defineComponent } from 'vue'

export default defineComponent({
    data() {
        return {
            signageItemLayoutData: {} as any
        }
    },

    props: {
        layoutDataEdit: {
            type: Object,
            default: null
        },
        layoutType: {
            type: String,
            default: ''
        },
        signageAssets: {
            type: Object,
            default: null
        },
        isCreatingNewSignageItem: {
            type: Boolean
        }
    },

    computed: {
        assetUrl() {
            if (this.signageAssets && this.signageAssets.length) {
                const signageAsset = this.signageAssets.find((sa: any) => sa.id == this.layoutDataEdit.media.img).thumbUrl;
                if (signageAsset && signageAsset.thumbUrl) {
                    return signageAsset.thumbUrl;
                }
                return null;
            }
            return null;
        },

        canvaLink() {
            return `https://www.canva.com/design/${this.signageItemLayoutData.canvaDesignId}`;
        }
    },

    created () {
        this.signageItemLayoutData = JSON.parse(JSON.stringify(this.layoutDataEdit));
    }
})
