
import { defineComponent } from 'vue'
import Axios from 'axios';
import textFit from 'textfit';

export default defineComponent({
    name: 'FullMenuLayout' as string,
    data: ()=>({
        layoutData: {} as any,
        loaded: false
    }),
    props: ['id', 'signature'],
    mounted() {
        return Axios.get(`${ process.env.VUE_APP_API_HOST }/api/signages/${this.id}/templaterequest/${this.signature}`).then((res) => {
            this.layoutData = res.data;
            this.loaded = true;
        });
    },
    watch: {
        layoutData() {
            this.$nextTick(() => {
                textFit(document.getElementsByClassName('textfit__header'), { minFontSize: 10, maxFontSize: 87, widthOnly: true, detectMultiLine: false });
                textFit(document.getElementsByClassName('textfit__title'), { minFontSize: 10, maxFontSize: 75, widthOnly: true, detectMultiLine: false });
                textFit(document.getElementsByClassName('textfit__description--multiline'), { minFontSize: 10, maxFontSize: 50});
            });
        }
    }
});

