
import { defineComponent } from 'vue';
import { getModule } from 'vuex-module-decorators';
import UserState, { IUserState } from '../../store/modules/users';
import OrgUsageStatBarGraphs from '../../components/OrgUsageStatBarGraphs.vue';
import TierLimit from '../../components/TierLimit.vue';
import TierState, { ITierState } from '../../store/modules/tier';

let userState = {} as IUserState;
let tierState = {} as ITierState;

export default defineComponent({
    name: 'OrganizationReports',
    data(){
        return {
            pageLoading: true,
            organizationId: '' as string,
        }
    },
    
    components: {
        OrgUsageStatBarGraphs,
        TierLimit
    },

    computed: {
        showTierCallout(): boolean {
            if(!tierState.orgTier.clientUsageStatsEnabled) {
                return true;
            }
            return false;
        }
    },
    methods: {
        
    },
    async created () {
        userState = getModule(UserState);
        tierState = getModule(TierState);

        await Promise.all([
            userState.fetchMe(null),
            tierState.fetchTier()
        ]);

        this.organizationId = userState.me?.organization.id as any;
        this.pageLoading = false;
    }
});
