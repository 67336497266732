
import Axios from 'axios';
import textFit from 'textfit';

import { defineComponent } from 'vue'

export default defineComponent({
    name: 'WayfindingLayout' as string,
    data() {
        return {
            item: {} as any
        }
    },
    computed: {
        backgroundStyle(): any {
            if (this.item.useBackgroundMedia) {
                if(!this.item.media.asset1_url) {
                    return { background: 'transparent'};
                }
                return { background: `url(${this.item.media.asset1_url}) center center no-repeat`, backgroundSize : 'cover'}
            } else {
                return { background: this.item.backgroundColor }
            }
        }
    },
    props: ['id', 'signature'],
    mounted() {
        return Axios.get(`${ process.env.VUE_APP_API_HOST }/api/signages/${this.id}/templaterequest/${this.signature}`).then((res) => {
            this.item = res.data;
        });
    },
    watch: {
        item() {
            this.$nextTick(() => {
                textFit(document.getElementsByClassName('textfit__waypoints'), { minFontSize: 10, maxFontSize: 150 });
            });
        }
    }
});
