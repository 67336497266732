
import { defineComponent } from 'vue'
import Icon from './Icon.vue';
import { mdiClose, mdiCursorMove } from '@mdi/js';


export default defineComponent({
    name: 'DraggableModal',
    data() {
        return {
            dragItem: null as any | null,
            active: false as boolean,
            currentX: 80 as number,
            currentY: 88 as number,
            initialX: 0 as number,
            initialY: 0 as number,
            xOffset: 80 as number,
            yOffset: 88 as number,
            isDragging: false as boolean,

            // Icons
            // ======
            mdiClose,
            mdiCursorMove
        }
    },

    components: {
        Icon
    },

    emits: ['update:modelValue'],

    props: {

        // For v-model
		modelValue: Boolean,

        title: {
            type: String,
            default: "Pass a 'title' prop"
        },
    },

    methods: {
        mouseDown: function(e: any) {
            // let _this = this;
            this.dragItem = this.$refs.popout;
            this.isDragging = true;

            if (e.type === "touchstart") {
                this.initialX = e.touches[0].clientX - this.xOffset;
                this.initialY = e.touches[0].clientY - this.yOffset;
            } else {
                this.initialX = e.clientX - this.xOffset;
                this.initialY = e.clientY - this.yOffset;
            }

            this.active = true;
            window.addEventListener('mousemove', this.mouseMoveHandler);
            window.addEventListener('mouseup', this.mouseUpHandler);
        },

        mouseMoveHandler: function(e: any) {
            if (this.active) {
                e.preventDefault();
                
                if (e.type === "touchmove") {
                    this.currentX = e.touches[0].clientX - this.initialX;
                    this.currentY = e.touches[0].clientY - this.initialY;
                } else {
                    this.currentX = e.clientX - this.initialX;
                    this.currentY = e.clientY - this.initialY;
                }

                this.xOffset = this.currentX;
                this.yOffset = this.currentY;                
            }
        },

        mouseUpHandler: function() {
            // let _this = this;
            this.isDragging = false;
            //-- dont let a user carry the preview off the screen
            //-- without handling those cases
            if(this.currentX < 0) {
                this.currentX = 1;
                this.xOffset = 1;
            } else if (this.currentX > (window.innerWidth - 10)) {
                this.currentX = window.innerWidth - 20;
                this.xOffset = window.innerWidth - 20;
            }
            
            if(this.currentY < 0) {
                this.currentY = 1;
                this.yOffset = 1;
            } else if (this.currentY > (window.innerHeight - 10)) {
                this.currentY = window.innerHeight - 50;
                this.yOffset = window.innerHeight - 50;
            }

            this.initialX = this.currentX;
            this.initialY = this.currentY;
            window.removeEventListener('mousemove', this.mouseMoveHandler);
            window.removeEventListener('mouseup', this.mouseUpHandler);
        },
    }
})
