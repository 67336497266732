
import { defineComponent } from 'vue';
import { getModule } from 'vuex-module-decorators'

import RoomState, { IRoomState } from '../../../store/modules/rooms';
import { IRoom } from '../../../store/interfaces/IRoom';

let roomState = {} as IRoomState;

export default defineComponent({
    name: 'RoomSign',
    data() {
        return {
            room: {} as IRoom
        }
    },

    async created () {
        roomState = getModule(RoomState);
        this.room = (await roomState.fetchRoom({id:this.$route.params.id as string, reload: false})).data ?? {} as IRoom ;
    }
});
