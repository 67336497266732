import {
    Action,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';

import store from '..';
import axios from 'axios';

import digitalAssetUploadService from '../../services/DigitalAssetUploadService';
import { ISignageList, ISignageItem, ISignageItemUpdate, ISignageItemReorderViewModel, IMessageSignageUpdate } from '../interfaces/ISignage';
import { IDigitalAssetUpload } from '../interfaces/IDigitalAssetUpload';
import { getAuthInstance } from '../../auth';
import { ISqStoreActionResponse } from '../interfaces/ISqStoreActionResponse';
import { v4 as uuidv4 } from 'uuid';
import SignagePreviewService from '../../services/SignagePreviewService';
import moment, { Moment } from 'moment';

let signageEventSource: ISignageEventSource[] = [];
const instanceId = uuidv4();

export interface IScheduleProperties {
    isDayEnabled: boolean;
    isDateEnabled: boolean;
    isTimeEnabled: boolean;
    scheduledTimeSet: boolean;
    activeDaysOfWeek: string[];
    startTime: null | Date;
    endTime: null | Date;
    startDate: null | Date;
    endDate: null | Date;
    // durationToDisplay: Date
}

export interface IScheduleSignageItemPayload {
    scheduleProperties: IScheduleProperties;
    signageListId: string;
    signageItems: ISignageItem[];
}

export interface ISignageEventSource {
    signageListId: string;
    instanceId: string;
    eventSource: EventSource;
}

export interface ISignageItemStateObject {
    signageListId: string;
    signage: ISignageItem[];
    expiration: Moment; 
}

export interface IInitializeSignageItemPayload {
    signageListId: string;
    layoutType: string;
}

export interface IDeleteSignageItemPayload {
    signageListId: string;
    signageItem: ISignageItem;
}

export interface IAssetAddedHandlerPayload {
    signageItem: ISignageItem;
    signageListId: string;
    shouldUpdatePreview: boolean;
}

export interface ISignageForListPayload {
    signageListId: string;
    forceReload: boolean | undefined;
}

export interface IDuplicateSignagePayload {
    signageList: ISignageList;
    signagesToDuplicate: ISignageItem[];
}

export interface IMoveSignagePayload {
    currentSignageList: ISignageList,
    newSignageList: ISignageList,
    sigangesToMove: ISignageItem[]
}

export interface IReorderSignagePayload {
    signageListId: string,
    reorderedList: ISignageItemReorderViewModel[]
}

export interface ITwitterSignagePayload {
    url: string,
    omitScript: string,
    maxHeight: string,
    maxWidth: string,
    theme: string
    chrome: string,
    showConversation: boolean,
    limit: number
}

export interface ISignageState {
    readonly signageItem: (signageId: string) => Promise<ISignageItem>;
    readonly signageForList: (id: string, reload: boolean) => Promise<ISignageItemStateObject>;
    readonly reactiveSignageForList: (id: string, reload: boolean) => Promise<ISignageItemStateObject>;
    readonly getFileUploadQueue: any[];
    readonly messageSignageUpdateBySignageListId: (signageListId: string) => IMessageSignageUpdate[];
    readonly messageSignageUpdateBySignageId: (signageId: string) => IMessageSignageUpdate;

    fetchSignageForList(payload:ISignageForListPayload): Promise<ISqStoreActionResponse<void>>;
    initializeSignageItem(payload: IInitializeSignageItemPayload): Promise<ISqStoreActionResponse<ISignageItem>>;
    reorderList(payload: IReorderSignagePayload): Promise<ISqStoreActionResponse<ISignageItemStateObject[]>>;
    updateSignageItem(signageItem: ISignageItem): Promise<ISqStoreActionResponse<ISignageItem>>;
    updateSignageItemMeta(signageItem: ISignageItem): Promise<ISqStoreActionResponse<ISignageItem>>;
    deleteSignageItem(payload: IDeleteSignageItemPayload): Promise<ISqStoreActionResponse<ISignageItem>>;
    saveSignageItem(payload: unknown | any): Promise<ISqStoreActionResponse<any>>;
    assetAddedHandler(payload: IAssetAddedHandlerPayload): Promise<any>;
    createSignageItem(payload: IAssetAddedHandlerPayload): Promise<any>;
    fileAddedHandler(payload: unknown | any): ISqStoreActionResponse<void>;
    duplicateSignageItems(payload: IDuplicateSignagePayload): Promise<any>;
    moveSignageItems(payload: IMoveSignagePayload): Promise<ISqStoreActionResponse<ISignageList>>;
    clearFileQueue(): ISqStoreActionResponse<void>;
    getTwitterEmbedHtml(payload: ITwitterSignagePayload): Promise<ISqStoreActionResponse<any>>;

    scheduleSignageItems(scheduleSignagePayload: IScheduleSignageItemPayload): Promise<ISqStoreActionResponse<any>>;

}

@Module({ dynamic: true, store, name: 'signage' })
export default class SignageState extends VuexModule implements ISignageState {
    // THESE HAVE TO BE NAMED UNIQUELY
    // THE WAY STATE WORKS IF YOU ALTER THIESE VIA CONTEXT 
    // ANY NAMED THE SAME WAY WILL CHANGE ALSO
    private signageItemStateObjects: ISignageItemStateObject[] = [];
    private fileUploadQueue = [] as any[];
    private messageItemUpdates: IMessageSignageUpdate[] = [];

    //#region GETTERS
    get signageItem(): (signageId: string) => Promise<ISignageItem> {
        return (signageId: string) => {
            const signageItemStateObject = this.signageItemStateObjects.find(x => { 
                if(!x.signage) return false;
                return x.signage.map(x=>x.id).includes(signageId)
            });
            
            if(!signageItemStateObject) {
                console.log('We did not find the signage state object. Make sure you have fetched the list containing this signage item.');
                return {} as ISignageItem;
            }

            let fetchPromise = Promise.resolve() as any;
            if(signageItemStateObject.expiration <= moment.utc()) {
                fetchPromise = this.fetchSignageForList({ signageListId:signageItemStateObject.signageListId, forceReload: true });
            }

            return fetchPromise.then(() => {
                if(!signageItemStateObject.signage) signageItemStateObject.signage = []; 

                const signage = signageItemStateObject.signage.find(x=>x.id === signageId);
                return signage ? JSON.parse(JSON.stringify(signage)) : {} as ISignageItem;
            });
        };
    }

    get signageForList(): (id: string, reload: boolean) => Promise<ISignageItemStateObject> {
        return (id: string, reload:boolean) => {
            const signageItemStateObject = this.signageItemStateObjects.find(x => x.signageListId === id);
            
            if(!signageItemStateObject) {
                console.log('We did not find the signage state object. Make sure you have fetched the list containing this signage item.');
                return {} as ISignageItemStateObject;
            } 

            let fetchPromise = Promise.resolve() as any;
            if(signageItemStateObject.expiration <= moment.utc()) {
                fetchPromise = this.fetchSignageForList({ signageListId:signageItemStateObject.signageListId, forceReload: reload});
            }
            
            return fetchPromise.then(() => {
                if(signageItemStateObject?.signage) {
                    signageItemStateObject.signage = signageItemStateObject.signage.sort((a: any, b: any) => (a.order === b.order ? 0 : (a.order < b.order ? -1 : 1)));
                }
                return JSON.parse(JSON.stringify(signageItemStateObject));
            });
        };
    }

    get getFileUploadQueue(): any[] {
        return this.fileUploadQueue;
    }

    get reactiveSignageForList(): (id: string, reload: boolean) => Promise<ISignageItemStateObject> {
        return (id: string, reload: boolean) => {
            const signageItemStateObject = this.signageItemStateObjects.find(x => x.signageListId === id);

            if(!signageItemStateObject) {
                console.log('We did not find the signage state object. Make sure you have fetched the list containing this signage item.');
                return {} as ISignageItemStateObject;
            } 
            let fetchPromise = Promise.resolve() as any;
            if(signageItemStateObject.expiration <= moment.utc() || reload) {
                fetchPromise = this.fetchSignageForList({ signageListId:signageItemStateObject.signageListId, forceReload: reload });
            }
            
            return fetchPromise.then(() => {
                if(signageItemStateObject?.signage) {
                    signageItemStateObject.signage = signageItemStateObject.signage.sort((a: any, b:any) => (a.order === b.order ? 0 : (a.order < b.order ? -1 : 1)));
                }
                return signageItemStateObject;
            });
        };
        
    }

    get messageSignageUpdateBySignageListId(): (signageListId: string) => IMessageSignageUpdate[] {
        return (signageListId: string) => {
            const update = this.messageItemUpdates.filter(x => x.signageListId === signageListId);
            return update ? update : [] as IMessageSignageUpdate[];
        }
    }

    get messageSignageUpdateBySignageId(): (signageId: string) => IMessageSignageUpdate {
        return (signageId: string) => {
            const update = this.messageItemUpdates.find(x => x.signageId === signageId);
            return update ? update : {} as IMessageSignageUpdate;
        }
    }
    //#endregion
 

    //#region ACTIONS
    @Action
    async fetchSignageForList(payload:ISignageForListPayload): Promise<ISqStoreActionResponse<void>> {
        if(!payload.signageListId) return { success: false, reason: 'No signage list id.' };
        if(!payload.forceReload && this.signageItemStateObjects.find(x=>x.signageListId === payload.signageListId)) { 
            return { success: true }; 
        }

        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            const fetchSignageItemsUrl = `${process.env.VUE_APP_API_HOST}/api/signagelists/${ payload.signageListId }`;
            const axiosResponse = await axios.get(fetchSignageItemsUrl, authHeader);
            
            const signageItemStateObject:ISignageItemStateObject = {
                signageListId: payload.signageListId, 
                signage: axiosResponse.data.signageItems, 
                expiration: moment.utc().add(3300, 'seconds') // api-service/src/services/digitalAssetService.ts : 348 We set the signed url time out to 3600
            };
            const mutatedSigangeItemStateObject = [...this.signageItemStateObjects.filter(x => x.signageListId !== payload.signageListId), signageItemStateObject];
            
            this.context.commit('setSignageStateObject', mutatedSigangeItemStateObject);

            // We also need to set up the event source 
            // for when signage items are done updating
            const sseUrl = `${ process.env.VUE_APP_API_HOST }/api/serverevents/event/sid.${ payload.signageListId }/${ instanceId }`;
            const eventSource = new EventSource(sseUrl);

            signageEventSource = [
                ...signageEventSource.filter(x=>x.signageListId !== payload.signageListId), 
                {signageListId: payload.signageListId, instanceId: instanceId, eventSource: eventSource}
            ];

            eventSource.onmessage = ((event) => {
                const responseMesssage = JSON.parse(event.data) as any;
                const responseSignage = responseMesssage.signage as ISignageItem;

                const signageUpdate = {
                    messageId: responseMesssage.id,
                    signageListId: payload.signageListId,
                    signageId: responseSignage.id,
                    finalSignageAsset: responseSignage.finalSignageAsset,
                    finalAssetGenerationError: responseSignage.finalAssetGenerationError,
                    wipThumb: responseSignage.wipThumb
                } as IMessageSignageUpdate;

               this.context.commit('setMessageSignageUpdate', signageUpdate);     
            });

            return { success: true };
        }
        catch(e:any) {
            console.error('Signage Store Error:', e);
        }
        return { success: false, reason: 'Error fetching signage items.' };
    }

    @Action
    async initializeSignageItem(payload: IInitializeSignageItemPayload): Promise<ISqStoreActionResponse<ISignageItem>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            const initializeSignageItemUrl = `${ process.env.VUE_APP_API_HOST }/api/signages/init?template=${payload.layoutType}`;
            const axiosResponse = await axios.get(initializeSignageItemUrl, authHeader);

            //clearing any unuploaded files on intialization
            //this may not be needed after unsaved changes is implemented for signage
            this.context.commit('clearFileUploadQueue');
            /* -----
                We want to create a new signage item with 
                the response from the servers as the layoutData.

                We'll also want to format the layout type as a 
                generic default name for the signage item being created.
            ----- */

            let formattedName = '';
            let isRefreshed = false;

            /*
                -- TODO --
                The layouts have a 'displayName' property
                that we should be using instead of doing this.
            */

            switch(payload.layoutType) {
                case 'media':
                    formattedName = 'Media Layout'
                    break;
                case 'wayfinding':
                    formattedName = 'Wayfinding Layout'
                    break;
                case 'informational':
                    formattedName = 'Informational Layout'
                    break;
                case 'singleitemmenu':
                    formattedName = 'Single Item Menu Layout'
                    break;
                case 'multiitemmenu':
                    formattedName = 'Multi Item Menu Layout'
                    break;
                case 'covid-occupancy':
                    formattedName = 'COVID-19 Occupancy'
                    break;
                case 'covid-safety':
                    formattedName = 'COVID-19 Safety'
                    break;
                case 'largeheadlineinformational':
                    formattedName = 'Large Headline Informational Layout'
                    break;
                case 'spotlight':
                    formattedName = 'Spotlight Layout'
                    break;
                case 'feature':
                    formattedName = 'Feature Layout'
                    break;
                case 'detailedinformational':
                    formattedName = 'Detailed Informational Layout'
                    break;
                case 'directory':
                    formattedName = 'Directory Layout'
                    break;
                case 'featuretwo':
                    formattedName = 'Feature 2'
                    break;
                case 'covidoccupancytwo':
                    formattedName = 'COVID-19 Occupancy 2';
                    break;
                case 'covidmask':
                    formattedName = 'COVID-19 Mask';
                    break;
                case 'staticweburl':
                    formattedName = 'Static Web Url';
                    isRefreshed = true;
                    break;
                case 'weathertoday':
                    formattedName = "Today's Weather";
                    isRefreshed = true;
                    break;
                case 'weatherthreeday':
                    formattedName = "Three-Day Weather";
                    isRefreshed = true;
                    break;
                case 'canva':
                    formattedName = "Canva";
                    isRefreshed = false;
                    break;
                case 'twitter':
                    formattedName = "Twitter";
                    isRefreshed = true;
                    break;
                case 'googleslidesimport':
                    formattedName = "Google Slides";
                    isRefreshed = false;
                    break;
                case 'mediaquadrants':
                    formattedName = "Media Quadrants";
                    isRefreshed = false;
                    break;
                default:
                    break;
            }

            const newSignageItem: ISignageItem = {
                name: formattedName,
            
                layoutType: payload.layoutType,
                startTime: '00:00:00',
                endTime: '00:30:00',
                scheduled: false,
                layoutData: axiosResponse.data,
                refreshedLayout: isRefreshed,
                signageList: {id: payload.signageListId}
            } as ISignageItem; 

            return { success: true, data: newSignageItem };
        }
        catch(e:any) {
            console.error('Signage Store Error:', e);
        }
        return { success: false, reason: 'Error initializing signage item.' };
    }

    @Action
    async reorderList(payload: IReorderSignagePayload): Promise<ISqStoreActionResponse<ISignageItemStateObject[]>>  {

        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            const reorderListURL = `${process.env.VUE_APP_API_HOST}/api/signagelists/${ payload.signageListId }/reordersignage`;
            const axiosResponse = await axios.put(reorderListURL, payload.reorderedList, authHeader);

            const signageList = axiosResponse.data as ISignageList;
            if(!signageList || !signageList.signageItems) return {success: false, reason: 'No signage items.'};

            const signageItemState :ISignageItemStateObject[] = signageList.signageItems.map(() => {
                return { signageListId: signageList.id, signage: signageList.signageItems } as ISignageItemStateObject;
            });

            const mutatedSigangeItemStateObject: ISignageItemStateObject[] = [
                ...this.signageItemStateObjects.filter(x=>x.signageListId !== signageList.id),
                ...signageItemState
            ]

            this.context.commit('setSignageStateObject', mutatedSigangeItemStateObject);
            
            return { success: true, data: signageItemState };
        }
        catch(e:any) {
            console.error('Signage Store Error:', e);
        }
        return { success: false, reason: 'Error updating signage item.' };
    }

    @Action
    async updateSignageItem(signageItem: ISignageItem): Promise<ISqStoreActionResponse<ISignageItem>> {
        const updateSignageItemUrl = `${ process.env.VUE_APP_API_HOST }/api/signages/${signageItem.id}`;
        const authHeader = await getAuthInstance().getAxiosHeader();
        try{
            const axiosResponse = await axios.put(updateSignageItemUrl, signageItem, authHeader);

            const resultSignageItem = axiosResponse.data as ISignageItem;

            const signageItemStateObject = this.signageItemStateObjects.find(x => { 
                if(!x.signage) return false;
                return x.signage.map(x=>x.id).includes(resultSignageItem.id)
            });

            if(!signageItemStateObject) return { success: false, reason: 'Failed to update state.' };

            const mutableSignageItemState = JSON.parse(JSON.stringify(signageItemStateObject));

            mutableSignageItemState.signage = [...mutableSignageItemState.signage.filter((x: ISignageItem) => x.id !== resultSignageItem.id), resultSignageItem];

            // insert the updated state obj back into the array and commit the change
            const mutatedSigangeItemStateObjects: ISignageItemStateObject[] = [
                ...this.signageItemStateObjects.filter(x=>x.signageListId !== mutableSignageItemState.signageListId),
                mutableSignageItemState
            ]
            
            this.context.commit('setSignageStateObject', mutatedSigangeItemStateObjects);
            this.context.commit('clearFileUploadQueue');

            return { success: true, data: JSON.parse(JSON.stringify(resultSignageItem)) };
        }
        catch(e: any) {
            console.error('Signage Store Error:', e);
        }
        return { success: false, reason: 'Error updating signage item.' };
    }

    @Action
    async updateSignageItemMeta(signageItem: ISignageItem): Promise<ISqStoreActionResponse<ISignageItem>> {
        const updateMetaUrl = `${ process.env.VUE_APP_API_HOST }/api/signages/metadata/${signageItem.id}`;
        const authHeader = await getAuthInstance().getAxiosHeader();

        try{
            const axiosResponse = await axios.put(updateMetaUrl, signageItem, authHeader);

            const resultSignageItem = axiosResponse.data as ISignageItem;

            const signageItemStateObject = this.signageItemStateObjects.find(x => { 
                if(!x.signage) return false;
                return x.signage.map(x=>x.id).includes(resultSignageItem.id)
            });

            if(!signageItemStateObject) return { success: false, reason: 'Failed to update state.' };

            const mutableSignageItemState = JSON.parse(JSON.stringify(signageItemStateObject));

            mutableSignageItemState.signage = [...mutableSignageItemState.signage.filter((x: ISignageItem) => x.id !== resultSignageItem.id), resultSignageItem];

            // insert the updated state obj back into the array and commit the change
            const mutatedSigangeItemStateObjects: ISignageItemStateObject[] = [
                ...this.signageItemStateObjects.filter(x=>x.signageListId !== mutableSignageItemState.signageListId),
                mutableSignageItemState
            ]
            
            this.context.commit('setSignageStateObject', mutatedSigangeItemStateObjects);

            return { success: true, data: JSON.parse(JSON.stringify(resultSignageItem)) };
        }
        catch(e: any) {
            console.error('Signage Store Error:', e);
        }
        return { success: false, reason: 'Error updating signage item metadata.' };
    }

    @Action
    async deleteSignageItem(payload: IDeleteSignageItemPayload): Promise<ISqStoreActionResponse<ISignageItem>> {

        try{
            const deleteSignageItemUrl = `${ process.env.VUE_APP_API_HOST }/api/signages/${payload.signageItem.id}`;
            const authHeader = await getAuthInstance().getAxiosHeader();
            const axiosResponse = await axios.delete(deleteSignageItemUrl, authHeader);

            if(!payload.signageListId) return { success:false, reason:'Signage item not in a list'};

            // get the state object for the list and make it mutable
            const mutableSignageItemState: ISignageItemStateObject = JSON.parse(JSON.stringify(this.signageItemStateObjects.find(x=>x.signageListId === payload.signageListId)));

            // replace the signage item with the new one
            mutableSignageItemState.signage = [
                ...mutableSignageItemState.signage.filter(x=>x.id !== payload.signageItem.id)
            ];

            // insert the updated state obj back into the array and commit the change
            const mutatedSigangeItemStateObjects: ISignageItemStateObject[] = [
                ...this.signageItemStateObjects.filter(x=>x.signageListId !== payload.signageListId),
                mutableSignageItemState
            ]


            this.context.commit('setSignageStateObject', mutatedSigangeItemStateObjects);

            return { success: true, data: axiosResponse.data };
        }
        catch(e) {
            console.error('Signage Store Error:', e);
        }
        return { success: false, reason: 'Error deleting signage item.' };
    }

    @Action({ rawError: true })
    async saveSignageItem(payload: unknown | any): Promise<ISqStoreActionResponse<any>> {      
        const _item = JSON.parse(JSON.stringify(payload.signageItem));

        //check to see if we have new files added from the layout editor
        if(this.fileUploadQueue.length) {
            let fileUpload = {} as IDigitalAssetUpload;

            // *** TODO *** were gonna wanna handle the api status somewhere
            // iterate through our new files and upload each of them
            for(const file of this.fileUploadQueue){
                if(!_item.layoutData.media) {
                    // *** THIS NEEDS TO BE MOVED TO MUTATIONS ***
                    _item.layoutData.media = {};
                }
                
                // if we have an id, this item already exists - update it
                if(_item.id) {
                    // check to see what were actualy uploading...
                    const assetId = _item.layoutData.media[file.layoutProperty];

                    let signageAsset = {};

                    if(assetId) {
                        signageAsset = _item.signageAssets.find((sa: any) => sa.id == assetId);
                    }
                 
                    fileUpload = {...signageAsset,
                        file: file.file,
                        secure: true,
                        assetQuotaType: 1,
                        generateThumb: true
                    } as IDigitalAssetUpload;

                // if theres no id, it doesnt exist - create it
                } else {
                    fileUpload = {
                        file: file.file,
                        secure: true,
                        assetQuotaType: 1,
                        generateThumb: true
                    } as IDigitalAssetUpload;
                }

                const asset = await digitalAssetUploadService.upload(fileUpload);

                if(!_item.signageAssets) {
                    _item.signageAssets = [];
                }

                const index = _item.signageAssets.findIndex((si: any) => si.id === asset.id);   

                if(index == -1) {
                    _item.signageAssets.push(asset);
                }
                else {
                    _item.signageAssets[index] = asset;
                }

                if(file.layoutProperty) {
                    _item.layoutData.media[file.layoutProperty] = asset.id;
                }
            }
        }

        // update the layout with the new data
        try {
            return this.assetAddedHandler(
                {
                    signageItem: _item,
                    signageListId: payload.signageListId,
                    shouldUpdatePreview: payload.shouldUpdatePreview
                }
            ) as Promise<any>;
        } catch (error) {
            console.error('Signage Store Error:', error);
            return { success: false, reason: 'Error creating signage' };
        }
    }

    @Action
    async assetAddedHandler(payload: IAssetAddedHandlerPayload): Promise<any>  {
        const generatePreview = payload.shouldUpdatePreview;
        
        if(payload.signageItem.id) {
            const signageItemUpdate: ISignageItemUpdate = { signageItem: payload.signageItem, generatePreview: generatePreview, previewRoute: payload.signageItem.layoutType };
            if(generatePreview) {
                const preview = await SignagePreviewService.generatePreview(signageItemUpdate);
                if(preview) {
                    payload.signageItem.wipThumb = preview.thumb;
                }
            }
    
            return this.updateSignageItem(payload.signageItem);
        }
        else {
            payload.signageItem.signageList = {id: payload.signageListId};
            const signageItemStateObject = this.signageItemStateObjects.find(x=>x.signageListId === payload.signageListId)
            payload.signageItem.order = signageItemStateObject?.signage ? signageItemStateObject?.signage.length : 0;
            const signageItemUpdate: ISignageItemUpdate = { signageItem: payload.signageItem, generatePreview: generatePreview, previewRoute: payload.signageItem.layoutType };
            if(generatePreview) {
                const preview = await SignagePreviewService.generatePreview(signageItemUpdate);              
                if(preview) {
                    payload.signageItem.wipThumb = preview.thumb;
                }
            }

            const newPayload = JSON.parse(JSON.stringify(payload)) as IAssetAddedHandlerPayload;
            newPayload.signageItem.id = uuidv4();

            return this.createSignageItem(newPayload);
        }
    }

    @Action
    async createSignageItem(payload: IAssetAddedHandlerPayload): Promise<any>  {
        const createSignageItemUrl = `${ process.env.VUE_APP_API_HOST }/api/signages/`;
        try{
            const authHeader = await getAuthInstance().getAxiosHeader();
            const axiosResponse = await axios.post(createSignageItemUrl, payload.signageItem, authHeader);
           
            // get the state object for the list and make it mutable
            const mutableSignageItemState: ISignageItemStateObject = JSON.parse(JSON.stringify(this.signageItemStateObjects.find(x=>x.signageListId === payload.signageListId)));

            // replace the signage item with the new one
            mutableSignageItemState.signage = [
                ...mutableSignageItemState.signage,
                axiosResponse.data
            ];

            // insert the updated state obj back into the array and commit the change
            const mutatedSigangeItemStateObjects: ISignageItemStateObject[] = [
                ...this.signageItemStateObjects.filter(x=>x.signageListId !== payload.signageListId),
                mutableSignageItemState
            ]

            
            this.context.commit('setSignageStateObject', mutatedSigangeItemStateObjects);
            this.context.commit('clearFileUploadQueue');

            return { success: true, data: axiosResponse.data };
        }
        catch(e: any) {
            console.error('Signage Store Error:', e);
        }
        return { success: false, reason: 'Error creating signage' };
    }
    
    @Action
    fileAddedHandler(payload: unknown | any): ISqStoreActionResponse<void> {
        this.context.commit('addFileToQueue', payload);
        return { success: true };
    }

    @Action
    async duplicateSignageItems(payload: IDuplicateSignagePayload): Promise<any> {
        const duplicateSignageListUrl = `${ process.env.VUE_APP_API_HOST }/api/signages/duplicatesignage`;
        try{
            const ids = payload.signagesToDuplicate.map(x => x.id);
            const signagePayload = { signageListId: payload.signageList.id, signageIds: ids }
            const authHeader = await getAuthInstance().getAxiosHeader();
            const axiosResponse = await axios.post(duplicateSignageListUrl, signagePayload, authHeader);
            
            // get the state object for the list and make it mutable
            const mutableSignageItemState: ISignageItemStateObject = JSON.parse(JSON.stringify(this.signageItemStateObjects.find(x=>x.signageListId ===  payload.signageList.id)));
            // add the duplicated signage items
            mutableSignageItemState.signage = [
                ...mutableSignageItemState.signage,
                ...axiosResponse.data
            ];
            // insert the updated state obj back into the array and commit the change
            const mutatedSigangeItemStateObjects: ISignageItemStateObject[] = [
                ...this.signageItemStateObjects.filter(x=>x.signageListId !==  payload.signageList.id),
                mutableSignageItemState
            ]

            this.context.commit('setSignageStateObject', mutatedSigangeItemStateObjects);

            return { success: true, data: axiosResponse.data };
        }
        catch(e: any) {
            console.error('Signage Store Error:', e);
        }
        return { success: false, reason: 'Error duplicating signage' };
    }

    @Action 
    async moveSignageItems(payload: IMoveSignagePayload): Promise<ISqStoreActionResponse<ISignageList>> {
        try {
            const moveSignageListUrl = `${ process.env.VUE_APP_API_HOST }/api/signagelists/${payload.currentSignageList.id}/movesignage/${payload.newSignageList.id}`;
            const ids = payload.sigangesToMove.map(x => x.id);
            const signagePayload = { signageIds: ids }
            
            const authHeader = await getAuthInstance().getAxiosHeader();

            const axiosResponse = await axios.post(moveSignageListUrl, signagePayload, authHeader);

            // get the state object for the list and make it mutable
            const mutableSignageItemState: ISignageItemStateObject = JSON.parse(JSON.stringify(this.signageItemStateObjects.find(x=>x.signageListId === payload.currentSignageList.id)));
            // replace the signage item with the new one
            mutableSignageItemState.signage = [
                ...mutableSignageItemState.signage.filter(x=> !signagePayload.signageIds.includes(x.id))
            ];
            // insert the updated state obj back into the array and commit the change
            const mutatedSigangeItemStateObjects: ISignageItemStateObject[] = [
                ...this.signageItemStateObjects.filter(x=>x.signageListId !== payload.currentSignageList.id),
                mutableSignageItemState
            ]

            this.context.commit('setSignageStateObject', mutatedSigangeItemStateObjects);

            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Signage Store Error:', e);
        }
        return { success: false, reason: 'Error moving sigange.' };
    }

    @Action
    clearFileQueue(): ISqStoreActionResponse<void> {
        this.context.commit('clearFileUploadQueue');
        return { success: true };
    }

    @Action
    async scheduleSignageItems(scheduleSignagePayload: IScheduleSignageItemPayload): Promise<ISqStoreActionResponse<any>> {
        try {
            const signageItemsIds = scheduleSignagePayload.signageItems.map((x: any) => x.id);
            const urlPayload = {
                scheduleProperties: scheduleSignagePayload.scheduleProperties,
                signageItems: signageItemsIds
            }

            const scheduleSignageItemsUrl = `${ process.env.VUE_APP_API_HOST }/api/signages/schedule`;

            const authHeader = await getAuthInstance().getAxiosHeader();

            const axiosResponse = await axios.put(scheduleSignageItemsUrl, urlPayload, authHeader);

            const signageItemIds = scheduleSignagePayload.signageItems.map((x: any) => x.id);
            const signageItemStateObject = this.signageItemStateObjects.find((x:any) =>  x.signageListId == scheduleSignagePayload.signageListId);
            
            if(!signageItemStateObject) return { success: false, reason: 'Failed to update state.' };

            const mutableSignageItemState = JSON.parse(JSON.stringify(signageItemStateObject));

            mutableSignageItemState.signage = [
                ...mutableSignageItemState.signage.filter((x: ISignageItem) => !signageItemIds.includes(x.id)), 
                ...axiosResponse.data
            ];

            const mutatedSigangeItemStateObjects: ISignageItemStateObject[] = [
                ...this.signageItemStateObjects.filter(x=>x.signageListId !== mutableSignageItemState.signageListId),
                mutableSignageItemState
            ]
            
            this.context.commit('setSignageStateObject', mutatedSigangeItemStateObjects);
            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Signage Store Error:', e);
        }
        return { success: false, reason: 'Error scheduling sigange.' };
    }

    @Action
    async getTwitterEmbedHtml(payload: ITwitterSignagePayload): Promise<ISqStoreActionResponse<any>> {
        try {
            const getTwitterEmbedHtmlUrl = `${ process.env.VUE_APP_API_HOST }/api/signages/twitter`;
            const authHeader = await getAuthInstance().getAxiosHeader();
            const axiosResponse = await axios.post(getTwitterEmbedHtmlUrl, payload, authHeader);
         
            if(axiosResponse.data.status) {
                return { success: false, reason: axiosResponse.data.status};
            }
            return { success: true, data: axiosResponse.data };
        }
        catch(e) {
            console.error('Signage Store Error:', e);
        }
        return { success: false, reason: 'Error getting embeded HTML for Twitter.' };
    }


    //#region MUTATIONS
    @Mutation
    async setSignageStateObject(payload: ISignageItemStateObject[]): Promise<void> {
        this.signageItemStateObjects = payload;
    }

    @Mutation
    async addFileToQueue(payload: unknown | any): Promise<void> {
        if(payload.layoutType != 'multiitemmenu' &&  payload.layoutType != 'feature' &&  payload.layoutType != 'featuretwo' &&  payload.layoutType != 'covidoccupancytwo' &&  payload.layoutType != 'covidmask'&&  payload.layoutType != 'mediaquadrants') {
            this.fileUploadQueue = [];
        }
        const queueItem = {
            file: payload.file,
            layoutProperty: payload.layoutProperty
        }

        const index = this.fileUploadQueue.findIndex(item => item.layoutProperty === payload.layoutProperty);
        if(index == -1) {
            this.fileUploadQueue.push(queueItem); 
        } else {
            this.fileUploadQueue.splice(index, 1, queueItem);
        }    
    }

    @Mutation
    async clearFileUploadQueue(): Promise<void> {
        this.fileUploadQueue = [];
    }

    @Mutation
    async setMessageSignageUpdate(itemMessage: IMessageSignageUpdate): Promise<void> {
        // if we already have an update for the message replace it     
        this.messageItemUpdates = [
            ...this.messageItemUpdates.filter(x => x.signageId != itemMessage.signageId),
            itemMessage
        ]
    }
    //#endregion
}