
import { defineComponent } from 'vue'
import Axios from 'axios';
import textFit from 'textfit';

export default defineComponent({
    name: 'DirectoryLayout' as string,
    data: ()=>({
            item: {} as any,
            loaded: false as boolean,
    }),

    props: ['id', 'signature'],
    mounted() {
        return Axios.get(`${ process.env.VUE_APP_API_HOST }/api/signages/${this.id}/templaterequest/${this.signature}`).then((res) => {
            this.item = res.data;
            this.loaded = true;
        });
    },
    watch: {
        item() {
            this.$nextTick(() => {
                textFit(document.getElementsByClassName('textfit__header'), { minFontSize: 10, maxFontSize: 175, widthOnly: true, detectMultiLine: false });
                textFit(document.getElementsByClassName('textfit__headline'), { minFontSize: 10, maxFontSize: 150, detectMultiLine: false });
                textFit(document.getElementsByClassName('textfit__directory'), { minFontSize: 10, maxFontSize: 70, widthOnly: true, detectMultiLine: false });
            });
        }
    }
});

