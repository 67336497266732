import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cc497bfe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "template__container" }
const _hoisted_2 = { class: "template__location-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "outer-container",
    class: "template__outer-container",
    style: _normalizeStyle(_ctx.backgroundStyle)
  }, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("article", {
        class: "template__location",
        style: _normalizeStyle({ background: _ctx.item.locationBackground })
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", {
            class: "template__label",
            style: _normalizeStyle({ color: _ctx.item.locationFontColor })
          }, _toDisplayString(_ctx.item.locationLabel), 5),
          _createElementVNode("span", {
            class: "template__name",
            style: _normalizeStyle({ color: _ctx.item.locationFontColor })
          }, _toDisplayString(_ctx.item.location), 5)
        ])
      ], 4),
      _createElementVNode("article", {
        class: "template__way-points",
        style: _normalizeStyle({ background: _ctx.item.waypointsBackground })
      }, [
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.waypoints, (waypoints, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              class: "template__textfit--waypoints",
              style: _normalizeStyle({ color: _ctx.item.waypointsFontColor })
            }, _toDisplayString(waypoints), 5))
          }), 128))
        ])
      ], 4)
    ])
  ], 4))
}