
import { defineComponent } from 'vue';
import { getModule } from 'vuex-module-decorators'
import { mdiTransitConnectionVariant } from '@mdi/js';
import { mdiAutoFix } from '@mdi/js';
import { mdiCloudDownload } from '@mdi/js';


import MDMDeploymentInstructions from '../../components/MDMDeploymentInstructions.vue';

import UserState, { IUserState } from '../../store/modules/users';
import { IOrganization, IOrganizationDeployment } from '../../store/interfaces/IOrganization';
import OrganizationState, { IOrganizationState } from '../../store/modules/organizations';
import DeploymentBlobService from '../../services/DeploymentBlobService';
import TierLimit from '../../components/TierLimit.vue';
import TierState, { ITierState } from '../../store/modules/tier';

let userState = {} as IUserState;
let orgState = {} as IOrganizationState;
let timeoutRef: any = null;
let tierState = {} as ITierState;

export default defineComponent({
    name: 'DeploymentView',
    data() {
        return {
            activeTab: 'overview',
            viewLoading: true,
            mdiTransitConnectionVariant,
            mdiAutoFix,
            mdiCloudDownload,
            deployment: {} as IOrganizationDeployment,
            mdmSelection: 'JamfPro',
            mdmOptions: [
                {value: 'JamfPro', label: 'Jamf Pro'},
                {value: 'JamfSchool', label: 'Jamf School'},
                {value: 'CiscoMeraki', label: 'Cisco Meraki'},
                {value: 'Other', label: 'Other'}
            ],

        }
    },
    components: {
        MDMDeploymentInstructions,
        TierLimit
    },
    props: {
    },
    computed: {
        companyId(): string | null {
            if(!userState.me) return null;
            return userState.me.organization.id;
        },
        organization(): IOrganization | null {
            if(!userState.me) return null;
            return userState.me.organization;
        },
        showTierCallout(): boolean {
            if(!tierState.orgTier.mdmMassDeploymentEnabled) {
                return true;
            }
            return false;
        }
    },
    methods: {
        mdmSelectionChange() {
            if (timeoutRef !== null) {
                clearTimeout(timeoutRef);
            }
            timeoutRef = setTimeout(async () => {
                if(!userState.me) return;
                const mutateOrg = Object.assign({}, userState.me.organization);
                mutateOrg.deployment.mdmType = this.mdmSelection;

                const storeResponse = await orgState.updateOrganization(mutateOrg);

                if(!storeResponse.success) {
                    (this as any).$message.error(storeResponse.reason ? `Deployment information update failed: ${storeResponse.reason}` : `Deployment information update failed`);
                }

                userState.fetchMe({reload:true});

                (this as any).$message.success('Deployment information updated');

            }, 1500);
        },
        autoDeploymentChange() {
            if (timeoutRef !== null) {
                clearTimeout(timeoutRef);
            }
            timeoutRef = setTimeout(async () => {
                if(!userState.me) return;
                const mutateOrg = Object.assign({}, userState.me.organization);
                mutateOrg.deployment.autoCreateRooms = this.deployment.autoCreateRooms;
                mutateOrg.deployment.autoLinkRooms = this.deployment.autoLinkRooms;

                const storeResponse = await orgState.updateOrganization(mutateOrg);

                if(!storeResponse.success) {
                    (this as any).$message.error(storeResponse.reason ? `Deployment options update failed: ${storeResponse.reason}` : `Deployment options update failed`);
                }

                userState.fetchMe({reload:true});

                (this as any).$message.success('Deployment options updated');

            }, 1500);
        },
        generateWindowsPairingKey() {
            if(!userState.me) return;
            DeploymentBlobService.GenerateDeploymenetRegistryKey(userState.me.organization)
        }
    },
    async created () {
        userState = getModule(UserState);
        orgState = getModule(OrganizationState);
        tierState = getModule(TierState);

        await tierState.fetchTier();

        await userState.fetchMe(null);
        if(!userState.me) {
            console.error("Error getting user information.");
            return;
        }

        if(userState.me && userState.me.organization && userState.me.organization.deployment) {
            this.deployment = userState.me.organization.deployment;
            this.mdmSelection = userState.me.organization.deployment.mdmType;
        }
        else {
            userState.me.organization.deployment = {} as IOrganizationDeployment;
        }

        this.viewLoading = false;
    }
});
