import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-512f27ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["viewBox"]
const _hoisted_2 = ["d"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    class: _normalizeClass(["dt-icon", { 'dt-icon--small' : _ctx.small }]),
    viewBox: _ctx.viewBox
  }, [
    _createElementVNode("path", { d: _ctx.icon }, null, 8, _hoisted_2)
  ], 10, _hoisted_1))
}