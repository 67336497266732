import {
    Action,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';



import store from '..';
import { getAuthInstance } from '../../auth';
import axios from 'axios';
import { ISqStoreActionResponse } from '../interfaces/ISqStoreActionResponse';
import { IBranding } from '../interfaces/IBranding';

export interface IBrandingState {
    readonly allBrandings: IBranding[] | null;
    fetchBrandings(): Promise<ISqStoreActionResponse<IBranding[] | null>>;
    updateBranding(branding: IBranding): Promise<ISqStoreActionResponse<IBranding[]>>;
}

@Module({ dynamic: true, store, name: 'groups' })
export default class BrandingState extends VuexModule implements IBrandingState {
    // THESE HAVE TO BE NAMED UNIQUELY
    // THE WAY STATE WORKS IF YOU ALTER THIESE VIA CONTEXT 
    // ANY NAMED THE SAME WAY WILL CHANGE ALSO
    private BRANDINGS: IBranding[] = null as any;
    

    //#region GETTERS
    get allBrandings(): IBranding[] | null {
        if(!this.BRANDINGS) return null;
        return [...this.BRANDINGS.map((x)=>Object.assign({}, x))];
    }
    //#endregion


    //#region ACTIONS
    @Action
    async fetchBrandings(): Promise<ISqStoreActionResponse<IBranding[] | null>> {
        if(this.BRANDINGS) return { success: true, data: [...this.BRANDINGS.map((x)=>Object.assign({}, x))]};

        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            const fetchBrandingsUrl = `${process.env.VUE_APP_API_HOST}/api/brandings`;
            const axiosResponse = await axios.get(fetchBrandingsUrl, authHeader);
            
            this.context.commit('setBrandings', axiosResponse.data);

            const returnData = this.BRANDINGS ? [...(this.BRANDINGS as IBranding[]).map((x)=>Object.assign({}, x))] : null;
            return { success: true, data: returnData as any};
        }
        catch(e:any) {
            console.error('Branding Store Error:', e);
        }

        return { success: false, reason: 'Error fetching Brandings.' };
    }
    
    @Action
    async updateBranding(branding: IBranding): Promise<ISqStoreActionResponse<IBranding[]>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
        
            const updateBrandingUrl = `${ process.env.VUE_APP_API_HOST }/api/brandings/${branding.id}`;
            const axiosResponse = await axios.put(updateBrandingUrl, branding, authHeader);
            
            const mutatedBrandings = [
                ...this.BRANDINGS.filter(x=>x.id !== branding.id),
                axiosResponse.data
            ];

            this.context.commit('setBrandings', mutatedBrandings);
            
            const returnData = this.BRANDINGS ? [...(this.BRANDINGS as IBranding[]).map((x)=>Object.assign({}, x))] : null;
            return { success: true, data: returnData as any};
        }
        catch(e:any) {
            console.error('Branding Store Error:', e);
        }

        return { success: false, reason: 'Error updating Brandings.' };
    }
    //#endregion


    //#region MUTATIONS
    @Mutation
    async setBrandings(brandings: IBranding[]): Promise<void> {
        this.BRANDINGS = brandings;
    }
    //#endregion
}
