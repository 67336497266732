
import { defineComponent } from 'vue'
import DigitalAssetUpload from '../DigitalAssetUpload.vue'

export default defineComponent({
    name: "MediaQuadrantsLayoutEditor",
    data() {
        return {
            signageItemLayoutData: {} as any
        }
    },

    props: {
        layoutDataEdit: {
            type: Object,
            default: null
        },
        layoutType: {
            type: String,
            default: ''
        },
        signageAssets: {
            type: Object,
            default: null
        },
    },

    components: {
        DigitalAssetUpload
    },

    methods: {
    },

    computed: {
        assetUrl() {
            const tmpAssetUrl = [null, null, null, null];
            if (this.signageAssets && this.signageAssets.length) {
                if(this.signageItemLayoutData.media && this.signageItemLayoutData.media.quadrant1) {
                    tmpAssetUrl[0] = this.signageAssets.find((sa: any) => sa.id == this.signageItemLayoutData.media.quadrant1).thumbUrl;
                }   

                if(this.signageItemLayoutData.media && this.signageItemLayoutData.media.quadrant2) {
                    tmpAssetUrl[1] = this.signageAssets.find((sa: any) => sa.id == this.signageItemLayoutData.media.quadrant2).thumbUrl;
                }

                if(this.signageItemLayoutData.media && this.signageItemLayoutData.media.quadrant3) {
                    tmpAssetUrl[2] = this.signageAssets.find((sa: any) => sa.id == this.signageItemLayoutData.media.quadrant3).thumbUrl;
                }

                if(this.signageItemLayoutData.media && this.signageItemLayoutData.media.quadrant4) {
                    tmpAssetUrl[3] = this.signageAssets.find((sa: any) => sa.id == this.signageItemLayoutData.media.quadrant4).thumbUrl;
                }
            }
            return tmpAssetUrl;
        }
    },

    created () {
        this.signageItemLayoutData = JSON.parse(JSON.stringify(this.layoutDataEdit));
    }
})
