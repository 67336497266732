
import { defineComponent } from 'vue'
import Icon from '../Icon.vue'
import { mdiClose, mdiContentDuplicate, mdiDragHorizontalVariant, mdiArrowUpBold, mdiArrowDownBold } from '@mdi/js';
import DigitalAssetUpload from '../DigitalAssetUpload.vue'
import draggable from 'vuedraggable';

export default defineComponent({
    data() {
        return {
            signageItemLayoutData: {} as any,

            // Icons
            mdiClose,
            mdiContentDuplicate,
            mdiDragHorizontalVariant,
            mdiArrowUpBold,
            mdiArrowDownBold
        }
    },

    props: {
        layoutDataEdit: {
            type: Object,
            default: null
        },
        layoutType: {
            type: String,
            default: ''
        },
        signageAssets: {
            type: Object,
            default: null
        },
    },

    components: {
        DigitalAssetUpload,
        Icon,
        draggable
    },

    computed: {
        assetUrl() {
            const tmpAssetUrl = [null, null, null];
            if (this.signageAssets && this.signageAssets.length) {
                if(this.signageItemLayoutData.media && this.signageItemLayoutData.media.col1_media) {
                    tmpAssetUrl[0] = this.signageAssets.find((sa: any) => sa.id == this.signageItemLayoutData.media.col1_media).thumbUrl;
                } 

                if(this.signageItemLayoutData.media && this.signageItemLayoutData.media.col2_media) {
                    tmpAssetUrl[1] = this.signageAssets.find((sa: any) => sa.id == this.signageItemLayoutData.media.col2_media).thumbUrl;
                }
                
                if(this.signageItemLayoutData.media && this.signageItemLayoutData.media.col3_media) {
                    tmpAssetUrl[2] = this.signageAssets.find((sa: any) => sa.id == this.signageItemLayoutData.media.col3_media).thumbUrl;
                } 
            }
            return tmpAssetUrl;
        }
    },
    
    methods: {
        getLayoutProperty(assetIndex: number): string {
            return `col${assetIndex + 1}_media`;
        },

        clearMenusHandler() {
            this.signageItemLayoutData.column1.menu = [];
            this.signageItemLayoutData.column2.menu = [];
            this.signageItemLayoutData.column3.menu = [];
        },

        moveListDown(colNum: number, index: number) {
            const colToMove = `column${colNum}`;
            const colToSwap = `column${colNum+1}`;
           
            this.signageItemLayoutData[colToMove].id = index+1;
            this.signageItemLayoutData[colToSwap].id = index;

            const columnToMove = this.signageItemLayoutData[colToMove];
            this.signageItemLayoutData[colToMove] = this.signageItemLayoutData[colToSwap];
            this.signageItemLayoutData[colToSwap] = columnToMove;

        },

        moveListUp(colNum: number, index: number) {
            const colToMove = `column${colNum}`;
            const colToSwap = `column${colNum-1}`;
            
            this.signageItemLayoutData[colToMove].id = index-1;
            this.signageItemLayoutData[colToSwap].id = index;

            const columnToMove = this.signageItemLayoutData[colToMove];
            this.signageItemLayoutData[colToMove] = this.signageItemLayoutData[colToSwap];
            this.signageItemLayoutData[colToSwap] = columnToMove;
        }
    },

    created () {
        this.signageItemLayoutData = JSON.parse(JSON.stringify(this.layoutDataEdit));
        
        // Add a unique identifier to each of the menu columns
        // so we have a unique key for when a user swaps the columns.
        this.signageItemLayoutData.column1.id = 1;
        this.signageItemLayoutData.column2.id = 2;
        this.signageItemLayoutData.column3.id = 3;
    },

    watch: {
        'signageItemLayoutData': {
            handler(newValue: any){
                //remove column ids so they are not passed back and used to compare with the original signage item
                delete newValue.column1.id;
                delete newValue.column2.id;
                delete newValue.column3.id;
                this.$emit('updateLayoutData', {updatedLayoutData: newValue, shouldUpdatePreview: true});
            },
            deep: true
        },
    }
})
