import { IOrganization } from '../store/interfaces/IOrganization';
import { IPairing } from '../store/interfaces/IPairing';

export interface IDeploymentBlobService {
    GenerateRegistryKey(pairingData: IPairing): void;
    GenerateDeploymenetRegistryKey(organization: IOrganization): void;
}

export class DeploymentBlobService implements IDeploymentBlobService {

    public GenerateDeploymenetRegistryKey(organization: IOrganization): void {
        const registryKey = `Windows Registry Editor Version 5.00\n[HKEY_LOCAL_MACHINE\\SOFTWARE\\Squirrels\\Ditto Receiver]\n"OrganizationId"="${organization.id}"\n"DeploymentCode"="${organization.deployment.deploymentCode}"`;
        const fileName = `DittoEnrollment-${organization.name}.reg`;
        const blobData = new Blob([registryKey], { type: 'data:text/plain;charset=utf-8' });
        this.presentBlobData(fileName, blobData);
    }

    public GenerateRegistryKey(pairingData: IPairing): void {
        const registryKey = `Windows Registry Editor Version 5.00\n[HKEY_LOCAL_MACHINE\\SOFTWARE\\Squirrels\\Ditto Receiver]\n"PairingCode"="${pairingData.code}"\n"Token"="${pairingData.token}"`;
        const fileName = `DittoEnrollment-${pairingData.code}.reg`;
        const blobData = new Blob([registryKey], { type: 'data:text/plain;charset=utf-8' });
        this.presentBlobData(fileName, blobData);
    }

    private presentBlobData(fileName: string, blobData: Blob) {
        const navigator = window.navigator as any
        if (navigator && navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blobData, fileName);
        } else {
            const blobURL = URL.createObjectURL(blobData);
            const element = document.createElement('a');
            element.setAttribute('href', blobURL);
            element.setAttribute('download', fileName);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        }
    }
}

export default new DeploymentBlobService

