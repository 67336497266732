import {
    Action,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';

import store from '..';

import { getAuthInstance } from '../../auth';
import axios from 'axios';
import { ISqStoreActionResponse } from '../interfaces/ISqStoreActionResponse';
import { ICharge } from '../interfaces/ICharge';

export interface IChargeState {
    readonly allCharges: ICharge[] | null;
    fetchCharges(reload?: boolean): Promise<ISqStoreActionResponse<ICharge[] | null>>;
}

@Module({ dynamic: true, store, name: 'cards' })
export default class ChargeState extends VuexModule implements IChargeState {
    private CHARGES: ICharge[] = null as any;
    //#region GETTERS
    get allCharges(): ICharge[] | null {
        if(!this.CHARGES) return null;
        return [...this.CHARGES.map((x)=>Object.assign({}, x))];
    }
    //#endregion

    //#region ACTIONS
    @Action
    async fetchCharges(reload = false): Promise<ISqStoreActionResponse<ICharge[] | null>> {
        if (!reload && this.CHARGES) return { success: true, data: [...this.CHARGES.map((x)=>Object.assign({}, x))]};

        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            
            const fetchChargesUrl = `${process.env.VUE_APP_API_HOST}/api/charges`;
            const axiosResponse = await axios.get(fetchChargesUrl, authHeader);

            if(axiosResponse && Array.isArray(axiosResponse.data) && axiosResponse.data.length > 0 ) {
                this.context.commit('setCharges', axiosResponse.data);
            }

            const returnData = this.CHARGES ? [...(this.CHARGES as ICharge[]).map((x)=>Object.assign({}, x))] : null;
            return { success: true, data: returnData as any};
        }
        catch(e:any) {
            console.error('Card Store Error:', e);
        }

        return { success: false, reason: 'Error fetching CHARGES.' };
    }

    //#endregion

    //#region MUTATIONS

    @Mutation
    async setCharges(charges: ICharge[]): Promise<void> {
        this.CHARGES = charges;
    }
    //#endregion


}
