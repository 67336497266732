import {
    Action,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';

import store from '..';

import { getAuthInstance } from '../../auth';
import axios from 'axios';
import { ISqStoreActionResponse } from '../interfaces/ISqStoreActionResponse';
import { ICustomer } from '../interfaces/ICustomer';
import { IUser } from '../interfaces/IUser';

export interface ICustomerState {
    readonly allCustomers: ICustomer[] | null;
    readonly customerForCurrentUser: (me: IUser) => ICustomer | null;
    fetchCustomers(): Promise<ISqStoreActionResponse<ICustomer[] | null>>;
    UpdateCustomer(customer: ICustomer): Promise<ISqStoreActionResponse<ICustomer[]>>;
    CreateCustomer(customer: ICustomer): Promise<ISqStoreActionResponse<ICustomer[]>>;
}

@Module({ dynamic: true, store, name: 'customers' })
export default class CustomerState extends VuexModule implements ICustomerState {
    private CUSTOMERS: ICustomer[] = null as any;
    private FETCHEDALLUSERS = false;
    //#region GETTERS
    get allCustomers(): ICustomer[] | null {
        if(!this.CUSTOMERS) return null;
        return [...this.CUSTOMERS.map((x)=>Object.assign({}, x))];
    }

    get customerForCurrentUser(): (me: IUser) => ICustomer | null {
        return (me: IUser) => {
            if (!this.CUSTOMERS) return null;
            try {
                const sub = this.CUSTOMERS.find(x => x.id === me.organization.billing.customer_id);
                return sub ? Object.assign({}, sub) : null;
            }
            catch{
                return null;
            }
        };
    }
    //#endregion

    //#region ACTIONS
    @Action
    async fetchCustomers(): Promise<ISqStoreActionResponse<ICustomer[] | null>> {
        if (this.FETCHEDALLUSERS && this.CUSTOMERS && this.CUSTOMERS.length > 0) return { success: true, data: [...this.CUSTOMERS.map((x)=>Object.assign({}, x))]};

        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            
            const fetchCustomersUrl = `${process.env.VUE_APP_API_HOST}/api/customers`;
            const axiosResponse = await axios.get(fetchCustomersUrl, authHeader);

            if(axiosResponse && axiosResponse.data && axiosResponse.data !== 'ok') {
                const data = Array.isArray(axiosResponse.data) ? axiosResponse.data : [axiosResponse.data];

                this.context.commit('setCustomers', data);
                this.context.commit('setFetchedAllCustomers', true)
            }

            const returnData = this.CUSTOMERS ? [...(this.CUSTOMERS as ICustomer[]).map((x)=>Object.assign({}, x))] : null;
            return { success: true, data: returnData as any};
        }
        catch(e:any) {
            console.error('Customer Store Error:', e);
            if(e.response && e.response.data) {
                return { success: false, reason: `Error updating customer: ${e.response.data}` };        
            }
        }

        return { success: false, reason: 'Error fetching customers.' };
    }

    @Action
    async UpdateCustomer(customer: ICustomer): Promise<ISqStoreActionResponse<ICustomer[]>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            
            const updateCustomerUrl = `${process.env.VUE_APP_API_HOST}/api/customers/${customer.id}`;

            const axiosResponse = await axios.put(updateCustomerUrl, customer, authHeader);

            if(axiosResponse && axiosResponse.data) {
                const mutatedCustomers = [
                    ...this.CUSTOMERS.filter(x=>x.id !== customer.id),
                    axiosResponse.data
                ];
                this.context.commit('setCustomers', mutatedCustomers);
            }

            const returnData = this.CUSTOMERS ? [...(this.CUSTOMERS as ICustomer[]).map((x)=>Object.assign({}, x))] : null;
            return { success: true, data: returnData as any};
        }
        catch(e:any) {
            console.error('Customer Store Error:', e);
            if(e.response && e.response.data) {
                return { success: false, reason: `Error updating customer: ${e.response.data}` };        
            }
        }

        return { success: false, reason: 'Error updating customer.' };
    }

    @Action
    async CreateCustomer(customer: ICustomer): Promise<ISqStoreActionResponse<ICustomer[]>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            
            const updateCustomerUrl = `${process.env.VUE_APP_API_HOST}/api/customers`;

            const axiosResponse = await axios.post(updateCustomerUrl, customer, authHeader);

            if(!this.CUSTOMERS) {
                this.context.commit('setCustomers', [axiosResponse.data]);
            }
            else {
                this.context.commit('setCustomers', [...this.CUSTOMERS, axiosResponse.data]);
            }

            const returnData = this.CUSTOMERS ? [...(this.CUSTOMERS as ICustomer[]).map((x)=>Object.assign({}, x))] : null;
            
            return { success: true, data: returnData as any};
        }
        catch(e:any) {
            console.error('Customer Store Error:', e);
        }

        return { success: false, reason: 'Error updating customer.' };
    }

    //#endregion

    //#region MUTATIONS
    @Mutation
    async setCustomers(customers: ICustomer[]): Promise<void> {
        this.CUSTOMERS = customers;
    }

    @Mutation
    async setFetchedAllCustomers(fetched: boolean): Promise<void> {
        this.FETCHEDALLUSERS = fetched;
    }
    //#endregion


}
