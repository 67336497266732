
import { getModule } from 'vuex-module-decorators';
import { IUser } from '../../store/interfaces/IUser';
import UserState, { IUserState } from '../../store/modules/users';
import { defineComponent } from 'vue';
import { mdiDelete, mdiShieldStar, mdiShieldLock } from '@mdi/js';
import { IUserModelSearch } from '../../store/interfaces/IModelSearch';
let userState = {} as IUserState;

export default defineComponent ({
    name: 'Users',
    data() {
        return {
            mdiDelete, mdiShieldLock, mdiShieldStar,
            myId: '' as string,
            userSearch: '',
            userSortField: '',
            userSortOrder: '',
            loading: false as boolean,
            userSearchResults: [] as IUser[],
            userCount: 0 as number,
            searchDebounce: null as any,
            currentPage: 1 as number
        }
    },

    components: {
    },

    computed: {
        hasReleaseAccess(): boolean {
            if(userState.me) {
                return userState.me.releaseUpdate;
            }
            return false;
        }
    },

    methods: {
        handleCurrentChange(current: any) {
            this.$router.push({ path: `/admin/users/${current.id}` })
        },

        handleCurrentPageChange(pg: number) {
            if(this.searchDebounce) clearTimeout(this.searchDebounce);
            this.searchDebounce = setTimeout(()=> {
                this.currentPage = pg;
                this.doUserSearch();
            this.loading = true;
            }, 1000);            
        },

        handleSearchChange() {
            if(this.searchDebounce) clearTimeout(this.searchDebounce);
            this.searchDebounce = setTimeout(()=> {
                this.currentPage = 1;
                this.doUserSearch();
            this.loading = true;
            }, 1000);            
        },

        handleSortChange(sort: any) {
            this.userSortField = sort.prop;
            this.userSortOrder = sort.order;
            if(this.searchDebounce) clearTimeout(this.searchDebounce);
            this.searchDebounce = setTimeout(()=> {
                this.currentPage = 1;
                this.doUserSearch();
            this.loading = true;
            }, 1000);            
        },

        doUserSearch() {
            const userSearchObj: IUserModelSearch = {
                pageIndex:this.currentPage, 
                pageSize:10, 
            };

            if(this.userSearch != '') {
                userSearchObj.name = this.userSearch; 
                userSearchObj.email = this.userSearch;
            }

            if(this.userSortField && this.userSortOrder) {
                userSearchObj.sortField = this.userSortField;

                let tempOrgSortOrder: 'asc' | 'desc' = 'asc';
                if(this.userSortOrder == 'descending') tempOrgSortOrder = 'desc';

                userSearchObj.sortOrder = tempOrgSortOrder;
            }

            userState.searchUsers(userSearchObj).then(storeRes => {
                //console.log(storeRes);
                this.userSearchResults = storeRes.data && storeRes.data.data ? storeRes.data.data : [] as IUser[];
                this.userCount = storeRes.data && storeRes.data.itemsCount ? storeRes.data.itemsCount : 0;
                this.loading = false;
            });
        },

        async onTurnOnNotification() {
            if(!this.hasReleaseAccess) { return; }

            await (this as any).$confirm(
                `Are you sure you want to turn the release notification on for all users?`,
                'Warning',
                {
                    confirmButtonText: 'Continue',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }
            );

            const response = await userState.enableReleaseNotification();

            if(!response.success) {
                (this as any).$message.error(response.reason ? `Enable release notification failed: ${response.reason}` : 'Release notification was not enabled for all users.');
            }

            (this as any).$message.success('Release notification enabled for all users.');
        }
    },

    async created() {
        this.loading = true;
        userState = getModule(UserState);

        await Promise.all([
            userState.searchUsers({pageIndex: 1, pageSize: 10}).then(storeRes => {
                this.userSearchResults = storeRes.data && storeRes.data.data ? storeRes.data.data : [] as IUser[];
                this.userCount = storeRes.data && storeRes.data.itemsCount ? storeRes.data.itemsCount : 0;
            }),
            userState.fetchMe(null),
        ]); 

        if(!userState.me) {
            (this as any).$message.error("Error getting user information.");
            return;
        }

        
        this.myId = userState.me.id;
        this.loading = false;
    }
})
