
// import Vue from 'vue'
// import Axios from 'axios';
import { defineComponent } from 'vue'

export default defineComponent({
    name: "TemplateEngine",
    props: ['id', 'signature'],
    data() {
        return {
            templateData: {} as any
        }
    },

    methods: {
    },
    

    // mounted() {
    //     return Axios.get(`${ process.env.VUE_APP_API_HOST }/api/signages/${this.id}/templaterequest/${this.signature}`).then((res) => {
    //         this.templateData = res.data;
    //     });
    // },

    components: {
    }
});
