import { IOrganization } from './IOrganization';
import { IReceiver } from './IReceiver';

export enum EnableRoomSettings {
    false = 0, 
    true = 1, 
    useOrgSetting = 2
}

export enum EnableRoomLocationSettings {
    topLeft = 0, 
    topRight = 1, 
    bottomLeft = 2,
    bottomRight = 3,
    useOrgSetting = 4
}

export interface IRoomListDisplayDevice {
    _id: string;
    displayName: string;
    deviceType: string;
}

export interface IRoomListDisplayLocation {
    id: string;
    name: string;
    parentId: string;
    parentName: string;
}

export interface IRoomListDisplayReceiverStatuses {
    _id: string;
    receiverId: string;
}

export interface IRoomListDisplay {
    id: string;
    roomName: string;
    roomCode: string;
    devices: IRoomListDisplayDevice[];
    location: IRoomListDisplayLocation;
    recstatuses: IRoomListDisplayReceiverStatuses[];
    status: number;
}

export interface IRoomDropDownList {
    id: string;
    type: string;
    roomName: string;
    roomCode: string;
    enableSignage?: boolean,
    enableAlerts?: boolean,
    devices?: string;
}

export interface IRoom {
    id: string;
    type: 'Room';
    roomName: string;
    roomCode?: string | null;
    roomCodeWithoutWhitespace: string;
    macOS_binary: string;
    macOS_last_generated: Date;
    win32_binary: string;
    win32_last_generated: Date;
    win64_binary: string;
    win64_last_generated: Date;
    useLocalBinaries: boolean;
    macOS_local_binary: string;
    win32_local_binary: string;
    win64_local_binary: string;
    devicePinCode: string;
    devicePinCodeLastGenerated: Date;
    showConnectionInfo: boolean;
    connectionInfoPosition: string;
    showSignageDateTime: EnableRoomSettings;
    signageDateTimeLocation: EnableRoomLocationSettings;
    enableMirroring: boolean;
    enableSignage: boolean;
    enableAlerts: boolean;
    enableModeratorControls: boolean;
    moderatorConnectionType: string;
    moderatorPassword?: string;
    schemaVersion: number;
    signature: string;
    tags: string;
    locationName: string;
    createdAt: Date;
    updatedAt: Date;
    organization: IOrganization;
    location: any;
    groups: any;
    devices: IReceiver[];
    enableHigherResolution: EnableRoomSettings;
}