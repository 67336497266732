
import { defineComponent } from 'vue';
import { IReceiver } from '../../store/interfaces/IReceiver';
import { IRoomDropDownList } from '../../store/interfaces/IRoom';
import { mdiSkipNext } from '@mdi/js';
import Icon from '../../components/Icon.vue';


export default defineComponent({
    name: 'NewReceiverRoomView',
    props: ['newReceiverModel', 'availableRooms'],
    data () {
        return {
            selectedRoom: null as IRoomDropDownList | null,
            newReceiver: {} as IReceiver,
            mdiSkipNext
        }
    },
    components: {
        Icon
    },

    computed: {
        noRoomsAvailable(): boolean {
            if(this.availableRooms.length == 0) {return true}
            return false;
        },
        noRoomsAvailablePlaceholder(): string {
            if(this.availableRooms.length == 0) {return 'No Rooms Available'}
            return 'Select A Room';
        },
    },
    inject: ['userOnboardStatus'],
    mounted() {
        this.newReceiver = Object.assign([], this.newReceiverModel);
    },
    methods: {
        nextStep() {
            this.$emit('link-room', null);
        },
        async onRoomLink () {
            if(!this.selectedRoom) return;

            if((this.selectedRoom.devices && this.selectedRoom.devices.length > 0) && (this.newReceiverModel.deviceType.toLowerCase() !== 'ssp')) {
                (this as any).$message.error('Can not add a legacy receiver to a room already containing a receiver');
                return;
            }

            this.$emit('link-room', this.selectedRoom);
        },
        onNewRoom () {
            this.$router.push({ path:'/rooms/new' });
        }
    }
});
