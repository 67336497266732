
import { defineComponent } from 'vue'
import Axios from 'axios';
import textFit from 'textfit';

export default defineComponent({
    name: 'FeatureLayout' as string,

    data: ()=>({
            item: {} as any,
            loaded: false as boolean,
    }),
    props: ['id', 'signature'],

    mounted() {
        return Axios.get(`${ process.env.VUE_APP_API_HOST }/api/signages/${this.id}/templaterequest/${this.signature}`).then((res) => {
            this.item = res.data;
            this.loaded = true;
        });
    },

    watch: {
        item() {
            this.$nextTick(() => {
                textFit(document.getElementsByClassName('textfit__headline'), { minFontSize: 10, maxFontSize: 350 });
                textFit(document.getElementsByClassName('textfit__secondary-headline'), { minFontSize: 10, maxFontSize: 115, widthOnly: true, detectMultiLine: false });
                textFit(document.getElementsByClassName('textfit__text1'), { minFontSize: 10, maxFontSize: 115, widthOnly: true, detectMultiLine: false });
                textFit(document.getElementsByClassName('textfit__text2'), { minFontSize: 10, maxFontSize: 90, widthOnly: true, detectMultiLine: false });
            });
        }
    }
});

