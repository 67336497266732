import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cb281002"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-1" }
const _hoisted_2 = {
  key: 1,
  class: "col__featured-img-space top"
}
const _hoisted_3 = { class: "menu-items-container" }
const _hoisted_4 = { class: "item__title-row" }
const _hoisted_5 = { class: "item__title textfit__title" }
const _hoisted_6 = { class: "item__price" }
const _hoisted_7 = { class: "item__description textfit__description--multiline" }
const _hoisted_8 = {
  key: 3,
  class: "col__featured-img-space bottom"
}
const _hoisted_9 = { class: "col-2" }
const _hoisted_10 = {
  key: 1,
  class: "col__featured-img-space top"
}
const _hoisted_11 = { class: "menu-items-container" }
const _hoisted_12 = { class: "item__title-row" }
const _hoisted_13 = { class: "item__title textfit__title" }
const _hoisted_14 = { class: "item__price" }
const _hoisted_15 = { class: "item__description textfit__description--multiline" }
const _hoisted_16 = {
  key: 3,
  class: "col__featured-img-space bottom"
}
const _hoisted_17 = { class: "col-3" }
const _hoisted_18 = {
  key: 1,
  class: "col__featured-img-space top"
}
const _hoisted_19 = { class: "menu-items-container" }
const _hoisted_20 = { class: "item__title-row" }
const _hoisted_21 = { class: "item__title textfit__title" }
const _hoisted_22 = { class: "item__price" }
const _hoisted_23 = { class: "item__description textfit__description--multiline" }
const _hoisted_24 = {
  key: 3,
  class: "col__featured-img-space bottom"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("section", {
        key: 0,
        class: "full-item-menu",
        style: _normalizeStyle({ background: _ctx.layoutData.background })
      }, [
        _createElementVNode("article", _hoisted_1, [
          (_ctx.layoutData.media && _ctx.layoutData.media.col1_media_url && _ctx.layoutData.column1.mediaPosition == 'TOP')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "col__featured-img top",
                style: _normalizeStyle({ background: 'url('+_ctx.layoutData.media.col1_media_url+') center center no-repeat', backgroundSize: 'cover'})
              }, null, 4))
            : _createCommentVNode("", true),
          (_ctx.layoutData.column1.mediaPosition == 'TOP')
            ? (_openBlock(), _createElementBlock("div", _hoisted_2))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h1", {
              id: "column1Banner",
              class: "column-title textfit__header",
              style: _normalizeStyle([Boolean(_ctx.layoutData.column1.headerText.trim()) ? { background: _ctx.layoutData.column1.headerColor, color: _ctx.layoutData.column1.headerFontColor } : { background: _ctx.none }])
            }, _toDisplayString(_ctx.layoutData.column1.headerText), 5),
            _createElementVNode("ul", {
              class: "menu-col",
              style: _normalizeStyle({ color: _ctx.layoutData.column1.fontColor  })
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.layoutData.column1.menu, (item, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: index,
                  class: "item"
                }, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("h2", _hoisted_5, _toDisplayString(item.title), 1),
                    _createElementVNode("h3", _hoisted_6, _toDisplayString(item.price), 1)
                  ]),
                  _createElementVNode("p", _hoisted_7, _toDisplayString(item.description), 1)
                ]))
              }), 128))
            ], 4)
          ]),
          (_ctx.layoutData.media && _ctx.layoutData.media.col1_media_url && _ctx.layoutData.column1.mediaPosition == 'BOTTOM')
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: "col__featured-img bottom",
                style: _normalizeStyle({ background: 'url('+_ctx.layoutData.media.col1_media_url+') center center no-repeat', backgroundSize: 'cover'})
              }, null, 4))
            : _createCommentVNode("", true),
          (_ctx.layoutData.column1.mediaPosition == 'BOTTOM')
            ? (_openBlock(), _createElementBlock("div", _hoisted_8))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("article", _hoisted_9, [
          (_ctx.layoutData.media && _ctx.layoutData.media.col2_media_url && _ctx.layoutData.column2.mediaPosition == 'TOP')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "col__featured-img top",
                style: _normalizeStyle({ background: 'url('+_ctx.layoutData.media.col2_media_url+') center center no-repeat', backgroundSize: 'cover'})
              }, null, 4))
            : _createCommentVNode("", true),
          (_ctx.layoutData.column2.mediaPosition == 'TOP')
            ? (_openBlock(), _createElementBlock("div", _hoisted_10))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("h1", {
              ref: "column2Banner",
              class: "column-title textfit__header",
              style: _normalizeStyle([Boolean(_ctx.layoutData.column2.headerText.trim()) ? { background: _ctx.layoutData.column2.headerColor, color: _ctx.layoutData.column2.headerFontColor } : { background: _ctx.none }])
            }, _toDisplayString(_ctx.layoutData.column2.headerText), 5),
            _createElementVNode("ul", {
              class: "menu-col",
              style: _normalizeStyle({ color: _ctx.layoutData.column2.fontColor  })
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.layoutData.column2.menu, (item, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: index,
                  class: "item"
                }, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("h2", _hoisted_13, _toDisplayString(item.title), 1),
                    _createElementVNode("h3", _hoisted_14, _toDisplayString(item.price), 1)
                  ]),
                  _createElementVNode("p", _hoisted_15, _toDisplayString(item.description), 1)
                ]))
              }), 128))
            ], 4)
          ]),
          (_ctx.layoutData.media && _ctx.layoutData.media.col2_media_url && _ctx.layoutData.column2.mediaPosition == 'BOTTOM')
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: "col__featured-img bottom",
                style: _normalizeStyle({ background: 'url('+_ctx.layoutData.media.col2_media_url+') center center no-repeat', backgroundSize: 'cover'})
              }, null, 4))
            : _createCommentVNode("", true),
          (_ctx.layoutData.column2.mediaPosition == 'BOTTOM')
            ? (_openBlock(), _createElementBlock("div", _hoisted_16))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("article", _hoisted_17, [
          (_ctx.layoutData.media && _ctx.layoutData.media.col3_media_url && _ctx.layoutData.column3.mediaPosition == 'TOP')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "col__featured-img top",
                style: _normalizeStyle({ background: 'url('+_ctx.layoutData.media.col3_media_url+') center center no-repeat', backgroundSize: 'cover'})
              }, null, 4))
            : _createCommentVNode("", true),
          (_ctx.layoutData.column3.mediaPosition == 'TOP')
            ? (_openBlock(), _createElementBlock("div", _hoisted_18))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("h1", {
              ref: "column3Banner",
              class: "column-title textfit__header",
              style: _normalizeStyle([Boolean(_ctx.layoutData.column3.headerText.trim()) ? { background: _ctx.layoutData.column3.headerColor, color: _ctx.layoutData.column3.headerFontColor } : { background: _ctx.none }])
            }, _toDisplayString(_ctx.layoutData.column3.headerText), 5),
            _createElementVNode("ul", {
              class: "menu-col",
              style: _normalizeStyle({ color: _ctx.layoutData.column3.fontColor  })
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.layoutData.column3.menu, (item, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: index,
                  class: "item"
                }, [
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("h2", _hoisted_21, _toDisplayString(item.title), 1),
                    _createElementVNode("h3", _hoisted_22, _toDisplayString(item.price), 1)
                  ]),
                  _createElementVNode("p", _hoisted_23, _toDisplayString(item.description), 1)
                ]))
              }), 128))
            ], 4)
          ]),
          (_ctx.layoutData.media && _ctx.layoutData.media.col3_media_url && _ctx.layoutData.column3.mediaPosition == 'BOTTOM')
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: "col__featured-img bottom",
                style: _normalizeStyle({ background: 'url('+_ctx.layoutData.media.col3_media_url+') center center no-repeat', backgroundSize: 'cover'})
              }, null, 4))
            : _createCommentVNode("", true),
          (_ctx.layoutData.column3.mediaPosition == 'BOTTOM')
            ? (_openBlock(), _createElementBlock("div", _hoisted_24))
            : _createCommentVNode("", true)
        ])
      ], 4))
    : _createCommentVNode("", true)
}