import {
    Action,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';

import store from '../../store';

import { getAuthInstance } from '../../auth';
import axios from 'axios';
import { ISqStoreActionResponse } from '../interfaces/ISqStoreActionResponse';
import { IOrganization } from '../interfaces/IOrganization';
import { IDigitalAssetQuota } from '../interfaces/IDigitalAssetQuota';
import { IModelSearchResponse, IOrganizationModelSearch } from '../interfaces/IModelSearch';

export interface IOrganizationState {
    readonly getOrganization: IOrganization;
    readonly getOrganizationById: (id: string) => IOrganization | undefined;
    readonly getAllOrganizations: IOrganization[];
    readonly digitalSignageStorageQuota: IDigitalAssetQuota;

    fetchOrganizations(id?: string): Promise<ISqStoreActionResponse<IOrganization[]>>;
    fetchOrganization(id: string, cacheOverride: boolean): Promise<ISqStoreActionResponse<IOrganization>>;
    updateOrganization(organization: IOrganization): Promise<ISqStoreActionResponse<void>>;
    searchOrganizations(organizationSearch: IOrganizationModelSearch): Promise<ISqStoreActionResponse<IModelSearchResponse<IOrganization[]>>>;
    fetchSignageAssetQuota(): Promise<ISqStoreActionResponse<IDigitalAssetQuota[]>>;
    restartTrial(organization: IOrganization): Promise<ISqStoreActionResponse<IOrganization>>;
    addBrandingsToOrganization(organization: IOrganization): Promise<ISqStoreActionResponse<IOrganization>>;
}

@Module({ dynamic: true, store, name: 'organizations' })
export default class OrganizationState extends VuexModule implements IOrganizationState {
    // THESE HAVE TO BE NAMED UNIQUELY
    // THE WAY STATE WORKS IF YOU ALTER THIESE VIA CONTEXT 
    // ANY NAMED THE SAME WAY WILL CHANGE ALSO
    private organizations = [] as IOrganization[];
    private isOrganizationsLoaded = false;
    private organization = {} as IOrganization;
    private isOrganizationLoaded = false;
    private quotas = [] as IDigitalAssetQuota[];

    //#region GETTERS
    get getOrganization(): IOrganization {
        return this.organization;
    }

    get getOrganizationById(): (id: string) => IOrganization | undefined {
        return (id: string) => {
            if (!this.isOrganizationsLoaded) return undefined;

            const org = this.organizations.find(x => x.id === id)
            return org ? JSON.parse(JSON.stringify(org)) : undefined;
        };
    }

    get getAllOrganizations(): IOrganization[] {
        return this.organizations;
    }

    get digitalSignageStorageQuota(): IDigitalAssetQuota {
        const quota = this.quotas.find((q) => q.quotaType == 'DIGITAL_SIGNAGE');
        return quota ? quota : {} as IDigitalAssetQuota
    }
    //#endregion

    //#region ACTIONS
    @Action
    async fetchOrganizations(id?:string): Promise<ISqStoreActionResponse<IOrganization[]>> {
        if (this.isOrganizationsLoaded && !id) return { success: true };
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            let fetchOrganizationUrl = `${process.env.VUE_APP_API_HOST}/api/organizations`;

            if(id) {
                fetchOrganizationUrl = `${fetchOrganizationUrl}/super/${id}`;
            }

            const axiosResponse = await axios.get(fetchOrganizationUrl, authHeader);

            let orgData;

            if(id) {
                orgData = [axiosResponse.data];
            } else {
                orgData = axiosResponse.data;
            }   
            
            this.context.commit('setOrganizations', orgData);
            this.context.commit('setIsOrganizationsLoaded', true);
            return { success: true };
        }
        catch(e:any) {
            console.error('Organization Store Error:', e);
        }

        return { success: false, reason: 'Error fetching all organization.' };
    }

    @Action
    async fetchOrganization(id: string, cacheOverride: boolean): Promise<ISqStoreActionResponse<IOrganization>> {
        if (!cacheOverride && this.isOrganizationLoaded) return { success: true };

        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            const fetchOrganizationUrl = `${process.env.VUE_APP_API_HOST}/api/organizations/${id}`;
            const axiosResponse = await axios.get(fetchOrganizationUrl, authHeader);

            this.context.commit('setOrganization', axiosResponse.data);
            this.context.commit('setIsOrganizationLoaded', true);
            return { success: true };
        }
        catch(e:any) {
            console.error('Organization Store Error:', e);
        }

        return { success: false, reason: 'Error fetching organization.' };
    }

    @Action
    async searchOrganizations(organizationSearch: IOrganizationModelSearch): Promise<ISqStoreActionResponse<IModelSearchResponse<IOrganization[]>>>{
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            let fetchOrganizationUrl = `${process.env.VUE_APP_API_HOST}/api/organizations`;

            if(organizationSearch.pageIndex && organizationSearch.pageSize) {
                fetchOrganizationUrl = `${fetchOrganizationUrl}?pageIndex=${organizationSearch.pageIndex}&pageSize=${organizationSearch.pageSize}`;

                if(organizationSearch.name){
                    fetchOrganizationUrl = `${fetchOrganizationUrl}&name=${organizationSearch.name}`;
                } 
                
                if(organizationSearch.ids) {
                    fetchOrganizationUrl = `${fetchOrganizationUrl}&ids=${organizationSearch.ids}`;
                }
                
                if (organizationSearch.billingType) {
                    fetchOrganizationUrl = `${fetchOrganizationUrl}&billing.billing_type=${organizationSearch.billingType}`;
                }

                if(organizationSearch.sortField && organizationSearch.sortOrder) {
                    fetchOrganizationUrl = `${fetchOrganizationUrl}&sortField=${organizationSearch.sortField}&sortOrder=${organizationSearch.sortOrder}`;
                }
            }

            const axiosResponse = await axios.get(fetchOrganizationUrl, authHeader);

            // console.log(axiosResponse)

            return { success: true, data: {itemsCount: axiosResponse.data.itemsCount, data: axiosResponse.data.data} as IModelSearchResponse<IOrganization[]> };
        }
        catch(e:any) {
            console.error('Organization Store Error:', e);
        }

        return { success: false, reason: 'Error searching organizations.' };
    }

    @Action
    async updateOrganization(organization: IOrganization): Promise<ISqStoreActionResponse<void>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
        
            const updateOrganizationUrl = `${ process.env.VUE_APP_API_HOST }/api/organizations/${organization.id}`;
            const axiosResponse = await axios.put(updateOrganizationUrl, organization, authHeader);
            
            // were in single org mode (normal users)
            if(this.isOrganizationLoaded && !this.isOrganizationsLoaded) {
                this.context.commit('setOrganization', axiosResponse.data);
            }
            else { //we're in multi org mode (super users)
                const mutatedOrgs = [
                    ...this.organizations.filter(x=>x.id !== organization.id),
                    axiosResponse.data
                ];
    
                this.context.commit('setOrganizations', mutatedOrgs);
            }

            return { success: true };
        }
        catch(e:any) {
            if(e.response) {
                console.error(e.response.status);
            }
            console.error('Organizations Store Error:', e);
        }

        return { success: false, reason: `Error updating organization.}`};
    }

    @Action
    async fetchSignageAssetQuota(): Promise<ISqStoreActionResponse<IDigitalAssetQuota[]>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            
            const signageAssetQuotaUrl = `${ process.env.VUE_APP_API_HOST }/api/organizations/digitalassetstorage`;
            
            const axiosResponse = await axios.get(signageAssetQuotaUrl, authHeader);

            this.context.commit('setOrgQuotas', axiosResponse.data);
           
            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Organization Store Error:', e);
        }

        return { success: false, reason: 'Error fetching digital asset quota.' };
    }

    @Action
    async restartTrial(organization: IOrganization): Promise<ISqStoreActionResponse<IOrganization>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            
            const restartTrialUrl = `${ process.env.VUE_APP_API_HOST }/api/organizations/billing/restartTrial`;
            const axiosResponse = await axios.post(restartTrialUrl, organization, authHeader);
            
            if(this.isOrganizationLoaded) {
                this.context.commit('setOrganization', axiosResponse.data);
            }

            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Organizations Store Error:', e);
        }

        return { success: false, reason: 'Error restarting trial.'};
    }

    @Action
    async addBrandingsToOrganization(organization: IOrganization): Promise<ISqStoreActionResponse<IOrganization>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
        
            const addBrandingsToOrganizationUrl = `${ process.env.VUE_APP_API_HOST }/api/brandings/addBranding`;
            const axiosResponse = await axios.post(addBrandingsToOrganizationUrl, organization, authHeader);
            
            if(this.isOrganizationLoaded) {
                this.context.commit('setOrganization', axiosResponse.data);
            }
            
            return { success: true };
        }
        catch(e:any) {
            if(e.response) {
                console.error(e.response.status);
            }
            console.error('Organizations Store Error:', e);
        }

        return { success: false, reason: `Error adding brandings to organization.}`};
    }
    //#endregion

    //#region MUTATIONS
    @Mutation
    async setOrganizations(organizations: IOrganization[]): Promise<void> {
        this.organizations = organizations;
    }

    @Mutation
    async setIsOrganizationsLoaded(isLoaded: boolean): Promise<void> {
        this.isOrganizationsLoaded = isLoaded;
    }
    @Mutation
    async setOrganization(organization: IOrganization): Promise<void> {
        this.organization = organization;
    }

    @Mutation
    async setIsOrganizationLoaded(isLoaded: boolean): Promise<void> {
        this.isOrganizationLoaded = isLoaded;
    }

    @Mutation
    async setOrgQuotas(quotas: IDigitalAssetQuota[]): Promise<void> {
        this.quotas = quotas;
    }
    //#endregion
}