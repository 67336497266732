
import { getModule } from 'vuex-module-decorators';
import { defineComponent } from 'vue';
import { mdiDelete, mdiShieldStar, mdiShieldLock } from '@mdi/js';
import OrganizationState, { IOrganizationState } from '../../store/modules/organizations';
import { IOrganization } from '../../store/interfaces/IOrganization';
import { IOrganizationModelSearch } from '../../store/interfaces/IModelSearch';

let organizationState = {} as IOrganizationState;

export default defineComponent ({
    name: 'Organizations',
    data() {
        return {
            mdiDelete, mdiShieldLock, mdiShieldStar,
            myId: '' as string,
            orgSearch: '',
            orgSortField: '',
            orgSortOrder: '',
            loading: false as boolean,
            orgSearchResults: [] as IOrganization[],
            orgCount: 0 as number,
            searchDebounce: null as any,
            currentPage: 1 as number
        }
    },

    components: {
    },

    computed: {
    },

    methods: {
        handleCurrentChange(current: any) {
            this.$router.push({ path: `/admin/organizations/${current.id}` })
        },

        handleCurrentPageChange(pg: number) {
            if(this.searchDebounce) clearTimeout(this.searchDebounce);
            this.searchDebounce = setTimeout(()=> {
                this.currentPage = pg;
                this.doOrgSearch();
            this.loading = true;
            }, 1000);            
        },

        handleSearchChange() {
            if(this.searchDebounce) clearTimeout(this.searchDebounce);
            this.searchDebounce = setTimeout(()=> {
                this.currentPage = 1;
                this.doOrgSearch();
            this.loading = true;
            }, 1000);            
        },

        handleSortChange(sort: any) {
            this.orgSortField = sort.prop;
            this.orgSortOrder = sort.order;
            if(this.searchDebounce) clearTimeout(this.searchDebounce);
            this.searchDebounce = setTimeout(()=> {
                this.currentPage = 1;
                this.doOrgSearch();
            this.loading = true;
            }, 1000);            
        },

        doOrgSearch() {
            const orgSearchObj: IOrganizationModelSearch = {
                pageIndex:this.currentPage, 
                pageSize:10, 
            };

            if(this.orgSearch != '') {
                const formatedSearch = this.orgSearch.replace('#', '');
                orgSearchObj.name = formatedSearch; 
            }

            if(this.orgSortField && this.orgSortOrder) {
                orgSearchObj.sortField = this.orgSortField;

                let tempOrgSortOrder: 'asc' | 'desc' = 'asc';
                if(this.orgSortOrder == 'descending') tempOrgSortOrder = 'desc';

                orgSearchObj.sortOrder = tempOrgSortOrder;
            }

            organizationState.searchOrganizations(orgSearchObj).then(storeRes => {
                //console.log(storeRes);
                this.orgSearchResults = storeRes.data && storeRes.data.data ? storeRes.data.data : [] as IOrganization[];
                this.orgCount = storeRes.data && storeRes.data.itemsCount ? storeRes.data.itemsCount : 0;
                this.loading = false;
            });
        }
    },

    async created() {
        this.loading = true;
        organizationState = getModule(OrganizationState);

        await Promise.all([
            organizationState.searchOrganizations({pageIndex:1, pageSize:10}).then(storeRes => {
                this.orgSearchResults = storeRes.data && storeRes.data.data ? storeRes.data.data : [] as IOrganization[];
                this.orgCount = storeRes.data && storeRes.data.itemsCount ? storeRes.data.itemsCount : 0;
            })
        ]); 

        this.loading = false;
    }
})
