
import { defineComponent } from 'vue';
import UserState, { IUserState } from '../store/modules/users';
import { getModule } from 'vuex-module-decorators';

let userState = {} as IUserState;

export default defineComponent({
    name: 'MDMDeploymentInstructions',
    props: ['deploymentOption'],
    computed: {
        mdmProfile() {
            if(!userState.me || !userState.me.organization || !userState.me.organization.deployment) return '';
            const indentSpacing = "&nbsp;&nbsp;&nbsp;";
            const brTag = "<br>";
            let mdmProfile = '';
            const org = userState.me.organization;

            switch (this.deploymentOption) {
                case 'JamfSchool':
                        mdmProfile = "&#60;plist&#62;" + brTag +
                        indentSpacing + "&#60;dict&#62;" + brTag +
                        indentSpacing + indentSpacing + "&#60;key&#62" + "organizationId" + "&#60;/key&#62;" + brTag +
                        indentSpacing + indentSpacing + "&#60;string&#62;" + org.id + "&#60;/string&#62;" + brTag +
                        indentSpacing + indentSpacing + "&#60;key&#62" + "deploymentCode" + "&#60;/key&#62;" + brTag +
                        indentSpacing + indentSpacing + "&#60;string&#62;" + org.deployment.deploymentCode + "&#60;/string&#62;" + brTag +
                        indentSpacing + indentSpacing + "&#60;key&#62" + "macAddress" + "&#60;/key&#62;" + brTag +
                        indentSpacing + indentSpacing + "&#60;string&#62;%WIFIMAC%&#60;/string&#62;" + brTag +
                        indentSpacing + indentSpacing + "&#60;key&#62" + "deviceName" + "&#60;/key&#62;" + brTag +
                        indentSpacing + indentSpacing + "&#60;string&#62;%Name%&#60;/string&#62" + brTag +
                        indentSpacing + indentSpacing + "&#60;key&#62" + "room" + "&#60;/key&#62;" + brTag +
                        indentSpacing + indentSpacing + "&#60;string&#62;%LocationName%&#60;/string&#62" + brTag +
                        indentSpacing + '&#60;/dict&#62;' + brTag +
                        '&#60;/plist&#62;';
                    break;
                case 'JamfPro':
                        mdmProfile = "&#60;dict&#62;" + brTag +
                        indentSpacing + "&#60;key&#62" + "organizationId" + "&#60;/key&#62;" + brTag +
                        indentSpacing + "&#60;string&#62;" + org.id + "&#60;/string&#62;" + brTag +
                        indentSpacing + "&#60;key&#62" + "deploymentCode" + "&#60;/key&#62;" + brTag +
                        indentSpacing + "&#60;string&#62;" + org.deployment.deploymentCode + "&#60;/string&#62;" + brTag +
                        indentSpacing + "&#60;key&#62" + "macAddress" + "&#60;/key&#62;" + brTag +
                        indentSpacing + "&#60;string&#62;$MACADDRESS&#60;/string&#62;" + brTag +
                        indentSpacing + "&#60;key&#62" + "deviceName" + "&#60;/key&#62;" + brTag +
                        indentSpacing + "&#60;string&#62;$DEVICENAME&#60;/string&#62" + brTag +
                        indentSpacing + "&#60;key&#62" + "room" + "&#60;/key&#62;" + brTag +
                        indentSpacing + "&#60;string&#62;$ROOM&#60;/string&#62" + brTag +
                        '&#60;/dict&#62;';
                    break;
                case 'CiscoMeraki':
                    mdmProfile = 
                        "<table style= 'width:550px'>" + 
                        "<tr style='font-weight:bold;'><td>KEY</td><td>TYPE</td><td>VALUE</td></tr>" + 
                        "<tr><td>organizationId</td><td>Text</td><td> " + org.id + "</td></tr>" +
                        "<tr><td style='padding-right:3px;'>deploymentCode</td><td>Text</td><td>" + org.deployment.deploymentCode + "</td></tr>" +
                        "<tr><td>macAddress</td><td>Device: MAC Address</td><td></td></tr>" +
                        "<tr><td>deviceName</td><td>Device: Name</td><td></td></tr>" +
                        "<table>"
                    break;
                case 'Other':
                    mdmProfile =
                        '<table style="width:550px">' +
                        '<tr style="font-weight:bold;"><td>KEY</td><td>TYPE</td><td>VALUE</td></tr>' +
                        '<tr><td>organizationId</td><td>string</td><td> ' + org.id + '</td></tr>' +
                        '<tr><td style="padding-right:3px;">deploymentCode</td><td>string</td><td>' + org.deployment.deploymentCode + '</td></tr>' +
                        '</table>'
                    break;
            }

            //todo do we want to/can we sign this?
            return mdmProfile;
        }
    },
    async created () {
        userState = getModule(UserState);
        await Promise.all([
            userState.fetchMe(null)
        ]);
    }
});
