
    import { defineComponent } from 'vue';
    import { getModule } from 'vuex-module-decorators';
    import SignageListDetails from '../../components/signage/SignageListDetails.vue';
    import TierLimit from '../../components/TierLimit.vue';
    import TierState, { ITierState } from '../../store/modules/tier';
    
    let tierState = {} as ITierState;
    
    export default defineComponent({
        name: 'Signage',
        data() {
            return {
                selectedSignageListId: null as string | null
            }
        },
    
        components: {
            SignageListDetails,
            TierLimit
        },
    
        methods: {
            sigangeListSelected(id: string | null) {
                this.selectedSignageListId = id;
            }
        },
    
        computed: {
            showTierCallout(): boolean {
                if(!tierState.orgTier.digitalSignageEnabled) {
                    return true;
                }
                return false;
            }
        },   
    
        async created () {
            tierState = getModule(TierState);

            await tierState.fetchTier();

            if(this.$route.params.listid){
                this.selectedSignageListId = this.$route.params.listid as string;
            }
        }
    });
    