export interface ISortService {
    Alphabetical(a: string,b: string): number;
    Numerical(a: number, b: number): number;
    Boolean(a: boolean, b: boolean): number;
}

export class SortService implements ISortService {
    Alphabetical(a: string,b: string): number {
        if(!a || !b) return 0;
        if(a.toUpperCase() < b.toUpperCase()) { return -1; }
        if(a.toUpperCase() > b.toUpperCase()) { return 1; }
        return 0;
    }

    Numerical(a: number,b: number): number {
        if(!a || !b) return 0;
        if(a < b) { return -1; }
        if(a > b) { return 1; }
        return 0;
    }

    Boolean(a: boolean, b: boolean): number {
        if(a === null || b === null) return 0;
        if(a < b) { return -1; }
        if(a) { return 1; }
        return 0;
    }
}

export default new SortService

