import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "image",
        style: _normalizeStyle({ background: 'url('+_ctx.item.media.img_url+') center center no-repeat', backgroundSize: 'cover' })
      }, null, 4))
    : _createCommentVNode("", true)
}