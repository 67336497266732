
import { defineComponent } from 'vue';
import { getModule } from 'vuex-module-decorators';
import SignageDetails from '../../components/signage/SignageDetails.vue';
import TierLimit from '../../components/TierLimit.vue';
import TierState, { ITierState } from '../../store/modules/tier';

let tierState = {} as ITierState;

export default defineComponent({
  name: 'Signage',
  data() {
    return {
      selectedSignageListId: null as string | null,
      signageItemId: '' as string
    }
  },

  components: {
    SignageDetails,
    TierLimit
  },

  methods: {
    sigangeListSelected(id: string | null) {
      this.selectedSignageListId = id;
    }
  },

  props: [
    'isCreatingNewSignageItem'
  ],

  computed: {
        showTierCallout(): boolean {
            if(!tierState.orgTier.digitalSignageEnabled) {
                return true;
            }
            return false;
        }
    },

  async created () {
    tierState = getModule(TierState);

    await tierState.fetchTier();

    if(this.$route.params.listid){
      this.selectedSignageListId = this.$route.params.listid as string;
    }
    this.signageItemId = this.$route.params.itemid ? this.$route.params.itemid as string : '0';
  },

watch: {
  $route() {
    this.signageItemId = this.$route.params.itemid ? this.$route.params.itemid as string : '0';
  }
}
});
