
import { defineComponent } from 'vue'
import DigitalAssetUpload from '../DigitalAssetUpload.vue'

export default defineComponent({
    data() {
        return {
            signageItemLayoutData: {} as any,
            signageItemPlaySound: false as boolean,
            uploadIsVideo: false as boolean
        }
    },

    props: {
        layoutDataEdit: {
            type: Object,
            default: null
        },
        playSoundEdit: {
            type: Boolean,
            default: false
        },
        layoutType: {
            type: String,
            default: ''
        },
        signageAssets: {
            type: Object,
            default: null
        },
    },

    components: {
        DigitalAssetUpload
    },

    methods: {
        updatePlaySound(isVideo: boolean) {
            this.uploadIsVideo = isVideo;
            this.signageItemPlaySound = isVideo;
            this.$emit('updatePlaySound', isVideo);
        }
    },

    computed: {
        assetUrl() {
            if (this.signageAssets && this.signageAssets.length) {
                const signageAsset = this.signageAssets.find((sa: any) => sa.id == this.layoutDataEdit.media.img).thumbUrl;
                if (signageAsset && signageAsset.thumbUrl) {
                    return signageAsset.thumbUrl;
                }
                return null;
            }
            return null;
        },

        isVideo() {
            let assetType = '';
            if(this.layoutDataEdit && this.layoutDataEdit.media && this.layoutDataEdit.media.img) {
                assetType = this.signageAssets.find((sa: any) => sa.id == this.layoutDataEdit.media.img).assetType;
            }
            if(this.uploadIsVideo || assetType == 'video')
            {
                return true;
            }
            return false;
            
        }
    },

    created () {
        this.signageItemLayoutData = JSON.parse(JSON.stringify(this.layoutDataEdit));
        this.signageItemPlaySound = this.playSoundEdit;
    },

    watch: {
        'signageItemLayoutData': {
            handler(newValue: any){
                this.$emit('updateLayoutData', {updatedLayoutData: newValue, shouldUpdatePreview: false});
            },
            deep: true
        },

        'signageItemPlaySound' : {
            handler(newValue: boolean) {
                this.$emit('updatePlaySound', newValue);
            }
        }
    }
})
