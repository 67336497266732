
import { defineComponent } from "vue";
import Axios from "axios";

export default defineComponent({
    name: "COVIDOccupancyTwoLayout" as string,
    data: ()=>({
        item: {} as any,
        loaded: false as boolean
    }),

    computed: {
        backgroundStyle() {
            if (this.item.useBackgroundMedia) {
                if (!this.item.media.asset2_url || this.item.media.asset2_url === "") {
                    return { background: "transparent" };
                }
                return {
                    background: `url(${this.item.media.asset2_url}) center center no-repeat`,
                    backgroundSize: "cover"
                };
            } else {
                return { background: this.item.backgroundColor };
            }
        }
    },
    props: ["id", "signature"],

    mounted() {
        return Axios.get(`${ process.env.VUE_APP_API_HOST }/api/signages/${this.id}/templaterequest/${this.signature}`).then((res) => {
            this.item = res.data;
            this.loaded = true;
        });
    }
});
