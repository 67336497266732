
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Unauthorized',
    computed: {
        dateYear() {
        return (new Date()).getFullYear();
        }
    }
});
