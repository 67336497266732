
import { defineComponent } from "vue";
import { getModule } from 'vuex-module-decorators'
import { ISubscriptionStatus } from "../store/interfaces/ISubscriptionStatus";
import UserState, { IUserState } from '../store/modules/users';
import { IOrganization } from "../store/interfaces/IOrganization";
import OrganizationState, { IOrganizationState } from '../store/modules/organizations';
import RoleState, { IRoleState } from '../store/modules/role';
import moment from "moment";

let organizationState = {} as IOrganizationState;
let userState = {} as IUserState;
let roleState = {} as IRoleState;

export default defineComponent({
    name: 'ExpiredSubscription',
    data() {
        return {
            reactivateTrialVisible: false,
            inactiveSubscriptionVisible: false,
            expiredTrialVisible: false,
            noTierVisible: false,
            organization: {} as IOrganization,
            subscriptionStatus: undefined as ISubscriptionStatus | undefined,

        }
    },
    methods: {
        async reactivateTrial() {
            const storeResponse = await organizationState.restartTrial(this.organization);

            if(!storeResponse.success) {
                (this as any).$message.error(storeResponse.reason ? `${storeResponse.reason}` : 'Restarting trial failed');
                return;
            }

            await Promise.all([
                userState.fetchMe({ reload: true }),
                userState.fetchSubscriptionStatus(true)
            ]);

            this.loadStatus();

            (this as any).$message.success('Trial restarted!');
        },

        loadStatus() {
            if(!userState.me) {
                return;
            }

            if(userState.me.organization) {
                this.organization = userState.me.organization;
            }

            if(!userState.me.superUser && !this.organization.tierId) {
                this.noTierVisible = true;
            }

            this.subscriptionStatus = userState.subscriptionStatus;

            if(!this.organization || !this.subscriptionStatus) {
                return;
            }

            if(this.organization.billing){
                const expirationDate = moment.utc(this.organization.billing.expiration_date);

                if(moment.utc().isAfter(moment.utc(expirationDate))) {
                    if(this.organization.billing.billing_type == 'Trial') {
                        this.expiredTrialVisible = true;

                        if(this.hasBilling && this.organization.billing.trial_count < this.organization.billing.max_trials) 
                        {
                            this.reactivateTrialVisible = true;
                        }
                        else {
                            this.reactivateTrialVisible = false;
                        }
                    }
                    else {
                        this.reactivateTrialVisible = false;
                        this.expiredTrialVisible = false;

                        if(!this.subscriptionStatus.subscriptionActive || this.organization.billing.stripe_card_error || this.organization.billing.billing_type == 'PO') 
                        {
                            this.inactiveSubscriptionVisible = true;
                        }
                        else {
                            this.inactiveSubscriptionVisible = false;
                        }
                    }
                }
                else {
                    this.reactivateTrialVisible = false;
                    this.expiredTrialVisible = false;
                    this.inactiveSubscriptionVisible = false;
                }
            }
        }
    },
    computed: {
        expiredSubscriptionVisible() {
            if(this.expiredTrialVisible || this.reactivateTrialVisible || this.inactiveSubscriptionVisible || this.noTierVisible) {
                return true;
            }
            return false;
        },
        
        hasBilling(): boolean {
            return roleState.isAdmin || roleState.hasBillingAccess;
        },
    },
    async created () {
        try {
            userState = getModule(UserState);
            organizationState = getModule(OrganizationState);
            roleState = getModule(RoleState);

            await Promise.all([
                userState.fetchMe(null),
                userState.fetchSubscriptionStatus(),
                roleState.fetchUserRole()
            ]);

            this.loadStatus();
        }
        catch(e:any) {
            // don't care. Its very possible were not auth'd and we don't have "me"
        }
    }
});
