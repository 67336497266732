
import { defineComponent } from 'vue';
import { getModule } from 'vuex-module-decorators';
import axios from 'axios';
import textFit from 'textfit';
import SignageState, { ISignageState, ITwitterSignagePayload } from '../../store/modules/signage';

let signageItemState = {} as ISignageState;

const __default__ = defineComponent({
    name: 'TwitterLayout' as string,
    data: ()=>({
        item: {} as any,
        iframeWidth: '900px'
    }),
    mounted() {
        signageItemState = getModule(SignageState);

        axios.get(`${ process.env.VUE_APP_API_HOST }/api/signages/${this.id}/templaterequest/${this.signature}`).then(async (res) => {
            this.item = res.data; 
            
            const payload = {
                url: this.item.url,
                omitScript: 'true',
                maxHeight: '900',
                maxWidth: '550',
                theme: this.theme,
                chrome: 'noscrollbar nofooter',
                showConversation: this.item.showConversation
            } as ITwitterSignagePayload;

            const twitterHtml = await signageItemState.getTwitterEmbedHtml(payload);

            if(!twitterHtml.success) {
                // We has an error from Twitter and should not generate the signage
                window.location.href = `https://httpstat.us/${twitterHtml.reason}`;
            }

            var generateHere = document.getElementById("twitter-div");
            if(twitterHtml.success && generateHere) {
                generateHere.innerHTML = twitterHtml.data;
            }

            // Add script that creates Twitter frame
            let twitterScript = document.createElement('script');
            twitterScript.setAttribute('src', 'https://platform.twitter.com/widgets.js');
            twitterScript.setAttribute('charset', 'utf-8');
            document.head.appendChild(twitterScript);
        })
    },

    props: ['id', 'signature'],

    computed: {
        backgroundStyle() {
            if (this.item.useBackgroundMedia) {
                return { background: `url(${this.item.media.asset1_url}) center center no-repeat`, backgroundSize : 'cover'}
            } else {
                return { background: this.item.backgroundColor }
            }
        },

        theme() {
            if(this.item.darkMode) {
                return 'dark';
            }
            return 'light';
        }
    },

    watch: {
        item() {
            this.$nextTick(() => {
                textFit(document.getElementsByClassName('textfit__headline'), { minFontSize: 10, maxFontSize: 285});
            });
        }
    }
});


import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "c87f9de6": (_ctx.iframeWidth)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__