
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'NewReceiverSuccessView',
    methods: {
        async createAnother () {
            this.$emit('restart');
        },
        async toInventory () {
            this.$router.push({ path: '/receivers' });
        }
    }
});
