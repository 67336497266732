
import { getModule } from 'vuex-module-decorators';
import { defineComponent } from 'vue';
import PageDirtyService from '../../services/PageDirtyService';

// -- Interfaces --
import { IUser } from '../../store/interfaces/IUser';
import { IRole } from '../../store/interfaces/IRole';
import { IRoomDropDownList } from '../../store/interfaces/IRoom';
import { IModelSelectionPool } from '../../store/interfaces/IModelSelectionPool';
interface IUserWithRoleName extends IUser {
   roleName: string; 
}
// -- State Stores --
import RoomState, { IRoomState } from '../../store/modules/rooms';
import RoleState, { IRoleState } from '../../store/modules/role';
import UserState, { IUserState } from '../../store/modules/users';
import TierState, { ITierState } from '../../store/modules/tier';
// -- Components --
import Icon from '../../components/Icon.vue';
import ModelSelector from '../../components/ModelSelector.vue';
import { mdiAccount, mdiMagnify, mdiShieldLock, mdiShieldStar, mdiLock } from '@mdi/js';
import TierLimit from '../../components/TierLimit.vue';
// -- Initialize State --
let roleState = {} as IRoleState;
let userState = {} as IUserState;
let roomState = {} as IRoomState;
let tierState = {} as ITierState;

export default defineComponent ({
    name: 'RolesDetailsView',
    data() {
        return {
            membersListFilter: '' as string,
            isAddMembersLoading: false as boolean,
            mdiAccount, mdiMagnify, mdiShieldLock, mdiShieldStar,mdiLock,
            myId: '' as string,
            isLoading: false as boolean,
            membersListSearch: '' as string,
            isMemberSelectionModalVisible: false as boolean,
            selectedUserIdsToAdd: [] as string[],
            availableUsers: [] as any,
            usersOfThisRole: [] as IUser[],
            activeTab: 'overview',
            assignedRooms: [] as IRoomDropDownList[],
            allRooms:[] as IRoomDropDownList[],
            role: {
                roomIds: [],
                color: '#00a887'
            } as any,

            rules: {
                name: [
                    {
                        required: true,
                        message: 'This field is required.',
                        trigger: 'blur'
                    }
                ],
                description: [
                    {
                        required: true,
                        message: 'This field is required.',
                        trigger: 'blur'
                    }
                ],
                rooms: [{ validator: this.checkRooms, trigger: 'blur' }],
            }
        }
    },

    components: {
        ModelSelector,
        Icon,
        TierLimit
    },

    props: ["creationMode"],

    watch: {
        role: {
            handler () {
                this.changeDetection();
            },
            deep: true
        }
    },

    inject: [
        'windowWidth',
        'userOnboardStatus',
        'currentStep'
    ],

    computed: {
        isAllUsersChecked(): boolean {
            return this.filteredAvailableUsers.find((user: any) => !user.selected) ? false : true
        },

        isRoleSystemRole(): boolean {
            if(!this.role || !this.role.name) return false;

            return this.role.name.toLowerCase() === 'admin' || this.role.name.toLowerCase() === 'user';
        },

        isAdmin(): boolean {
            return roleState.isAdmin;
        },

        // Billing switch will return 2 if on (true), 0 if off (false)
        billingSwitchValue: {
            get(){
                if(!this.role || !this.role.application) return false;

                if(this.role.application.billing == 2) {
                    return true;
                } else {
                    return false;
                }
            },

            set(newValue: any){
                if(newValue)  {
                    this.role.application.billing = 2;
                } else {
                    this.role.application.billing = 0;
                }
            }
        },

        users(): IUserWithRoleName[] | undefined {
            if(!userState || !userState.allUsers) return;

            return userState.allUsers.map((user: IUser) => {
                if(!this.roles) return;

                const foundRole = this.roles.find((r: IRole)=> r.id === user.role);
                return {
                    ...user,
                    roleName: foundRole ? foundRole.name : ''
                } as IUserWithRoleName;

            }) as IUserWithRoleName[];
        },

        userRole(): IRole | null {
            return roleState.userRole;
        },

        roles(): IRole[] | null {
            return roleState.roles;
        },

        filteredAvailableUsers(): any[] {
            let usersResponse = [...this.availableUsers];
            
            if(this.membersListFilter) {
                usersResponse = usersResponse.filter((user: any) => user.roleName === this.membersListFilter)
            }

            if(this.membersListSearch) {
                const searchValue = this.membersListSearch.toLowerCase();
                return usersResponse.filter((user: any)=> {
                    return  user.name.toLowerCase().includes(searchValue) || user.roleName.toLowerCase().includes(searchValue);
                });
            } else {
                return usersResponse;
            }
        },

        roomCount(): string {
            if(!this.role.roomIds || this.role.roomIds.includes('*')) {
                return '*';
            }

            return this.role.roomIds.length;
        },

        showTierCallout(): boolean {
            if(!tierState.orgTier.rolesEnabled) {
                return true;
            }
            return false;
        }
    },

    async created() {
        this.isLoading = true;

        //-- Open the correct tab automatically --
        if(this.$route.hash === '#members') {
            this.activeTab = 'members';
        }
    
        //-- State Store --
        roleState = getModule(RoleState);
        userState = getModule(UserState);
        roomState = getModule(RoomState);
        tierState = getModule(TierState);

        await Promise.all([
            userState.fetchUsers(),
            userState.fetchMe(null),
            roleState.fetchRoles(),
            roleState.fetchUserRole(),
            roomState.fetchRoomsDDL(),
            tierState.fetchTier()
        ]); 

        await this.loadAllRooms();

        

        if(!userState.me) {
            (this as any).$message.error("Error getting user information.");
        } else {
            this.myId = userState.me.id;
        }

        // Initialize empty role if creation mode
        if(this.creationMode)  {
            this.role = { roomIds: [], color: "#00a887", application: { billing: 0 } }

        // Otherwise get role from state store and make an editible copy
        } else {
            if(roleState.roles) {
                this.role = JSON.parse(JSON.stringify(roleState.roles.find((role: any) => role.id === this.$route.params.id)));
            } else {
                this.role = { roomIds: [], color: "#00a887", application: { billing: 0 } }
            } 
        }

        this.usersOfThisRole = (this as any).users.filter((user: any) => user.role == this.role.id);

        //-- Available users map for Add Members modal --
        const usersMap = (this as any).users.map((user: IUserWithRoleName) => {
            return {
                id: user.id,
                role: user.role,
                name: user.name,
                selected: false,
                roleName: user.roleName
            };
        });

        // Only show users of a different role and that are not the current user
        this.availableUsers = usersMap.filter((user: any) => user.role != this.role.id && user.id != this.myId);

        // Sort users alphabetically 
        this.availableUsers.sort((a: IUser, b: IUser) => a.name.localeCompare(b.name));
        
        this.assignedRooms = this.allRooms && this.role.roomIds ? this.allRooms.filter((x: any) => this.role.roomIds.includes(x.id)) : [] as IRoomDropDownList[];
        this.isLoading = false;
    },

    methods: {
        async loadAllRooms(): Promise<void> {
            const allRoom = {
              id: '*',
              roomName: 'All Rooms',
              type: 'Room'
            } as any;
            const rooms = (await roomState.fetchRoomsDDL()).data;
             
            if(rooms && !rooms.some(x => x.id == '*')) {
                rooms.unshift(allRoom);
            }

            this.allRooms = rooms ?? [];
        },
        async checkRooms(rule: any, value: any, callback: any)  {
            if(!this.role.roomIds.length) {
                callback(new Error('A Ditto room must be added to the role.'))

                await (this as any).$confirm('A Ditto room must be added to the role.', 'Warning', {
                    confirmButtonText: 'Ok',
                    showCancelButton: false,
                    type: 'warning'
                });
            } else {
                callback() 
            }
        },

        selectAllUsersHandler() {
            const inverseCheckboxValue = !this.isAllUsersChecked;
            this.filteredAvailableUsers.forEach((user: any)=>{
                user.selected = inverseCheckboxValue;
            });
        },

        async onAddMembersHandler() {
            const selectedMembers: IUser[] = this.availableUsers.filter((user: any)=> user.selected);
            
            if(selectedMembers.filter(x => x.role).length) {
                await (this as any).$confirm('Members currently assigned to another role will be removed and assigned to this role.', 'Warning', {
                    confirmButtonText: 'Continue',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                });
            }

            this.isAddMembersLoading = true;
            let failedUpdate = false;

            for (const member of selectedMembers) {
                member.role = this.role.id;
                let userUpdate = await userState.updateUser(member);
                if(!userUpdate.success) { failedUpdate = true; }
            }

            if(failedUpdate) {
                (this as any).$message.error('Error adding memebers.');
            }

            (this as any).$message.success('Members added.');

            this.usersOfThisRole = (this as any).users.filter((user: any) => user.role == this.role.id);

            this.isAddMembersLoading = false;
            this.isMemberSelectionModalVisible = false;
        },
        
        async onSaveHandler() {

            // Validate the form using custom validation rules for rooms
            const isValid = await (this.$refs['form'] as any).validate();
            if(!isValid) return;

            let storeResponse = null;
            if(this.creationMode) {
                this.role.organizationId = userState.me?.organization.id;
                storeResponse = await roleState.createRole(this.role);
            }
            else {
                storeResponse = await roleState.updateRole(this.role);
            }

            if(!storeResponse.success) {
                (this as any).$message.error(storeResponse.reason ? `Role ${this.creationMode? 'creation' : 'update'} failed: ${storeResponse.reason}` : `Role ${this.creationMode? 'creation' : 'update'} failed`);
                return;
            }

            if(this.creationMode) {
                (this as any).$message.success('Role has been created.');
                
                if(storeResponse.data) {  
                    this.role = storeResponse.data;
                    this.$router.push({ path: `/roles/${storeResponse.data.id}` });
                }
            } else {
                (this as any).$message.success('Role has been updated.');
            }

            PageDirtyService.markPageClean();

            return;
        },

        async roomSelectionChanged(selection: IModelSelectionPool[]) { 
            let selectedRoomIds = selection.filter((x) => x.selected).map((x) => x.id);

            this.role.roomIds = selectedRoomIds;
            this.assignedRooms = this.allRooms && this.role.roomIds ? this.allRooms.filter((x: any) => this.role.roomIds.includes(x.id)) : [] as IRoomDropDownList[];
            (this.$refs['form'] as any).validateField('rooms');
        },

        memberDialogClosedHandler() {
            //-- Clear search --
            this.membersListSearch = '';
            this.membersListFilter = '';
            //-- Clear selected users --
            this.availableUsers.forEach((user: any) => {
                user.selected = false;
            });
        },

        userSelectedHandler(user: any) {
            user.selected = !user.selected;
        },

        activateRoomManagementSelector() {
            if(!this.isAdmin) return;

            if(!this.allRooms) return [];

			const availableRoomsMap = this.allRooms.map((x: any) => {
                return {
                    id: x.id,
                    name: x.roomName,
                    selected: this.role.roomIds.includes(x.id),
                };
            });

			(this.$refs as any).modelSelector.activate(availableRoomsMap);
		},

        changeDetection() {
            let id = this.$route.params.id;
            // we're creating but we've already saved because we have an ID
            if(this.role.id && this.creationMode) {
                id = this.role.id;
            }

            let role = {};
            if(!this.role.id && this.creationMode)  {
                role = { roomIds: [], color: "#00a887", application: { billing: 0 } }

            // Otherwise get role from state store and make an editible copy
            } else {
                if(roleState.roles) {
                    role = JSON.parse(JSON.stringify(roleState.roles.find((role: any) => role.id === id)));
                } else {
                    role = { roomIds: [], color: "#00a887", application: { billing: 0 } }
                } 
            }

            PageDirtyService.monitorObjectSet([
                {defaultObject: role, mutatedObject: this.role}
            ]);
        }
    }
})
