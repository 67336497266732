
import axios from 'axios';
import { defineComponent } from 'vue';
import { getModule } from 'vuex-module-decorators';
import { getAuthInstance } from '../../auth';
import { IOrganization } from '../../store/interfaces/IOrganization';
import UserState, { IUserState } from '../../store/modules/users';
import RoleState, { IRoleState } from '../../store/modules/role';

let userState = {} as IUserState;
let roleState = {} as IRoleState;

export default defineComponent ({
    name: 'UserSwap',
    data() {
        return {
            orgToEmail: '' as string,
            loading: true as boolean,
            userConfirmEmail: '' as string,
            orgConfirm: null as IOrganization | null,
            switchResponse: '' as string,
            reset: false as boolean
        }
    },

    methods: {
        async orgLookup() {
            if(this.orgToEmail === 'reset' && userState.me) {
                this.reset = true;
                console.log(userState.me);
                this.userConfirmEmail = userState.me.email;
                this.orgConfirm = {id: (userState.me as any).resetOrg, name: "Return to creation org"} as IOrganization;
                return;
            }
            else {
                this.reset = false;
            }

            const authHeader = await getAuthInstance().getAxiosHeader();
            try{
                const orgResponse = await axios.get(`${process.env.VUE_APP_API_HOST}/api/users/orglookup/${this.orgToEmail}`, authHeader);
                
                console.log(orgResponse)
                this.userConfirmEmail = this.orgToEmail;
                this.orgConfirm = orgResponse.data;
            }
            catch(e: any) {
                let message = e;
                if(e.response && e.response.data) {
                    message = e.response.data;
                }
                (this as any).$message.error(message);
            }
        },
        async updateUser() {
            const authHeader = await getAuthInstance().getAxiosHeader();
            if(!this.orgConfirm || !this.orgConfirm.id) {
                this.switchResponse = 'No org to switch to';
                return;
            }

            let orgId = this.reset ? 'reset' : this.orgConfirm.id;

            const switchResponse = await axios.post(`${process.env.VUE_APP_API_HOST}/api/users/switchorganization/`, {orgId:orgId}, authHeader);

            this.switchResponse = switchResponse.data;


            (this as any).$message.success(this.switchResponse);
        }
    },

    async created() {
        userState = getModule(UserState);
        roleState = getModule(RoleState);

        await Promise.all([
            userState.fetchMe(null),
            roleState.fetchUserRole()
        ]); 

        if(!userState.me || !userState.me.email || !userState.me.email.endsWith('+support@airsquirrels.com')) {
            this.$router.push({ path: `/` })
            return;
        }

        this.loading = false;
    }
})
