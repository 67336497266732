import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38cf3da5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "subscription-status-progress d-flex flex-column flex-sm-row justify-content-between justify-content-xl-start align-items-sm-center" }
const _hoisted_2 = { class: "primary-color" }
const _hoisted_3 = {
  key: 0,
  class: "subscription-status-progress__text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_progress = _resolveComponent("el-progress")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["subscription-status-progress__container", _ctx.dashboardMode ? 'subscription-status-progress__label--dashboard my-4' : 'subscription-status-progress__label me-sm-2'])
    }, [
      _createElementVNode("strong", _hoisted_2, _toDisplayString(_ctx.formattedSubscriptionStatusText), 1),
      _createVNode(_component_el_progress, {
        percentage: _ctx.percentage,
        type: "line",
        class: "subscription-status-progress__progress",
        "show-text": false,
        "text-inside": false,
        "stroke-width": _ctx.dashboardMode ? 30 : 18
      }, null, 8, ["percentage", "stroke-width"])
    ], 2),
    _createElementVNode("div", null, [
      (!_ctx.dashboardMode)
        ? (_openBlock(), _createElementBlock("em", _hoisted_3, _toDisplayString(_ctx.receiverMessage), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}