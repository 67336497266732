import {
    Action,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';

import store from '../../store';
import { IEmergencyAlertServer } from '../interfaces/IEmergencyAlertServer';
import { getAuthInstance } from '../../auth';
import axios from 'axios';
import { ISqStoreActionResponse } from '../interfaces/ISqStoreActionResponse';

export interface IEmergencyAlertServersState {
    readonly allEmergencyAlertServers: IEmergencyAlertServer[] | undefined;
    readonly serverById: (id: string) => IEmergencyAlertServer | undefined;
    
    fetchEmergencyAlertServers(): Promise<ISqStoreActionResponse<void>>;
    getRecentAlerts(emergencyAlertServer: IEmergencyAlertServer): Promise<ISqStoreActionResponse<any>>;
    createAlertServer(emergencyAlertServer: IEmergencyAlertServer): Promise<ISqStoreActionResponse<IEmergencyAlertServer>>;
    updateAlertServer(emergencyAlertServer: IEmergencyAlertServer): Promise<ISqStoreActionResponse<void>>;
    deactivateAlertServer(emergencyAlertServer: IEmergencyAlertServer): Promise<ISqStoreActionResponse<void>>;
}

@Module({ dynamic: true, store, name: 'emergencyAlertServers' })
export default class EmergencyAlertServersState extends VuexModule implements IEmergencyAlertServersState {

    // THESE HAVE TO BE NAMED UNIQUELY
    // THE WAY STATE WORKS IF YOU ALTER THIESE VIA CONTEXT 
    // ANY NAMED THE SAME WAY WILL CHANGE ALSO
    private emergencyAlertServers: IEmergencyAlertServer[] = [];
    private isEmergencyAlertServersLoaded = false;

    //#region GETTERS
    get allEmergencyAlertServers(): IEmergencyAlertServer[] {
       const servers: IEmergencyAlertServer[] = [...this.emergencyAlertServers.map((x)=>Object.assign({}, x))];
       return servers? servers : [] as IEmergencyAlertServer[];
    }

    get serverById(): (id: string) => IEmergencyAlertServer | undefined {
        return (id: string) => {
            if (!this.isEmergencyAlertServersLoaded) return undefined;
            const server = this.allEmergencyAlertServers.find((x: IEmergencyAlertServer) => x.id === id);
            return server ? Object.assign({}, server) : undefined;
        };
    }
    //#endregion


    //#region ACTIONS
    @Action
    async fetchEmergencyAlertServers(): Promise<ISqStoreActionResponse<void>> {
        
        if (this.isEmergencyAlertServersLoaded) return { success: true };

        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            const fetchEmergencyAlertServersUrl = `${process.env.VUE_APP_API_HOST}/api/emergencyalertservers`;
            const axiosResponse = await axios.get(fetchEmergencyAlertServersUrl, authHeader);
            
            this.context.commit('setEmergencyAlertServers', axiosResponse.data);
            this.context.commit('setIsEmergencyAlertServersLoaded', true);
            return { success: true };
        }
        catch(e:any) {
            console.error('Emergency Alert Servers Store Error:', e);
        }

        return { success: false, reason: 'Error fetching emergency alert servers.' };
    }

    //#region ACTIONS
    @Action
    async getRecentAlerts(emergencyAlertServer: IEmergencyAlertServer): Promise<ISqStoreActionResponse<any>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            
            const getRecentAlertsUrl = `${process.env.VUE_APP_API_HOST}/api/emergencyalertservers/recentalerts`;
            const axiosResponse = await axios.post(getRecentAlertsUrl, emergencyAlertServer, authHeader);
            
            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Emergency Alert Servers Store Error:', e);
        }

        return { success: false, reason: 'Error getting recent alerts.' };
    }

    //#region ACTIONS
    @Action
    async createAlertServer(emergencyAlertServer: IEmergencyAlertServer): Promise<ISqStoreActionResponse<IEmergencyAlertServer>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            const createEmergencyAlertServersUrl = `${process.env.VUE_APP_API_HOST}/api/emergencyalertservers`;
            const axiosResponse = await axios.post(createEmergencyAlertServersUrl, emergencyAlertServer, authHeader);
            
            if(this.isEmergencyAlertServersLoaded) {
                this.context.commit('setEmergencyAlertServers', [...this.emergencyAlertServers, axiosResponse.data]);
            }
            
            return { success: true, data: axiosResponse.data }
        }
        catch(e:any) {
            console.error('Emergency Alert Servers Store Error:', e);
        }

        return { success: false, reason: 'Error creating emergency alert server.' };
    }

    //#region ACTIONS
    @Action
    async updateAlertServer(emergencyAlertServer: IEmergencyAlertServer): Promise<ISqStoreActionResponse<void>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            
            const updateEmergencyAlertServersUrl = `${process.env.VUE_APP_API_HOST}/api/emergencyalertservers/${ emergencyAlertServer.id }`;
            const axiosResponse = await axios.put(updateEmergencyAlertServersUrl, emergencyAlertServer, authHeader);
            
            const mutatedEmergencyAlertServers = [
                ...this.emergencyAlertServers.filter(x=>x.id !== emergencyAlertServer.id),
                axiosResponse.data
            ];

            this.context.commit('setEmergencyAlertServers', mutatedEmergencyAlertServers);
            
            return { success: true };
        }

        catch(e:any) {
            console.error('Emergency Alert Servers Store Error:', e);
        }
        
        return { success: false, reason: 'Error creating emergency alert server.' };
    }

    //#region ACTIONS
    @Action
    async deactivateAlertServer(emergencyAlertServer: IEmergencyAlertServer): Promise<ISqStoreActionResponse<void>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            
            const deleteEmergencyAlertServersUrl = `${process.env.VUE_APP_API_HOST}/api/emergencyalertservers/${ emergencyAlertServer.id }`;
            await axios.delete(deleteEmergencyAlertServersUrl, authHeader);
            
            emergencyAlertServer.is_active = false;
            const mutatedEmergencyAlertServers = [
                ...this.emergencyAlertServers.filter(x=>x.id !== emergencyAlertServer.id),
                emergencyAlertServer
            ];

            this.context.commit('setEmergencyAlertServers', mutatedEmergencyAlertServers);

            return { success: true };
        }

        catch(e:any) {
            console.error('Emergency Alert Servers Store Error:', e);
        }

        return { success: false, reason: 'Error deleting emergency alert server.' };
    }

    //#region MUTATIONS
    @Mutation
    async setEmergencyAlertServers(emergencyAlertServers: IEmergencyAlertServer[]): Promise<void> {
        this.emergencyAlertServers = emergencyAlertServers;
    }

    @Mutation
    async setIsEmergencyAlertServersLoaded(isLoaded: boolean): Promise<void> {
        this.isEmergencyAlertServersLoaded = isLoaded;
    }
    //#endregion
}