import {
    Action,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';

import store from '../../store';

import { getAuthInstance, IAuthService } from '../../auth';
import axios, { AxiosRequestConfig } from 'axios';
import { ISqStoreActionResponse } from '../interfaces/ISqStoreActionResponse';
import { IUser } from '../interfaces/IUser';
import { ISubscriptionStatus } from '../interfaces/ISubscriptionStatus';
import { IUserCreateModel } from '../interfaces/IUserCreateModel';
import { IUserRegistrationModel } from '../interfaces/IUserRegistrationModel';
import { IPurchaseOrderUser } from '../interfaces/IPurchaseOrderUser';
import { IModelSearchResponse, IUserModelSearch } from '../interfaces/IModelSearch';
import { IAuth0UserData } from '../interfaces/IAuth0UserData';

export interface IFetchMeInupt {
    authInstance?: IAuthService;
    reload?: boolean;
}

export interface IUserState {
    readonly allUsers: IUser[] | undefined;
    readonly userById: (id: string) => IUser | undefined;
    readonly me: IUser | undefined;
    readonly subscriptionStatus: ISubscriptionStatus | undefined;

    fetchMe(input?: IFetchMeInupt | null): Promise<ISqStoreActionResponse<IUser>>;
    reloadMe(): Promise<ISqStoreActionResponse<IUser>>;
    fetchUsers(userId?: string): Promise<ISqStoreActionResponse<void>>;
    fetchSubscriptionStatus(forceUpdate?: boolean): Promise<ISqStoreActionResponse<ISubscriptionStatus>>;
    searchUsers(userSearch: IUserModelSearch): Promise<ISqStoreActionResponse<IModelSearchResponse<IUser[]>>>;
    updateMe(me: IUser): Promise<ISqStoreActionResponse<IUser>>;
    updateUser(user: IUser): Promise<ISqStoreActionResponse<IUser>>;
    deleteUser(user: IUser): Promise<ISqStoreActionResponse<IUser>>;
    resetPassword(user: IUser): Promise<ISqStoreActionResponse<IUser>>;
    resendEmailVerification(userId: string): Promise<ISqStoreActionResponse<null>>;
    createUser(newUser: IUserCreateModel): Promise<ISqStoreActionResponse<IUser>>;
    registerUser(registerUser: IUserRegistrationModel): Promise<ISqStoreActionResponse<IUserRegistrationModel>>;
    requestPurchaseOrder(requestPOUser: IPurchaseOrderUser): Promise<ISqStoreActionResponse<void>>;
    fetchAuth0UserData(userId: string): Promise<ISqStoreActionResponse<IAuth0UserData>>;
    updateAuth0Signature (userId: string): Promise<ISqStoreActionResponse<void>>;
    enableReleaseNotification(): Promise<ISqStoreActionResponse<void>>;
}

@Module({ dynamic: true, store, name: 'users' })
export default class UserState extends VuexModule implements IUserState {
    private USERS: IUser[] = [];
    private ME: IUser = null as any;
    private SUBSCRIPTION_STATUS: ISubscriptionStatus = null as any;
    private isUsersLoaded = false;

    //#region GETTERS
    get allUsers(): IUser[] {
        return [...this.USERS.map((x)=>Object.assign({}, x))];
    }

    get userById(): (id: string) => IUser | undefined {
        return (id: string) => {
            if (!this.isUsersLoaded) return undefined;
            const user = this.USERS.find(x => x.id === id);

            return user ? Object.assign({}, user) : undefined;
        };
    }

    get me(): IUser | undefined {
        return this.ME ? Object.assign({}, this.ME) : undefined
    }

    get subscriptionStatus(): ISubscriptionStatus | undefined {
        return this.SUBSCRIPTION_STATUS ? Object.assign({}, this.SUBSCRIPTION_STATUS) : undefined
    }

    //#endregion

    //#region ACTIONS
    @Action
    async fetchMe(input: IFetchMeInupt | null = null): Promise<ISqStoreActionResponse<IUser>> {
        if(!input || input && !input.reload) {
            if (this.ME) return { success: true, data: Object.assign({}, this.ME) };
        }

        try {
            let authHeader: AxiosRequestConfig;
            if(input && input.authInstance) {
                authHeader = await input.authInstance.getAxiosHeader();
            }
            else {
                authHeader = await getAuthInstance().getAxiosHeader();
            }

            const fetchUsersUrl = `${process.env.VUE_APP_API_HOST}/api/users/me`;
            const axiosResponse = await axios.get(fetchUsersUrl, authHeader);

            this.context.commit('setMe', axiosResponse.data);

            return { success: true, data: Object.assign({}, this.ME) };
        }
        catch(e:any) {
            if(e.response && e.response.data) {
                console.error('Users Store Error:', e.response);
            }
            console.error('Users Store Error:', e);

        }

        return { success: false, reason: 'Authentication validation error. Please contact support.' };
    }

    @Action
    async reloadMe(): Promise<ISqStoreActionResponse<IUser>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
        
            const fetchUsersUrl = `${process.env.VUE_APP_API_HOST}/api/users/me`;
            const axiosResponse = await axios.get(fetchUsersUrl, authHeader);

            this.context.commit('setMe', axiosResponse.data);

            return { success: true, data: Object.assign({}, this.ME) };
        }
        catch(e:any) {
            console.error('Users Store Error:', e);
        }

        return { success: false, reason: 'Authentication validation error. Please contact support.' };
    }

    @Action
    async fetchUsers(userId?: string): Promise<ISqStoreActionResponse<void>> {
        let user;
        if(userId) user = this.userById(userId)
        if (this.isUsersLoaded && user) return { success: true };

        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            
            let fetchUsersUrl = `${process.env.VUE_APP_API_HOST}/api/users/`;

            if(userId) {
                fetchUsersUrl = `${fetchUsersUrl}/${userId}`;
            }

            const axiosResponse = await axios.get(fetchUsersUrl, authHeader);

            const users = userId ? [axiosResponse.data] : axiosResponse.data

            this.context.commit('setUsers', users);
            this.context.commit('setIsLoaded', true);
            return { success: true };
        }
        catch(e:any) {
            console.error('Users Store Error:', e);
        }

        return { success: false, reason: 'Error fetching users.' };
    }

    @Action 
    async fetchSubscriptionStatus(forceUpdate = false): Promise<ISqStoreActionResponse<ISubscriptionStatus>> {
        if (!forceUpdate && this.SUBSCRIPTION_STATUS) return { success: true, data: Object.assign({}, this.SUBSCRIPTION_STATUS) };

        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            
            const fetchUsersUrl = `${process.env.VUE_APP_API_HOST}/api/subscriptions/getSubscriptionStatus`;
            const axiosResponse = await axios.get(fetchUsersUrl, authHeader);

            this.context.commit('setSubscriptionStatus', axiosResponse.data);

            return { success: true, data: Object.assign({}, this.SUBSCRIPTION_STATUS) };
        }
        catch(e:any) {
            console.error('Users Store Error:', e);
        }

        return { success: false, reason: 'Error fetching me.' };
    }

    @Action
    async fetchAuth0UserData(userId: string): Promise<ISqStoreActionResponse<IAuth0UserData>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            
            const fetchUsersUrl = `${process.env.VUE_APP_API_HOST}/api/users/auth0userdatalookup/${userId}`;
            const axiosResponse = await axios.get(fetchUsersUrl, authHeader);

            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Users Store Error:', e);
        }

        return { success: false, reason: 'Error fetching auth data.' };
    }

    @Action
    async searchUsers(userSearch: IUserModelSearch): Promise<ISqStoreActionResponse<IModelSearchResponse<IUser[]>>>{
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            let fetchUserUrl = `${process.env.VUE_APP_API_HOST}/api/users`;

            if(userSearch.pageIndex && userSearch.pageSize) {
                fetchUserUrl = `${fetchUserUrl}?pageIndex=${userSearch.pageIndex}&pageSize=${userSearch.pageSize}`;

                if(userSearch.name){
                    fetchUserUrl = `${fetchUserUrl}&name=${userSearch.name}`;
                } 
                
                if(userSearch.email) {
                    fetchUserUrl = `${fetchUserUrl}&email=${userSearch.email}`;
                }

                if(userSearch.organizationId) {
                    fetchUserUrl = `${fetchUserUrl}&organizationId=${userSearch.organizationId}`;
                }
            }

            const axiosResponse = await axios.get(fetchUserUrl, authHeader);

            // console.log(axiosResponse)

            return { success: true, data: {itemsCount: axiosResponse.data.itemsCount, data: axiosResponse.data.data} as IModelSearchResponse<IUser[]> };
        }
        catch(e:any) {
            console.error('Organization Store Error:', e);
        }

        return { success: false, reason: 'Error searching organizations.' };
    }


    @Action
    async updateMe(me: IUser): Promise<ISqStoreActionResponse<IUser>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
        
            const updateUserUrl = `${process.env.VUE_APP_API_HOST}/api/users/${me.id}`;
            const axiosResponse = await axios.put(updateUserUrl, me, authHeader);

            this.context.commit('setMe', axiosResponse.data);

            return { success: true, data: Object.assign({}, this.ME) };
        }
        catch(e:any) {
            console.error('Users Store Error:', e);
        }

        return { success: false, reason: 'Error updating user.' };
    }

    @Action
    async updateUser(user: IUser): Promise<ISqStoreActionResponse<IUser>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
        
            const updateUserUrl = `${process.env.VUE_APP_API_HOST}/api/users/${user.id}`;
            const axiosResponse = await axios.put(updateUserUrl, user, authHeader);
            const mutatedUsers = [
                ...this.USERS.filter(x=>x.id !== user.id),
                axiosResponse.data
            ];
            if(this.ME.id === axiosResponse.data.id) {
                this.context.commit('setMe', axiosResponse.data)
            }
            this.context.commit('setUsers', mutatedUsers);
            return { success: true, data: Object.assign({}, axiosResponse.data) };
        }
        catch(e:any) {
            console.error('Users Store Error:', e);
            if(e.response && e.response.data) {
                return { success: false, reason: e.response.data };        
            }
        }
        return { success: false, reason: 'Error updating user.' };
    }

    @Action
    async updateAuth0Signature (userId: string): Promise<ISqStoreActionResponse<void>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            
            const fetchUsersUrl = `${process.env.VUE_APP_API_HOST}/api/users/updateauth0signature/${userId}`;
            await axios.post(fetchUsersUrl, null, authHeader);

            return { success: true };
        }
        catch(e:any) {
            console.error('Users Store Error:', e);
        }

        return { success: false, reason: 'Error generation signature.' };
    }

    @Action
    async deleteUser(user: IUser): Promise<ISqStoreActionResponse<IUser>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
        
            const deleteUserUrl = `${process.env.VUE_APP_API_HOST}/api/users/${user.id}`;
            await axios.delete(deleteUserUrl, authHeader);

            const mutatedUsers = [
                ...this.USERS.filter(x=> !(x.id.includes(user.id)))
            ];
            this.context.commit('setUsers', mutatedUsers);

            return { success: true, data: Object.assign({}, user) };
        }
        catch(e:any) {
            console.error('Users Store Error:', e);
            if(e.response && e.response.data) {
                return { success: false, reason: e.response.data };        
            }
        }
        return { success: false, reason: 'Error updating user.' };
    }
    
    @Action
    async resendEmailVerification(userId: string): Promise<ISqStoreActionResponse<null>> {
        try {
            const resendVerificationUrl = `${process.env.VUE_APP_API_HOST}/api/users/${userId}/send/verification`;
            const axiosResponse = await axios.get(resendVerificationUrl);

            return { 
                success: axiosResponse.status === 200, 
                reason: axiosResponse.status !== 200 ? axiosResponse.data : null
            };
        }
        catch(e:any) {
            // don't think we need to pass anything here
        }

        return { success: false, reason: 'Failed to send verification email.' };
    }

    @Action
    async createUser(newUser: IUserCreateModel): Promise<ISqStoreActionResponse<IUser>> {       
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
           
            const createUserUrl = `${ process.env.VUE_APP_API_HOST }/api/users`;   
            const axiosResponse = await axios.post(createUserUrl, newUser, authHeader);
            
            if(this.isUsersLoaded) {
                this.context.commit('setUsers', [...this.USERS, axiosResponse.data]);
            }
            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Users Store Error:', e);
            if(e.response && e.response.data) {
                return { success: false, reason: e.response.data };        
            }
        }

        return { success: false, reason: 'Error creating user.'};
    }

    @Action
    async resetPassword(user: IUser): Promise<ISqStoreActionResponse<IUser>> { 
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
        
            const resetPasswordUrl = `${process.env.VUE_APP_API_HOST}/api/users/${user.id}/password/reset`;

            const axiosResponse = await axios.get(resetPasswordUrl, authHeader);
            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Users Store Error:', e);
        }

        return { success: false, reason: 'Error resetting user password.' }
    }

    @Action
    async registerUser(registerUser: IUserRegistrationModel): Promise<ISqStoreActionResponse<IUserRegistrationModel>> {
        let errResponse = 'Error registering user.';
        
        try {
            const registerUserUrl = `${ process.env.VUE_APP_API_HOST }/api/users/registration`;   
            const axiosResponse = await axios.post(registerUserUrl, registerUser);
            
            if(this.isUsersLoaded) {
                this.context.commit('setUsers', [...this.USERS, axiosResponse.data]);
            }
            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Users Store Error:', e);
            errResponse = e.response;
        }

        return { success: false, reason: errResponse};
    }

    @Action
    async requestPurchaseOrder(requestPOUser: IPurchaseOrderUser): Promise<ISqStoreActionResponse<void>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            const requestPOUrl = `${ process.env.VUE_APP_API_HOST }/api/users/requestpo`;   
            const axiosResponse = await axios.post(requestPOUrl, requestPOUser, authHeader);
            
            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Users Store Error:', e);
        }

        return { success: false, reason: 'Error requesting purchase order.'};
    }

    @Action
    async enableReleaseNotification(): Promise<ISqStoreActionResponse<void>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
        
            const enableReleaseNotificationUrl = `${process.env.VUE_APP_API_HOST}/api/users/enablereleasenotification`;
            const axiosResponse = await axios.post(enableReleaseNotificationUrl, null, authHeader);

            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Users Store Error:', e);
        }

        return { success: false, reason: 'Release notification was not enabled for all users.' };
    }

    //#endregion

    //#region MUTATIONS

    @Mutation
    async setUsers(users: IUser[]): Promise<void> {
        this.USERS = users;
    }

    @Mutation
    async setMe(user: IUser): Promise<void> {
        this.ME = user;
    }

    @Mutation
    async setSubscriptionStatus(subscriptionStatus: ISubscriptionStatus): Promise<void> {
        this.SUBSCRIPTION_STATUS = subscriptionStatus;
    }

    @Mutation
    async setIsLoaded(isLoaded: boolean): Promise<void> {
        this.isUsersLoaded = isLoaded;
    }
    //#endregion


}
