
import { defineComponent } from 'vue';
import ReceiverState, { IReceiverState } from '../../store/modules/receivers';
import { getModule } from 'vuex-module-decorators'
import { IReceiver, IReceiverListDisplay } from '../../store/interfaces/IReceiver';
import { IRoom, IRoomDropDownList } from '../../store/interfaces/IRoom';
import RoomState, { IRoomState } from '../../store/modules/rooms';

let receiverState = {} as IReceiverState;
let roomState = {} as IRoomState;

export interface IReceiverWithAvailableRooms extends IReceiver {
    availableRooms: IRoomDropDownList[];
}

export default defineComponent({
    name: 'QuickLink',
    data() {
        return {
            receivers: [] as IReceiverWithAvailableRooms[],
            viewLoading: true,
            roomToLink: null as string | null,
            availableReceivers: [] as IReceiverListDisplay[]
        }
    },
    computed: {
    },
    methods: {
        async handleSelection(receiverId: string) {
            this.viewLoading = true;

            if(!this.roomToLink) return;
            const roomStoreAddResponse = await roomState.addReceivers({roomId: this.roomToLink, receiverIds: [receiverId]});
            if(!roomStoreAddResponse.success) {
                (this as any).$message.error(roomStoreAddResponse.reason ? `Failed to add receiver to room: ${roomStoreAddResponse.reason}` : 'Failed to add receiver to room');
                return;
            }

            await (this as any).$message.success('Receiver added to room');

            await this.getAvailableRoomsForReceiver();
            this.roomToLink = null;
            this.viewLoading = false;
        },
        async getAvailableRoomsForReceiver() {
            this.receivers = [];

            const receiversWithOutRoomsResponse = await receiverState.fetchReceiverDisplayList({pageIndex:1, pageSize: 10000, isActive: 'false' });
            const availableReceivers = receiversWithOutRoomsResponse.success && receiversWithOutRoomsResponse.data && receiversWithOutRoomsResponse.data.data ? receiversWithOutRoomsResponse.data.data : [] as IReceiverListDisplay[];
            
            for (const receiver of availableReceivers) {
                
                const availableRoomsForReceiverRes = await roomState.fetchRoomsDDLAvailableForReceiver({
                    activeRoom: null, 
                    isSSP: receiver.deviceType == 'SSP',
                    reload: true
                });

                const availableRoomsForReceiver = availableRoomsForReceiverRes.data;
                
                const displayRoom = {
                    id: receiver.id,
                    displayName: receiver.displayName,
                    deviceType: receiver.deviceType?.replace(/SSP/g,'Ditto Receiver')?.replace(/DittoReceiver/g,'Ditto Receiver')?.replace(/Cast/g,'Legacy (Cast)')?.replace(/AirPlay/g,'Legacy (AirPlay)'),
                    hardwareType: receiver.platform,
                    platform: receiver.platform,
                    manualIpAddresses: receiver.manualIpAddresses,
                    lastKnownIpAddress: receiver.lastKnownIpAddress,
                    availableRooms: availableRoomsForReceiver
                } as IReceiverWithAvailableRooms

                if(receiver.deviceType == 'SSP' || receiver.deviceType == 'Ditto Receiver') {
                    displayRoom.deviceType = `Ditto Receiver - ${receiver.platform == 'windows' ? 'Windows' : 'tvOS'}`;
                }
                // else{
                //     if(receiver.platform == 'ATV3'){
                //         displayRoom.deviceType = 'Apple TV 3/2';
                //     }
                //     else if(receiver.platform == 'ATV4'){
                //         displayRoom.deviceType = 'Apple TV HD/4K';
                //     }
                //     else if(receiver.platform == 'Other'){
                //         displayRoom.deviceType = 'Other - ' + receiver.deviceType;
                //     }
                //     else {
                //         displayRoom.deviceType = receiver.platform;
                //     }
                // }

                this.receivers.push(displayRoom);
            }
        },

        noRoomsAvailable(receiverRooms: IRoom[]): boolean {
            if(receiverRooms && receiverRooms.length == 0) {return true}
            return false;
        },

        noRoomsAvailablePlaceholder(receiverRooms: IRoom[]): string {
            if(receiverRooms && receiverRooms.length == 0) {return 'No Rooms Available'}
            return 'Select Room to Link';
        },
    },
    async created () {
        receiverState = getModule(ReceiverState);
        roomState = getModule(RoomState);
        await this.getAvailableRoomsForReceiver();

        this.viewLoading = false;
    }
});

