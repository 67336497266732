
import { defineComponent } from 'vue';
import { IReceiver, IDeviceIdentifier } from '../../store/interfaces/IReceiver';
import { mdiKeyboardBackspace, mdiHelpCircle, mdiArrowLeft } from '@mdi/js';
import Icon from '../../components/Icon.vue';

export default defineComponent({
    name: 'NewReceiverFinalPairingView',
    props: ['newReceiverModel'],
    data () {
        return {
            domLoaded: false as boolean,
            newReceiver: {} as IReceiver,

            // FORMS
            // -- pairing code --
            pairingCodeForm: {
                pairingCode: '' as string
            },
            pairingCodeRules: {
                pairingCode: [
                    {
                        required: true,
                        message: 'Please enter a pairing code',
                        trigger: 'blur'
                    },
                ],
            },

            // -- pairing key form --
            generatePairingKeyForm: {
                receiverName: '' as string
            },
            
            generatePairingKeyRules: {
                receiverName: [
                    {
                        required: true,
                        message: 'Please enter a receiver name.',
                        trigger: 'blur'
                    },
                ],
            },
            

            // -- other --
            // same as legcy but with connectionType added
            legacyOtherForm: {
                receiverName: '' as string,
                ipAddress: '' as string,
                macAddress: '' as string,
                connectionType: {
                    value: '' as string,
                    label: '' as string
                },
            },
            legacyOtherRules: {
                receiverName: [
                    {
                        required: true,
                        message: 'Please enter a receiver name.',
                        trigger: 'blur'
                    },
                ],
                ipAddress: [
                    {
                        required: true,
                        message: 'Please enter an IP address.',
                        trigger: 'blur'
                    },
                ],
                macAddress: [
                    {
                        required: true,
                        message: 'Please enter a MAC address.',
                        trigger: 'blur'
                    },
                ],
                connectionType: [
                    // Custom validator function needed for connectionType object
                    {
                        validator: (rule: any, connectionType: any, callback: any)=> {
                            if(!connectionType.value) {
                                callback(new Error('Please select a connection type.'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'blur'
                    },
                ],
            },
            connectionTypes: [{
                value: 'AirPlay',
                label: 'AirPlay'
            },
            {
                value: 'Cast',
                label: 'Cast'
            }],
            mdiKeyboardBackspace,
            mdiHelpCircle,
            mdiArrowLeft
        }
    },

     computed: {
        
        showCreateReceiverClickTarget(): boolean {
            if(this.newReceiver.hardwareType == 'Other') {
                if(this.legacyOtherForm.connectionType.value && this.legacyOtherForm.receiverName && this.legacyOtherForm.ipAddress && this.legacyOtherForm.macAddress) return true;
                return false;
            } 

            if(this.legacyOtherForm.receiverName && this.legacyOtherForm.ipAddress && this.legacyOtherForm.macAddress) return true;
            return false;

        },

        helpLink(): string {
            let link = '';
            switch (this.newReceiver.hardwareType) {
                case 'ATV3':
                    link = 'https://help.airsquirrels.com/ditto/340-ditto-is-compatible-with-apple-tv-4k-apple-tv-hd-apple-tv-3-and-apple-tv-2';
                    break;

                case 'Chromecast':
                    link = 'https://help.airsquirrels.com/ditto/342-how-to-add-a-ditto-receiver-chromecast';
                    break;
            
                default:
                    link = 'https://help.airsquirrels.com/ditto/getting-started#room-and-receiver-set-up';
            }
            return link;
        }
    },
    inject: ['userOnboardStatus','currentStep'],
    mounted() {
        this.newReceiver = Object.assign({}, this.newReceiverModel);
    },

    updated() {
        this.$nextTick(()=> {
            this.domLoaded = true;
        });
    },

    components: {
        Icon
    },

    methods: {

        onPreviousStep() {
            this.$emit('final-pairing', null, null);
        },

       async onCreateReceiver() {
            if(!this.newReceiver) return;

            (this as any).$refs['legacyOtherForm'].validate((valid: any) => {
                if(valid) {
                    this.newReceiver.manualIpAddresses = [this.legacyOtherForm.ipAddress];
                    this.newReceiver.identifiers = {mac: this.legacyOtherForm.macAddress} as IDeviceIdentifier;
                    this.newReceiver.displayName = this.legacyOtherForm.receiverName;
                    this.newReceiver.deviceType = this.legacyOtherForm.connectionType.value ? this.legacyOtherForm.connectionType.value : this.newReceiver.deviceType;
                    this.$emit('final-pairing', null, this.newReceiver);
                } else {
                    (this as any).$message.error("Please fill out all receiver information.");
                }
            });
        },


        async onPair () {
            (this as any).$refs['pairingCodeForm'].validate((valid: any) => {
                if(valid) {
                    this.$emit('final-pairing', this.pairingCodeForm.pairingCode, null);
                }
            });
        },

        async generatePairingKey() {
            if(!this.newReceiver) return;

            (this as any).$refs['generatePairingKeyForm'].validate((valid: any)=> {
                if(valid) {
                    const hashDate = new Date();
                    this.newReceiver = {
                        deviceType: 'SSP',
                        hardwareType: 'DittoReceiver',
                        displayName: this.generatePairingKeyForm.receiverName,
                        identifiers: {mac:`#${hashDate.getTime()}`} as IDeviceIdentifier,
                        manualIpAddresses: [`#${hashDate.getTime()}`],
                        platform:'windows'
                    } as IReceiver
                    this.$emit('final-pairing', '#', this.newReceiver);
                }
            });

            
        }
    }
});

