
import { defineComponent } from 'vue';
import moment from "moment";

export default defineComponent({
    name: "AlertEditorDetailView" as string,
    data() {
        return {
            editingName: false,
            autoEvent: true,
            alertInfo: {}
        };
    },
    
    computed: {
    
    },

    props: {
    },
    
    methods: {
        activate(alertDetails: any) {
            this.alertInfo = Object.assign([], alertDetails);
        },

        testIndentifier: function() {
            var S4 = function() {
                return (((1 + Math.random()) * 0x10000) | 0)
                    .toString(16)
                    .substring(1);
            };
            return (
                S4() +
                S4() +
                "-" +
                S4() +
                "-" +
                S4() +
                "-" +
                S4() +
                "-" +
                S4() +
                S4() +
                S4()
            );
        },

        formatDateToDisplayInUTC(dateString: any) {
            let date = new Date(dateString);
            return moment(date)
                .utc()
                .format("llll");
        }
    }
});
