import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3be0f33a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "signage__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SignageLists = _resolveComponent("SignageLists")!
  const _component_TierLimit = _resolveComponent("TierLimit")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SignageLists, { onSigangeListSelected: _ctx.sigangeListSelected }, null, 8, ["onSigangeListSelected"]),
    _createVNode(_component_TierLimit, {
      modelValue: _ctx.showTierCallout,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showTierCallout) = $event)),
      isCallout: false,
      isLimit: false,
      message: "Upgrade your Ditto account to unlock signage."
    }, null, 8, ["modelValue"])
  ]))
}