import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b672738"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "reports-graph" }
const _hoisted_2 = { class: "reports-graph__header" }
const _hoisted_3 = { class: "reports-graph__heading" }
const _hoisted_4 = { class: "reports-graph__title" }
const _hoisted_5 = {
  key: 0,
  class: "reports-graph__sum-label"
}
const _hoisted_6 = ["id", "height", "width"]
const _hoisted_7 = {
  key: 0,
  class: "reports-graph__no-reports-overlay"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.title), 1),
        (!_ctx.noStats && _ctx.enableSumLabel)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.sumLabel), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("p", null, _toDisplayString(_ctx.subTitle), 1)
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: "col-sm-12 reports-graph__canvas-container",
      style: _normalizeStyle({ minHeight: _ctx.height + 'px' })
    }, [
      _createElementVNode("canvas", {
        id: _ctx.chartId,
        height: _ctx.height,
        width: _ctx.width
      }, null, 8, _hoisted_6),
      (_ctx.noStats)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("p", null, [
              _createTextVNode("No report data to display for "),
              _createElementVNode("strong", null, _toDisplayString(_ctx.title), 1),
              _createTextVNode(".")
            ])
          ]))
        : _createCommentVNode("", true)
    ], 4)), [
      [_directive_loading, _ctx.loading || _ctx.innerLoading]
    ])
  ]))
}