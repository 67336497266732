
import { getModule } from 'vuex-module-decorators';
import { IOrganization } from '../../store/interfaces/IOrganization';
import UserState, { IUserState } from '../../store/modules/users';
import OrganizationState, { IOrganizationState } from '../../store/modules/organizations';
import { defineComponent } from 'vue';
import PageDirtyService from '../../services/PageDirtyService';

let userState = {} as IUserState;
let organizationState = {} as IOrganizationState;

export default defineComponent ({
    name: 'OrganizationCompany',
    data() {
        return {
            company: {} as IOrganization,
            viewLoading: true,
            saving: false,
            helpDeskContactDefault: 'Contact your organization\'s technical support team for assistance.' as string,
            rules:{
                name:[{required: true, message: 'Please enter an Organization name.', trigger: 'change'}]
            }
        }
    },
    methods: {
        validateForm (): Promise<boolean> {
            return new Promise((resolve) => {
                (this as any).$refs['form'].validate((valid: boolean, obj: any) => {
                    if(!valid) {
                        let errorHTML = '';
                        for(const key in obj) {
                            for(const err of obj[key]) {
                                errorHTML = `${errorHTML}${err.message}<br>`;
                            }
                        }
                        console.error(errorHTML);
                        (this as any).$message({
                            dangerouslyUseHTMLString: true,
                            message: errorHTML,
                            type: 'error'
                        });
                    }

                    resolve(valid);
                });
            });
        },
        async onSubmit() {
            if(!await this.validateForm()) return;
            this.saving = true;
            if(this.company.helpDeskContact == '') {
                this.company.helpDeskContact = this.helpDeskContactDefault;
            }

            const storeResponse = await organizationState.updateOrganization(this.company);
            if(!storeResponse.success) {
                (this as any).$message.error("Update Failed", storeResponse.reason);
                this.saving = false;
                return;
            }

            (this as any).$message.success('Organization updated.');

            await userState.fetchMe({reload:true});

            this.company = Object.assign({}, userState.me?.organization);
            this.saving = false;
        },

        async onCancel() {
            await (this as any).$confirm('Are you sure you wish to undo Company changes?', 'Warning', {
                confirmButtonText: 'Undo Changes',
                cancelButtonText: 'Cancel',
                type: 'warning'
            });
            if(!userState.me) return;
            this.company = Object.assign({}, userState.me.organization);
        }
    },
    async created() {
        userState = getModule(UserState);
        organizationState = getModule(OrganizationState);
        await userState.fetchMe(null);
        if(!userState.me) {
            console.error("Error getting user information.");
            return;
        }

        this.company = Object.assign({}, userState.me.organization);

        this.viewLoading = false;
    },
    watch: {
        company: {
            handler (e) {
                PageDirtyService.monitorObjectSet([{defaultObject: userState.me?.organization, mutatedObject: e}]);
            },
            deep: true
        }
    }
})
