
import { defineComponent } from 'vue';
import SubscriptionState, { ISubscriptionState } from '../../../store/modules/subscriptions';
import CardState, { ICardState } from '../../../store/modules/cards';
import { ICard } from '../../../store/interfaces/ICard';
import { ISubscriptionPlan } from '../../../store/interfaces/ISubscription';
import { getModule } from 'vuex-module-decorators';
import UserState, { IUserState } from '../../../store/modules/users';
import moment from 'moment';

let userState = {} as IUserState;
let subscriptionState = {} as ISubscriptionState;
let cardState: ICardState = null as any;

export default defineComponent({
    name: 'PaymentSummary',
    data() {
        return {
            subscriptionPlan: {} as ISubscriptionPlan
        }
    },

    props: ['expirationDate'],

    computed: {
        cardLogo(): string {
            if(!this.card) return 'mastercardLogo.png';

            if(this.card.brand === 'visa') {
                return 'visaLogo.png'
            }

            else if(this.card.brand === 'mastercard') {
                'mastercardLogo.png' 
            }

            return 'mastercardLogo.png'
        },

        card(): ICard | null {
            if(!cardState.allCards) return null
            return cardState.allCards[0]
        },

        cardBrand(): string {
            if(!this.card || !this.card.brand) return '';
            return this.card.brand.toLowerCase();
        },

        userSubscriptionStatus(): string {
            if (!userState.subscriptionStatus) return '';
            return userState.subscriptionStatus.type.toUpperCase();
        },

        planDaysRemaining(): string {
            const daysRemaining = moment.utc(this.expirationDate).diff(moment.utc(), 'days');
            if(daysRemaining <= 0) {
                return 'expired';
            }

            return `${daysRemaining.toString()} days remaining`;
        }
    },

    async created() {
        userState = getModule(UserState);
        subscriptionState = getModule(SubscriptionState);
        cardState = getModule(CardState);

        await Promise.all([
            userState.fetchSubscriptionStatus(),
            subscriptionState.fetchSubscriptions(),
            cardState.fetchCards()
        ]);
    }
});
