import { RouteLocation } from "vue-router";
import deepEqual from "deep-equal";
// import _ from "lodash";
//import KeyValueService from "./KeyValueService";

export interface IDataMonitorSet {
    defaultObject: any,
    mutatedObject: any
}

export interface IPageDirtyService {
    readonly isPageDirty: boolean;
    // eslint-disable-next-line @typescript-eslint/ban-types
    pageGuard(to: RouteLocation, from: RouteLocation, next: Function, guard: Function): Function
    markPageDirty(): void;
    markPageClean(): void
    setWindowListener(): void,
    monitorObjectSet(dataSet: IDataMonitorSet[]): boolean
}

let pageDirty = false;
let windowListenerSet = false;

export class PageDirtyService implements IPageDirtyService {
    get isPageDirty(): boolean {
        return pageDirty;
    }
    // eslint-disable-next-line @typescript-eslint/ban-types
    pageGuard(to: RouteLocation, from: RouteLocation, next: Function, guard: Function | null): any {
        // need to know what needs to call the authGuard/ unauthGuard/ or nothing and what does not
        if(pageDirty) {
            const confirmNavigation = window.confirm('You have unsaved changes. If you leave the page without saving, your changes will be lost.');

            if(!confirmNavigation) {
                return next(from);

            }
            else
            {
                pageDirty = false;
                next();
            }

        }

        if(guard) {
            return guard(to,from,next);
        }
        else {
            return next();
        }
    }

    setWindowListener(): void {

        if(windowListenerSet) return;

        windowListenerSet = true;
        window.addEventListener('beforeunload', (event) => {
            if(pageDirty) {
                event.preventDefault();
                event.returnValue = " ";
            }
        });
    }

    monitorObjectSet(dataSet: IDataMonitorSet[]): boolean {
        for(const set of dataSet) {
            // if both are null or undefined nothing has changed go to the next pair
            if(!set.mutatedObject && !set.defaultObject) continue;

            // if just one is null or undefined then something has changed
            if(!set.mutatedObject) {
                this.markPageDirty();
                return true;
            }

            if(!set.defaultObject) {
                this.markPageDirty();
                return true;
            }

            // both objects are something so now we need to compare them
            const dirty = !deepEqual(set.defaultObject, set.mutatedObject);

            if(dirty) {
                this.markPageDirty();
                return true;
            }
        }
        this.markPageClean();
        return false;
    }

    markPageDirty(): void {
        //console.log('page marked dirty');
        pageDirty = true;
    }

    markPageClean(): void {
        //console.log('page marked clean');
        pageDirty = false;
    }
}

export default new PageDirtyService;

