
import { defineComponent } from 'vue'
import axios from 'axios';
import textFit from 'textfit';

export default defineComponent({
    name: 'MenuItemLayout' as string,
    props: ['id', 'signature'],
    data: ()=>({
        item: {} as any
    }),

    mounted() {
        return axios.get(`${ process.env.VUE_APP_API_HOST }/api/signages/${this.id}/templaterequest/${this.signature}`).then((res) => {
            this.item = res.data;
            
        });
    },

    watch: {
        item() {
            this.$nextTick(() => {
                textFit(document.getElementsByClassName('textfit__header'), { minFontSize: 10, maxFontSize: 75, widthOnly: true, detectMultiLine: false });
                textFit(document.getElementsByClassName('textfit__title'), { minFontSize: 10, maxFontSize: 130, widthOnly: true, detectMultiLine: false });
                textFit(document.getElementsByClassName('textfit__description--multiline'), { minFontSize: 10, maxFontSize: 75});
                textFit(document.getElementsByClassName('textfit__footer--multiline'), { minFontSize: 10, maxFontSize: 70});
            });
        }
    }
});

