
import { defineComponent } from 'vue'
import axios from 'axios';
import textFit from 'textfit';

export default defineComponent({
    name: 'Spotlight' as string,
    data: ()=>({
        item: {} as any,
        loaded: false,
    }),
    props: ['id', 'signature'],
    mounted() {
        return axios.get(`${ process.env.VUE_APP_API_HOST }/api/signages/${this.id}/templaterequest/${this.signature}`).then((res) => {
            this.item = res.data;
            this.loaded = true;
        },
        (err) => console.error(err));
    },

    watch: {
        item() {
            this.$nextTick(() => {
                textFit(document.getElementsByClassName('textfit__headline1'), { minFontSize: 10, maxFontSize: 110, widthOnly: true, detectMultiLine: false });
                textFit(document.getElementsByClassName('textfit__headline2'), { minFontSize: 10, maxFontSize: 190, widthOnly: true, detectMultiLine: false });
                textFit(document.getElementsByClassName('textfit__secondary-headline'), { minFontSize: 10, maxFontSize: 75, widthOnly: true, detectMultiLine: false });
                textFit(document.getElementsByClassName('textfit__description'), { minFontSize: 10, maxFontSize: 90, widthOnly: true, detectMultiLine: false });
            });
        }
    }
});

