
import { defineComponent } from 'vue';
import { getModule } from 'vuex-module-decorators'
import UserState, { IUserState } from '../../store/modules/users';
import AuthService  from '../../auth/authService';
import { IUserRegistrationModel } from '../../store/interfaces/IUserRegistrationModel';

let userState ={} as IUserState;

export default defineComponent({
  name: "SignupView",
  data() {
      const validateTerms = (rule: any, value: string, callback: any) => {
        const _this = this as any;
        if (!_this.acceptTerms) {
              callback(new Error('You must accept the Terms and Conditions to continue.'));
          }
          else {
              callback();
          }
      };
      const validateEmail = (rule: any, value: string, callback: any) => {
          const _this = this as any;
          if (_this.invalidEmail) {
              callback(new Error('Please specify a valid email address.'));
          }
          else {
              callback();
          }
      };
      return {
        submittingForm: false,
        viewLoading: false,
        signupAllowed: false,
        invalidEmail: false,
        errorMessage: '',
        acceptTerms: false,
        newUser: {
            firstName: '',
            lastName: '',
            company: '',
            dittoUsage: '',
            email: ''
        },
        rules: {
            firstName: [
                {
                    required: true,
                    message: 'This field is required.',
                    trigger: 'blur'
                }
            ],
            lastName: [
                {
                    required: true,
                    message: 'This field is required.',
                    trigger: 'blur'
                }
            ],
            company: [
                {
                    required: true,
                    message: 'This field is required.',
                    trigger: 'blur'
                }
            ],
            dittoUsage: [
                {
                    required: true,
                    message: 'This field is required.',
                    trigger: 'change'
                }
            ],
            email: [
                {    
                    required: true,
                    message: 'This field is required.',
                    trigger: 'blur'
                },
                {
                    type: 'email',
                    message: 'Please specify a valid email address.',
                    trigger: 'blur'
                },
                {
                    validator: validateEmail,
                    trigger: 'blur'
                }
            ],
            terms: [
                {    
                    validator: validateTerms,
                    trigger: 'change'
                }
            ]
        }
    }
},
computed: {
    dateYear() {
        return (new Date()).getFullYear();
    }
},
methods: {
    validateForm(): Promise<boolean> {
        return new Promise((resolve) => {
            (this as any).$refs['form'].validate((valid: boolean, obj: any) => {
                if(!valid) {
                    let errorHTML = '';
                    for(const key in obj) {
                        for(const err of obj[key]) {
                            errorHTML = `${errorHTML}${err.message}<br>`;
                        }
                    }
                    console.error(errorHTML);
                }
                resolve(valid);
            });
        });
    },
    async onSubmit() {
        if(!await this.validateForm()) return;
        this.submittingForm = true;
        this.createDittoUser();
    },
    async createDittoUser() {
        const newUser = {
            name: this.newUser.firstName + ' ' + this.newUser.lastName,
            email: this.newUser.email,
            orgName: this.newUser.company,
            usageTypes: this.newUser.dittoUsage == 'Both' ? 'Digital Signage;Screen Mirroring' : this.newUser.dittoUsage,
            pid: this.findQueryValue('pid')
        } as IUserRegistrationModel;

        window.sessionStorage.setItem('userData', JSON.stringify({
            orgName: newUser.orgName,
            email: newUser.email,
            lastName: this.newUser.lastName,
            firstName: this.newUser.firstName,
            pid: this.findQueryValue('pid')
        }));

         if (newUser) {
            const hsKey = this.findCookieValue('hubspotutk');
            newUser['hubspotutk'] = hsKey;
            this.viewLoading = true;
            
            const userCreated = await userState.registerUser(newUser);
           
            if (userCreated.success) {
                this.signUpResponseHandler(userCreated.data);
            }
            else {
                this.submittingForm = false;
                this.signUpFailureHandler(userCreated.reason);
            }
        } else {
            this.viewLoading = false;
        }
    },
    findQueryValue: function (query: string) {
        const queryArray = window.location.search.substring(1).split("&");
        
        const value = queryArray.find((arrayVal) => {
            const keyVal = arrayVal.split("=");
            return keyVal[0].toLowerCase().trim() == query.toLowerCase();
        })

        if(!value) {
            return null;
        }

        return value.split("=")[1];
    },
    findCookieValue: function (cookieKey: string) {
        const cookieArray = document.cookie.split(";");

        const cookieValue = cookieArray.find((arrayVal) => {
            const keyVal = arrayVal.split("=");
            return keyVal[0].toLowerCase().trim() == cookieKey.toLowerCase();
        })

        if (!cookieValue) {
            return;
        }

        return cookieValue.split("=")[1];
    },
    async signUpResponseHandler(user: any) {
        this.viewLoading = true;
      
        await AuthService.loginWithRedirect({
            redirect_uri: window.location.protocol +'//'+window.location.host,
            ditto_user_id: user.id,  
            ditto_org_id: user.organization.id,
            ditto_signature: user.auth0Signature,
            login_hint: user.email,
            screen_hint: 'signUp'
        });
    },
    signUpFailureHandler: function (response: any) {
        this.viewLoading = true;
    
        if (response.data && response.data.errors && response.data.errors.email && response.data.errors.email.message) {
            this.errorMessage = response.data.errors.email.message;
            this.invalidEmail = true;
            this.validateForm();
        } else {
            this.errorMessage = 'There was an error during signup. Please try again.';
        }
    
        this.viewLoading = false;
        this.invalidEmail = false;
    },
  },
  async created() {
        const signupId = this.findQueryValue('sid') as string;

        if(!signupId || signupId != 'eyJhbGciOiJIUzUxMiJ9') {
            window.location.href = 'https://www.airsquirrels.com/ditto/try';
        }
        else {
            userState = getModule(UserState);
            this.signupAllowed = true;
        }
  }
});
