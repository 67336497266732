import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a2d6da2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "draggable-modal__header-title" }
const _hoisted_2 = { class: "draggable-modal__header-icons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_Transition, { name: "draggable-modal" }, {
      default: _withCtx(() => [
        (_ctx.modelValue)
          ? (_openBlock(), _createElementBlock("section", {
              key: 0,
              ref: "popout",
              class: "draggable-modal draggable-modal--popout",
              style: _normalizeStyle({ top: _ctx.currentY + 'px', left: _ctx.currentX + 'px' })
            }, [
              _createElementVNode("header", {
                class: "draggable-modal__header",
                onMousedown: _cache[2] || (_cache[2] = ($event: any) => (_ctx.mouseDown($event)))
              }, [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.title), 1)
                ]),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_el_tooltip, {
                    effect: "dark",
                    content: "Click & drag to reposition",
                    placement: "top",
                    "show-after": 500,
                    "hide-after": 0,
                    disabled: _ctx.isDragging
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Icon, {
                        class: "draggable-modal__drag-icon",
                        icon: _ctx.mdiCursorMove
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["disabled"]),
                  _createElementVNode("button", {
                    class: "dt-icon-button",
                    onMousedown: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
                    onClick: _cache[1] || (_cache[1] = ($event: any) => {_ctx.$emit('update:modelValue', false);})
                  }, [
                    _createVNode(_component_Icon, { icon: _ctx.mdiClose }, null, 8, ["icon"])
                  ], 32)
                ])
              ], 32),
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ], 4))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}