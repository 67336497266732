
import { defineComponent } from 'vue'
import Icon from '../Icon.vue'
import { mdiClose, mdiContentDuplicate, mdiDragHorizontalVariant } from '@mdi/js';
import draggable from 'vuedraggable';

export default defineComponent({
    data() {
        return {
            signageItemLayoutData: {} as any,

            // Icons
            //-------
            mdiClose,
            mdiContentDuplicate,
            mdiDragHorizontalVariant
        }
    },
    
    props: {
        layoutDataEdit: {
            type: Object,
            default: null
        },
    },

    components: {
        Icon,
        draggable
    },

    methods: {
        clearDirectoryHandler() {
            this.signageItemLayoutData.column1.directory = [];
            this.signageItemLayoutData.column2.directory = [];
        }
    },

    created () {
        this.signageItemLayoutData = JSON.parse(JSON.stringify(this.layoutDataEdit));
    },

    watch: {
        'signageItemLayoutData': {
            handler(newValue: any){
                this.$emit('updateLayoutData', {updatedLayoutData: newValue, shouldUpdatePreview: true});
            },
            deep: true
        }
    }
})
