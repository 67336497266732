import {
    Action,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '../../store';
import { getAuthInstance } from '../../auth';
import axios from 'axios';
import { ISqStoreActionResponse } from '../interfaces/ISqStoreActionResponse';
import { ITierUsage, ITier, IFeature, ICheckTierChangePayload } from '../interfaces/ITier';

export interface ITierState {
    readonly orgTier: ITier;
    readonly tiersList: ITier[];
    readonly orgTierUsage: ITierUsage;
    readonly featuresList: IFeature[];
    readonly featuresByCategoryList: any[];
    readonly canAddUsers: boolean;
    readonly canActivateReceivers: boolean;
    readonly canAddSignageItems: boolean;

    fetchTier(ordId?: string): Promise<ISqStoreActionResponse<ITier>>;
    fetchAllTiersList(): Promise<ISqStoreActionResponse<ITier[]>>;
    fetchTierUsage(orgId?: string): Promise<ISqStoreActionResponse<ITierUsage>>
    fetchAllFeaturesList(): Promise<ISqStoreActionResponse<IFeature[]>>;
    fetchAllFeaturesByCategoryList(): Promise<ISqStoreActionResponse<any[]>>;
    fetchAddUsersCheck(forceReload?: boolean): Promise<ISqStoreActionResponse<boolean>>
    fetchActivateReceiversCheck(forceReload?: boolean): Promise<ISqStoreActionResponse<boolean>>
    fetchAddSignageItemCheck(forceReload?: boolean): Promise<ISqStoreActionResponse<boolean>>
    checkTierChange(payload: ICheckTierChangePayload): Promise<ISqStoreActionResponse<any>>
}

@Module({ dynamic: true, store, name: 'tier' })
export default class TierState extends VuexModule implements ITierState {
    // THESE HAVE TO BE NAMED UNIQUELY
    // THE WAY STATE WORKS IF YOU ALTER THIESE VIA CONTEXT
    // ANY NAMED THE SAME WAY WILL CHANGE ALSO
    private tier: ITier = {} as ITier;
    private allTiers: ITier[] = [] as ITier[];
    private tierUsage: ITierUsage = {} as ITierUsage;
    private allFeatures: IFeature[] = [] as IFeature[];
    private allFeaturesByCategory: any = [] as any;
    private isAllTiersListLoaded = false;
    private isAllFeaturesListLoaded = false;
    private isAllFeaturesByCategoryListLoaded = false;
    private addUsersCheck = false;
    private isUserCheckLoaded = false;
    private activateReceiversCheck = false;
    private isUserReceiverCheckLoaded = false;
    private addSignageItemsCheck = false;
    private isSignageItemCheckLoaded = false;


    //#region GETTERS
    get orgTier(): ITier {
        return this.tier;
    }

    get tiersList(): ITier[] {
        return this.allTiers;
    }

    get orgTierUsage(): ITierUsage {
        return this.tierUsage;
    }

    get featuresList(): IFeature[] {
        return this.allFeatures;
    }

    get featuresByCategoryList(): any[] {
        return this.allFeaturesByCategory;
    }

    get canAddUsers(): boolean {
        return this.addUsersCheck;
    }

    get canActivateReceivers(): boolean {
        return this.activateReceiversCheck;
    }

    get canAddSignageItems(): boolean {
        return this.addSignageItemsCheck;
    }
    //#endregion

    //#region ACTIONS
    @Action
    async fetchTier(orgId?: string): Promise<ISqStoreActionResponse<ITier>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            
            let fetchTierUrl = `${process.env.VUE_APP_API_HOST}/api/tiers`;

            if(orgId) {
                fetchTierUrl = `${fetchTierUrl}?orgid=${orgId}`;
            }
            
            const axiosResponse = await axios.get(fetchTierUrl, authHeader);

            this.context.commit('setTier', axiosResponse.data);

            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Tier Store Error:', e);
        }

        return { success: false, reason: 'Error fectching tier.' };
    }

    @Action
    async fetchAllTiersList(): Promise<ISqStoreActionResponse<ITier[]>> {
        if(this.isAllTiersListLoaded) {
            return { success: true, data: this.allTiers };
        }

        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            
            const fetchAllTiersUrl = `${process.env.VUE_APP_API_HOST}/api/tiers/private_list`;
            const axiosResponse = await axios.get(fetchAllTiersUrl, authHeader);

            this.context.commit('setAllTiersList', axiosResponse.data);

            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Tier Store Error:', e);
        }

        return { success: false, reason: 'Error fectching all tiers.' };
    }

    @Action
    async fetchTierUsage(orgId?: string): Promise<ISqStoreActionResponse<ITierUsage>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            
            let fetchTierUsageUrl = `${process.env.VUE_APP_API_HOST}/api/tiers/usage`;

            if(orgId) {
                fetchTierUsageUrl = `${fetchTierUsageUrl}?orgid=${orgId}`;
            }

            const axiosResponse = await axios.get(fetchTierUsageUrl, authHeader);

            this.context.commit('setTierUsage', axiosResponse.data);

            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Tier Store Error:', e);
        }

        return { success: false, reason: 'Error fectching tier.' };
    }

    @Action
    async fetchAllFeaturesList(): Promise<ISqStoreActionResponse<IFeature[]>> {
        if(this.isAllFeaturesListLoaded) {
            return { success: true, data: this.allFeatures };
        }

        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            
            const fetchAllFeaturesUrl = `${process.env.VUE_APP_API_HOST}/api/tiers/features`;
            const axiosResponse = await axios.get(fetchAllFeaturesUrl, authHeader);

            this.context.commit('setAllFeaturesList', axiosResponse.data);

            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Tier Store Error:', e);
        }

        return { success: false, reason: 'Error fectching all features.' };
    }

    @Action
    async fetchAllFeaturesByCategoryList(): Promise<ISqStoreActionResponse<any[]>> {
        if(this.isAllFeaturesByCategoryListLoaded) {
            return { success: true, data: this.allFeatures };
        }

        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            
            const fetchAllFeaturesUrl = `${process.env.VUE_APP_API_HOST}/api/tiers/featuresbycategory`;
            const axiosResponse = await axios.get(fetchAllFeaturesUrl, authHeader);

            this.context.commit('setAllFeaturesByCategoryList', axiosResponse.data);

            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Tier Store Error:', e);
        }

        return { success: false, reason: 'Error fectching all features.' };
    }

    @Action
    async fetchAddUsersCheck(forceReload?: boolean): Promise<ISqStoreActionResponse<boolean>> {
        if(this.isUserCheckLoaded && !forceReload) {
            return { success: true, data: this.canAddUsers };
        }

        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            
            const fetchUserCheckUrl = `${process.env.VUE_APP_API_HOST}/api/tiers/addusers`;
            const axiosResponse = await axios.get(fetchUserCheckUrl, authHeader);

            this.context.commit('setUserCheck', axiosResponse.data);

            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Tier Store Error:', e);
        }

        return { success: false, reason: 'Error fectching user limit check.' };
    }

    @Action
    async fetchActivateReceiversCheck(forceReload?: boolean): Promise<ISqStoreActionResponse<boolean>> {
        if(this.isUserReceiverCheckLoaded && !forceReload) {
            return { success: true, data: this.canActivateReceivers };
        }

        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
           
            const fetchReceiverCheckUrl = `${process.env.VUE_APP_API_HOST}/api/tiers/activatereceivers`;
            const axiosResponse = await axios.get(fetchReceiverCheckUrl, authHeader);

            this.context.commit('setReceiverCheck', axiosResponse.data);

            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Tier Store Error:', e);
        }

        return { success: false, reason: 'Error fectching receiver limit check.' };
    }

    @Action
    async fetchAddSignageItemCheck(forceReload?: boolean): Promise<ISqStoreActionResponse<boolean>> {
        if(this.isSignageItemCheckLoaded && !forceReload) {
            return { success: true, data: this.canAddUsers };
        }

        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            
            const fetchSignageItemCheckUrl = `${process.env.VUE_APP_API_HOST}/api/tiers/addsignage`;
            const axiosResponse = await axios.get(fetchSignageItemCheckUrl, authHeader);

            this.context.commit('setSignageItemCheck', axiosResponse.data);

            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Tier Store Error:', e);
        }

        return { success: false, reason: 'Error fectching signage item limit check.' };
    }

    @Action
    async checkTierChange(payload: ICheckTierChangePayload): Promise<any>  {
        try{
            const authHeader = await getAuthInstance().getAxiosHeader();

            const checkTierChangeUrl = `${ process.env.VUE_APP_API_HOST }/api/tiers/checkchange`;
            const axiosResponse = await axios.post(checkTierChangeUrl, payload, authHeader);

            return { success: true, data: axiosResponse.data };
        }
        catch(e: any) {
            console.error('Tier Store Error:', e);
        }
        return { success: false, reason: 'Error checking tier change.' };
    }
    //#endregion

    //#region MUTATIONS
    @Mutation
    async setTier(tier: ITier): Promise<void> {
        this.tier = tier;
    }

    @Mutation
    async setAllTiersList(tiers: ITier[]): Promise<void> {
        this.allTiers = tiers;
        this.isAllTiersListLoaded = true;
    }

    @Mutation
    async setTierUsage(tierUsage: ITierUsage): Promise<void> {
        this.tierUsage = tierUsage;
    }

    @Mutation
    async setAllFeaturesList(features: IFeature[]): Promise<void> {
        this.allFeatures = features;
        this.isAllFeaturesListLoaded = true;
    }

    @Mutation
    async setAllFeaturesByCategoryList(features: any[]): Promise<void> {
        this.allFeaturesByCategory = features;
        this.isAllFeaturesByCategoryListLoaded = true;
    }

    @Mutation
    async setUserCheck(allowed: boolean): Promise<void> {
        this.addUsersCheck = allowed;
        this.isUserCheckLoaded = true;
    }

    @Mutation
    async setReceiverCheck(allowed: boolean): Promise<void> {
        this.activateReceiversCheck = allowed;
        this.isUserReceiverCheckLoaded = true;
    }

    @Mutation
    async setSignageItemCheck(allowed: boolean): Promise<void> {
        this.addSignageItemsCheck = allowed;
        this.isSignageItemCheckLoaded = true;
    }
    //#endregion
}