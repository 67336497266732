
import { defineComponent } from 'vue';
import { mdiCheckBold } from '@mdi/js';
import { ISignageList } from '../store/interfaces/ISignage';

export interface ISelectedSignageList extends ISignageList {
    isSelected: boolean;
}

export default defineComponent ({
    name: "SignageListSelectionModal",
    data: () => ({
        listFilter: '' as string,
        selectedList: {} as ISelectedSignageList,
        mdiCheckBold
    }),
    computed: {
        sortedAvailableLists() {
            const lists = Object.assign([], this.signageLists);
            let alphaOrderedLists = lists.sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
            alphaOrderedLists = alphaOrderedLists.filter((x: ISignageList) => x.id !== this.poppedUpList?.id);
            return alphaOrderedLists.map((element: ISelectedSignageList) => {
                if (element) {
                    if (element.id == this.selectedList.id) element.isSelected = true;
                    else element.isSelected = false;
                    return element;
                }
            });
        },

        filteredLists() : any[] {
            if (this.listFilter) {
                return this.sortedAvailableLists.filter((x: any) => x?.name.toLowerCase().includes(this.listFilter.toLowerCase()));
            } else {
                return this.sortedAvailableLists;
            }
        },

        isSelectedList(listId: string) {
            if(listId == this.selectedList.id ) {return true}
            return false;
        },
         
        listSelected() {
            if(!this.selectedList.id) { return true;}
            return false;
        }
    },

    props: ['modelValue', 'poppedUpList', 'signageLists', 'loading'],

    emits: ['update:modelValue', 'move-signages'],

    methods: {
        handleClose(){
            this.$emit('update:modelValue', false);
        },

        listClick: function(list: ISelectedSignageList) {
            if(list && this.selectedList.id != list.id) {
                this.selectedList = list;
            }
            else { this.selectedList = {} as ISelectedSignageList; }   
        },

        closeModal() {
            this.$emit('update:modelValue', false);
        },

        onMove() {
            this.$emit('move-signages', this.selectedList);
        },

        afterCloseHandler() {
            // clear out filters and selections after closing
            this.selectedList = {} as ISelectedSignageList;
            this.listFilter = '';
        }

        
    }
});
