import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { getAuthInstance } from "../auth/authService";
import { ISignageItem, ISignageItemUpdate } from "../store/interfaces/ISignage";
import { v4 as uuidv4 } from 'uuid';

// Needed for a cancel token on requests
let source = null as CancelTokenSource | null;

export interface ISignagePreview {
    preview: string;
    thumb: string;
}

export interface ISignagePreviewService {
    updatePreview(signageItem: ISignageItem): Promise<ISignagePreview>;
    generatePreview(signageItemUpdate: ISignageItemUpdate): Promise<ISignagePreview | null>;
    generatePreviewRepo(signageUpdate: ISignageItemUpdate): Promise<ISignagePreview | null>;
}

export class SignagePreviewService implements ISignagePreviewService {
    async updatePreview(signageItem: ISignageItem): Promise<ISignagePreview> {

        const signageItemUpdate: ISignageItemUpdate = {
            signageItem: signageItem,
            generatePreview: true,
            previewRoute: signageItem.layoutType
        };

        const previewPromise = this.generatePreview(signageItemUpdate);

        return previewPromise.then((preview: any) => {
            if(preview) {
                return preview;
            }
        }).catch((error: any) => {
            throw error;
        })
    }

    async generatePreview(signageItemUpdate: ISignageItemUpdate): Promise<ISignagePreview | null> {
        if(signageItemUpdate.signageItem.refreshedLayout) return null;   
        
        if(!signageItemUpdate.generatePreview || !signageItemUpdate.previewRoute) return null;
        
        return await this.generatePreviewRepo(signageItemUpdate);   
    }

    async generatePreviewRepo(signageUpdate: ISignageItemUpdate): Promise<ISignagePreview | null> {
        // Check if we have a source applied to the request
        if (source) source.cancel('Operation canceled by the user.');
        // Assign the source factory to the source variable
        source = axios.CancelToken.source();
        
        const generatePreviewUrl = `${ process.env.VUE_APP_API_HOST }/api/signages/${signageUpdate.signageItem.id ? signageUpdate.signageItem.id : uuidv4()}/previewtemplate`;
        const previewTemplateUrl = `${window.location.protocol}//${window.location.hostname}/signage/template/${signageUpdate.previewRoute}/`;
        const authHeader = await getAuthInstance().getAxiosHeader();

        const options = {...authHeader, cancelToken: source.token }
        const payload = {
            data: signageUpdate.signageItem.layoutData,
            url: previewTemplateUrl
        }

        const axiosResponse = await axios.post(generatePreviewUrl, payload, {
            ...options
        })
        
        .catch((thrown) => {
            if(axios.isCancel(thrown)) {
                // console.log("Preview gen canceled by user");
                throw Error(thrown);
            } else {
                throw Error(thrown);
            }
        }) as AxiosResponse;
        
        // No axios response means the preview was canceled
        // and another preview was started.
        return axiosResponse ? axiosResponse.data : null;
    }
}

export default new SignagePreviewService;

