
import { getModule } from 'vuex-module-decorators';
import { defineComponent } from 'vue'
import EmergencyAlertsState, { IEmergencyAlertsState } from '../../store/modules/emergencyAlerts';
import { IEmergencyAlert } from '../../store/interfaces/IEmergencyAlert';
import Icon from '../../components/Icon.vue';
import { mdiLightbulb } from '@mdi/js';
import ModelSelector from '../../components/ModelSelector.vue';
import RoomState, { IRoomState } from '../../store/modules/rooms';
import { IRoom, IRoomDropDownList } from '../../store/interfaces/IRoom';
import { ILocation, ILocationDropDownList } from '../../store/interfaces/ILocation';
import LocationState, { ILocationState } from '../../store/modules/locations';
import { IModelSelectionPool } from '../../store/interfaces/IModelSelectionPool';
import UserState, { IUserState } from '../../store/modules/users';
import moment from "moment";
import PageDirtyService from '../../services/PageDirtyService';
import TierLimit from '../../components/TierLimit.vue';
import TierState, { ITierState } from '../../store/modules/tier';

let emergencyAlertsState = {} as IEmergencyAlertsState,
    locationState = {} as ILocationState,
    roomState = {} as IRoomState,
    userState = {} as IUserState,
    tierState = {} as ITierState;


export default defineComponent({
    name: 'AlertsView',
    data() {

        // Functions for element to use to check
        // validity of the rooms & locations fields
        const checkRooms = (rule: any, value: any, callback: any) => {
            if(((this as any).selectedEmergencyAlert.rooms && (this as any).selectedEmergencyAlert.rooms.length > 0) ||
                ((this as any).selectedEmergencyAlert.locations && (this as any).selectedEmergencyAlert.locations.length > 0)
            ){
                callback();
                (this as any).$refs['alertForm'].validateField('rooms');
            }
            else {
                callback(new Error('Please select room(s) or location(s)'));
            }
        };

        const checkExpTime = (rule: any, value: any, callback: any) => {
            if(!(this as any).selectedEmergencyAlert.expirationTime) {
                callback(new Error('Please select an Alert expiration time.'));
                return;
            }

            const now = moment().utc();
            const selected = moment((this as any).selectedEmergencyAlert.expirationTime).utc();

            if(selected.isSameOrBefore(now)) {
                callback(new Error('Expiration Time must be in the future.'));
                return;
            }
            
            callback();
            (this as any).$refs['alertForm'].validateField('expirationTime');
            return;
        };

        const checkEffTime = (rule: any, value: any, callback: any) => {
            if(!(this as any).selectedEmergencyAlert.effectiveTime) {
                callback(new Error('Please select an Alert effective time.'));
                return;
            }
            
            callback();
            (this as any).$refs['alertForm'].validateField('effectiveTime');
            return;
        };

        const checkLocations = (rule: any, value: any, callback: any) => {
            if(((this as any).selectedEmergencyAlert.rooms && (this as any).selectedEmergencyAlert.rooms.length > 0) ||
                ((this as any).selectedEmergencyAlert.locations && (this as any).selectedEmergencyAlert.locations.length > 0)
            ){
                callback();
                (this as any).$refs['alertForm'].validateField('locations');
                
            } else {
                callback(new Error('Please select room(s) or location(s)'));
            }
        };

        return {
            alerts: [],
            alertFilter: '',
            selectedEmergencyAlert: {} as IEmergencyAlert,
            mdiLightbulb,
            locationsPickerVisible: false,
            isAlertsLoading: true as boolean,
            isAlertDetailsLoading: true as boolean,
            alertCreationLoading: false as boolean,
            roomsDDL: [] as IRoomDropDownList[],
            locationsDDL: [] as ILocationDropDownList[],
            rules: {
                title: [
                    {
                        required: true,
                        message: 'Please enter an Alert title',
                        trigger: 'blur',
                    },
                ],
                severity: [
                    {
                        required: true,
                        message: 'Please enter an Alert severity',
                        trigger: 'blur',
                    },
                ],
                description: [
                    {
                        required: true,
                        message: 'Please enter an Alert description',
                        trigger: 'blur',
                    },
                ],
                expirationTime: [
                    {
                        validator: checkExpTime,
                        trigger: 'blur',
                    },
                ],
                effectiveTime: [
                    {
                        validator: checkEffTime,
                        trigger: 'blur',
                    },
                ],
                rooms: [
                    {
                        validator: checkRooms,
                        message: 'Please select rooms or locations for this alert.',
                        trigger: 'blur',
                    },
                ],
                locations: [
                    {
                        validator: checkLocations,
                        message: 'Please select rooms or locations for this alert.',
                        trigger: 'blur',
                    },
                ],
                
            }
        }
    },


    components: {
        Icon,
        ModelSelector,
        TierLimit
    },


    methods: {
        formatDate(dateString: string) {
            let date = new Date(dateString);
            return moment(date).utc().format('llll');
        },

        newAlertHandler: function() {
            let newAlert = {
                type: "EmergencyAlert",
                title: "",
                description: "",
                is_active: true,
                isNew: true,
                organization: { _id: userState.me?.organization.id, type: "Organization" },
                rooms: [] as IRoom[],
                locations: [] as ILocation[],
                msgType: "Alert",
                severity: "Extreme", //Extreme, Severe, Moderate, Minor, Unknown
                urgency: "Immediate", //Immediate, Expected, Future, Past, Unknown
            } as IEmergencyAlert;

            return newAlert;
        },

        async changeDetection() {
            if(!this.creationMode) {
                PageDirtyService.markPageClean();
                return;
            }
            
            const originalAlert = {
                type: "EmergencyAlert",
                title: "",
                description: "",
                is_active: true,
                isNew: true,
                organization: { _id: userState.me?.organization.id, type: "Organization" },
                rooms: [] as IRoom[],
                locations: [] as ILocation[],
                msgType: "Alert",
                severity: "Extreme", //Extreme, Severe, Moderate, Minor, Unknown
                urgency: "Immediate", //Immediate, Expected, Future, Past, Unknown
            } as IEmergencyAlert;


            
            PageDirtyService.monitorObjectSet([
                {defaultObject: originalAlert, mutatedObject: this.selectedEmergencyAlert},
            ]);
        },

        createAlert() {
            (this as any).$refs['alertForm'].validate(async (valid: boolean) => {
                if (valid) {
                    this.alertCreationLoading = true;
                    const storeResponse = await emergencyAlertsState.createAlert(this.selectedEmergencyAlert); 
                    if(!storeResponse.success) {
                        (this as any).$message.error(
                            storeResponse.reason ? `Emergency alert creation failed: ${storeResponse.reason}` : 'Emergency alert creation failed'
                        );
                        this.alertCreationLoading = false;
                        return;
                    }
                    
                    if(storeResponse.data) {
                        this.$router.push(`/alerts/${ storeResponse.data.id }`);
                    }
                    
                    this.alertCreationLoading = false;
                    (this as any).$message.success('Emergency alert created');
                    PageDirtyService.markPageClean();
                }
            });
        },

        async deactivateAlert(alert: IEmergencyAlert) {
            await (this as any).$confirm(`This will immediately deactivate the alert and stop displaying it to users. Are you sure you want to deactivate ${ alert.title }?`, 'Warning', {
                confirmButtonText: 'Deactivate',
                cancelButtonText: 'Cancel',
                type: 'warning'
            });

            const alertUpdate = { ...alert, is_active: false, expirationTime: new Date().toISOString() };
            alertUpdate.deactivateReason = [
                `Emergency Alert was deactivated from the Ditto Account Portal at ${moment()
                    .utc()
                    .format()} UTC by user ${userState.me?.email}`
            ];
            
            const storeResponse = await emergencyAlertsState.updateAlert(alertUpdate as IEmergencyAlert);

            if(!storeResponse.success) {
                (this as any).$message.error(storeResponse.reason ? `Failed to update alert: ${storeResponse.reason}` : 'Failed to update alert');  
                return;  
            }

            if(storeResponse.data) {
                (this as any).$message.success(`Successfully deactivated alert ${ storeResponse.data.title }`);
            }
        },

        manageRoomsClickHandler() {
            const alertEnabledRoomsMap: IModelSelectionPool[] = (this as any).alertEnabledRooms.map((room: IRoom) =>{
                return {
                    id: room.id,
                    name: room.roomName,
                    selected: this.selectedEmergencyAlert.rooms.map((r) => r.id).includes(room.id)
                }
            });
            (this as any).$refs['roomModelSelector'].activate(alertEnabledRoomsMap);
        },

        manageLocationsClickHandler() {
            const locationsMap: IModelSelectionPool[] = this.locationsDDL.map((location: ILocationDropDownList) =>{
                return {
                    id: location.id,
                    name: location.name,
                    selected:  this.selectedEmergencyAlert.locations.map((l) => l.id).includes(location.id)
                }
            });
            (this as any).$refs['locationModelSelector'].activate(locationsMap);
        },
        
        loadSelectedEmergencyAlert(id?: any){ 
            if(!this.creationMode) {
                this.isAlertDetailsLoading = true;
                const idToLoad = id ? id : this.$route.params.id;
                const alertToLoad = (this as any).allEmergencyAlerts.find((alert: IEmergencyAlert)=>{
                    return alert.id == idToLoad;
                });
                this.isAlertDetailsLoading = false;
                return alertToLoad;
            }

            return this.newAlertHandler() as IEmergencyAlert;
        },

        roomSelectionChanged(rooms: IRoom[]) {
            const selectedRooms = rooms.filter((room: any)=> { 
                return room.selected
            });

            const selectedRoomsId = selectedRooms.map((a: any)=> {
                return a.id;
            });

            const hydratedSelectedRooms = (this as any).alertEnabledRooms.filter((room: any)=> {
                return selectedRoomsId.includes(room.id);
            });
            this.selectedEmergencyAlert.rooms = hydratedSelectedRooms;
        },

        locationSelectionChanged(locations: ILocation[]) {
            console.log(locations);
            const selectedLocations = locations.filter((location: any)=> { 
                return location.selected
            });

            const selectedLocationsId = selectedLocations.map((a: any)=> {
                return a.id;
            });

            const hydratedSelectedLocations = this.locationsDDL.filter((location: any)=> {
                return selectedLocationsId.includes(location.id);
            });
            this.selectedEmergencyAlert.locations = hydratedSelectedLocations;
        },

        onTierModalClose() {
            this.$router.push({ path: '/' });
            return;
        }

    },

    props: ['creationMode'],

    computed: {
        filteredEmergencyAlerts() {
            if(this.alertFilter) {
                const searchValue = this.alertFilter.toLowerCase();
                return (this as any).allEmergencyAlerts.filter((alert: IEmergencyAlert)=> {
                    const alertTitle = alert.title.toLowerCase();
                    return  alertTitle.includes(searchValue) ||
                            alert.severity.toLowerCase().includes(searchValue);
                });
            } else {
                return this.allEmergencyAlerts;
            }
        },

        allEmergencyAlerts(): IEmergencyAlert[] | undefined {
            if(emergencyAlertsState.allEmergencyAlerts) {
                const activeAlerts = emergencyAlertsState.allEmergencyAlerts.filter(server => server.is_active && !server.clonedChild).sort((a:IEmergencyAlert, b:IEmergencyAlert) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
                const inactiveAlerts = emergencyAlertsState.allEmergencyAlerts.filter(server => !server.is_active && !server.clonedChild).sort((a:IEmergencyAlert, b:IEmergencyAlert) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
                const returnAlerts = [...activeAlerts, ...inactiveAlerts];
                return returnAlerts;
            }
            return [];
        },

        alertEnabledRooms(): IRoomDropDownList[] | undefined {
            if(!this.roomsDDL) return undefined;

            return this.roomsDDL.filter((x:IRoomDropDownList) => x.enableAlerts);
        },

        showTierCallout(): boolean {
            if(!tierState.orgTier.emergencyAlertsEnabled) {
                return true;
            }
            return false;
        }
    },  


    async created () {
        this.isAlertsLoading = true;
        emergencyAlertsState = getModule(EmergencyAlertsState);
        roomState = getModule(RoomState);
        locationState = getModule(LocationState);
        userState = getModule(UserState);
        tierState = getModule(TierState);

        const response = await Promise.all([
            roomState.fetchRoomsDDL(true),
            locationState.fetchLocationsDDL(),
            userState.fetchMe(),
            emergencyAlertsState.fetchEmergencyAlerts(),
            tierState.fetchTier()
        ]);

        if(response[0] && response[0].success) {
            this.roomsDDL = roomState.nonLegacyRooms;
        }

        if(response[1] && response[1].success) {
            this.locationsDDL = response[1].data ?? [] as ILocationDropDownList[];
        }

        this.selectedEmergencyAlert = this.loadSelectedEmergencyAlert();
        this.isAlertsLoading = false;

        this.$watch(() => this.$route.params, () => {
            this.$nextTick(()=> {
                this.selectedEmergencyAlert = this.loadSelectedEmergencyAlert();
                const alertDetailsBody = (this as any).$refs['alertDetailsBody'];
                if(alertDetailsBody) {
                    alertDetailsBody.scrollTop = 0;
                }
            });
            
        },

        { immediate: false });
    },

    watch: {
    'selectedEmergencyAlert': {
      handler(){
        this.changeDetection();
      },
      deep: true
    }
  }
});


