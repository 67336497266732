import {
    Action,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';

import store from '..';

import { getAuthInstance } from '../../auth';
import axios from 'axios';
import { ISqStoreActionResponse } from '../interfaces/ISqStoreActionResponse';
import { ISubscription, ISubscriptionPlan } from '../interfaces/ISubscription';
import moment from 'moment';
import UserState from './users';
import { getModule } from 'vuex-module-decorators'
import ChargeState from './charges';
import InvoiceState from './invoices';
import { IOrganization } from '../interfaces/IOrganization';
import { ITier } from '../interfaces/ITier';

export interface ICalculateSubscriptionAdjustmentInput {
    customerId: string;
    quantity: number;
    tierId: string;
    billingFrequency: string;
}

export interface IUpdateSubscriptionInput {
    customerId: string;
    tier: ITier;
    quantity: number;
    organization: IOrganization;
    calculatedTotal: string;
    billingFrequency: string;
}

export interface IRefundInput {
    customerId: string;
    organizationId: string;
    refundAmount: number;
}

export interface ISubscriptionCreationInupt {
    customer: string;
    planId: string;
    productId: string;
    quantity: number;
    billingFrequency: string;
}

export interface ISubscriptionState {
    readonly allSubscriptions: ISubscription[];
    readonly subscriptionById: (id: string) => ISubscription | null;
    readonly allSubscriptionPlans: ISubscriptionPlan[];
    fetchSubscriptions(reload?: boolean): Promise<ISqStoreActionResponse<ISubscription[]>>;
    fetchSuperSubscriptions(customerId: string): Promise<ISqStoreActionResponse<ISubscription[]>>;
    fetchInvoice(invoiceId: string): Promise<ISqStoreActionResponse<any>>;
    reactivateSubscription(subscription: ISubscription): Promise<ISqStoreActionResponse<ISubscription[]>>;
    calculateSubscriptionAdjustment(inupt: ICalculateSubscriptionAdjustmentInput): Promise<ISqStoreActionResponse<any>>;
    updateSubscription(subscription: IUpdateSubscriptionInput): Promise<ISqStoreActionResponse<ISubscription[]>>;
    fetchSubscriptionPlans(): Promise<ISqStoreActionResponse<ISubscriptionPlan[]>>;
    startSubscription(creationInput: ISubscriptionCreationInupt): Promise<ISqStoreActionResponse<ISubscription[]>>;
    cancelSubscription(subscriptionId: string): Promise<ISqStoreActionResponse<null>>;
    refundSubscription(refundInput: IRefundInput): Promise<ISqStoreActionResponse<any>>;
}

@Module({ dynamic: true, store, name: 'subscriptions' })
export default class SubscriptionState extends VuexModule implements ISubscriptionState {
    private SUBSCRIPTIONS: ISubscription[] = null as any;
    private SUBSCRIPTION_PLANS: ISubscriptionPlan[] = null as any;
    //#region GETTERS
    get allSubscriptions(): ISubscription[] {
        if(!this.SUBSCRIPTIONS) return []
        return [...this.SUBSCRIPTIONS.map((x)=>Object.assign({}, x))];
    }

    get allSubscriptionPlans(): ISubscriptionPlan[] {
        if(!this.SUBSCRIPTION_PLANS) return [];
        return [...this.SUBSCRIPTION_PLANS.map((x)=>Object.assign({}, x))];
    }

    get subscriptionById(): (id: string) => ISubscription | null {
        return (id: string) => {
            if (!this.SUBSCRIPTIONS) return null;
            const sub = this.SUBSCRIPTIONS.find(x => x.id === id);
            return sub ? Object.assign({}, sub) : null;
        };
    }
    //#endregion

    //#region ACTIONS
    @Action
    async fetchSubscriptions(reload = false): Promise<ISqStoreActionResponse<ISubscription[]>> {
        if (this.SUBSCRIPTIONS && !reload) return { success: true, data: [...this.SUBSCRIPTIONS.map((x)=>Object.assign({}, x))]};

        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            
            const fetchSubscriptionsUrl = `${process.env.VUE_APP_API_HOST}/api/subscriptions`;
            const axiosResponse = await axios.get(fetchSubscriptionsUrl, authHeader);

            this.context.commit('setSubscriptions', axiosResponse.data);
            
            return { success: true, data: axiosResponse.data};
        }
        catch(e:any) {
            console.error('Subscription Store Error:', e);
        }

        return { success: false, reason: 'Error fetching subscriptions.' };
    }

    @Action
    async fetchSuperSubscriptions(customerId: string): Promise<ISqStoreActionResponse<ISubscription[]>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            
            const fetchSuperSubscriptionsUrl = `${process.env.VUE_APP_API_HOST}/api/subscriptions/super/${customerId}`;
            const axiosResponse = await axios.get(fetchSuperSubscriptionsUrl, authHeader);

            this.context.commit('setSubscriptions', axiosResponse.data);
            
            return { success: true, data: axiosResponse.data};
        }
        catch(e:any) {
            console.error('Subscription Store Error:', e);
        }

        return { success: false, reason: 'Error fetching subscriptions.' };
    }

    @Action
    async fetchInvoice(invoiceId: string): Promise<ISqStoreActionResponse<any>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            
            const fetchInvoiceUrl = `${process.env.VUE_APP_API_HOST}/api/subscriptions/invoice/${invoiceId}`;
            const axiosResponse = await axios.get(fetchInvoiceUrl, authHeader);
            
            return { success: true, data: axiosResponse.data};
        }
        catch(e:any) {
            console.error('Subscription Store Error:', e);
        }

        return { success: false, reason: 'Error fetching invoice.' };
    }

    @Action
    async reactivateSubscription(subscription: ISubscription): Promise<ISqStoreActionResponse<ISubscription[]>> {
        try {
            const cancelDate = moment.utc(subscription.current_period_end * 1000);
            const cancelAtPeriodEnd = subscription.cancel_at_period_end;

            if(!cancelAtPeriodEnd || cancelAtPeriodEnd && cancelDate < moment.utc()) {
                return { success: false, reason: 'Can not reactivate a expired subscription' };
            }

            const authHeader = await getAuthInstance().getAxiosHeader();
            const reactivateUrl = `${process.env.VUE_APP_API_HOST}/api/subscriptions/${subscription.id}/reactivate`;
            
            const axiosResponse = await axios.get(reactivateUrl, authHeader);
            
            if(axiosResponse.data) {
                const mutatedData = [...this.SUBSCRIPTIONS.filter(x=>x.id !== axiosResponse.data.id), axiosResponse.data];
                this.context.commit('setSubscriptions', mutatedData);
                return { success: true, data: mutatedData};
            }

            return { success:false, reason: 'No Data from api' };
            
        }
        catch(e:any) {
            // nothing
        }


        return { success: false, reason: 'Error reactivating subscription.' };
    }

    @Action
    async calculateSubscriptionAdjustment(input: ICalculateSubscriptionAdjustmentInput): Promise<ISqStoreActionResponse<any>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            const checkoutUrl = `${process.env.VUE_APP_API_HOST}/api/subscriptions/calculate`;
            
            const axiosResponse = await axios.post(checkoutUrl, input, authHeader);
            
            if(!axiosResponse) {
                return { success:false, reason: 'No Data from api' };
            }

            return { success: true, data: axiosResponse.data};
        }
        catch(e:any) {
            if(e && e.response) {
                return { success: false, reason: e.response.data };
            }
        }

        return { success: false, reason: 'Error calculating subscription.' };
    }

    @Action
    async updateSubscription(subscription: IUpdateSubscriptionInput): Promise<ISqStoreActionResponse<ISubscription[]>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            const updateUrl = `${process.env.VUE_APP_API_HOST}/api/subscriptions`;
            
            const axiosResponse = await axios.put(updateUrl, subscription, authHeader);
            
            if(axiosResponse.data) {
                const mutatedData = [...this.SUBSCRIPTIONS.filter(x=>x.id !== axiosResponse.data.id), axiosResponse.data];
                this.context.commit('setSubscriptions', mutatedData);

                const userState = getModule(UserState);
                userState.fetchSubscriptionStatus(true);
                userState.reloadMe();

                return { success: true, data: mutatedData};
            }

            return { success:false, reason: 'No Data from api' };
            
        }
        catch(e:any) {
            // nothing
        }


        return { success: false, reason: 'Error updating subscription.' };
    }

    @Action
    async fetchSubscriptionPlans(): Promise<ISqStoreActionResponse<ISubscriptionPlan[]>> {
        if (this.SUBSCRIPTION_PLANS) return { success: true, data: [...this.SUBSCRIPTION_PLANS.map((x)=>Object.assign({}, x))]};

        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            // const planUrl = `${process.env.VUE_APP_API_HOST}/api/plans`;
            const planUrl = `${process.env.VUE_APP_API_HOST}/api/subscriptions/products`;
            
            const axiosResponse = await axios.get(planUrl, authHeader);
            
            if(!axiosResponse.data) {
                return { success:false, reason: 'No Data from api' };
            }

            this.setSubscriptionPlans(axiosResponse.data);
             
            return { success: true, data: axiosResponse.data};
            
        }
        catch(e:any) {
            // nothing
        }


        return { success: false, reason: 'Error calculating subscription.' };
    }

    @Action
    async startSubscription(creationInput: ISubscriptionCreationInupt): Promise<ISqStoreActionResponse<ISubscription[]>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            const createUrl = `${process.env.VUE_APP_API_HOST}/api/subscriptions`;
            
            const axiosResponse = await axios.post(createUrl, creationInput, authHeader);
            
            if(axiosResponse.data) {
                const mutatedData = [...this.SUBSCRIPTIONS.filter(x=>x.id !== axiosResponse.data.id), axiosResponse.data];
                this.context.commit('setSubscriptions', mutatedData);

                const userState = getModule(UserState);
                userState.fetchSubscriptionStatus(true);
                userState.reloadMe();
                const chargeState = getModule(ChargeState);
                chargeState.fetchCharges(true);
                const invoiceState = getModule(InvoiceState);
                invoiceState.fetchInvoices(true);

                return { success: true, data: mutatedData};
            }

            return { success:false, reason: 'No Data from api' };
            
        }
        catch(e:any) {
            if(e && e.response) {
                return { success: false, reason: e.response.data };
            }
        }

        return { success: false, reason: 'Error updating subscription.' };
    }

    @Action
    async cancelSubscription(subscriptionId: string): Promise<ISqStoreActionResponse<null>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            const deleteUrl = `${process.env.VUE_APP_API_HOST}/api/subscriptions/${subscriptionId}`;
            
            const axiosResponse = await axios.delete(deleteUrl, authHeader);
            
            if(axiosResponse.data) {
                const mutatedData = [...this.SUBSCRIPTIONS.filter(x=>x.id !== axiosResponse.data.id), axiosResponse.data];
                this.context.commit('setSubscriptions', mutatedData);

                const userState = getModule(UserState);
                userState.fetchSubscriptionStatus(true);
                userState.reloadMe();

                return { success: true, data: null};
            }

            return { success:false, reason: 'No Data from api' };
            
        }
        catch(e:any) {
            if(e && e.response) {
                return { success: false, reason: e.response.data };
            }
        }

        return { success: false, reason: 'Error canceling subscription.' };
    }

    @Action
    async refundSubscription(refundInput: IRefundInput): Promise<ISqStoreActionResponse<any>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            const refundUrl = `${process.env.VUE_APP_API_HOST}/api/subscriptions/refund`;
            
            const axiosResponse = await axios.post(refundUrl, refundInput, authHeader);

            return { success:true, data: axiosResponse.data };
            
        }
        catch(e:any) {
            if(e && e.response) {
                return { success: false, reason: e.response.data };
            }
        }

        return { success: false, reason: 'Error refunding subscription.' };
    }

    //#endregion

    //#region MUTATIONS

    @Mutation
    async setSubscriptions(subscriptions: ISubscription[]): Promise<void> {
        this.SUBSCRIPTIONS = subscriptions;
    }

    @Mutation
    async setSubscriptionPlans(subscriptionPlans: ISubscriptionPlan[]): Promise<void> {
        this.SUBSCRIPTION_PLANS = subscriptionPlans;
    }
    //#endregion


}
