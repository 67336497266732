import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "align-middle" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "d-flex align-items-center" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "model-selector__title my-0" }
const _hoisted_7 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_selection = _resolveComponent("selection")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_ctx.isCallout)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["callout callout--no-max callout--warning d-flex justify-content-between", {'flex-column' : _ctx.windowWidth.value < 576}])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["d-flex align-items-center float-start", {'mb-3' : _ctx.windowWidth.value < 576}])
        }, [
          _createElementVNode("img", {
            class: "logo--x-large",
            src: require('../assets/images/ditto-tier-logo.png')
          }, null, 8, _hoisted_1),
          _createElementVNode("span", _hoisted_2, [
            _createTextVNode(_toDisplayString(_ctx.message) + " ", 1),
            (_ctx.showCompareLink)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  class: "primary-color",
                  to: "/organization/billing#compareplans"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Click here to learn more.")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ], 2),
        _createElementVNode("span", null, [
          _createVNode(_component_el_button, {
            class: "ms-0 mb-2 me-2 float-end",
            type: "warning",
            onClick: _ctx.onUpgrade
          }, {
            default: _withCtx(() => [
              _createTextVNode("Upgrade Plan")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ], 2))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createVNode(_component_el_dialog, {
          "model-value": _ctx.modelValue,
          "before-close": _ctx.handleClose
        }, {
          title: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("img", {
                class: "logo--x-large",
                src: require('../assets/images/ditto-tier-logo.png')
              }, null, 8, _hoisted_5),
              _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.title), 1)
            ])
          ]),
          footer: _withCtx(() => [
            _createElementVNode("span", _hoisted_7, [
              _createVNode(_component_el_button, { onClick: _ctx.onCancel }, {
                default: _withCtx(() => [
                  _createTextVNode("Cancel")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: _ctx.onUpgrade
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Upgrade Plan")
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_selection, null, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.message), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model-value", "before-close"])
      ]))
}