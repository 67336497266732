
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'DittoIcon',                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
    data() {
        return {
            
        }
    },
    components: {
    },

    props: {
        icon: {
            type: String,
            required: true
        },
        viewBox: {
            type: String,
            default: '0 0 24 24'
        },
        small: {
            type: Boolean,
            default: false
        },
    },
});

