/* eslint-disable */ 
import createAuth0Client, { Auth0Client, GetIdTokenClaimsOptions, GetTokenSilentlyOptions, IdToken, LogoutOptions, RedirectLoginOptions } from '@auth0/auth0-spa-js';
import { AxiosRequestConfig } from 'axios';
import moment from 'moment';
import { Moment } from 'moment';
import { getModule } from 'vuex-module-decorators';
import { IUser } from '../store/interfaces/IUser';
import UserState, { IUserState } from '../store/modules/users';
import { IRoleState } from '../store/modules/role';
import TierState, { ITierState } from '../store/modules/tier';

export interface IAuthService {
    isLoading: boolean;
    isAuthenticated: boolean;
    auth0user: any;
    dittoUser: IUser;
    auth0Client: Auth0Client;
    popupOpen: boolean;
    error?: any;
    cacheToken?: string;
    cacheToken_exp?: Moment;
    useAuth0(options: any): Promise<void>;
    loginWithRedirect(o?: RedirectLoginOptions): Promise<void>;
    getIdTokenClaims(o?: GetIdTokenClaimsOptions): Promise<IdToken>;
    getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string | null>;
    logout(o?: LogoutOptions): void;
    getAxiosHeader(): Promise<AxiosRequestConfig>;
    checkRoleApplicationAccess(toPath: string, roleState: IRoleState): boolean;
}

const DEFAULT_REDIRECT_CALLBACK = () => {
    const paramObj = convertQueryStringToObj();
    if(window.location.search && (paramObj['redirectUrl'] && paramObj['redirectUrl'] !== ''))
    {   
        window.location.href = `${window.location.protocol}//${window.location.hostname}/${decodeURIComponent(paramObj['redirectUrl'])}`;
        return
    }
    let newSearch = ''
    for(const key of Object.keys(paramObj)) {
        if(key === 'state' || key === 'code') continue;
        
        newSearch = `${newSearch !== '' ? '&' : ''}${key}=${paramObj[key]}`;
    }
    window.location.search = newSearch;
    window.history.replaceState({}, document.title, window.location.pathname);
    return 
};

let instance: IAuthService;

export const getAuthInstance = (): IAuthService => {
    return instance;
}

const convertQueryStringToObj = () => {
    const rawParams = window.location.search.replace('?', '').split('&');
    const paramObj = {} as any;
    for(const param of rawParams) {
        const split = param.split('=');
        paramObj[split[0]] = split[1];
    }

    return paramObj;
}

export class AuthService implements IAuthService {
    isLoading = true;
    isAuthenticated = false;
    auth0user: any;
    dittoUser: IUser = {} as IUser;
    auth0Client: Auth0Client = {} as Auth0Client;
    popupOpen = false;
    error?: any;
    cacheToken?: string;
    cacheToken_exp?: Moment;
    options: any;

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async useAuth0(options: any): Promise<void> {
        /* ok... where here. We are here for 3 reasons. 
            1) We are here because someone who is un authenticated, and has not logged in has loaded this application.
                Send them to the log in page
            2) We are here because someone who is un authenticated.... but has logged in has been redirected back to this application from auth0
                Send them to the error page
            3) We are here because someone who is logged in and authenticated has been redirected to this location.
                Load the user set some things and let them continue to the application
            4) we are here because someone's account is jacked up and the signature is bad!
        */

        options.onRedirectCallback = options.onRedirectCallback ? options.onRedirectCallback : DEFAULT_REDIRECT_CALLBACK;
        options.redirectUri = options.redirectUri ? options.redirectUri : window.location.origin;

        this.options = options;

        try {
            this.auth0Client = await createAuth0Client({
                domain: this.options.domain,
                client_id: this.options.clientId,
                audience: this.options.audience,
                redirect_uri: this.options.redirectUri,
                cacheLocation: 'localstorage',
                useRefreshTokens: true,
            });

            instance = this;

            const paramObj = convertQueryStringToObj();
            console.log(paramObj);
            if (paramObj["code"] && paramObj["state"]) {
                try {
                    await this.auth0Client.handleRedirectCallback();
                }
                catch(e:any){
                    // dont really care that this failed... we will handle later
                }
                this.error = null;
                this.options.onRedirectCallback();
            }

            this.isAuthenticated = await this.auth0Client.isAuthenticated();

            this.auth0user = await this.auth0Client.getUser();

            if(!this.isAuthenticated && !this.auth0user && !paramObj['error']) {
                if(paramObj['code'] == 'success' && paramObj['success'] == 'true' && paramObj['message'] == 'Your%20email%20was%20verified.%20You%20can%20continue%20using%20the%20application.') {
                    return this.loginWithRedirect();
                }
                else if(paramObj['message'] == 'This%20URL%20can%20be%20used%20only%20once' && paramObj['success'] == 'false') {
                    return this.loginWithRedirect();
                }

                // this.isLoading = false;
                // return;
            }




            if(!this.isAuthenticated && !this.auth0user && paramObj['error']) {
                if(window.location.href.includes('/error/?')) return;
                
                window.location.href = `${window.location.origin}/error/${window.location.search}`;
                return;
            }

            // we've handled all of the error cases, the user is probably not authenticated at this point
            if(!this.isAuthenticated && !this.auth0user) {
                // this will let the authguard take over from here. It will notice that we are not logged (if we need to be) and handle it from there
                this.isLoading = false;
                return;
            }

            const userState = getModule(UserState) as IUserState;
            const meResponse = await userState.fetchMe({authInstance: this});

            console.log('meResponse', meResponse.reason)

            if(!window.location.href.includes('/error/?') && !meResponse.success) {
                window.location.href = `${window.location.origin}/error/?error=authenticationerror&error_description=${meResponse.reason}`;
                return;
            }            
            this.dittoUser = meResponse.data ?? {} as IUser;

            // if(this.dittoUser.superUser) {
            //     const subdomain = window.location.hostname.split('.', 1);
            //     let adminRedirectLink;
            //     if(subdomain[0] == 'account') {
            //         adminRedirectLink = window.location.origin.replace('account', 'admin');
            //     }
            //     else {
            //         adminRedirectLink = `${window.location.protocol}//admin.${window.location.hostname}/`;
            //     }
            //     window.location.href = adminRedirectLink;
            //     return;
            // }

            // This is for Canva connection
            if(this.isAuthenticated && paramObj['canvatype'] && paramObj['user'] && paramObj['brand'] && paramObj['state']){
                const tierState = getModule(TierState) as ITierState;
                await tierState.fetchTier();
                
                const url = new URL(window.location.href);
                const user = url.searchParams.get('user') ?? paramObj['user'];
                const brand = url.searchParams.get('brand') ?? paramObj['brand'];

                if(tierState.orgTier.signageCanvaEnabled) {
                    // Update Ditto user with Canva user id
                    if(paramObj['canvatype'] == 'app') {
                        const canvaUserId = `${decodeURIComponent(brand)}:${decodeURIComponent(user)}`;
                        if(!this.dittoUser.canvaTeamUserIds) {
                            this.dittoUser.canvaTeamUserIds = [canvaUserId];
                        }
                        else {
                            if(!this.dittoUser.canvaTeamUserIds.some((x:any) => x.includes(canvaUserId))) {
                                this.dittoUser.canvaTeamUserIds.push(canvaUserId);
                            }
                        }
                    }
                    else {
                        this.dittoUser.canvaId = decodeURIComponent(user);
                    }
                    await userState.updateMe(this.dittoUser);
                    // Redirect success back to Canva
                    window.location.href = `https://canva.com/apps/configured?success=true&state=${paramObj['state']}`;
                    return;
                }
                else {
                    // Remove Canva user id from Ditto user
                    if(paramObj['canvatype'] == 'app') {
                        const canvaUserId = `${decodeURIComponent(brand)}:${decodeURIComponent(user)}`;
                        if(this.dittoUser.canvaTeamUserIds && this.dittoUser.canvaTeamUserIds.some((x:any) => x.includes(canvaUserId))) {
                            const idx = this.dittoUser.canvaTeamUserIds.indexOf(canvaUserId);
                            this.dittoUser.canvaTeamUserIds.splice(idx);
                        }}
                    else {
                        this.dittoUser.canvaId = undefined;
                    }
                    await userState.updateMe(this.dittoUser);
                    window.location.href = `${window.location.origin}/error/?error=authenticationerror&error_description=Canva has not been enabled for your organization.&canvastate=${paramObj['state']}`;
                    return;
                }
            }

            this.isLoading = false;
            return;
        }
        catch(e:any) {
            console.error('auth error', e)
            if(!window.location.href.includes('/error/?') && !window.location.href.includes('/logout')) {
                window.location.href = `${window.location.origin}/error/?error=authenticationerror&error_description=${e}`;
            }
        }
    }

    
    loginWithRedirect(o?: RedirectLoginOptions): Promise<void> {
        // handle sending the user back to a specific location with a query string to avoid auth0 fun with call backs
        const baseHostUrl = `${window.location.protocol}//${window.location.hostname}/`;
        const route = window.location.href.replace(baseHostUrl, '');
        let redirectUri = `${baseHostUrl}`;
        redirectUri = route !== '' ? `${redirectUri}?redirectUrl=${encodeURIComponent(route)}` : `${redirectUri}`;
        const opt = {redirect_uri: redirectUri, ...o};
        return this.auth0Client.loginWithRedirect(opt);
    }

    getIdTokenClaims(o?: GetIdTokenClaimsOptions): Promise<IdToken> {
        return this.auth0Client.getIdTokenClaims(o);
    }

    async getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string | null> {
        if(!this.isAuthenticated) {
            return Promise.resolve(null);
        }

        if(this.cacheToken && this.cacheToken_exp && this.cacheToken_exp > moment()) {
            return Promise.resolve(this.cacheToken);
        }

        try {
            const res = await this.auth0Client.getTokenSilently(o);
            this.cacheToken = res;
            this.cacheToken_exp = moment().add(20, 'seconds');
            return res; 
        }
        catch(e:any) {
            console.error(e);
            try {
                await this.auth0Client.loginWithPopup({prompt: 'none'})
                const res = await this.auth0Client.getTokenSilently(o);
                this.cacheToken = res;
                this.cacheToken_exp = moment().add(20, 'seconds');
                return res; 
            }
            catch(e:any){
                console.error(e);
            }
            return '';
        }
    }

    logout(o?: LogoutOptions): void | Promise<void> {
        return this.auth0Client.logout(o);
    }

    async getAxiosHeader(): Promise<AxiosRequestConfig> {
        const authToken = await this.getTokenSilently();
        return  {
            headers: {
                authorization: `Bearer ${authToken}`
            }
        }
    }

    checkRoleApplicationAccess(toPath: string, roleState: IRoleState): boolean {
        let hasAccess = true as boolean;
        
        if(toPath.includes('admin')) {
            hasAccess = roleState.hasSuperAccess;
        }
        else if(toPath.includes('receivers') || toPath.includes('activate')) {
            hasAccess = roleState.hasReceiversAccess;
        }
        else if(toPath.includes('rooms')) {
            hasAccess = roleState.hasRoomsAccess;
        }
        else if(toPath.includes('locations')) {
            hasAccess = roleState.hasLocationsAccess;
        }
        else if(toPath.includes('signage') || toPath.includes('templateengine')) {
            hasAccess = roleState.hasSignageAccess;
        }
        else if(toPath.includes('alerts')) {
            hasAccess = roleState.hasAlertsAccess;
        }
        else if(toPath.includes('billing')) {
            //we're giving everyone access to the billing page so they can see the upgrade options
            hasAccess = true;
        }
        else if(toPath.includes('organization')) {
            hasAccess = roleState.hasOrganizationAccess;
        }
        else if(toPath == '/users/new') {
            hasAccess = roleState.hasUsersAccess && roleState.isAdmin;
        }
        else if(toPath == '/users/swap') {
            hasAccess = roleState.hasUsersAccess && roleState.isAdmin;
        }
        //users security is handled on the views
        //since we allow all users to see their own account info
        else if(toPath.includes('users')) {
            hasAccess = true;
        }
        else if(toPath.includes('admin')) {
            hasAccess = roleState.hasSuperAccess;
        }
        else if(toPath.includes('roles')) {
            hasAccess = roleState.hasUsersAccess && roleState.isAdmin;
        }
        else {
            hasAccess = true;
        }

        return hasAccess;
    }

}

export default new AuthService();