
import { defineComponent } from 'vue'
import Icon from '../Icon.vue'
import { mdiClose, mdiContentDuplicate, mdiHelpCircle } from '@mdi/js';

export default defineComponent({
    data() {
        return {
            signageItemLayoutData: {} as any,

            // Icons
            // ------
            mdiContentDuplicate,
            mdiClose,
            mdiHelpCircle
        }
    },

    components: {
        Icon
    },

    props: {
        layoutDataEdit: {
            type: Object,
            default: null
        },
    },

    computed: {
        isUrlValid(): boolean {
            const regex = new RegExp("https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,20}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)");
            if(regex.test(this.signageItemLayoutData.url)) {
                return true;
            }
            return false;
        },
    },

    created () {
        this.signageItemLayoutData = JSON.parse(JSON.stringify(this.layoutDataEdit));
    },

    watch: {
        'signageItemLayoutData': {
            handler(newValue: any){
                this.$emit('updateLayoutData', {updatedLayoutData: newValue, shouldUpdatePreview: false});
            },
            deep: true
        }
    }
})
