
import { defineComponent } from 'vue';
import View from 'ol/View'
import Map from 'ol/Map'
import TileLayer from 'ol/layer/Tile'
import OSM from 'ol/source/OSM'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { defaults as defaultControls } from 'ol/control';
import { fromLonLat } from 'ol/proj';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style'
import { Draw, Snap, Modify } from 'ol/interaction';
import * as proj from 'ol/proj';
import Polygon from 'ol/geom/Polygon';
import Feature from 'ol/Feature';
import 'ol/ol.css'
import GeometryType from 'ol/geom/GeometryType';

export default defineComponent({
    name: 'MapContainer',
    data() {
        return {
            olMap: {} as Map,
            vectorLayer: {} as VectorLayer<any>,
            selectedFeature: null,
            vectorSource: {} as VectorSource<any>,
            mapLoading: true as boolean
        }
    },
    components: {},
    props: ['alertPolygon'],
    async mounted(): Promise<void> {
        const raster = new TileLayer({
            source:
                new OSM()
        });

        this.vectorSource = new VectorSource();

        this.vectorLayer = new VectorLayer({
            source: this.vectorSource as VectorSource<any>,
            style: new Style({
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.7)'
                    //,opacity: 1
                }),
                stroke: new Stroke({
                    color: '#add8e6',
                    width: 5
                }),
                image: new CircleStyle({
                    radius: 7,
                    fill: new Fill({
                        color: '#ffcc33'
                    })
                })
            })
        });

        this.vectorLayer.setZIndex(9);
        
        // default to center of the US
        let latLonWebMercator = fromLonLat([-98.5795, 39.8283]);
        let zoomLevel = 4;
        
        if(!this.alertPolygon) {
            await new Promise<void>((resolve) => {
                console.log('Prompting for geolocation');
                navigator.geolocation.getCurrentPosition((geoloc) => {
                    latLonWebMercator = fromLonLat([geoloc.coords.longitude, geoloc.coords.latitude]);
                    zoomLevel = 17;
                    resolve();
                }, function () {
                    console.log('User failed to allow geolocation');
                    resolve();
                });
            })
        }

        

        raster.setZIndex(8);

        this.olMap = new Map({
            target: this.$refs['map-root'] as any,
            layers: [this.vectorLayer as VectorLayer<any>, raster],
            view: new View({
                zoom: zoomLevel,
                center: latLonWebMercator
            }),
            controls: defaultControls()
        })    
        
        this.addInteractionListeners();

        this.mapLoading = false;
    },
    watch: {
        alertPolygon: {
            handler: async function(): Promise<void> {
                while(!this.olMap || !(this.olMap as any).ol_uid) {
                    // we don't have a map yet so we have to sit tight a minute.
                    console.log('Map not ready yet');
                    await new Promise<void>((resolve) => {
                        setTimeout(()=>{resolve()}, 1000);
                    }); 
                }
                
                if(!this.alertPolygon) return;

                const modify = new Modify({ source: this.vectorSource as VectorSource<any>});

                const convertedPoints = [];

                //we need to convert each lat/long into the WebMercator format
                for(const coordinatePair of this.alertPolygon) {
                    const transformedGPSCoords = proj.transform([coordinatePair[1], coordinatePair[0]], 'EPSG:4326', 'EPSG:3857');  //openlayer wants long / lat order flip our data and convert to XY
                    convertedPoints.push([transformedGPSCoords[0], transformedGPSCoords[1]]);
                }

                //setup our polygon defaults that we're going to draw
                const polygon = new Polygon([convertedPoints]);
                
                const polygonFeature = new Feature(polygon);

                this.vectorSource.addFeature(polygonFeature);

                // Create vector layer attached to the vector source.
                let locationVectorLayer = new VectorLayer({
                    source: this.vectorSource as VectorSource<any>
                }) as any;

                // Add the vector layer to the map ontop.
                this.olMap.addLayer(locationVectorLayer);
                //this.vectorSource.setZIndex(10);
            
                this.olMap.addInteraction(modify);

                //make sure our drawn vector box is focused in
                if (this.vectorSource) {
                    const locationVectorExtent = this.vectorSource.getExtent();
                    this.olMap.getView().fit(locationVectorExtent, {size: this.olMap.getSize()});
                }
            },
            immediate: true
        }
    },
    methods: {
        clear () {
            try{
            this.vectorSource.clear();
            }
            catch(e:any) {
                console.error('failed to clear vector source', e);
            }
        },
        
        addInteractionListeners() {
            const draw = new Draw({
                source: this.vectorSource as VectorSource<any>,
                type: GeometryType.POLYGON
            });

            draw.on('drawstart', () => {
                this.clear();
            });

            draw.on('drawend', (draw) => {
                // Go through this drawn feature and get coordinates of their geometry.
                const coordinates = (draw.feature.getGeometry() as any).getCoordinates();
                const gpsCoordinates = [];

                for(const cordinate of coordinates) {
                    for(const coord of cordinate) {
                        const transformedGPSCoords = proj.transform([coord[0], coord[1]], 'EPSG:3857', 'EPSG:4326'); //converts to long/lat format
                        gpsCoordinates.push([transformedGPSCoords[1], transformedGPSCoords[0]]); //now flip it to lat / long when we store it
                    }
                }

                // thats not gonna work
                // this.alertPolygon = gpsCoordinates;
                this.$emit('alert-polygon-change', gpsCoordinates)

            });
            this.olMap.addInteraction(draw);
            const snap = new Snap({ source: this.vectorSource as VectorSource<any> });
            this.olMap.addInteraction(snap);  
        }
    }
})
