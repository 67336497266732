
import { defineComponent } from 'vue'
import DigitalAssetUpload from '../../components/DigitalAssetUpload.vue'

export default defineComponent({
    data() {
        return {
            signageItemLayoutData: {} as any
        }
    },

    components: {
        DigitalAssetUpload
    },

    props: {
        layoutDataEdit: {
            type: Object,
            default: null
        },
        layoutType: {
            type: String,
            default: ''
        },
        signageAssets: {
            type: Object,
            default: null
        },
        layoutDisbabled: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        isUrlValid(): boolean {
            const regex = new RegExp("https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,20}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)");
            if(regex.test(this.signageItemLayoutData.url)) {
                return true;
            }
            return false;
        },

        assetUrl() {
            if (this.signageAssets && this.signageAssets.length) {
                const signageAsset = this.signageAssets.find((sa: any) => sa.id == this.signageItemLayoutData.media.asset1);
                if (signageAsset && signageAsset.thumbUrl) {
                    return signageAsset.thumbUrl;
                }
                return null;
            }
            return null;
        },

        isTweet(): boolean {
            return this.signageItemLayoutData.url.includes('status');
        }
    },

    created () {
        this.signageItemLayoutData = JSON.parse(JSON.stringify(this.layoutDataEdit));
    },

    watch: {
        'signageItemLayoutData': {
            handler(newValue: any){
                this.$emit('updateLayoutData', {updatedLayoutData: newValue, shouldUpdatePreview: false});
            },
            deep: true
        }
    }
})
