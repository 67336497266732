
import { defineComponent } from 'vue';
import { getModule } from 'vuex-module-decorators'

import GroupState, { IGroupState } from '../../store/modules/groups';
import RoomState, { IRoomState } from '../../store/modules/rooms';
import { IRoom, EnableRoomSettings, EnableRoomLocationSettings } from '../../store/interfaces/IRoom';
import LocationState, { ILocationState } from '../../store/modules/locations';
import { ILocation, ILocationDropDownList } from '../../store/interfaces/ILocation';
import ReceiverState, { IReceiverState } from '../../store/modules/receivers';
import { IReceiver, IReceiverDropDownList, IReceiverListDisplay } from '../../store/interfaces/IReceiver';
import { IUser } from '../../store/interfaces/IUser';
import UserState, { IUserState } from '../../store/modules/users';
import OnboardStatusState, { IOnboardStatusState } from '../../store/modules/onboarding';
import { mdiAlertCircleOutline, mdiRefresh, mdiHelp, mdiExclamationThick, mdiMagnify } from '@mdi/js';
import { IOnboardStatus } from '../../store/interfaces/IOnboardingStatus';
import PageDirtyService from '../../services/PageDirtyService';
import { IOrganization } from '../../store/interfaces/IOrganization';
import Icon from '../../components/Icon.vue';
import { IReceiverModelSearch } from '../../store/interfaces/IModelSearch';
import SignageListState, { ISignageListState } from '../../store/modules/signageList';
import { ISignageList }  from '../../store/interfaces/ISignage';
import SortService from '../../services/SortService';
import TierLimit from '../../components/TierLimit.vue';
import TierState, { ITierState } from '../../store/modules/tier';
import ReceiverStatusState, { IReceiverStatusState } from '../../store/modules/receiverstatuses';

let roomState = {} as IRoomState;
let userState = {} as IUserState;
let groupState = {} as IGroupState;
let locationState = {} as ILocationState;
let receiverState = {} as IReceiverState;
let onboardStatusState = {} as IOnboardStatusState;
let signageListState = {} as ISignageListState;
let tierState = {} as ITierState;
let receiverStatusState = {} as IReceiverStatusState;


export interface IDisplayLocation extends ILocation {
    displayName:string
}

export default defineComponent({
    name: 'RoomDetailsView',
    data() {
        const validateRoomCode = (rule: any, value: any, callback: any) => {
            const _thisCast = this as any;
            if(!_thisCast.room.id) return callback();

            if(!this.roomCodeMutation || this.roomCodeMutation.toString() === '') return callback(new Error('Please enter a room code.'));

            if(this.roomCodeEditEnabled) {
                if(this.roomCodeMutation.toString().replace(/\s/g,'').length < 6) return callback(new Error('Room code must be at least 6 characters.'));
                if(this.roomCodeMutation.length > 25) return callback(new Error('Room code must be less than 25 characters.'));
            }

            callback();
        };
        const validateModeratorPassword = (rule: any, value: any, callback: any) => {
            const _this = this as any;
            if(!_this.room.enableModeratorControls) return callback();
            
            if(_this.room.moderatorConnectionType === 'password' && (!value || value === '')) return callback(new Error('Please enter a password.'));

            callback();
        };
        return {
            domLoaded: false as boolean,
            room: {} as IRoom,
            receivers: [] as IReceiverListDisplay[],
            availableReceivers: [] as IReceiverDropDownList[],
            allLocations: [] as ILocationDropDownList[],
            organization: {} as IOrganization,
            me: undefined as IUser | undefined,
            receiverToAdd: {} as IReceiver,
            mdiRefresh,
            mdiAlertCircleOutline,
            mdiHelp,
            mdiExclamationThick,
            mdiMagnify,
            viewLoading: true,
            saving: false,
            roomCodeWarningVisible: false,
            roomCodeEditEnabled: false,
            roomCodeMutation: '',
            removeReceiverWarningVisible: false,
            deleteRoomWarningVisible: false,
            activeTab: 'overview',
            groupsApiLoading: false,
            removeReceiversApiLoading: false,
            addReceiversApiLoading: false,
            deleteRoomApiLoading: false,
            chosenGroups: [],
            timer: '' as any,
            showTierDialog: false,
            rules:{
                roomName:[{required: true, message: 'Please enter a room name.', trigger: 'change'}],
                roomCode: { required: true, validator: validateRoomCode, trigger: 'change' },
                moderatorPassword: [{ required: true, validator: validateModeratorPassword, trigger: 'change' }]
            },
            allSignageLists: [] as ISignageList[] | undefined,
            signageListsFilter: '' as string,
            isSignageListsLoading: false,
            selectedLists: [] as ISignageList[],
            daysOfWeek: [
                {
                    order:1,
                    name: 'Mon',
                    value: 'Monday'
                },
                {   
                    order:2,
                    name: 'Tues',
                    value: 'Tuesday'
                },
                {
                    order:3,
                    name: 'Wed',
                    value: 'Wednesday'
                },
                {
                    order:4,
                    name: 'Thurs',
                    value: 'Thursday'
                },
                {
                    order:5,
                    name: 'Fri',
                    value: 'Friday'
                },
                {
                    order:6,
                    name: 'Sat',
                    value: 'Saturday'
                },
                {
                    order:7,
                    name: 'Sun',
                    value: 'Sunday'
                },
            ] as any
        }
    },
    inject: [
        'userOnboardStatus',
        'currentStep',
        'windowWidth',
        'hideOnboardingByAccess'
    ],
    props: ["creationMode"],
    updated() {
        this.$nextTick(()=> {
            this.domLoaded = true;
        });
    },
    computed: {
        filteredSignageLists(): ISignageList[] | undefined {
            if(this.signageListsFilter) {
                const searchValue = this.signageListsFilter.toLowerCase();
                return (this as any).allSignageLists.filter((signageList: ISignageList)=> {
                    const signageName = signageList.name.toLowerCase();
                    return  signageName.includes(searchValue);
                });
            } else {
                return this.allSignageLists;
            }
        },

        onboardingDisable() {
            if(onboardStatusState && onboardStatusState.userOnboardStatus && onboardStatusState.userOnboardStatus.isOnboarding && onboardStatusState.userOnboardStatus.showDialog && (!this.room.roomName || !this.receiverToAdd.displayName)) {
                return true;
            }
            return false;
        },

        isRoomCodeWarningVisable(): boolean {
            if(this.roomCodeEditEnabled) return false;
            return this.roomCodeWarningVisible;
        },

        showConnectionInfo(): boolean {
            return this.room.enableMirroring;
        },

        showSignageDateTimeSettings(): boolean {
            return this.room.enableSignage;
        },

        allGroups(): any[] | undefined {
            return groupState.allGroups;
        },

        hasSSPReceiver(): boolean {
            if(!this.receiversDisplay) return false;
            return this.receiversDisplay.some(x => x.deviceType.toUpperCase() === 'DITTO RECEIVER');
        },

        isTrial(): boolean {
            if(!userState.me ||
                !userState.me.organization ||
                !userState.me.organization.billing ||
                !userState.me.organization.billing.billing_type
            ) return true;

            return userState.me.organization.billing.billing_type.toLowerCase() === 'trial';
        },

        noReceiversAvailable(): boolean {
            if(this.availableReceivers.length == 0) {return true}
            return false;
        },

        noReceiversAvailablePlaceholder(): string {
            if(this.availableReceivers.length == 0) {return 'No Receivers Available'}
            return 'Select';
        },

        noLocationsAvailable(): boolean {
            if(!this.allLocations || this.allLocations.length == 0) {return true}
            return false;
        },

        noLocationsAvailablePlaceholder(): string {
            if(!this.allLocations || this.allLocations.length == 0) {return 'No Locations Available'}
            return 'Select a location to link';
        },

        receiversDisplay: function(): IReceiverListDisplay[] {
            if(!this.receivers) return [];

            return this.receivers.map((x: IReceiverListDisplay) => {
                return {...x, 
                    deviceType: x.deviceType?.replace(/SSP/g,'Ditto Receiver'),
                    platform: x.platform?.replace(/windows/g, 'Windows').replace(/tvos/g, 'tvOS').replace(/other/g, 'Other').replace(/chromecast/g, 'Chromecast').replace(/atvlegacy/g, 'ATV 2/3')
                };
            });
        },

        showSecurityWarning() {
            if(this.receiversDisplay) {
                const distinctSecurityTypes = [...new Set(this.receiversDisplay.map(x => x.security))];
                if(distinctSecurityTypes.length > 1) {
                    return true;
                }
            }
            return false;
        },

        onboardingRoom(): boolean {
            return ((this as any).userOnboardStatus && !(this as any).userOnboardStatus.value.showDialog && (this as any).userOnboardStatus.value.isOnboarding && (this as any).userOnboardStatus.value.room && (this as any).userOnboardStatus.value.room.id == this.room.id) ?? false;
        },

        onboardingReceiverToAdd(): boolean {
            return ((this as any).userOnboardStatus && !(this as any).userOnboardStatus.value.showDialog && (this as any).userOnboardStatus.value.isOnboarding && (this as any).userOnboardStatus.value.device && (this as any).userOnboardStatus.value.device.id == this.receiverToAdd.id) ?? false;
        },

        roomReceiverStatus() : string {
            if(this.receiversDisplay) {
                const deviceCount = this.receiversDisplay.length;
                if(deviceCount == 0) { return '';}

                const roomReceiverStatuses = receiverStatusState.statusesByRoomId(this.room.id);
                const activeReceivers = this.receiversDisplay ? this.receiversDisplay.map(x => x.id) : [];
                const activeReceiversStatuses = roomReceiverStatuses ? roomReceiverStatuses.filter(x => activeReceivers.includes(x.receiverId)) : [];

                let roomReceiverStatusesCount = 0;

                if (activeReceiversStatuses) {
                    roomReceiverStatusesCount = activeReceiversStatuses.length;
                }

                if (roomReceiverStatusesCount > 0 && roomReceiverStatusesCount == deviceCount) {
                    return 'ONLINE';
                }
                else if (roomReceiverStatusesCount > 0 && roomReceiverStatusesCount < deviceCount) {
                    return 'WARNING';
                }
                else if (!this.receiversDisplay.some(x => x.deviceType && x.deviceType.toLowerCase() === 'ditto receiver')) {
                    return 'LEGACY';
                }
                else {
                    return 'OFFLINE';
                }
            }
            else {
                return '';
            }
        },

        enableHigherResolutionSetting: {
            get: function(): boolean {
                if(this.room.enableHigherResolution == EnableRoomSettings.true) {
                    return true;
                }
                else if(this.room.enableHigherResolution == EnableRoomSettings.false) {
                    return false;
                }
                else {
                    return this.organization.enableHigherResolution ?? false;
                }
            },
            set: function (newValue: boolean) {
                if(newValue == this.organization.enableHigherResolution) {
                    this.room.enableHigherResolution = EnableRoomSettings.useOrgSetting;
                    return;
                }
                else {
                    if(newValue == true) {
                        this.room.enableHigherResolution = EnableRoomSettings.true;
                        return;
                    }
                    this.room.enableHigherResolution = EnableRoomSettings.false;
                    return;
                } 
            }
        },

        showSignageDateTimeSetting: {
            get: function(): boolean {
                if(this.room.showSignageDateTime == EnableRoomSettings.true) {
                    return true;
                }
                else if(this.room.showSignageDateTime == EnableRoomSettings.false) {
                    return false;
                }
                else {
                    return this.organization.showSignageDateTime ?? false;
                }
            },
            set: function (newValue: boolean) {
                if(newValue == this.organization.showSignageDateTime) {
                    this.room.showSignageDateTime = EnableRoomSettings.useOrgSetting;
                    return;
                }
                else {
                    if(newValue == true) {
                        this.room.showSignageDateTime = EnableRoomSettings.true;
                        return;
                    }
                    this.room.showSignageDateTime = EnableRoomSettings.false;
                    return;
                } 
            }
        },

        signageDateTimeLocationSetting: {
            get: function(): string {
                if(this.room.signageDateTimeLocation == EnableRoomLocationSettings.useOrgSetting) {
                    return this.organization.signageDateTimeLocation ?? 'topRight';
                }
                else {
                    return ({
                        0: 'topLeft',
                        1: 'topRight',
                        2: 'bottomLeft',
                        3: 'bottomRight'
                    }[this.room.signageDateTimeLocation])
                }
            },
            set: function (newValue: string) {
                if(newValue == this.organization.signageDateTimeLocation) {
                    this.room.signageDateTimeLocation = EnableRoomLocationSettings.useOrgSetting;
                    return;
                }
                else {
                    switch(newValue) {
                        case 'topRight':
                            this.room.signageDateTimeLocation =  EnableRoomLocationSettings.topRight;
                            break;
                        case 'topLeft': 
                            this.room.signageDateTimeLocation =  EnableRoomLocationSettings.topLeft;
                            break;
                        case 'bottomLeft':
                            this.room.signageDateTimeLocation =  EnableRoomLocationSettings.bottomLeft;
                            break;
                        case 'bottomRight':
                            this.room.signageDateTimeLocation =  EnableRoomLocationSettings.bottomRight;
                            break;
                        default:
                            this.room.signageDateTimeLocation =  EnableRoomLocationSettings.topRight;
                            break;
                    }
                    return;
                } 
            }
        },

        hasAlerts(): boolean {
            return tierState.orgTier.emergencyAlertsEnabled;
        },

        hasSignage(): boolean {
            return tierState.orgTier.digitalSignageEnabled;
        },

        hasModeratorControls(): boolean {
            return tierState.orgTier.moderatorControlsEnabled;
        },

        canActivateReceivers(): boolean {
            return tierState.canActivateReceivers;
        },

        isMultiReceiverPerRoomEnabled(): boolean {
            return tierState.orgTier.multiReceiverPerRoomEnabled;
        },

        canAddReceivers(): boolean {
            if(!tierState.canActivateReceivers) {
                return false;
            }

            if(!this.receiversDisplay || (this.receiversDisplay && this.receiversDisplay.length == 0)) {
                return true;
            }

            if(this.receiversDisplay && this.receiversDisplay.length > 0 && this.isMultiReceiverPerRoomEnabled) {
                return true;
            }
            
            return false;
        },

        showTierCallout(): boolean {
            if((this.hasSSPReceiver && (!this.hasAlerts || !this.hasSignage || !this.hasModeratorControls )) || !this.canActivateReceivers || (this.receiversDisplay && this.receiversDisplay.length > 0 && !this.canAddReceivers)) {
                return true;
            }
            return false;
        },

        tierLimitReachedMessage() {
            let upgradeText = ''
            if(!this.creationMode && ((this.hasSSPReceiver && (!this.hasAlerts || !this.hasSignage || !this.hasModeratorControls)) || (this.receiversDisplay && this.receiversDisplay.length > 0 && !this.canAddReceivers)) && !this.canActivateReceivers) {
                upgradeText = 'link additional receivers or to unlock more features'
            }
            else if (!this.creationMode && ((this.hasSSPReceiver && (!this.hasAlerts || !this.hasSignage || !this.hasModeratorControls)) || (this.receiversDisplay && this.receiversDisplay.length > 0 && !this.canAddReceivers))) {
                upgradeText = 'unlock more features';
            }
            else if (!this.canActivateReceivers ) {
                upgradeText = 'link additional rooms and receivers';
            }

            return `Upgrade your Ditto account to ${upgradeText}.`;
        },

        upgradeText(): string {
            return 'Upgrade your Ditto account to unlock this feature.';
        }
    },

    components: {
        Icon,
        TierLimit
    },

    methods: {
        sortTable(e: any) {
            if(e.prop == null) {
                this.receivers.sort((a: any,b: any)=>{
                    return a.displayName.localeCompare(b.displayName)
                });

                return;
            }

            if(e.prop !== 'lastKnownVersion') return;
            console.log(e);

            this.receivers.sort((a: any, b: any)=>{
                // handle when we don't have version#
                let aVersion = 0;
                let bVersion = 0;

                if(a.lastKnownVersion) {
                    const aSplit = a.lastKnownVersion.split('.')
                    const aMajor = aSplit[0] ? Number(aSplit[0])*1000000 : 0;
                    const aMinor = aSplit[1] ? Number(aSplit[1])*1000 : 0;
                    const aPatch = aSplit[2] ? Number(aSplit[2]) : 0;

                    aVersion = aMajor + aMinor + aPatch;

                    console.log('aVersion', aVersion);
                }

                if(b.lastKnownVersion) {
                    const bSplit = b.lastKnownVersion.split('.')
                    const bMajor = bSplit[0] ? Number(bSplit[0])*1000000 : 0;
                    const bMinor = bSplit[1] ? Number(bSplit[1])*1000 : 0;
                    const bPatch = bSplit[2] ? Number(bSplit[2]) : 0;

                    bVersion = bMajor + bMinor + bPatch;

                    console.log('bVersion', bVersion);
                }

                if(e.order == 'ascending') {
                    return aVersion - bVersion;
                }
                else {
                    return bVersion - aVersion;
                }
            });
        },
        async createNewOnboarding() {
            let newOnboarding = {} as IOnboardStatus;
            newOnboarding.organization = userState.me && userState.me.organization ? userState.me.organization : undefined;
            newOnboarding.user = userState.me;
            newOnboarding.initiatedUser = userState.me;
            newOnboarding.isOnboarding = true;
            newOnboarding.showDialog = true;
            newOnboarding.guideStepId = 'overviewInitial';
            newOnboarding.step = 1;

            await onboardStatusState.createOnboardStatus(newOnboarding);
            await onboardStatusState.fetchOnboardStatus(true);
            if(!(this as any).hideOnboardingByAccess.value) {
                onboardStatusState.start();
            }
        },

        validateForm (): Promise<boolean> {
            return new Promise((resolve) => {
                (this as any).$refs['form'].validate((valid: boolean, obj: any) => {
                    if(!valid) {
                        let errorHTML = '';
                        for(const key in obj) {
                            for(const err of obj[key]) {
                                errorHTML = `${errorHTML}${err.message}<br>`;
                            }
                        }
                        console.error(errorHTML);
                        (this as any).$message({
                            dangerouslyUseHTMLString: true,
                            message: errorHTML,
                            type: 'error'
                        });
                    }

                    resolve(valid);
                });
            });
        },

        onCancel() {
            this.$router.push({ path:'/rooms' });
        },

        downloadRoomBackgroundHandler() {
            this.$router.push({ path: `/rooms/background/${this.room.id}` })
        },

        downloadRoomSignHandler() {
            this.$router.push({ path: `/rooms/instructions/${this.room.id}` })
        },

        async onSubmit () {
            if(this.creationMode && this.onboardingReceiverToAdd) {
                await (this as any).$confirm('This receiver was created using the Ditto setup tutorial. Adding this receiver before completing the Ditto setup tutorial will cause the tutorial to restart from the beginning. Are you sure you want to add this receiver?', 'Warning', {
                    confirmButtonText: 'Continue',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                });

                await onboardStatusState.end();
                await this.createNewOnboarding();
            }

            if(this.onboardingRoom) {
                await (this as any).$confirm('This room was created using the Ditto setup tutorial. Editing this room before completing the Ditto setup tutorial will cause the tutorial to restart from the beginning. Are you sure you want to edit this room?', 'Warning', {
                    confirmButtonText: 'Continue',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                });
            }

            if(this.roomCodeEditEnabled) {
                this.room.roomCode = this.roomCodeMutation
            }
            if(!await this.validateForm()) return;
            this.saving = true;

            let storeResponse = null;
            if(this.creationMode) {
                storeResponse = await roomState.createRoom(this.room);
            } else {
                storeResponse = await roomState.updateRoom(this.room);
            }

            if(storeResponse.success && storeResponse.data && this.creationMode) {
                this.room = storeResponse.data ? storeResponse.data : this.room;
                this.roomCodeMutation = this.room.roomCode ?? '';
                
                if(this.receiverToAdd.id) {
                    storeResponse = await roomState.addReceivers({roomId: storeResponse.data.id, receiverIds: [this.receiverToAdd.id]})
                    this.receiverToAdd = {} as IReceiver;
                    await tierState.fetchActivateReceiversCheck(true);
                }
            }

            if(!storeResponse.success) {
                (this as any).$message.error(storeResponse.reason ? `Room ${this.creationMode? 'creation' : 'update'} failed: ${storeResponse.reason}` : `Room ${this.creationMode? 'creation' : 'update'} failed`);
                this.saving = false;
                return;
            }

            if(this.creationMode) {
                (this as any).$message.success('Room has been created.');
                
                if(this.room && this.room.id) {        
                    this.$router.push({ path: `/rooms/${this.room.id}` });
                }
            } else {
                (this as any).$message.success('Room has been updated.');
            }

            if(onboardStatusState && onboardStatusState.userOnboardStatus && onboardStatusState.userOnboardStatus.isOnboarding && onboardStatusState.userOnboardStatus.showDialog) {
                await onboardStatusState.attachRoom(this.room);
                await onboardStatusState.nextStep();
                this.$router.push({ path: `/rooms` });
            }

            if(this.onboardingRoom) {
                await onboardStatusState.end();
                await this.createNewOnboarding();
            }

            await roomState.fetchRoomsDDL(true);
            await this.loadRoom(true);

            PageDirtyService.markPageClean();

            this.saving = false;
        },

        async onDelete () {
            this.deleteRoomWarningVisible = true;
            this.deleteRoomApiLoading = true;
            this.saving = true;
            try {
                await (this as any).$confirm(`${this.onboardingRoom ? 'This room was created using the Ditto setup tutorial. Deleting this room before completing the Ditto setup tutorial will cause the tutorial to restart from the beginning. ' : ''}Are you sure you want to delete this room?`, 'Warning', {
                    confirmButtonText: 'Continue',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                });

                const storeResponse = await roomState.deleteRoom({roomId: this.room.id, locationId: this.room.location ? this.room.location.id : undefined});

                if(!storeResponse.success) {
                    console.error("Update Failed", storeResponse.reason);
                    this.deleteRoomWarningVisible = false;
                    this.deleteRoomApiLoading = false;
                    this.saving = false;
                    return;
                }

            if(this.onboardingRoom) {
                await onboardStatusState.end();
                await this.createNewOnboarding();
            }

                (this as any).$message.success('Room has been deleted.');
                    
                PageDirtyService.markPageClean();
                await tierState.fetchActivateReceiversCheck(true);
                
                this.$router.push({ path: '/rooms' });
            }
            catch{
                //canceled
            }

            this.deleteRoomWarningVisible = false;
            this.deleteRoomApiLoading = false;
            this.saving = false;
        },

        async addReceiversHandler() {
            if(!this.canActivateReceivers) {
                this.showTierDialog = true;
                return;
            }

            if(this.onboardingReceiverToAdd) {
                await (this as any).$confirm('This receiver was created using the Ditto setup tutorial. Adding this receiver before completing the Ditto setup tutorial will cause the tutorial to restart from the beginning. Are you sure you want to add this receiver?', 'Warning', {
                    confirmButtonText: 'Continue',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                });

                await onboardStatusState.end();
                await this.createNewOnboarding();
            }

            if (this.hasSSPReceiver && this.receiverToAdd.deviceType.toLowerCase() != 'ssp') {
                (this as any).$message.error('Can not add a legacy receiver to a room containing a Ditto Receiver');
                this.addReceiversApiLoading = false;
                return;
            }

            if(!this.receiverToAdd || !this.receiverToAdd.id) return;
            const receiverIds = [] as string[];
            receiverIds.push(this.receiverToAdd.id);
            this.addReceiversApiLoading = true;
            const payload = {
                receiverIds: receiverIds,
                roomId: this.room.id
            }

            const storeResponse = await roomState.addReceivers(payload);

            if(!storeResponse.success) {
                (this as any).$message.error(storeResponse.reason ? `Room update failed: ${storeResponse.reason}` : `Room update failed`);
                this.addReceiversApiLoading = false;
                return;
            }
            
            await this.loadRoom(true);

            this.receiverToAdd = {} as IReceiver;

            this.getAvailableReceivers(true);

            await tierState.fetchActivateReceiversCheck(true);

            this.addReceiversApiLoading = false;
            (this as any).$message.success('Receiver added to room.');
        },


        async removeReceiverHandler(receiver: IReceiver) {
            let onboardingReceiverToRemove : boolean = ((this as any).userOnboardStatus && !(this as any).userOnboardStatus.value.showDialog && (this as any).userOnboardStatus.value.isOnboarding && (this as any).userOnboardStatus.value.device && (this as any).userOnboardStatus.value.device.id == receiver.id) ?? false;
            try {
                if(onboardingReceiverToRemove) {
                    await (this as any).$confirm('This receiver was created using the Ditto setup tutorial. Removing this receiver before completing the Ditto setup tutorial will cause the tutorial to restart from the beginning. Are you sure you want to remove this receiver?', 'Warning', {
                        confirmButtonText: 'Continue',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    });

                    await onboardStatusState.end();
                    await this.createNewOnboarding();
                } else if (this.room.devices.length === 1) {
                    await (this as any).$confirm('Are you sure you want to remove this receiver? This is the only receiver in the room and users will no longer be able to share.', 'Warning', {
                        confirmButtonText: 'Unlink Receiver',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    });
                }

                this.removeReceiversApiLoading = true;
                const storeResponse = await roomState.removeReceivers([receiver.id]);

                if(!storeResponse.success) {
                    (this as any).$message.error(storeResponse.reason ? `Room update failed: ${storeResponse.reason}` : `Room update failed`);
                    return;
                }
                
                await this.loadRoom(true);

                this.getAvailableReceivers(true);

                await tierState.fetchActivateReceiversCheck(true);

                (this as any).$message.success('Receiver removed from room.');
                this.removeReceiversApiLoading = false;
            }
            catch {
                // do nothing
            }
        },

        showRoomCodeWarning() {
            if(this.roomCodeEditEnabled) return;
            this.roomCodeWarningVisible = true;
        },

        changeRoomCode() {
            if(!this.roomCodeEditEnabled) {
                this.roomCodeWarningVisible = true;
                this.roomCodeMutation = this.room.roomCode ?? '';
                return;
            }
        },

        cancelRoomCodeWarning() {
            this.roomCodeEditEnabled = false;
            this.roomCodeWarningVisible = false;
        },

        async confirmEditRoomCode() {
            this.roomCodeEditEnabled = true;
            await this.validateForm();
            this.roomCodeWarningVisible = false;
        },

        async roomCodeRefreshHandler() {
            this.viewLoading = true;
            const roomFromState = (await roomState.fetchRoom({id:this.$route.params.id as string, reload: false})).data;
            if(!roomFromState) return;

            roomFromState.roomCode = null;

            const storeResponse = await roomState.updateRoom(roomFromState);

            if(!storeResponse.success || !storeResponse.data) {
                    (this as any).$message.error(storeResponse.reason ? `Room code change failed: ${storeResponse.reason}` : `Room code change failed`);
                    this.viewLoading = false;
                    return;
                }

            this.room.roomCode = storeResponse.data.roomCode;
            this.roomCodeMutation = storeResponse.data.roomCode?? '';
            (this as any).$message.success('Room code changed');
            this.viewLoading = false;
        },

        async selectHandler(e: any) {
            let groupToAddPromise = null as any;
            e.forEach((group: any, i: any)=> {
                if(typeof group == 'string') {
                    this.groupsApiLoading = true;
                    if(!this.me) return;

                    groupToAddPromise = groupState.createGroup({
                        name: group,
                        organization: this.me.organization,
                    });
                    e.splice(i, 1);
                }
            });

            const response = await groupToAddPromise;
            const mutatedGroups = [...this.room.groups.map((x:  any)=>Object.assign({}, x))];
            if (response) {
                mutatedGroups.push({
                    id: response.data.id,
                    type: 'Group'
                })
            }

            this.room.groups = mutatedGroups;
            this.groupsApiLoading = false;
        },

        async getAvailableReceivers(reload: boolean): Promise<void> {
            const availableReceiverResponse = await receiverState.fetchReceiversDLL(reload);
            const onboardingIsActive = onboardStatusState && onboardStatusState.userOnboardStatus && onboardStatusState.userOnboardStatus.isOnboarding && onboardStatusState.userOnboardStatus.showDialog;

            if (availableReceiverResponse.data && onboardingIsActive) {
                const onboardReceiverId = onboardStatusState.userOnboardStatus ? onboardStatusState.userOnboardStatus.device.id : null;
                const receiver = availableReceiverResponse.data.find((x:IReceiverDropDownList)=> x.id === onboardReceiverId);

                if (receiver) {
                    this.availableReceivers = [receiver];
                }
            } else {
                // remove onboarding receiver and all types that don't belong
                this.availableReceivers = availableReceiverResponse.success && availableReceiverResponse.data ? availableReceiverResponse.data : [];

                const deviceType = this.receivers && this.receivers.length > 0 ? this.receivers[0].deviceType : null;

                this.availableReceivers = this.availableReceivers.filter((x:IReceiverDropDownList) => {
                    let isAvailable = true;
                    if(x.room) {
                        isAvailable = false;
                    }                    
                    if(isAvailable && deviceType) {
                        isAvailable = x.deviceType == deviceType;    
                    }                 
                    return isAvailable;
                })
            }
        },

        async loadRoom(reload: boolean) {

            if(this.creationMode) {
                this.room = {} as IRoom;
                await this.getAvailableReceivers(reload);
                this.viewLoading = false; 
                return;
            }

            this.room = (await roomState.fetchRoom({id:this.$route.params.id as string, reload: reload})).data ?? {} as IRoom;

            await this.getAvailableReceivers(reload);

            const deviceIds = this.room.devices.map(x=>x.id);

            if(deviceIds && deviceIds.length > 0) {
                const receiverLookup: IReceiverModelSearch = {
                    pageIndex: 1,
                    pageSize: (deviceIds.length + 1),
                    ids: deviceIds
                };

                const lookupResponse = await receiverState.fetchReceiverDisplayList(receiverLookup);

                if(lookupResponse.data && lookupResponse.data.data) {
                    this.receivers = lookupResponse.data.data;
                }
            }
            else {
                this.receivers = [];
            }

            this.roomCodeMutation = this.room.roomCode ?? '';
            this.viewLoading = false;
        },

        handleCurrentChange(row: any, column: any) {
            if(column.property === "id") return;
            this.$router.push({ path: `/receivers/${row.id}` })
        },

        async changeDetection() { 
            let id = this.$route.params.id;
            // we're creating but we've already saved because we have an ID
            if(this.room.id && this.creationMode) {
                id = this.room.id;
            }

            if(this.creationMode) return;

            const room = (await roomState.fetchRoom({id:id as string, reload: false})).data ?? {} as IRoom;

            if( (this.organization.enableHigherResolution && this.room.enableHigherResolution == EnableRoomSettings.true)
                || (!this.organization.enableHigherResolution && this.room.enableHigherResolution == EnableRoomSettings.false) ) {
                room.enableHigherResolution = this.room.enableHigherResolution;
            }

            PageDirtyService.monitorObjectSet([
                {defaultObject: room, mutatedObject: this.room},
                {defaultObject: room.roomCode, mutatedObject: this.roomCodeMutation}
            ]);
        },

        receiverStatus(status: any): string {
            let recStatus = '-';
            switch(status) {
                case 1:
                    recStatus = 'LEGACY';
                    break;
                case 2: 
                    recStatus = 'OFFLINE';
                    break;
                case 3:
                    recStatus = 'ONLINE';
                    break;
            }

            return recStatus;
        },

        checkEnableModeratorControls() {
            if(!this.room.enableModeratorControls) {
                this.room.moderatorConnectionType = 'onScreenOneTime';
                this.room.moderatorPassword = '';
            }
        },

        checkModeratorType() {
            if(this.room.moderatorConnectionType !== 'password') {
                this.room.moderatorPassword = '';
            }
        },

        onRowClick(row: any) {
            this.$router.push('/signage/' + row.id);
        },

        async loadSignageListsForRooms() {
            this.isSignageListsLoading = true;
            const roomIds = [this.room.id];
            const storeResponse = await signageListState.fetchSignageListsForRooms(roomIds);
            if(!storeResponse.success) {
                (this as any).$message.error(storeResponse.reason ? `Signage list retrival failed: ${storeResponse.reason}` : 'Signage list retrival failed');
                return;
            }

            if(storeResponse.data) {
                this.allSignageLists = storeResponse.data.sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
            }
            this.isSignageListsLoading = false;
            return;
        },

        convert24to12(time: string) {
            return new Date ('1970-01-01T' + time + 'Z').toLocaleTimeString('en-US', { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric'});
        },

        sortByDay(a: any, b: any) {
            const first = this.daysOfWeek.find((day: any)=> day.value === a);
            const second = this.daysOfWeek.find((day: any)=> day.value === b);
            return first.order - second.order;
        },

        alphabeticalSort(a: any, b: any, column: string) {
            return SortService.Alphabetical(a[column],b[column]);
        }
    },

    beforeUnmount () {
        clearInterval(this.timer);
    },

    async created () {
        userState = getModule(UserState);
        roomState = getModule(RoomState);
        groupState = getModule(GroupState);
        locationState = getModule(LocationState);
        receiverState = getModule(ReceiverState);
        onboardStatusState = getModule(OnboardStatusState);
        signageListState = getModule(SignageListState);
        tierState = getModule(TierState);
        receiverStatusState = getModule(ReceiverStatusState);

        await Promise.all([
            groupState.fetchGroups(),
            userState.fetchMe(null),
            onboardStatusState.fetchOnboardStatus(),
            this.loadRoom(true),
            tierState.fetchTier(),
            tierState.fetchActivateReceiversCheck(),
            receiverStatusState.fetchStatuses()
        ]);

        if(!userState.me) {
            (this as any).$message.error("Error getting user information.");
            return;
        }

        this.allLocations = (await locationState.fetchLocationsDDL()).data ?? [];

        this.me = userState.me;  
        this.organization = this.me.organization;
        this.loadSignageListsForRooms();
        
        if(this.$route.hash.toString().toLowerCase() === '#receiver') {
            this.activeTab = this.$route.hash.toString().replace('#', '');
        }
    },

    watch: {
        room: {
            handler () {
                this.changeDetection();
            },
            deep: true
        },
        roomCodeMutation: {
            handler () {
                this.changeDetection();
            }
        }
    },
});

