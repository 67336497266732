
import { getModule } from 'vuex-module-decorators';
import { defineComponent } from 'vue';
import { mdiDelete, mdiCog, mdiExclamationThick, mdiCheckBold, mdiHelp, mdiShieldAccount, mdiShieldLock, mdiShieldStar } from '@mdi/js';
import Icon from '../../components/Icon.vue';
import { IRole } from '../../store/interfaces/IRole';
import { IUser } from '../../store/interfaces/IUser';
interface IRoleWithMembers extends IRole {
   members: any[]; 
}
import RoleState, { IRoleState } from '../../store/modules/role';
import UserState, { IUserState } from '../../store/modules/users';
import TierLimit from '../../components/TierLimit.vue';
import TierState, { ITierState } from '../../store/modules/tier';

let roleState = {} as IRoleState;
let userState = {} as IUserState;
let tierState = {} as ITierState;

export default defineComponent({
    name: 'RolesView',

    data() {
        return {
            rolesSearchFilter: '' as string,
            isLoading: false as boolean,
            mdiDelete, mdiCog, mdiExclamationThick, mdiCheckBold, mdiHelp, mdiShieldStar, mdiShieldAccount, mdiShieldLock,
            roleSearch: '' as string,
            roleFilter: '' as any,
            activeFilter: '',
            viewLoading: false as boolean,
        }
    },
    inject: [
        'windowWidth',
        'userOnboardStatus',
        'currentStep'
    ],

    computed: {
        isSystemRole(): boolean {
            return roleState.isSystemRole;
        },

        users(): IUser[] | undefined {
            return userState.allUsers;
        },

        roles(): any {
            return roleState.roles;
        },

        rolesWithMembersMap(): IRoleWithMembers[] {
            if(!this.roles) return [];

            const mappedRoles = this.roles.map((role: any)=> {
                return {
                    ...role,
                    members: this.users ? this.users.filter((user: IUser) => user.role === role.id) : []
                } as IRoleWithMembers;
            });

            const systemRoles = mappedRoles.filter((x: any) => x.readOnly).sort((a: any, b:any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
            const nonSystemRoles = mappedRoles.filter((x: any) => !x.readOnly).sort((a: any, b:any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
            return [...systemRoles, ...nonSystemRoles];
        },

        filteredRoles(): any{
            if(!this.rolesSearchFilter) return this.rolesWithMembersMap;
            const search = this.rolesSearchFilter.toLowerCase();
            return this.rolesWithMembersMap.filter((role: any) => role.name.toLowerCase().includes(search));
        },

        showTierCallout(): boolean {
            if(!tierState.orgTier.rolesEnabled) {
                return true;
            }
            return false;
        }
    },

    components: {
        Icon,
        TierLimit
    },

    methods: {
        async onDeleteRole(role: any) {
            if(role.readOnly || role.members.length) return;
            await (this as any).$confirm('Permanently delete role?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                });

            let storeResponse = await roleState.deleteRole(role.id);

            if(!storeResponse.success) {
                (this as any).$message.error(storeResponse.reason ? `Role deletion failed: ${storeResponse.reason}` : `Role deletion failed`);
                return;
            }

            (this as any).$message.success('Role has been deleted.');

            return;
        }
    },

    async created() {
        this.isLoading = true;
        roleState = getModule(RoleState);
        userState = getModule(UserState);
        tierState = getModule(TierState);

        await Promise.all([
            userState.fetchUsers(),
            userState.fetchMe(null),
            roleState.fetchRoles(),
            roleState.fetchUserRole(),
            tierState.fetchTier()
        ]); 

        if(!userState.me) {
            (this as any).$message.error("Error getting user information.");
            return;
        }

        // this.myId = userState.me.id;
        this.isLoading = false;
    }
});
