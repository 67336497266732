
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'NewReceiverPairingView',
    data() {
        return {
            domLoaded: false as boolean,

            pairingCodeForm: {
                pairingCode: null
            },

            rules: {
                pairingCode: [
                    {
                        required: true,
                        message: 'Please enter a pairing code',
                        trigger: 'blur'
                    },
                ],
            }
        }
    },

    inject: ['userOnboardStatus','currentStep'],
    mounted() {
        this.$nextTick(()=> {
            this.domLoaded = true;
        });
    },

    methods: {
        onNextStep() {
            this.$emit('pairing', null);
        },
        async onPair () {
            (this as any).$refs['pairingCodeForm'].validate((valid: any)=> {
                if(valid) {
                    this.$emit('pairing', this.pairingCodeForm.pairingCode);
                }
            });
       }
    }
});
