
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Lightbox',
    data() {
        return {
            isImageLoaded: false as boolean
        }
    },
    props: {
        // For v-model
        modelValue: Boolean,

        image: {
            type: String,
            default: 'https://images.unsplash.com/photo-1518335935020-cfd6580c1ab4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80'
        }
    },

    // For v-model
    emits: ['update:modelValue'],

    created() {
        window.addEventListener('keydown', this.onKeydown);
    },

    unmounted() {
        window.removeEventListener('keydown', this.onKeydown);
    },

    methods: {
        closeLightbox() {
            this.$emit('update:modelValue', false);
        },

        onKeydown(e: any) {
            if(e.key === "Escape") {
                this.closeLightbox();
            }
        },
        
        onImageLoad() {
            this.isImageLoaded = true;
        }
    },
})
