
import { getModule } from 'vuex-module-decorators';
import { defineComponent } from 'vue'
import { ElMessage } from 'element-plus'

import ModelSelector from '../ModelSelector.vue';
import TwoColumnSelector from '../TwoColumnSelector.vue';
import Icon from '../Icon.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import TierLimit from '../../components/TierLimit.vue';

// Interaces ---
import { IRoom, IRoomDropDownList } from '../../store/interfaces/IRoom';
import { IModelSelectionPool } from '../../store/interfaces/IModelSelectionPool';
import { ISignageList } from '../../store/interfaces/ISignage';
import { IDigitalAssetQuota } from '../../store/interfaces/IDigitalAssetQuota';

// State ---
import RoomState, { IRoomState } from '../../store/modules/rooms';
import UserState, { IUserState } from '../../store/modules/users';
import OrganizationState, { IOrganizationState } from '../../store/modules/organizations';
import SignageListState, { ISignageListState } from '../../store/modules/signageList';
import RoleState, {IRoleState} from '../../store/modules/role';
import TierState, { ITierState } from '../../store/modules/tier';

// Icons ---
import { mdiMagnify, mdiPencil, mdiDotsVertical, mdiDelete, mdiEye, mdiEyeOff, mdiAlertCircle, mdiCalendarClock, mdiExclamationThick } from '@mdi/js';

export interface IScheduleProperties {
    isDayEnabled: boolean;
    isDateEnabled: boolean;
    isTimeEnabled: boolean;
    scheduledTimeSet: boolean;
    activeDaysOfWeek: string[];
    startTime: null | Date;
    endTime: null | Date;
    startDate: null | Date;
    endDate: null | Date;
}

export interface IScheduleSignageListPayload {
    scheduleProperties: IScheduleProperties;
    signageLists: ISignageList[];
}

let signageListState = {} as ISignageListState,
    roomState = {} as IRoomState,
    userState = {} as IUserState,
    organizationState = {} as IOrganizationState,
    roleState = {} as IRoleState,
    tierState = {} as ITierState;

export default defineComponent({
    name: 'SignageLists',
    data() {
        return {
            openSignageItemContextId: null as string | null,
            scheduleType: 'Time',
            selectedSignageLists: [] as ISignageList[],
            value: new Date(),
            deleteConfirmationModal: false,
            allRooms: [] as IRoomDropDownList[],
            signageListsFilter: '' as string,
            signageAssetQuota: {} as IDigitalAssetQuota,
            signageListsToDelete: [] as ISignageList[],
            signageListsToShowHide: [] as ISignageList[],
            sigangeListToMutateRooms: null as ISignageList | null,
            newListRooms: [] as IRoom[],
            noAccessRooms: [] as IRoom[],
            isSchedulingSingleList: false as boolean,
            selectedSignageListsSwap: [] as any[],
            hasScheduling: false as boolean,
            hasListManagement: false as boolean,
            hasGoogleSlides: false as boolean,
            activeDaysOfWeek: [] as string[],
            selectedSignageListsNameCount: 5,
            showNoAccessRoomMessage: false as boolean,
            signageListDraft: {
                name:'',
                listType: '',
                rooms: []
            },

            daysOfWeek: [
                {
                    order:1,
                    name: 'Mon',
                    value: 'Monday'
                },
                {   
                    order:2,
                    name: 'Tues',
                    value: 'Tuesday'
                },
                {
                    order:3,
                    name: 'Wed',
                    value: 'Wednesday'
                },
                {
                    order:4,
                    name: 'Thurs',
                    value: 'Thursday'
                },
                {
                    order:5,
                    name: 'Fri',
                    value: 'Friday'
                },
                {
                    order:6,
                    name: 'Sat',
                    value: 'Saturday'
                },
                {
                    order:7,
                    name: 'Sun',
                    value: 'Sunday'
                },
            ] as any,

            massScheduleSettings: {
                isDayEnabled: false as boolean,
                isDateEnabled: false as boolean,
                isTimeEnabled: false as boolean,
                scheduledTimeSet: false as boolean,
                activeDaysOfWeek: [] as string[],
                startTime: null,
                endTime: null,
                dates: [] as Date[],
            } as any, 

            scheduleFormRules: {
                date: [{ validator: this.validateDate, trigger: 'blur' }],
                time: [{ validator: this.validateTime, trigger: 'blur' }],
                day: [{ validator: this.validateDay, trigger: 'blur' }],
                scheduled: [{ validator: this.validateScheduled, trigger: 'blur' }]
            } as any,

            // Loading flags
            // ==============
            isSignageListsLoading: false as boolean,
            isSignageQuotaLoading: false as boolean,
            isSignageDeleteLoading: false as boolean,
            isSetRoomsLoading: false as boolean,
            isNewSignageListLoading: false as boolean,
            isCreationModalVisible: false as boolean,
            isSignageScheduleLoading: false as boolean,

            // Popup Visibility Booleans
            // ==========================
            isScheduleModalVisible: false,
            isScheduleSaveWarningVisible: false as boolean,
            
            // Icons
            // ======
            mdiMagnify,
            mdiDotsVertical,
            mdiDelete, 
            mdiEye, 
            mdiPencil,
            mdiEyeOff,
            mdiAlertCircle,
            mdiCalendarClock,
            mdiExclamationThick
        }
    },
    components: {
        Icon,
        ModelSelector,
        TwoColumnSelector,
        TierLimit,
        VueDatePicker
    },
    
    methods: {
        sortByDay(a: any, b: any) {
            const first = this.daysOfWeek.find((day: any)=> day.value === a);
            const second = this.daysOfWeek.find((day: any)=> day.value === b);
            return first.order - second.order;
        },

        convert24to12(time: string) {
            return new Date ('1970-01-01T' + time + 'Z').toLocaleTimeString('en-US', { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric'});
        },

        validateDate(rule: any, value: any, callback: any) {
            if(this.massScheduleSettings.isDateEnabled && (!this.massScheduleSettings.dates[0] || !this.massScheduleSettings.dates[0])) {
                callback(new Error('Select start and end date.'));
            }
            callback();
        },

        validateTime(rule: any, value: any, callback: any) {
            if(this.massScheduleSettings.isTimeEnabled && (!this.massScheduleSettings.startTime || !this.massScheduleSettings.endTime)) {
                callback(new Error('Select start and end time.'));
            }
            callback();
        },

        validateDay(rule: any, value: any, callback: any) {
            if(this.massScheduleSettings.isDayEnabled && this.massScheduleSettings.activeDaysOfWeek.length < 1) {
                callback(new Error('Select days of the week.'));
            }
            callback();
        },

        validateScheduled(rule: any, value: any, callback: any) {
            if(this.massScheduleSettings.scheduledTimeSet && (!this.massScheduleSettings.isDayEnabled && !this.massScheduleSettings.isDateEnabled && !this.massScheduleSettings.isTimeEnabled)) {
                callback(new Error('Set schedule before saving.'));
            }
            callback();
        },

        async showHideSignageLists(list?: ISignageList) {
            let hide = false;

            if(list){
                this.signageListsToShowHide = [list];
                hide = ! list.hideListFromDisplay;
            }
            else if(!list && this.selectedSignageLists && this.selectedSignageLists.length > 0) {
                this.signageListsToShowHide = this.selectedSignageLists;
                hide = !this.isSelectedHidden;
            }

            if(!this.signageListsToShowHide || this.signageListsToShowHide.length == 0) return;

            this.isSignageListsLoading = true;

            for(const list of this.signageListsToShowHide) {
                list.hideListFromDisplay = hide;
                const results = await signageListState.updateSignageList(list);
                
                if(!results.success) {
                    ElMessage({
                        message: `Signage list update failed`,
                        type: 'error',
                    });
                    this.isSignageListsLoading = false;
                    return;
                }
            }

            ElMessage({
                message: `Successfully updated signage list${ this.selectedSignageLists.length > 1 ? 's' : '' }.`,
                type: 'success',
            });

            this.isSignageListsLoading = false;

            this.selectedSignageLists = [];
            this.signageListsToShowHide = [];
            
        },

        onScheduleModalClose(){
            this.isScheduleModalVisible = false;
        },

        onScheduleModalClosed(){
            if(this.isSchedulingSingleList) {
                this.selectedSignageLists = this.selectedSignageListsSwap;
            }
            (this.$refs['scheduleForm'] as any).clearValidate();
        },

        async onScheduleSaveClick() {
            let isValid = false;

            try {
                isValid = await (this.$refs['scheduleForm'] as any).validate();
            } catch (error: any) {
                let errorHTML = '';
                for(const key in error) {
                    for(const err of error[key]) {
                        errorHTML = `${errorHTML}${err.message}<br>`;
                    }
                }

                ElMessage({
                    dangerouslyUseHTMLString: true,
                    message: errorHTML,
                    type: 'error',
                });

                return;
            }

            if(!isValid) return;

            const properties = {
                isDayEnabled: this.massScheduleSettings.isDayEnabled,
                isDateEnabled: this.massScheduleSettings.isDateEnabled,
                isTimeEnabled: this.massScheduleSettings.isTimeEnabled,
                scheduledTimeSet: this.massScheduleSettings.scheduledTimeSet,
                activeDaysOfWeek: this.massScheduleSettings.activeDaysOfWeek,
                startTime: this.massScheduleSettings.startTime ? new Date(`2022/1/1 ${this.massScheduleSettings.startTime.hours}:${this.massScheduleSettings.startTime.minutes}:${this.massScheduleSettings.startTime.seconds}`).toTimeString().split(' ')[0] as any : new Date(`2022/1/1 00:00:00`).toTimeString().split(' ')[0] as any,
                endTime: this.massScheduleSettings.endTime ? new Date(`2022/1/1 ${this.massScheduleSettings.endTime.hours}:${this.massScheduleSettings.endTime.minutes}:${this.massScheduleSettings.endTime.seconds}`).toTimeString().split(' ')[0] : new Date(`2022/1/1 00:30:00`).toTimeString().split(' ')[0] as any,
                startDate:this.massScheduleSettings.dates[0],
                endDate:this.massScheduleSettings.dates[1]
            } as any;

            const payload = {
                scheduleProperties: properties as IScheduleProperties,
                signageLists: this.selectedSignageLists as ISignageList[]
            } as IScheduleSignageListPayload;

            this.isSignageScheduleLoading = true;
            const results = await signageListState.scheduleSignageLists(payload);

            if(!results.success) {
                 ElMessage({
                    message: `Error scheduling signage list${ payload.signageLists.length > 1 ? 's' : '' }`,
                    type: 'success',
                });

                this.isScheduleModalVisible = false;
                this.isSignageScheduleLoading = false;
                return;
            }
                
            await signageListState.fetchScheduleConflicts();

            this.isScheduleModalVisible = false;
            this.isSignageScheduleLoading = false;
            this.selectedSignageLists = [];
                
            ElMessage({
                message: `Successfully scheduled ${ payload.signageLists.length } signage list${ payload.signageLists.length > 1 ? 's' : '' }`,
                type: 'success',
            });
        },

        onMassScheduleClick(signageList?: any) {
            if(!this.hasScheduling) {
                return;
            }

            if(!signageList && this.selectedSignageLists.length == 1) {
                signageList = this.selectedSignageLists[0];
            }
            
            if(signageList) {
                this.selectedSignageLists = [];
                this.selectedSignageLists.push(signageList);
            
                this.isSchedulingSingleList = true;
                this.massScheduleSettings = {
                    isDayEnabled: signageList.isDayEnabled,
                    isDateEnabled: signageList.isDateEnabled,
                    isTimeEnabled:signageList.isTimeEnabled,
                    scheduledTimeSet: signageList.scheduledTimeSet,
                    activeDaysOfWeek: signageList.activeDaysOfWeek ? [...signageList.activeDaysOfWeek]  : [],
                    startTime: {
                        hours: signageList.startTime ? signageList.startTime.split(':')[0] : 0,
                        minutes: signageList.startTime ? signageList.startTime.split(':')[1] : 0,
                        seconds: 0
                    },
                    endTime: {
                        hours: signageList.endTime ? signageList.endTime.split(':')[0] : 0,
                        minutes: signageList.endTime ? signageList.endTime.split(':')[1] : 0,
                        seconds: 0
                    },
                    dates: [signageList.startDate, signageList.endDate]
                } as any;

            } else {
                this.massScheduleSettings = {
                    isDayEnabled: false as boolean,
                    isDateEnabled: false as boolean,
                    isTimeEnabled: false as boolean,
                    scheduledTimeSet: false as boolean,
                    activeDaysOfWeek: [] as string[],
                    startTime: null,
                    endTime: null,
                    dates: [] as Date[],
                } as any;
            }
            
            this.isScheduleSaveWarningVisible = this.selectedSignageLists.length > 1 && this.selectedSignageLists.some((signageList: ISignageList) => signageList.scheduledTimeSet);
            this.isScheduleModalVisible = true;
        },

        handleSelectionChange(val:any) {
            this.selectedSignageLists = val;
            this.selectedSignageListsSwap = val;
        },

        onDayOfWeekClick(day: any) {
            if(!this.massScheduleSettings.isDayEnabled || !this.massScheduleSettings.scheduledTimeSet) return;
            const index = this.massScheduleSettings.activeDaysOfWeek.findIndex((d: string) => d === day.value);
            if(index > -1) {
                this.massScheduleSettings.activeDaysOfWeek.splice(index,1);
            } else {
                this.massScheduleSettings.activeDaysOfWeek.push(day.value);
            }
        },

        onRowClick(row: any) {
            this.$router.push('/signage/' + row.id);
        },

        checkRooms(rule: any, value: any, callback: any)  {
            if(!this.newListRooms.length && !this.isSystemRole && roleState.userRole && !roleState.userRole.roomIds.includes('*')) {
                callback(new Error('A Ditto room must be added to the role'))
            } else {
                callback() 
            }
        },

        onContextClick(el: any) {
            document.addEventListener('click', this.closeContextMenu, true);
            if(el.id == this.openSignageItemContextId) {
                this.closeContextMenu();
            } else {
                this.openSignageItemContextId = el.id;
            }
        },

        closeContextMenu(){
            this.$nextTick(()=> {
                this.openSignageItemContextId = null;
                document.removeEventListener('click', this.closeContextMenu, true);  
            });
        },
        
        async createSignageList() {
            // Validate the form
            let isValid = false;

            try{
                isValid = await (this as any).$refs['signageListCreationForm'].validate();
            }
            catch (error: any) {
             let errorHTML = '';
                for(const key in error) {
                    for(const err of error[key]) {
                        errorHTML = `${errorHTML}${err.message}<br>`;
                    }
                }

                ElMessage({
                    dangerouslyUseHTMLString: true,
                    message: errorHTML,
                    type: 'error',
                });
            }

            if(!isValid) return;


            let newList = {} as ISignageList;

            // Set defaults for list
            newList.name = this.signageListDraft.name;
            newList.listType = this.signageListDraft.listType;
            newList.rooms = this.newListRooms;
            newList.signageItems = [];
        
            this.isNewSignageListLoading = true;

            let storeResponse = await signageListState.createSignageList(newList);
            if(!storeResponse.success) {
                ElMessage({
                    message: `Error creating signage list`,
                    type: 'error',
                });
                this.isNewSignageListLoading = false;
                return;
            }

            ElMessage({
                    message: `Signage list created`,
                    type: 'success',
                });

            this.isCreationModalVisible = false;
            this.isNewSignageListLoading = false;
            this.signageListDraft = {
                name:'',
                listType: '',
                rooms: []
            }; 
        },

        async roomSelectionChanged(rooms: any) {
            const selectedRooms = rooms.filter((room: any)=> room.selected);
            
            const selectedRoomsId = selectedRooms.map((a: any)=> {
                return a.id;
            });

            let hydratedSelectedRooms = (this as any).allRooms.filter((room: any)=> {
                return selectedRoomsId.includes(room.id);
            });

            hydratedSelectedRooms = [...hydratedSelectedRooms, ...this.noAccessRooms];

            this.isSetRoomsLoading = true;

            try {
                const resp = await signageListState.setRoomList({...this.sigangeListToMutateRooms, rooms: hydratedSelectedRooms as any } as ISignageList);

                this.isSetRoomsLoading = false;
                ElMessage({
                    message: `${ resp.data ? resp.data.name : '' } rooms updated`,
                    type: 'success',
                });
            }
            catch {
                ElMessage({
                    message: `Error updating rooms.`,
                    type: 'error',
                });
            }
        },

        mapRooms(signageList: ISignageList) : IModelSelectionPool[] {
            let roomsMap: IModelSelectionPool[] = (this as any).allRooms.map((room: IRoom) =>{
                return {
                    id: room.id,
                    name: room.roomName,
                    selected: signageList.rooms.map((r) => r.id).includes(room.id),
                    disabled: false
                }
            });

            this.noAccessRooms = signageList.rooms.filter( (x: any) => { return !roomsMap.map(y => y.id).includes(x.id) });

            const noAccessRoomsMap: IModelSelectionPool[] = this.noAccessRooms.map((room: IRoom) =>{
                return {
                    id: room.id,
                    name: room.roomName,
                    selected: true,
                    disabled: true
                } as any
            });

            roomsMap = [...roomsMap, ...noAccessRoomsMap];

            return roomsMap.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        },
        
        toggleManageRooms(signageList: ISignageList) {
            this.sigangeListToMutateRooms = signageList;

            const roomsMap = this.mapRooms(signageList);
            
            this.showNoAccessRoomMessage = roomsMap.some((x: any) => x.disabled == true);

            (this as any).$refs['roomModelSelector'].activate(roomsMap);
        },

        setListCreationRooms(rooms: any) {
            const selectedRooms = rooms.filter((room: any)=> room.selected);
            
            const selectedRoomsId = selectedRooms.map((a: any)=> {
                return a.id;
            });

            const hydratedSelectedRooms = (this as any).allRooms.filter((room: any)=> {
                return selectedRoomsId.includes(room.id);
            });

            this.newListRooms = hydratedSelectedRooms;
        },

        async toggleCreateList() {
            this.isCreationModalVisible = true;
            const roomsMap: IModelSelectionPool[] = await (this as any).allRooms.map((room: IRoom) =>{
                return {
                    id: room.id,
                    name: room.roomName,
                    selected: false
                }
            });
            (this as any).$refs['roomSelector'].activate(roomsMap);
        },

        deleteSignageListPrompt(signageList?: ISignageList | null) {
            if(signageList) {
                this.signageListsToDelete = [signageList];
            }
            else {
                if(this.selectedSignageLists && this.selectedSignageLists.length > 0) {
                    this.signageListsToDelete = this.selectedSignageLists;
                }
            }

            this.deleteConfirmationModal = true;
        },

        async deleteSignageList() {
            if(!this.signageListsToDelete || this.signageListsToDelete.length == 0) {
                this.deleteConfirmationModal = false;
                return;
            } 
            
            this.isSignageDeleteLoading = true;
            this.isSignageListsLoading = true;

            for(const list of this.signageListsToDelete) {
                const results = await signageListState.deleteSignageList(list as ISignageList);
               
                if(!results.success) {
                    ElMessage({
                        message: `Signage list deletion failed`,
                        type: 'error',
                    });
                    this.isSignageListsLoading = false;
                    this.deleteConfirmationModal = false;
                    return;
                }
            }
            
            this.selectedSignageLists = [];
            this.signageListsToDelete = [];

            ElMessage({
                message: `Successfully deleted signage list${ this.selectedSignageLists.length > 1 ? 's' : '' }.`,
                type: 'success',
            });

            this.isSignageListsLoading = false;
            this.deleteConfirmationModal = false;
            this.isSignageDeleteLoading = false;
            
            await organizationState.fetchSignageAssetQuota() 
        },

        onCancelListDeletion(){
            this.deleteConfirmationModal = false;
        },

        onCancelListCreation(){
            this.isCreationModalVisible = false;
            (this.$refs as any)['signageListCreationForm'].clearValidate();
            this.newListRooms = [];
            this.signageListDraft = {
                name:'',
                listType: '',
                rooms: []
            }; 
        },

        isListScheuleConflicted(listId: string): boolean {
            const conflict = signageListState.hasSchedultConflict(listId);
            return conflict;
        },

        formatListType(type: string): string {
            if(type === 'googleSlides') {
                return 'Google Slides';
            }
            return 'Standard';
        }
    },

    inject: [
        'windowWidth'
    ],

    computed: {
        isSelectedHidden(): boolean {
            const hidden = this.selectedSignageLists.filter(x => x.hideListFromDisplay)
            if(this.selectedSignageLists.length > 0 && hidden.length == this.selectedSignageLists.length) {
                return true;
            }
            return false;
        },

        showHideText() {
            if(this.isSelectedHidden == true) { return 'Show'; }
            return 'Hide';
        },

        signageListCreationFormRules(): any {
            const isRequired = !this.isSystemRole;
                return {
                name: [
                    {
                        required: true,
                        message: 'Please enter a name.',
                        trigger: 'blur'
                    },
                ],
                rooms: [{ required: isRequired, validator: this.checkRooms, trigger: 'change' }],
            }
        },

        isSystemRole()  : boolean {
            return roleState.isSystemRole;
        },

        allSignageLists(): ISignageList[] | undefined {
            const lists = signageListState.reactiveAllSignageLists.filter(x => !x.hideListFromDisplay).sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
            const hiddenLists = signageListState.reactiveAllSignageLists.filter(x => x.hideListFromDisplay).sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
            return [...lists, ...hiddenLists];
        },

        filteredSignageLists(): ISignageList[] | undefined {
            if(this.signageListsFilter) {
                const searchValue = this.signageListsFilter.toLowerCase();
                return (this as any).allSignageLists.filter((signageList: ISignageList)=> {
                    const signageName = signageList.name.toLowerCase();
                    return  signageName.includes(searchValue);
                });
            } else {
                return this.allSignageLists;
            }
        },

        assetQuotaPercentage(): any {
            if(!organizationState.digitalSignageStorageQuota.usedQuota || !organizationState.digitalSignageStorageQuota.totalQuota) return 0;
            return (organizationState.digitalSignageStorageQuota.usedQuota/organizationState.digitalSignageStorageQuota.totalQuota)*100;
        },

        assetCapacityGb() {
            return (organizationState.digitalSignageStorageQuota.totalQuota / 1024 / 1024 / 1024);
        },

        usedStorageToSize() {
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
            let bytes = organizationState.digitalSignageStorageQuota.usedQuota;

            if (bytes === 0) return '0'
            const i = Math.floor(Math.log(bytes) / Math.log(1024));
            if (i === 0) return `${bytes} ${sizes[i]})`
            return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
        },

        isRoomRestricted(): boolean {
            if(!roleState.isSystemRole && roleState.userRole && !roleState.userRole.roomIds.includes('*')) {
                return true;
            }

            return false;
        },

        selectedListHasNoAccessRooms(): boolean {
            if(roleState.userRole && roleState.userRole.roomIds.includes('*')) {
                return false;
            }
            
            if(this.signageListsToDelete) {
                let hasDisabledRoom = false as boolean;
                this.signageListsToDelete.forEach((list: ISignageList) => {
                    const roomsMap = this.mapRooms(list);
                    if(roomsMap.some((x: any) => x.disabled == true)) {
                        hasDisabledRoom = true;
                    }
                });
                
                return hasDisabledRoom;      
            }
            
            return false;
        },

        showTierCallout(): boolean {
            if (this.filteredSignageLists && this.filteredSignageLists.length > 0 && (!tierState.orgTier.sigangeSchedulingEnabled || !tierState.orgTier.sigangeListManagementEnabled)) {
                return true;
            }

            return false;
        },

        upgradeText(): string {
            return 'Upgrade your Ditto account to unlock this feature.';
        }
    },  


    async created () {
        signageListState = getModule(SignageListState);
        roomState = getModule(RoomState);
        userState = getModule(UserState);
        organizationState = getModule(OrganizationState);
        roleState = getModule(RoleState);
        tierState = getModule(TierState);

        this.isSignageListsLoading = true;
        this.isSignageQuotaLoading = true;

        await Promise.all([
            signageListState.fetchSignageLists(true).then(()=>{ this.isSignageListsLoading = false }),
            roomState.fetchRoomsDDL(true),
            userState.fetchMe(),
            organizationState.fetchSignageAssetQuota(), 
            roleState.fetchUserRole(),
            signageListState.fetchScheduleConflicts(),
            tierState.fetchTier()
        ]);

        this.hasScheduling = tierState.orgTier.sigangeSchedulingEnabled;
        this.hasListManagement = tierState.orgTier.sigangeListManagementEnabled;
        this.hasGoogleSlides = tierState.orgTier.signageGoogleSlidesEnabled;
        this.allRooms = roomState.nonLegacyRooms;
        this.isSignageQuotaLoading = false;
    }
});
