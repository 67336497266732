import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "room-background" }
const _hoisted_2 = {
  ref: "roomBackgroundCanvas",
  id: "roomBackgroundCanvas",
  width: "5120",
  height: "2880",
  style: {"display":"none","position":"absolute"}
}
const _hoisted_3 = {
  ref: "roomBackgroundCanvasImage",
  id: "roomBackgroundCanvasImage",
  alt: "Generating Ditto desktop background...",
  width: "2560",
  height: "1440"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("canvas", _hoisted_2, null, 512),
    _createElementVNode("img", _hoisted_3, null, 512),
    _createElementVNode("span", {
      class: "roomName",
      ref: "roomName",
      id: "roomName",
      style: {"visibility":"hidden","color":"#fff","font-size":"44px","font-family":"Helvetica"}
    }, "For " + _toDisplayString(_ctx.room.roomName), 513),
    _createElementVNode("span", {
      class: "roomCode",
      ref: "roomCode",
      id: "roomCode",
      style: {"visibility":"hidden","color":"#fff","font-size":"44px","font-family":"Helvetica"}
    }, "Visit http://connect.goditto.com/" + _toDisplayString(_ctx.room.roomCodeWithoutWhitespace), 513)
  ]))
}